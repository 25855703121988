import React, { FC } from "react";
import NormalButton from "components/Buttons/NormalButton";
import { Select, Text } from "components/Form";

const ProductShowModal: FC<{
  setShowModal?: Function;
  setShowEditModal?: Function;
  handleSubmit: Function;
  productsCategoryFormHandler: Function;
  errors: any;
  register: Function;
  id?: number;
  defaultValue?: string;
  productType: string;
  selectOptions: { id: number; name: string }[];
  loading: boolean;
}> = ({
  errors,
  handleSubmit,
  productsCategoryFormHandler,
  register,
  setShowModal,
  setShowEditModal,
  id,
  defaultValue,
  productType,
  selectOptions,
  loading,
}) => {
  return (
    <div className="fixed inset-0 bg-transparent z-50 p-2 md:flex md:flex-row md:justify-center md:items-center">
      <div
        className="fixed inset-0 bg-black/60 -z-20"
        onClick={() => {
          if (setShowModal) {
            setShowModal(false);
          }
          if (setShowEditModal) {
            setShowEditModal(false);
          }
        }}
      />
      <div className="w-[300px] sm:w-[560px] bg-[#e4e6e9] shadow-[0_0_64px_#000] mx-auto h-fit rounded-b-[6px]">
        <form onSubmit={handleSubmit(productsCategoryFormHandler)}>
          <h3 className="bg-[#0078AE] max-w-[600px] text-[#000] font-semibold flex justify-between py-2 px-2">
            <span className="text-[16px] leading-5">
              {productType === "productsOption"
                ? id
                  ? "Editing Product Option"
                  : "New Product Option"
                : id
                  ? "Editing Category"
                  : "New Category"}
            </span>
            <span className="inline-flex justify-center items-center text-red-600">
              <i
                onClick={() => {
                  if (setShowModal) {
                    setShowModal(false);
                  }
                  if (setShowEditModal) {
                    setShowEditModal(false);
                  }
                }}
                className="icon-cancel cursor-pointer text-white"
              />
            </span>
          </h3>
          <div className="space-y-5 pt-5 px-5 pb-5 ">
            <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-[130px_300px]">
              <label
                htmlFor="title"
                className={`text-sm leading-5 cursor-pointer text-black`}
              >
                Title
              </label>
              <div className="flex flex-col gap-y-1">
                <Text
                  errors={errors}
                  name="title"
                  register={register}
                  hideError
                  required
                  hideIcon
                  className="bg-[#ffffff] text-sm"
                  defaultName={defaultValue ? defaultValue : ""}
                />
                <p
                  className={`${
                    errors?.title?.message ? "block" : "hidden"
                  } text-[#b94a48] text-sm whitespace-nowrap`}
                >
                  Title can't be blank
                </p>
              </div>
            </div>
            <div
              className={`grid grid-cols-1 gap-y-1 sm:grid-cols-[130px_300px] ${
                id ? "hidden" : "block"
              }`}
            >
              <label
                htmlFor="parent"
                className="cursor-pointer text-sm leading-5"
              >
                Parent
              </label>
              <Select
                errors={errors}
                name="parent"
                register={register}
                hideError
                classForSelect="focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] bg-[#DCDCDC]"
                optionsWithObject={selectOptions}
              />
            </div>
            <NormalButton
              buttonName={
                productType === "productsOption"
                  ? id
                    ? "Update Product option"
                    : "Create Product option"
                  : id
                    ? "Update Product category"
                    : "Create Product category"
              }
              className="border border-black rounded px-2 sm:ml-[130px] hover:bg-[rgb(239,239,239)]"
              buttonType="submit"
              icon={loading ? "icon-spin4" : ""}
              loading={loading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProductShowModal;
