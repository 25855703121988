import { gql } from "@apollo/client";

export const OLD_USER_NEW_USERS_ITEM_QUERY = gql`
  query oldUserNewUser($id: Int!) {
    oldUserNewUser(id: $id) {
      id
      oldUser
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        userName
      }
    }
  }
`;

export const OLD_USER_NEW_USERS_COLLECTION_QUERY = gql`
  query oldUserNewUsers {
    oldUserNewUsers {
      id
      oldUser
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        userName
      }
    }
  }
`;
export const OLD_USER_NEW_USERS_FILTER_QUERY = gql`
  query filterOldUserNewUsers(
    $id: FilterOldUserNewUserId
    $oldUser: FilterOldUserNewUserOldUser
    $user: FilterOldUserNewUserUser
    $limit: Int
    $page: Int
    $createdAt: FilterOldUserNewUserDateCreatedAt
    $updatedAt: FilterOldUserNewUserDateUpdatedAt
    $sortBy: OldUserNewUsersSortBy
    $globalSearch: String
  ) {
    filterOldUserNewUsers(
      id: $id
      limit: $limit
      page: $page
      oldUser: $oldUser
      createdAt: $createdAt
      updatedAt: $updatedAt
      sortBy: $sortBy
      user: $user
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        oldUser
        createdAt
        updatedAt
        user {
          id
          userName
          firstName
          lastName
        }
      }
      totalNumberOfItems
    }
  }
`;
export const USER_EMAIL_DATA_ITEM_QUERY = gql`
  query userEmailData($id: Int!) {
    userEmailData(id: $id) {
      id
      domain
      userName
      password
      address
      port
      authentication
      sender
      lastUid
      user {
        id
        firstName
        lastName
        userName
      }
    }
  }
`;

export const USERS_EMAIL_DATA_COLLECTION_QUERY = gql`
  query usersEmailData {
    usersEmailData {
      id
      domain
      userName
      password
      address
      port
      authentication
      sender
      lastUid
      user {
        id
        firstName
        lastName
        userName
      }
    }
  }
`;
export const ROLE_ITEM_QUERY = gql`
  query role($id: Int!) {
    role(id: $id) {
      id
      name
      createdAt
      updatedAt
      users {
        id
        firstName
        lastName
        userName
      }
    }
  }
`;

export const ROLES_COLLECTION_QUERY = gql`
  query roles {
    roles {
      id
      name
      createdAt
      updatedAt
      users {
        id
        firstName
        lastName
        userName
      }
    }
  }
`;
export const ROLE_FILTER_QUERY = gql`
  query filterRoles(
    $id: FilterRolesId
    $name: FilterRolesName
    $createdAt: FilterRolesDateCreatedAt
    $updatedAt: FilterRolesDateUpdatedAt
    $limit: Int
    $page: Int
    $sortBy: RolesSortBy
    $globalSearch: String
  ) {
    filterRoles(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      limit: $limit
      page: $page
      sortBy: $sortBy
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        createdAt
        updatedAt
        users {
          id
          firstName
          lastName
          userName
        }
      }
      totalNumberOfItems
    }
  }
`;

export const USER_ITEM_QUERY = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      email
      rememberCreatedAt
      signInCount
      currentSignInAt
      lastSignInAt
      currentSignInIp
      lastSignInIp
      createdAt
      updatedAt
      login
      userName
      firstName
      lastName
      phone
      emailSignature
      isDeleted
      roles {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const USERS_COLLECTION_QUERY = gql`
  query users {
    users {
      id
      email
      rememberCreatedAt
      signInCount
      currentSignInAt
      lastSignInAt
      currentSignInIp
      lastSignInIp
      createdAt
      updatedAt
      login
      userName
      firstName
      lastName
      phone
      emailSignature
      isDeleted
      roles {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const USERS_FILTER_QUERY = gql`
  query filterUsers(
    $id: FilterUsersId
    $email: FilterUsersEmail
    $lastSignInAt: FilterUsersLastSignInAt
    $limit: Int
    $page: Int
    $currentSignInIp: FilterUsersCurrentSignInIp
    $lastSignInIp: FilterUsersLastSignInIp
    $userName: FilterUsersUserName
    $isDeleted: Boolean
    $sortBy: UsersSortBy
    $globalSearch: String
  ) {
    filterUsers(
      id: $id
      email: $email
      lastSignInAt: $lastSignInAt
      currentSignInIp: $currentSignInIp
      limit: $limit
      page: $page
      lastSignInIp: $lastSignInIp
      userName: $userName
      isDeleted: $isDeleted
      sortBy: $sortBy
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        email
        rememberCreatedAt
        signInCount
        currentSignInAt
        lastSignInAt
        currentSignInIp
        lastSignInIp
        createdAt
        updatedAt
        login
        userName
        firstName
        lastName
        phone
        emailSignature
        isDeleted
      }
      totalNumberOfItems
    }
  }
`;

export const COUNTRY_ITEM_QUERY = gql`
  query country($id: Int!) {
    country(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const COUNTRIES_COLLECTION_QUERY = gql`
  query countries {
    countries {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const COUNTRIES_FILTER_QUERY = gql`
  query filterCountries(
    $id: FilterCountryId
    $name: FilterCountryName
    $createdAt: FilterCountryDateCreatedAt
    $updatedAt: FilterCountryDateUpdatedAt
    $sortBy: CountrySortBy
    $limit: Int
    $page: Int
    $globalSearch: String
  ) {
    filterCountries(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      limit: $limit
      page: $page
      sortBy: $sortBy
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const STATE_ITEM_QUERY = gql`
  query state($id: Int!) {
    state(id: $id) {
      id
      name
      createdAt
      alias
      updatedAt
      tax
    }
  }
`;

export const STATES_COLLECTION_QUERY = gql`
  query states {
    states {
      id
      name
      createdAt
      alias
      updatedAt
      tax
    }
  }
`;
export const STATES_FILTER_QUERY = gql`
  query filterStates(
    $id: FilterStateId
    $name: FilterStateName
    $tax: FilterStateTax
    $alias: FilterStateAlias
    $createdAt: FilterStateDateCreatedAt
    $updatedAt: FilterStateDateUpdatedAt
    $sortBy: StatesSortBy
    $limit: Int
    $page: Int
    $globalSearch: String
  ) {
    filterStates(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      sortBy: $sortBy
      globalSearch: $globalSearch
      tax: $tax
      limit: $limit
      page: $page
      alias: $alias
    ) {
      dataCollection {
        id
        name
        createdAt
        alias
        updatedAt
        tax
      }
      totalNumberOfItems
    }
  }
`;
export const TIMEZONE_ITEM_QUERY = gql`
  query timeZone($id: Int!) {
    timeZone(id: $id) {
      id
      code
      zone
      createdAt
      updatedAt
    }
  }
`;

export const TIMEZONES_COLLECTION_QUERY = gql`
  query timeZones {
    timeZones {
      id
      code
      zone
      createdAt
      updatedAt
    }
  }
`;
export const TIMERZONES_FILTER_QUERY = gql`
  query filterTimeZones(
    $id: FilterTimeZonesId
    $code: FilterTimeZonesCode
    $zone: FilterTimeZonesZone
    $createdAt: FilterTimeZonesDateCreatedAt
    $updatedAt: FilterTimeZonesDateUpdatedAt
    $sortBy: TimeZonesSortBy
    $limit: Int
    $page: Int
    $globalSearch: String
  ) {
    filterTimeZones(
      id: $id
      code: $code
      createdAt: $createdAt
      updatedAt: $updatedAt
      sortBy: $sortBy
      limit: $limit
      page: $page
      globalSearch: $globalSearch
      zone: $zone
    ) {
      dataCollection {
        id
        code
        zone
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const CRMSETTING_ITEM_QUERY = gql`
  query crmSetting($id: Int!) {
    crmSetting(id: $id) {
      id
      name
      value
      valueText
      createdAt
      updatedAt
    }
  }
`;

export const CRMSETTINGS_COLLECTION_QUERY = gql`
  query crmSettings {
    crmSettings {
      id
      name
      value
      valueText
      createdAt
      updatedAt
    }
  }
`;

export const CRMSETTINGS_PAYMENT_EMAIL_TPLS = gql`
  query paymentEmailTpls {
    paymentEmailTpls {
      id
      name
      value
      valueText
      createdAt
      updatedAt     
    }
  }
`;

export const CRMSETTINGS_PAYMENT_EMAIL_TPLS_RESULT = gql`
  query paymentEmailTplsResult {
    paymentEmailTplsResult {
      id
      name
      value
      valueText
      createdAt
      updatedAt     
    }
  }
`;

export const CRMSETTING_FILTER_QUERY = gql`
  query filterCrmSettings(
    $id: FilterCrmSettingId
    $value: FilterCrmSettingValue
    $createdAt: FilterCrmSettingCreatedAt
    $updatedAt: FilterCrmSettingUpdatedAt
    $sortBy: CrmSettingSortBy
    $globalSearch: String
    $name: FilterCrmSettingName
    $valueText: FilterCrmSettingValueText
    $limit: Int
    $page: Int
  ) {
    filterCrmSettings(
      id: $id
      value: $value
      createdAt: $createdAt
      updatedAt: $updatedAt
      sortBy: $sortBy
      globalSearch: $globalSearch
      name: $name
      limit: $limit
      page: $page
      valueText: $valueText
    ) {
      dataCollection {
        id
        name
        value
        valueText
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const DOMAIN_IGNORE_LIST_ITEM_QUERY = gql`
  query domainIgnoreList($id: Int!) {
    domainIgnoreList(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DOMAIN_IGNORE_LIST_COLLECTION_QUERY = gql`
  query domainIgnoreLists {
    domainIgnoreLists {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const DOMAIN_IGNORE_LIST_FILTER_QUERY = gql`
  query filterDomainIgnoreLists(
    $id: FilterDomainIgnoreListId
    $name: FilterDomainIgnoreListName
    $createdAt: FilterDomainIgnoreListCreatedAt
    $updatedAt: FilterDomainIgnoreListUpdatedAt
    $sortBy: DomainIgnoreListSortBy
    $limit: Int
    $page: Int
    $globalSearch: String
  ) {
    filterDomainIgnoreLists(
      id: $id
      name: $name
      createdAt: $createdAt
      limit: $limit
      page: $page
      updatedAt: $updatedAt
      sortBy: $sortBy
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const IGNORE_WORD_SEARCH_ITEM_QUERY = gql`
  query ignoreWordSearch($id: Int!) {
    ignoreWordSearch(id: $id) {
      id
      word
      createdAt
      updatedAt
    }
  }
`;

export const IGNORE_WORD_SEARCHES_COLLECTION_QUERY = gql`
  query ignoreWordSearches {
    ignoreWordSearches {
      id
      word
      createdAt
      updatedAt
    }
  }
`;
export const IGNORE_WORD_SEARCHES_FILTER_QUERY = gql`
  query filterIgnoreWordSearches(
    $id: FilterIgnoreWordSearchId
    $word: FilterIgnoreWordSearchWord
    $createdAt: FilterIgnoreWordSearchCreatedAt
    $updatedAt: FilterIgnoreWordSearchUpdatedAt
    $sortBy: IgnoreWordSearchSortBy
    $limit: Int
    $page: Int
    $globalSearch: String
  ) {
    filterIgnoreWordSearches(
      id: $id
      word: $word
      createdAt: $createdAt
      updatedAt: $updatedAt
      limit: $limit
      page: $page
      sortBy: $sortBy
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        word
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const SYSTEM_ERROR_ITEM_QUERY = gql`
  query systemError($id: Int!) {
    systemError(id: $id) {
      id
      name
      text
      data
      createdAt
      updatedAt
    }
  }
`;

export const SYSTEM_ERRORS_COLLECTION_QUERY = gql`
  query systemErrors {
    systemErrors {
      id
      name
      text
      data
      createdAt
      updatedAt
    }
  }
`;
export const SYSTEM_ERRORS_FILTER_QUERY = gql`
  query filterSystemErrors(
    $id: FilterSystemErrorId
    $name: FilterSystemErrorName
    $text: FilterSystemErrorText
    $data: FilterSystemErrorData
    $createdAt: FilterSystemErrorCreatedAt
    $limit: Int
    $page: Int
    $updatedAt: FilterSystemErrorUpdatedAt
    $sortBy: SystemErrorSortBy
    $globalSearch: String
  ) {
    filterSystemErrors(
      id: $id
      name: $name
      createdAt: $createdAt
      limit: $limit
      page: $page
      updatedAt: $updatedAt
      sortBy: $sortBy
      text: $text
      data: $data
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        text
        data
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const USER_SETTING_ITEM_QUERY = gql`
  query userSetting($id: Int!) {
    userSetting(id: $id) {
      id
      name
      value
      user {
        id
        firstName
        lastName
        userName
      }
      createdAt
      updatedAt
    }
  }
`;

export const USER_SETTINGS_COLLECTION_QUERY = gql`
  query usersSettings {
    usersSettings {
      id
      name
      value
      user {
        id
        firstName
        lastName
        userName
      }
      createdAt
      updatedAt
    }
  }
`;
export const PRODUCT_ITEM_QUERY = gql`
  query product($id: Int!) {
    product(id: $id) {
      id
      name
      price
      description
      priceMethod
      priceOffset
      link
      coverImageName
      coverImageUid
      coverImageFile
      createdAt
      updatedAt
    }
  }
`;

export const PRODUCTS_COLLECTION_QUERY = gql`
  query products {
    products {
      id
      name
      price
      description
      priceMethod
      priceOffset
      coverImageName
      coverImageUid
      coverImageFile
      link
      createdAt
      updatedAt
    }
  }
`;
export const PRODUCT_FILTER_QUERY = gql`
  query filterProducts(
    $id: FilterProductsId
    $name: FilterProductsName
    $createdAt: FilterProductsDateCreatedAt
    $updatedAt: FilterProductsDateUpdatedAt
    $price: FilterProductsPrice
    $description: FilterProductsDescription
    $priceMethod: FilterProductsPriceMethods
    $priceOffset: FilterProductsPriceOffset
    $coverImageName: String
    $productPriceTable: FilterProductsPriceTable
    $link: FilterProductsLink
    $productCategory: FilterProductsProductCategory
    $globalSearch: String
    $limit: Int
    $page: Int
    $sortBy: ProductSortBy
  ) {
    filterProducts(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      price: $price
      description: $description
      priceMethod: $priceMethod
      priceOffset: $priceOffset
      coverImageName: $coverImageName
      limit: $limit
      page: $page
      productPriceTable: $productPriceTable
      link: $link
      sortBy: $sortBy
      productCategory: $productCategory
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        price
        description
        priceMethod
        priceOffset
        coverImageName
        coverImageUid
        coverImageFile
        link
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const FILTER_PRODUCT = gql`
  query filterProducts(
    $id: FilterProductsId
    $name: FilterProductsName
    $createdAt: FilterProductsDateCreatedAt
    $updatedAt: FilterProductsDateUpdatedAt
    $price: FilterProductsPrice
    $description: FilterProductsDescription
    $priceMethod: FilterProductsPriceMethods
    $priceOffset: FilterProductsPriceOffset
    $coverImageUid: String
    $productPriceTable: FilterProductsPriceTable
    $link: FilterProductsLink
    $productCategory: FilterProductsProductCategory
    $globalSearch: String
    $sortBy: ProductSortBy
  ) {
    filterProducts(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      price: $price
      description: $description
      priceMethod: $priceMethod
      priceOffset: $priceOffset
      coverImageUid: $coverImageUid
      productPriceTable: $productPriceTable
      link: $link
      sortBy: $sortBy
      productCategory: $productCategory
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        createdAt
        updatedAt
        priceMethod
        price
        description
      }
      totalNumberOfItems
    }
  }
`;
export const HIGHLIGHT_ITEM_QUERY = gql`
  query highlight($id: Int!) {
    highlight(id: $id) {
      id
      name
      color
      highlightType
      priority
      createdAt
      updatedAt
    }
  }
`;

export const HIGHLIGHTS_COLLECTION_QUERY = gql`
  query highlights {
    highlights {
      id
      name
      color
      highlightType
      priority
      createdAt
      updatedAt
    }
  }
`;
export const HIGHLIGHT_FILTER_QUERY = gql`
  query filterHighlights(
    $id: FilterHighlightsId
    $name: FilterHighlightsName
    $createdAt: FilterHighlightsDateCreatedAt
    $updatedAt: FilterHighlightsDateUpdatedAt
    $limit: Int
    $page: Int
    $highlightType: FilterHighlightsHighlightType
    $priority: FilterHighlightsPriority
    $color: FilterHighlightsColor
    $sortBy: HighlightSortBy
    $globalSearch: String
  ) {
    filterHighlights(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      sortBy: $sortBy
      highlightType: $highlightType
      priority: $priority
      limit: $limit
      page: $page
      color: $color
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        color
        highlightType
        priority
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const INDUSTRY_TAG_ITEM_QUERY = gql`
  query industryTag($id: Int!) {
    industryTag(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const INDUSTRY_TAGS_COLLECTION_QUERY = gql`
  query industryTags {
    industryTags {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const INDUSTRY_TAGS_FILTER_QUERY = gql`
  query filterIndustryTags(
    $id: FilterIndustryTagId
    $name: FilterIndustryTagName
    $createdAt: FilterIndustryTagCreatedAt
    $updatedAt: FilterIndustryTagUpdatedAt
    $sortBy: IndustryTagSortBy
    $limit: Int
    $page: Int
    $globalSearch: String
  ) {
    filterIndustryTags(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      limit: $limit
      page: $page
      sortBy: $sortBy
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const EXPENSES_ITEM_QUERY = gql`
  query expense($id: Int!) {
    expense(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const EXPENSES_COLLECTION_QUERY = gql`
  query expenses {
    expenses {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const EXPENSES_FILTER_QUERY = gql`
  query filterExpenses(
    $id: FilterExpenseId
    $name: FilterExpenseName
    $limit: Int
    $page: Int
    $createdAt: FilterExpenseDateCreatedAt
    $updatedAt: FilterExpenseDateUpdatedAt
    $sortBy: ExpenseSortBy
    $globalSearch: String
  ) {
    filterExpenses(
      id: $id
      name: $name
      createdAt: $createdAt
      limit: $limit
      page: $page
      updatedAt: $updatedAt
      sortBy: $sortBy
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const QUICK_QUOTE_ADDITIONAL_INFOS_COLLECTION_QUERY = gql`
  query quickQuoteAdditionalInfos {
    quickQuoteAdditionalInfos {
      id
      description
      price
      count
      isDefault
      createdAt
      updatedAt
    }
  }
`;
export const QUICK_QUOTE_ADDITIONAL_INFOS_FILTER_QUERY = gql`
  query filterQuickQuoteAdditionalInfos(
    $id: FilterQuickQuoteAdditionalInfoId
    $description: FilterQuickQuoteAdditionalInfoDescription
    $createdAt: FilterQuickQuoteAdditionalInfoCreatedAt
    $updatedAt: FilterQuickQuoteAdditionalInfoUpdatedAt
    $sortBy: FilterQuickQuoteAdditionalInfoSortBy
    $globalSearch: String
    $price: FilterQuickQuoteAdditionalInfoPrice
    $count: FilterQuickQuoteAdditionalInfoCount
    $isDefault: Boolean
    $limit: Int
    $page: Int
  ) {
    filterQuickQuoteAdditionalInfos(
      id: $id
      description: $description
      createdAt: $createdAt
      updatedAt: $updatedAt
      sortBy: $sortBy
      globalSearch: $globalSearch
      price: $price
      count: $count
      limit: $limit
      page: $page
      isDefault: $isDefault
    ) {
      dataCollection {
        id
        description
        price
        count
        isDefault
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const QUICK_QUOTE_ADDITIONAL_INFOS_ITEM_QUERY = gql`
  query quickQuoteAdditionalInfo($id: Int!) {
    quickQuoteAdditionalInfo(id: $id) {
      id
      description
      price
      count
      isDefault
      createdAt
      updatedAt
    }
  }
`;
export const QUICK_QUOTE_LINE_ITEM_QUERY = gql`
  query quickQuoteLine($id: Int!) {
    quickQuoteLine(id: $id) {
      id
      name
      description
      price
      count
      createdAt
      updatedAt
    }
  }
`;
export const QUICK_QUOTE_LINE_COLLECTION_QUERY = gql`
  query quickQuoteLines {
    quickQuoteLines {
      id
      name
      description
      price
      count
      createdAt
      updatedAt
    }
  }
`;
export const QUICK_QUOTE_LINE_FILTER_QUERY = gql`
  query filterQuickQuoteLines(
    $id: FilterQuickQuoteLineId
    $description: FilterQuickQuoteLineDescription
    $createdAt: FilterQuickQuoteLineCreatedAt
    $updatedAt: FilterQuickQuoteLineUpdatedAt
    $sortBy: FilterQuickQuoteLineSortBy
    $globalSearch: String
    $price: FilterQuickQuoteLinePrice
    $count: FilterQuickQuoteLineCount
    $limit: Int
    $page: Int
    $name: FilterQuickQuoteLineName
  ) {
    filterQuickQuoteLines(
      id: $id
      description: $description
      createdAt: $createdAt
      updatedAt: $updatedAt
      sortBy: $sortBy
      globalSearch: $globalSearch
      price: $price
      count: $count
      name: $name
      limit: $limit
      page: $page
    ) {
      dataCollection {
        id
        name
        description
        price
        count
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
export const ADMIN_DASHBOARD = gql`
  query adminDashBoard {
    adminDashBoard {
      modelName
      lastUsed
      count
      lastUpdatedTime
      progressBarPercent
    }
  }
`;

export const HIGHLIGHT_TYPES = gql`
  query highlightTypes {
    highlightTypes {
      id
      name
    }
  }
`;

export const USER_EMAIL_DATA_FILTER_QUERY = gql`
  query filterUserEmailData(
    $id: FilterUserEmailDataId
    $user: FilterUserEmailDataUser
    $createdAt: FilterUserEmailDataDateCreatedAt
    $updatedAt: FilterUserEmailDataDateUpdatedAt
    $domain: FilterUserEmailDataDomain
    $userName: FilterUserEmailDataUserName
    $password: String
    $limit: Int
    $page: Int
    $address: FilterUserEmailDataAddress
    $port: FilterUserEmailDataPort
    $authentication: FilterUserEmailDataAuthentication
    $sender: FilterUserEmailDataSender
    $lastUid: FilterUserEmailDataLastUid
    $sortBy: UserEmailDataSortBy
    $globalSearch: String
  ) {
    filterUserEmailData(
      id: $id
      userName: $userName
      createdAt: $createdAt
      updatedAt: $updatedAt
      limit: $limit
      page: $page
      domain: $domain
      password: $password
      address: $address
      port: $port
      authentication: $authentication
      sender: $sender
      lastUid: $lastUid
      sortBy: $sortBy
      user: $user
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        domain
        userName
        password
        address
        port
        authentication
        sender
        lastUid
        createdAt
        updatedAt
        user {
          id
          userName
          firstName
          lastName
        }
      }
      totalNumberOfItems
    }
  }
`;

export const USER_SETTINGS_FILTER_QUERY = gql`
  query filterUserSettings(
    $id: FilterUserSettingsId
    $name: FilterUserSettingsName
    $user: FilterUserSettingsUser
    $value: FilterUserSettingsValue
    $createdAt: FilterUserSettingsCreatedAt
    $limit: Int
    $page: Int
    $updatedAt: FilterUserSettingsUpdatedAt
    $sortBy: UserSettingsSortBy
    $globalSearch: String
  ) {
    filterUserSettings(
      id: $id
      name: $name
      createdAt: $createdAt
      limit: $limit
      page: $page
      updatedAt: $updatedAt
      sortBy: $sortBy
      value: $value
      user: $user
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        value
        user {
          id
          firstName
          lastName
          userName
        }
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
  }
`;
