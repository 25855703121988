import { useReactiveVar } from "@apollo/client";
import { setNotification } from "helpers/cache";
import { ReactNode } from "react";
import Notification from "./Notification";

export const NotificationProvider = (props: { children: ReactNode }) => {
  const notification: {
    type: string;
    message: string;
  }[] = useReactiveVar<
    {
      type: string;
      message: string;
    }[]
  >(setNotification);

  return (
    <div>
      <div
        className={
          "notification-wrapper fixed bottom-3 right-3 w-[300px] z-[999]"
        }
      >
        {Array.isArray(notification) &&
          notification?.map(
            (
              notification: {
                type: string;
                message: string;
              },
              index: number,
            ) => {
              return (
                <Notification
                  key={index}
                  type={notification?.type}
                  message={notification?.message}
                />
              );
            },
          )}
      </div>
      {props.children}
    </div>
  );
};

export default NotificationProvider;
