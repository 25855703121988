import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { TRANSACTIONlOGS_COLLECTION_QUERY } from "../../Services/Queries/TransactionLogQuery";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import { Params } from "../../../../types/Main";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import Input from "../../../../components/Form/El/Input";
import Select from "../../../../components/Form/El/Select";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { convertDateToUSFormat } from "../../../../helpers/utils";
import OkImage from "../../../../assets/ok.png";
import CrossCircleImage from "../../../../assets/cross-circle.png";
import MasterCard from "../../../../assets/mastercard.png";
import AmexCard from "../../../../assets/amex.svg";
import VisaCard from "../../../../assets/visa.png";
import DiscoverImage from "../../../../assets/discover.jpg";
import JcbCard from "../../../../assets/jcb.png";
import DinersClubCard from "../../../../assets/diners-club.png";
import EnrouteCard from "../../../../assets/enroute.png";
import MaestroCard from "../../../../assets/maestro-card.png";
import SoloCard from "../../../../assets/solo.png";
import VoyagerCard from "../../../../assets/voyager.png";
import VoidImage from "../../../../assets/void.png";
import RefundImage from "../../../../assets/refund.png";
import HistoryImage from "../../../../assets/history.png";
import TableNew from "../../../../components/Table/TableNew";
import EditQuoteTransactionsPopup from "./EditQuoteTransactionLogsPopup";
import { QuoteEditData } from "../../../../types/Quote";
import { TransactionDetail } from "../../../../types/TransactionDetail";
import { regExpNumber } from "../../../../helpers/RegExp";
import { capitalize } from "lodash";

export interface TransactionDetailsTypes {
  id?: number;
  title: string;
  type: "auth" | "charge" | "refund" | "manual_refund" | "manual_charge";
  amount: number | string | undefined;
  creditCard: number | string;
  holderName: string;
  quoteId?: number;
}

const EditQuoteTransactionLogs: FC<{
  quoteId: number;
  quoteEditData: QuoteEditData;
}> = ({ quoteId, quoteEditData }) => {
  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "id",
        label: "#",
      },
      {
        name: "actions",
        label: "Actions",
      },
      {
        name: "cardHolder",
        label: "Card Holder",
      },
      {
        name: "card",
        label: "Card",
      },
      {
        name: "amount",
        label: "Amount",
      },
      {
        name: "cardNumber",
        label: "Card Number",
      },
      {
        name: "expDate",
        label: "Expiry",
      },
      {
        name: "transaction",
        label: "Transaction",
      },
      {
        name: "status",
        label: "Status",
      },
      {
        name: "dateCreated",
        label: "Created At",
      },
      {
        name: "auth",
        label: "Auth",
      },
      {
        name: "refNum",
        label: "Ref Num",
      },
      {
        name: "ref",
        label: "Cust.Ref",
      },
    ],
    [],
  );

  const [fetchTransactionLog] = useLazyQuery(TRANSACTIONlOGS_COLLECTION_QUERY, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const userDetails: any = useReactiveVar(userContextData);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const [countPages, setCountPages] = useState(0);
  const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [transactionDetail, setTransactionDetail] =
    useState<TransactionDetail>();

  const onVoidTagged = useCallback(
    (
      voidType: string,
      transaction: {
        id: number;
        cardHolder: string;
        cardNumber: string;
        amount: number;
      },
    ) => {
      if (window.confirm(`Are you sure ${voidType} this transaction?`)) {
        setTransactionDetail({
          title: "Transaction",
          type: voidType,
          id: transaction.id,
          holderName: transaction.cardHolder,
          cardNumber: transaction.cardNumber,
          amount: transaction.amount,
        });
      }
    },
    [],
  );
  const [isNeedUpdateLogs, setIsNeedUpdateLogs] = useState(false);
  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    dataCollection.forEach((dataCollectionItem, index) => {
      const {
        id,
        cardHolder,
        cardType,
        amount,
        cardNumber,
        expiry,
        transactionType,
        createdAt,
        refNum,
        customRefNum,
        authorizationNum,
        status,
        message,
      } = dataCollectionItem;

      const getIconCard = () => {
        if (cardType?.includes("Mastercard")) {
          return MasterCard;
        }

        if (cardType?.includes("American Express")) {
          return AmexCard;
        }

        if (cardType?.includes("Visa")) {
          return VisaCard;
        }

        if (cardType?.includes("Discover")) {
          return DiscoverImage;
        }

        if (cardType?.includes("Jcb")) {
          return JcbCard;
        }

        if (cardType?.includes("Diners club")) {
          return DinersClubCard;
        }

        if (cardType?.includes("Enroute")) {
          return EnrouteCard;
        }

        if (cardType?.includes("Maestro")) {
          return MaestroCard;
        }

        if (cardType?.includes("Solo")) {
          return SoloCard;
        }

        if (cardType?.includes("Voyager")) {
          return VoyagerCard;
        }

        return "";
      };

      const iconCard = getIconCard();

      const transactionDetails: { name: string; value: string }[] = message
        .split("\n")
        .map((msg: string) => {
          const msgNameAndValue = msg.split(":");

          return {
            name: msgNameAndValue[0],
            value: msgNameAndValue[1],
          };
        });

      const currentCardNumber = cardNumber ? `************${cardNumber}` : "-";

      currentChildrenForTable.push({
        id: id ?? index,
        row: {
          id: id ?? index,
          actions: (
            <div className="flex flex-row gap-1 flex-wrap">
              {(transactionType === "refund" ||
                transactionType === "charge" ||
                transactionType === "tagged_refund") && (
                <button
                  onClick={() => {
                    onVoidTagged("tagged_void", {
                      cardHolder,
                      cardNumber: currentCardNumber,
                      id,
                      amount,
                    });
                  }}
                >
                  <img
                    src={VoidImage}
                    className="w-[22px] h-[22px] object-contain"
                  />
                </button>
              )}
              {transactionType === "charge" && (
                <button
                  onClick={() => {
                    onVoidTagged("tagged_refund", {
                      cardHolder,
                      cardNumber: currentCardNumber,
                      id,
                      amount,
                    });
                  }}
                >
                  <img
                    src={RefundImage}
                    className="w-[22px] h-[22px] object-contain"
                  />
                </button>
              )}
              {(transactionType === "auth" ||
                transactionType === "tagged_void") && (
                <div>
                  <img
                    src={HistoryImage}
                    className="w-[22px] h-[22px] object-contain"
                  />
                </div>
              )}
            </div>
          ),
          cardHolder: cardHolder ?? "-",
          card: (
            <img
              className="w-[26px] h-[26px] object-cover"
              src={iconCard}
              alt="iconCard"
            />
          ),
          amount: `${parseInt(amount || 0).toFixed(1)} USD`,
          cardNumber: cardNumber ? `************${cardNumber}` : "-",
          expDate: expiry ?? "-",
          transaction: transactionType ?? "-",
          status: (
            <img
              className="w-[22px] h-[22px] object-contain"
              src={status ? OkImage : CrossCircleImage}
              alt=""
            />
          ),
          dateCreated: createdAt ? convertDateToUSFormat(createdAt) : "-",
          auth: authorizationNum ?? "-",
          refNum: refNum ?? "-",
          ref: customRefNum ?? "-",
        },
        expandRow: (
          <ul>
            {transactionDetails.map(
              (transactionDetail, transactionDetailIndex) => (
                <li
                  key={`edit-quote-transaction-log-detail-${index}-${transactionDetailIndex}`}
                >
                  <span className="font-bold">{`${transactionDetail.name}`}</span>
                  {` ${transactionDetail.value?.trim() || ""}`}
                </li>
              ),
            )}
          </ul>
        ),
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);

  const [isLoadingLogs, setIsLoadingLogs] = useState(false);

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const currentParams = {
      pageSize,
      currentPage,
      quoteId,
    };

    onChangeParamsCallback(
      prevFetchSettings,
      currentParams,
      (newParams) => setPrevFetchSettings(newParams),
      () => {
        setIsNeedUpdateLogs(true);
      },
    );
  }, [pageSize, currentPage, quoteId, prevFetchSettings]);

  useEffect(() => {
    if (isNeedUpdateLogs) {
      onFetchLogs();
    }
  }, [isNeedUpdateLogs]);

  const onErrorFetch = () => {
    setCountPages(0);
    setDataCollection([]);
  };

  const onFetchLogs = async () => {
    setIsLoadingLogs(true);
    await fetchTransactionLog({
      variables: {
        quoteId,
        limit: pageSize,
        page: currentPage,
      },
    })
      .then((res) => {
        const { data } = res;
        if (!data) {
          onErrorFetch();
          return;
        }
        const { transactionLogsByQuote } = data;

        if (!transactionLogsByQuote) {
          onErrorFetch();
          return;
        }

        const { dataCollection, totalNumberOfItems } = transactionLogsByQuote;

        if (!dataCollection || !totalNumberOfItems) {
          onErrorFetch();
          return;
        }

        const newCountPages = Math.ceil(totalNumberOfItems / pageSize);

        setDataCollection(dataCollection);
        setCountPages(newCountPages);
      })
      .finally(() => {
        setIsLoadingLogs(false);
      });
  };

  const [amount, setAmount] = useState<number>(0);
  const [manualType, setManualType] = useState<string>("charge");

  return (
    <section>
      <div className="edit-quote__selects flex gap-1">
        <button
          className="btn  btn-small btn-blue"
          onClick={() => {
            setTransactionDetail({
              title: "Auth Transaction",
              type: "auth",
              amount: undefined,
            });
          }}
        >
          Auth
        </button>
        <button
          className="btn  btn-small btn-blue"
          onClick={() => {
            setTransactionDetail({
              title: "Refund Transaction",
              type: "charge",
              amount: undefined,
            });
          }}
        >
          Charge
        </button>
        <button
          className="btn  btn-small btn-warning"
          onClick={() => {
            setTransactionDetail({
              title: "Refund Transaction",
              type: "refund",
              amount: undefined,
            });
          }}
        >
          Refund
        </button>
        <Input
          value={amount}
          onChange={(value) => {
            let currentValue = value;

            if (typeof currentValue === "string") {
              currentValue = +currentValue;
            }

            setAmount(currentValue || 0);
          }}
          name="transaction-amount"
          placeholder="0"
          className="max-w-[200px]"
          pattern={regExpNumber}
          type="number"
        />
        <Select
          value={manualType ? capitalize(manualType) : undefined}
          optionsGroups={[{ options: ["Charge", "Refund"] }]}
          onChange={(value) => {
            if (value && typeof value === "string") {
              setManualType(value.toLowerCase());
            }
          }}
          name="transaction-type"
          className="max-w-[200px]"
        />
        <button
          className="btn  btn-small btn-blue"
          onClick={() => {
            if (manualType === "charge" || manualType === "refund") {
              setTransactionDetail({
                title: `Manual ${capitalize(manualType)} Transaction`,
                type: `manual_${manualType}`,
                amount,
              });
            }
          }}
        >
          Manual Transaction
        </button>
      </div>
      <TableNew
        heading={tableHeading}
        setIsNeedUpdateTableData={() => {}}
        loading={isLoadingLogs}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        children={childrenForTable}
      />
      {!!transactionDetail && (
        <EditQuoteTransactionsPopup
          quoteEditData={quoteEditData}
          transactionDetail={transactionDetail}
          onCloseModal={() => {
            setTransactionDetail(undefined);
          }}
        />
      )}
    </section>
  );
};

export default EditQuoteTransactionLogs;
