import { FC, Fragment, useCallback, useEffect, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useMutation } from "@apollo/client";
import {
  CREATE_IGNORE_WORD_SEARCH,
  UPDATE_IGNORE_WORD_SEARCH,
} from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";

const AdminDashboardEditIgnoreWordSearch: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentWord("");
  }, []);

  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_IGNORE_WORD_SEARCH, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { updateIgnoreWordSearch } = data;

      if (!updateIgnoreWordSearch) return;

      const { id } = updateIgnoreWordSearch;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_IGNORE_WORD_SEARCH, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createIgnoreWordSearch } = data;

      if (!createIgnoreWordSearch) return;

      const { id } = createIgnoreWordSearch;

      onAfterSave(id || 0);
    },
  });

  const [currentWord, setCurrentWord] = useState("");

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { word } = currentDataCollectionItem;

    if (word) {
      setCurrentWord(word);
    }
  }, [currentDataCollectionItem]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            word: currentWord,
          },
        });
      }
      return await createItems({
        variables: {
          word: currentWord,
        },
      });
    };

    await handleSaveById();
  }, [currentWord, currentId]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm = !!currentWord;
    setDisabledSaveButton(!isValidForm);
  }, [currentWord]);

  return (
    <Fragment>
      <Input
        value={currentWord}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentWord(value);
          }
        }}
        name="ignore-word-search-word"
        labelWidth={labelWidth}
        label="Word"
        placeholder="Word"
        inputWidth={inputWidth}
        isIncorrectValue={!currentWord}
      />
    </Fragment>
  );
};

export default AdminDashboardEditIgnoreWordSearch;
