import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { QUOTE_IMAGES_COLLECTION_QUERY } from "../../Services/Queries/Queries";
import { setNotification } from "../../../../helpers/cache";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import {
  CREATE_QUOTE_IMAGE,
  DELETE_QUOTE_IMAGE,
  UPDATE_QUOTE_IMAGE,
} from "../../Services/Mutations/Mutations";
import QuoteDeleteModal from "./QuoteDeleteModal1";
import { useDisableBodyScroll } from "../../../../helpers";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import QuoteFileIcon from "../../../../helpers/QuoteFileIcon";
import CheckboxNew from "../../../../components/Form/El/CheckboxNew";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";
import EditQuoteInputFiles from "./EditFilesInputFiles";
import { Base64toObject, fileToBase64Promise } from "../../../../helpers/utils";
import SelectFromServerData from "../../../../components/Form/El/SelectFromServerData";
import Input from "../../../../components/Form/El/Input";
import { cloneDeep } from "lodash";

const EditQuoteArtWork: FC<{
  quoteId: number;
  accessModulesTabsArtWork: any;
  accessControlStructure: any;
  hasEditAndDeleteAccess: boolean;
  userId?: number;
}> = ({
  quoteId,
  accessModulesTabsArtWork,
  accessControlStructure,
  hasEditAndDeleteAccess,
  userId,
}) => {
  const [getArtworkFiles] = useLazyQuery(QUOTE_IMAGES_COLLECTION_QUERY, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const [deleteQuoteImage] = useMutation(DELETE_QUOTE_IMAGE, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      setIsNeedUpdateFiles(true);

      setNotification([
        {
          type: "SUCCESS",
          message: "File deleted successfully",
        },
      ]);
    },
  });

  const [updateQuoteImage] = useMutation(UPDATE_QUOTE_IMAGE, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      setIsNeedUpdateFiles(true);

      setNotification([
        {
          type: "SUCCESS",
          message: "File updated successfully",
        },
      ]);
    },
  });
  const [createQuoteImage] = useMutation(CREATE_QUOTE_IMAGE, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      setNotification([
        {
          type: "SUCCESS",
          message: "File created successfully",
        },
      ]);
    },
  });

  const accessModulesData = useMemo(
    () => accessModulesTabsArtWork?.data,
    [accessModulesTabsArtWork],
  );
  const accessTabsData = useMemo(
    () => accessModulesData?.tabs,
    [accessModulesData],
  );
  const accessLogoFiles = useMemo(
    () => accessTabsData?.logoFiles,
    [accessTabsData],
  );
  const accessSampleFiles = useMemo(
    () => accessTabsData?.sampleFiles,
    [accessTabsData],
  );
  const accessFinalProofFiles = useMemo(
    () => accessTabsData?.finalProof,
    [accessTabsData],
  );

  const getAccessEditByType = useCallback(
    (type: number) => {
      switch (type) {
        case 0:
          return accessLogoFiles;
        case 1:
          return accessSampleFiles;
        case 2:
          return accessFinalProofFiles;
      }
    },
    [accessLogoFiles, accessSampleFiles, accessFinalProofFiles],
  );

  const getAccessButtonsByType = useCallback((type: number) => {
    const accesses = getAccessEditByType(type);

    return accesses ? accesses?.data?.buttons : [];
  }, []);

  const [isLoadingUpdateArtworkFiles, setIsLoadingUpdateArtworkFiles] =
    useState<number | null>(null);
  const [isLoadingCreateArtworkFiles, setIsLoadingCreateArtworkFiles] =
    useState(false);
  const [isLoadingArtworkFiles, setIsLoadingArtworkFiles] = useState(false);
  const [isLoadingDeleteArtworkFiles, setIsLoadingDeleteArtworkFiles] =
    useState(false);
  const [isNeedUpdateFiles, setIsNeedUpdateFiles] = useState(true);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const [deletedId, setDeletedId] = useState<number | undefined>();
  const [imageType, setImageType] = useState(0);
  const [commentsImage, setCommentsImage] = useState<
    { id: number; value: string }[]
  >([]);

  const onSetNewCommentImage = useCallback(
    (id: number, value: string) => {
      const tmpCommentsImage = cloneDeep(commentsImage);
      const index = tmpCommentsImage.findIndex((el) => el.id === id);

      if (index === -1) {
        tmpCommentsImage.push({ id, value });
      }

      tmpCommentsImage[index] = { id, value };

      setCommentsImage(tmpCommentsImage);
    },
    [commentsImage],
  );

  const onGetArtworkFiles = useCallback(async () => {
    setIsLoadingArtworkFiles(true);
    await getArtworkFiles({
      variables: {
        quoteId,
      },
    })
      .then((res) => {
        const { data } = res;
        if (!data) {
          setDataCollection([]);
          return;
        }

        const { quoteImagesByQuote } = data;

        if (!quoteImagesByQuote) {
          setDataCollection([]);
          return;
        }

        setDataCollection(quoteImagesByQuote);
        setCommentsImage([]);
      })
      .finally(() => {
        setIsLoadingArtworkFiles(false);

        if (isNeedUpdateFiles) {
          setIsNeedUpdateFiles(false);
        }
      });
  }, [quoteId]);

  const onDeleteArtworkFiles = useCallback(async () => {
    setIsLoadingDeleteArtworkFiles(true);

    await deleteQuoteImage({
      variables: {
        ids: [deletedId],
      },
    }).finally(() => {
      setIsLoadingDeleteArtworkFiles(false);
      if (deletedId) {
        setDeletedId(undefined);
      }
    });
  }, [deletedId]);

  const forceDownload = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    url: string,
    fileName: string,
  ) => {
    e.preventDefault();
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  useEffect(() => {
    if (
      quoteId &&
      isNeedUpdateFiles &&
      accessLogoFiles &&
      accessSampleFiles &&
      accessFinalProofFiles
    ) {
      onGetArtworkFiles();
    }
  }, [
    isNeedUpdateFiles,
    quoteId,
    accessLogoFiles,
    accessSampleFiles,
    accessFinalProofFiles,
  ]);

  const onUpdateArtworkFile = useCallback(
    async (
      id: number,
      variables: Record<string, string | number | boolean>,
    ) => {
      setIsLoadingUpdateArtworkFiles(id);

      await updateQuoteImage({
        variables: {
          id,
          ...variables,
        },
      }).finally(() => {
        setIsLoadingUpdateArtworkFiles(null);
      });
    },
    [],
  );
  const tableHeading = useMemo((): TableHeading => {
    let currentTableHeading: TableHeading = [
      {
        name: "image",
        label: "Image",
      },
      {
        name: "description",
        label: "Description",
      },
      {
        name: "status",
        label: "",
      },
    ];

    if (
      hasEditAndDeleteAccess &&
      (getAccessButtonsByType(0)?.includes("delete") ||
        getAccessButtonsByType(1)?.includes("delete") ||
        getAccessButtonsByType(2)?.includes("delete"))
    ) {
      currentTableHeading.push({
        name: "delete",
        label: "",
      });
    }

    return currentTableHeading;
  }, [hasEditAndDeleteAccess]);

  const childrenForTable = useCallback(
    (
      type: number,
      currentDataCollection: DataCollectionItem[],
    ): ChildrenTable[] => {
      let currentChildrenForTable: ChildrenTable[] = [];

      const getImageTypeLabelByNumber = (id: number) => {
        if (id === 0) {
          return "Logo Files";
        }

        if (id === 1) {
          return "Samples";
        }

        if (id === 2) {
          return "Final Proof";
        }

        return "-";
      };

      currentDataCollection
        .filter((el) => el.imageType === type)
        .forEach((dataCollectionItem) => {
          const {
            id,
            imageName,
            imageURL,
            imageType,
            imageFile,
            comment,
            approved,
            imageUid,
          } = dataCollectionItem;

          let fileUrl = "";

          if (imageURL && !imageURL.toLowerCase().includes("file missing")) {
            fileUrl = imageURL;
          }
          if (!fileUrl && imageUid) {
            fileUrl = imageUid;
          }

          let fileFromBase64ToFile = "";

          if (imageFile) {
            fileFromBase64ToFile = Base64toObject(imageFile);
          }

          let currentChildrenForTableItem: ChildrenTable = {
            id,
            row: {
              image: (
                <div className="flex items-center justify-center w-full">
                  <div className="w-24 p-3 object-center">
                    <QuoteFileIcon imageName={imageName} imageURL={fileUrl} />
                  </div>
                </div>
              ),
              description: (
                <Fragment>
                  <div>
                    <span className="font-bold">Name: </span>
                    <span>
                      {!!imageName && (!!fileUrl || !!fileFromBase64ToFile) && (
                        <>
                          <a
                            href={fileUrl || fileFromBase64ToFile}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {imageName}
                          </a>{" "}
                          (
                          <a
                            href={fileUrl || fileFromBase64ToFile}
                            onClick={(e) =>
                              forceDownload(e, fileUrl, imageName)
                            }
                          >
                            Download
                          </a>
                          )
                        </>
                      )}
                      {!!imageName && !fileUrl && !fileFromBase64ToFile && (
                        <Fragment>{imageName}</Fragment>
                      )}
                      {!imageName && "-"}
                    </span>
                  </div>
                  <div>
                    <span className="font-bold">Type: </span>
                    <span>{getImageTypeLabelByNumber(imageType) || "-"}</span>
                  </div>
                  <div>
                    <span className="font-bold">Comment: </span>
                    <span>{comment || "-"}</span>
                  </div>
                </Fragment>
              ),
              status: (
                <div
                  className={`text-[13px] font-bold ${
                    approved ? "" : "text-[#af3013]"
                  }`}
                >
                  {approved ? "Approved" : "Disapproved"}
                </div>
              ),
            },
          };

          const accessButtonsObj = getAccessButtonsByType(type);

          if (accessButtonsObj?.includes("delete")) {
              currentChildrenForTableItem.row.delete = (
                  <button
                      className="btn btn-warning btn-mini"
                      onClick={() => {
                          setDeletedId(id);
                      }}
                  >
                      <i
                          className={`${
                              deletedId === id
                                  ? "inline-block animate-spin icon-spin4"
                                  : "icon-trash-empty"
                          } icon-medium`}
                      />
                  </button>
              )
          }

          const getImageComment = (id: number) => {
            const currentCommentObj = commentsImage.find((el) => el.id === id);

            return currentCommentObj?.value || "";
          };

          const imageComment = getImageComment(id);

          if (
            hasEditAndDeleteAccess &&
            (accessButtonsObj?.includes("checkbox") ||
              accessButtonsObj?.includes("edit"))
          ) {
            currentChildrenForTableItem.expandRow = (
              <div className="flex flex-col gap-1">
                {accessButtonsObj?.includes("checkbox") && (
                  <CheckboxNew
                    value={approved}
                    onChange={(value) => {
                      onUpdateArtworkFile(id, { approved: value });
                    }}
                    name={`artwork-file-approved-checkbox-${id}`}
                    label="Approved: "
                    disabled={isLoadingUpdateArtworkFiles === id}
                  />
                )}
                {accessButtonsObj?.includes("edit") && (
                  <Fragment>
                    <Input
                      value={imageComment}
                      onChange={(value) => {
                        onSetNewCommentImage(id, value.toString());
                      }}
                      name={`comment-image-${id}`}
                      inputWidth="200px"
                    />
                    <div className="btn-group">
                      <button
                        className="btn btn-success btn-small"
                        onClick={() => {
                          onUpdateArtworkFile(id, {
                            comment: imageComment || "",
                          });
                        }}
                      >
                        <i
                          className={`${
                            isLoadingUpdateArtworkFiles === id
                              ? "inline-block animate-spin icon-spin4"
                              : "icon-floppy"
                          } pr-1`}
                        />
                        Save comment
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            );
          }

          currentChildrenForTable.push(currentChildrenForTableItem);
        });

      return currentChildrenForTable;
    },
    [hasEditAndDeleteAccess, deletedId, commentsImage],
  );

  const logoChildrenForTable = useMemo(
    () => childrenForTable(0, dataCollection),
    [dataCollection, commentsImage],
  );
  const samplesChildrenForTable = useMemo(
    () => childrenForTable(1, dataCollection),
    [dataCollection, commentsImage],
  );
  const finalProofChildrenForTable = useMemo(
    () => childrenForTable(2, dataCollection),
    [dataCollection, commentsImage],
  );

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  useDisableBodyScroll(showDeleteModal);
  useEffect(() => {
    setShowDeleteModal(!!deletedId);
  }, [deletedId]);

  const [fileComment, setFileComment] = useState("");
  const [filesList, setFilesList] = useState<File[]>([]);

  const onCreateFiles = useCallback(async () => {
    const newFilesList: {
      imageName: string;
      imageFile: string;
    }[] = [];

    setIsLoadingCreateArtworkFiles(true);

    for (const file of filesList) {
      if (file.size > 1e7) {
        alert(
          `File with name ${file.name} more than 10MB and will not be added`,
        );
        return;
      }

      await fileToBase64Promise(file).then((result) => {
        if (typeof result === "string") {
          newFilesList.push({
            imageName: file.name,
            imageFile: result,
          });
        }
      });
    }

    await createQuoteImage({
      variables: {
        quoteId: quoteId,
        comment: fileComment,
        imageFiles: newFilesList,
        userId,
        imageType,
      },
    })
      .then((res) => {
        const { data } = res;

        if (!data) return;

        if (data.createQuoteImage) {
          setFilesList([]);
          setIsNeedUpdateFiles(true);
          setNotification([
            {
              type: "SUCCESS",
              message: "Files successfully saved",
            },
          ]);
        }
      })
      .finally(() => {
        setIsLoadingCreateArtworkFiles(false);
      });
  }, [filesList, quoteId]);

  return (
    <section>
      <TableWithHeaderNew
        heading={tableHeading}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateFiles(true);
        }}
        children={logoChildrenForTable}
        countPages={logoChildrenForTable.length ? 1 : 0}
        header="Logo Files"
        showTryAgainMenu={false}
        loading={isLoadingArtworkFiles}
      />
      <TableWithHeaderNew
        heading={tableHeading}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateFiles(true);
        }}
        children={samplesChildrenForTable}
        countPages={samplesChildrenForTable.length ? 1 : 0}
        header="Samples Files"
        showTryAgainMenu={false}
        loading={isLoadingArtworkFiles}
      />
      <TableWithHeaderNew
        heading={tableHeading}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateFiles(true);
        }}
        children={finalProofChildrenForTable}
        countPages={finalProofChildrenForTable.length ? 1 : 0}
        header="Final Proof Files"
        showTryAgainMenu={false}
        loading={isLoadingArtworkFiles}
      />
      {!!hasEditAndDeleteAccess && (
        <Fragment>
          <div className="text-xl text-[#393939]">Upload new image</div>
          <SelectFromServerData
            value={imageType}
            onChange={(value) => {
              if (typeof value === "undefined") return;
              let currentValue = value;

              if (typeof currentValue === "string") {
                currentValue = +currentValue;
              }

              setImageType(currentValue);
            }}
            type="quoteImageTypes"
            selectWidth="350px"
          />
          <EditQuoteInputFiles
            onChange={(newFiles) => {
              setFilesList(newFiles);
            }}
            fileComment={{
              value: fileComment,
              onChange: (value) => {
                setFileComment(value);
              },
            }}
            filesList={filesList}
          />
          <div className="btn-group">
            <button
              className="btn btn-small btn-success"
              onClick={() => {
                onCreateFiles();
              }}
              disabled={isLoadingCreateArtworkFiles || !filesList.length}
            >
              <i
                className={`${
                  isLoadingCreateArtworkFiles
                    ? "inline-block animate-spin icon-spin4"
                    : "icon-floppy"
                } pr-1`}
              />
              Add File
            </button>
          </div>
        </Fragment>
      )}
      {showDeleteModal && (
        <QuoteDeleteModal
          setShowDeleteModal={() => {
            setDeletedId(undefined);
          }}
          deleteTitle={"Delete logo image"}
          onDelete={onDeleteArtworkFiles}
          loading={isLoadingDeleteArtworkFiles}
        />
      )}
    </section>
  );
};

export default EditQuoteArtWork;
