import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { QuoteEditData, QuoteExpenses } from "../../../../types/Quote";
import SelectFromServerData from "../../../../components/Form/El/SelectFromServerData";
import Calendar from "../../../../components/Form/El/Calendar";
import CheckboxNew from "../../../../components/Form/El/CheckboxNew";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  QUOTE_MISC_QUERY,
  QUOTE_MISC_QUERY_SUPPLIER,
} from "../../../Services/Queries/Common/CommonQueries";
import { setNotification } from "../../../../helpers/cache";
import { Params } from "../../../../types/Main";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import Input from "../../../../components/Form/El/Input";
import { regExpNumber } from "../../../../helpers/RegExp";
import {
  CREATE_QUOTE_COMMENT,
  CREATE_QUOTE_EXPENSE,
  CREATE_QUOTE_FILES,
  CREATE_QUOTE_MESSAGE,
  DELETE_QUOTE_EXPENSE,
  DELETE_QUOTE_FILES,
  UPDATE_QUOTE_EXPENSE,
  UPDATE_CASHBACK
} from "../../Services/Mutations/Mutations";
import { LoadingDesign } from "../../../../helpers";
import QuoteDeleteModal from "./QuoteDeleteModal1";
import { capitalize, cloneDeep } from "lodash";
import { CreditOrder } from "../../../../types/CreditOrder";
import { Comments } from "../../../../types/Comments";
import {
  Base64toObject,
  convertDateToUSFormat,
  fileToBase64Promise,
} from "../../../../helpers/utils";
import { FileQuote } from "../../../../types/File";
import EditQuoteInputFiles from "./EditFilesInputFiles";
import { CASHBACK_QUERY } from "modules/Clients/Services/Queries/Queries";

const EditQuoteMisc: FC<{
  quoteEditData: QuoteEditData;
  setQuoteEditData:  React.Dispatch<React.SetStateAction<QuoteEditData>>;
  onUpdateQuotePartial: (data: Partial<QuoteEditData>) => Promise<void>;
  accessModules: any;
  tabMiscAccess: any;
  userId?: number;
  hasEditAndDeleteAccess: boolean;
  roleName?: string;
}> = ({
  quoteEditData,
  setQuoteEditData,
  onUpdateQuotePartial,
  accessModules,
  tabMiscAccess,
  userId,
  hasEditAndDeleteAccess,
  roleName,
}) => {
    const accessForm = useMemo(() => tabMiscAccess?.forms, [tabMiscAccess]);
    const accessHeader = useMemo(() => tabMiscAccess?.header, [tabMiscAccess]);

    const accessHeaderFields = useMemo(
      () => accessHeader?.fields,
      [accessHeader],
    );
    const accessHeaderButtons = useMemo(
      () => accessHeader?.buttons,
      [accessHeader],
    );

    const accessHeaderFieldsSupplier = useMemo(
      () => accessHeaderFields?.supplier,
      [accessHeaderFields],
    );
    const accessHeaderFieldsRequiredDeliveryDate = useMemo(
      () => accessHeaderFields?.requiredDeliveryDate,
      [accessHeaderFields],
    );
    const accessHeaderFieldsUseInventory = useMemo(
      () => accessHeaderFields?.useInventory,
      [accessHeaderFields],
    );

    const accessFormEditExpenses = useMemo(
      () => accessForm?.editExpenses,
      [accessForm],
    );
    const accessFormOrderCredit = useMemo(
      () => accessForm?.orderCredits,
      [accessForm],
    );
    const accessFormComments = useMemo(() => accessForm?.comments, [accessForm]);
    const accessFormMessage = useMemo(
      () => accessForm?.messageBoard,
      [accessForm],
    );
    const accessFormFiles = useMemo(() => accessForm?.files, [accessForm]);

    const accessFieldsExpense = useMemo(
      () => accessFormEditExpenses?.fields,
      [accessFormEditExpenses],
    );
    const accessFieldsSelectExpense = useMemo(
      () =>
        accessFieldsExpense?.expense && accessFieldsExpense?.expense?.editable,
      [accessFieldsExpense],
    );
    const accessFieldsValueExpense = useMemo(
      () => accessFieldsExpense?.value && accessFieldsExpense?.value?.editable,
      [accessFieldsExpense],
    );
    const accessButtonsExpense = useMemo(
      () => accessFormEditExpenses?.buttons,
      [accessFormEditExpenses],
    );

    const accessFormFieldsValueComments = useMemo(
      () =>
        accessFormComments?.fields?.text &&
        accessFormComments?.fields?.text?.editable,
      [accessFormComments],
    );
    const accessButtonsComments = useMemo(
      () => accessFormComments?.buttons,
      [accessFormComments],
    );

    const accessFormFieldsValueMessages = useMemo(
      () =>
        accessFormMessage?.fields?.text &&
        accessFormMessage?.fields?.text?.editable,
      [accessFormMessage],
    );
    const accessButtonsMessages = useMemo(
      () => accessFormMessage?.buttons,
      [accessFormMessage],
    );

    const queryMiscByRole = useMemo(() => {
      if (roleName === 'supplier') {
        return QUOTE_MISC_QUERY_SUPPLIER
      }

      return QUOTE_MISC_QUERY
    }, [roleName]);

    const [fetchCashback] = useLazyQuery(CASHBACK_QUERY, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [updateMiscInfoByQuote] = useLazyQuery(queryMiscByRole, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [currentExpensesId, setCurrentExpensesId] = useState<number | null>(
      null,
    );
    const [currentFileId, setCurrentFileId] = useState<number | undefined>();
    const [isLoadingUpdateExpense, setIsLoadingUpdateExpense] = useState(false);
    const [isLoadingUpdateComment, setIsLoadingUpdateComment] = useState(false);
    const [isLoadingUpdateMessage, setIsLoadingUpdateMessage] = useState(false);

    const [expensesBuQuoteList, setExpensesByQuoteList] = useState<
      {
        id: number;
        expensesId: number;
        expenseName: string;
        value: number;
      }[]
    >([]);
    const [creditByQuoteList, setCreditByQuoteList] = useState<CreditOrder[]>([]);
    const [commentsByQuoteList, setCommentsByQuoteList] = useState<Comments[]>(
      [],
    );
    const [messageByQuoteList, setMessageByQuoteList] = useState<Comments[]>([]);
    const [filesByQuoteList, setFilesByQuoteList] = useState<FileQuote[]>([]);

    const getCashback = useCallback(async () => {
      if (!quoteEditData?.client?.id) return;

      setIsLoadingSaveCashback(true)
      setCashback(0);

      await fetchCashback({ variables: { id: quoteEditData?.client?.id } })
        .then(async (res) => {
          const { data } = res;

          if (!data) return;

          const { cashbackQuery } = data;
          setCashback(cashbackQuery);
        })
        .finally(() => {
          setIsLoadingSaveCashback(false);
        });
    }, [fetchCashback, quoteEditData?.client?.id]);

    useEffect(() => {
      getCashback();
    }, [quoteEditData?.client?.id]);

    const clearExpenses = useMemo(
      (): { expensesId: number; value: number | string } => ({
        expensesId: 1,
        value: "",
      }),
      [],
    );

    const [currentExpense, setCurrentExpense] = useState(clearExpenses);
    const [cashback, setCashback] = useState<number>(0);

    const clearEditDataExpense = useCallback(() => {
      if (currentExpensesId) {
        setCurrentExpensesId(null);
      }

      setCurrentExpense(clearExpenses);
    }, [currentExpensesId]);

    useEffect(() => {
      if (!currentExpensesId) {
        clearEditDataExpense();
        return;
      }

      const searchExpense = expensesBuQuoteList.find(
        (el) => el.id === currentExpensesId,
      );

      if (searchExpense) {
        setCurrentExpense({
          expensesId: searchExpense.expensesId,
          value: searchExpense.value,
        });
      }
    }, [currentExpensesId]);

    const [createQuoteComment] = useMutation(CREATE_QUOTE_COMMENT, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [createQuoteMessage] = useMutation(CREATE_QUOTE_MESSAGE, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [createQuoteExpense] = useMutation(CREATE_QUOTE_EXPENSE, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });
    const [updateQuoteExpense] = useMutation(UPDATE_QUOTE_EXPENSE, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [updateCashback] = useMutation(UPDATE_CASHBACK, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [deleteQuoteExpense] = useMutation(DELETE_QUOTE_EXPENSE, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [createQuoteFile] = useMutation(CREATE_QUOTE_FILES, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [deleteQuoteFile] = useMutation(DELETE_QUOTE_FILES, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingUpdateFiles, setIsLoadingUpdateFiles] = useState(false);
    const [deleteId, setDeleteId] = useState<
      | {
        type: string;
        id: number;
      }
      | undefined
    >();
    const [commentText, setCommentText] = useState("");
    const [messageText, setMessageText] = useState("");
    const [fileComment, setFileComment] = useState("");
    const [filesList, setFilesList] = useState<File[]>([]);

    const onCreateComment = useCallback(async () => {
      setIsLoadingUpdateComment(true);

      await createQuoteComment({
        variables: {
          quoteId: quoteEditData.id,
          userId,
          text: commentText,
        },
      })
        .then((res) => {
          const { data } = res;

          if (!data) return;

          if (data.createQuoteComment?.id) {
            setIsNeedUpdateMiscInfoByQuote(true);

            setNotification([
              {
                type: "SUCCESS",
                message: "Comment successfully sent",
              },
            ]);
          }
        })
        .finally(() => {
          setIsLoadingUpdateComment(false);
        });
    }, [commentText, quoteEditData]);

    const onCreateFiles = useCallback(async () => {
      const newFilesList: {
        fileName: string;
        file: string;
      }[] = [];

      setIsLoadingUpdateFiles(true);

      for (const file of filesList) {
        if (file.size > 1e7) {
          alert(
            `File with name ${file.name} more than 10MB and will not be added`,
          );
          return;
        }

        await fileToBase64Promise(file).then((result) => {
          if (typeof result === "string") {
            newFilesList.push({
              fileName: file.name,
              file: result,
            });
          }
        });
      }

      await createQuoteFile({
        variables: {
          quoteId: quoteEditData.id,
          comment: fileComment,
          files: newFilesList,
          userId,
        },
      })
        .then((res) => {
          const { data } = res;

          if (!data) return;

          if (data.createQuoteFile) {
            setIsNeedUpdateMiscInfoByQuote(true);
            setNotification([
              {
                type: "SUCCESS",
                message: "Files successfully saved",
              },
            ]);
          }
        })
        .finally(() => {
          setIsLoadingUpdateFiles(false);
        });
    }, [filesList, quoteEditData]);

    const onCreateMessage = useCallback(async () => {
      setIsLoadingUpdateMessage(true);

      await createQuoteMessage({
        variables: {
          quoteId: quoteEditData.id,
          userId,
          text: messageText,
        },
      })
        .then((res) => {
          const { data } = res;

          if (!data) return;

          if (data.createQuoteMessage?.id) {
            setIsNeedUpdateMiscInfoByQuote(true);
            setMessageText('');
            setNotification([
              {
                type: "SUCCESS",
                message: "Message successfully sent",
              },
            ]);
          }
        })
        .finally(() => {
          setIsLoadingUpdateMessage(false);
        });
    }, [messageText, quoteEditData]);

    const onDelete = useCallback(async () => {
      if (!deleteId) return;
      const onDeleteHandler = async () => {
        if (deleteId.type === "expense") {
          return await deleteQuoteExpense({
            variables: {
              ids: [deleteId.id],
            },
          });
        }

        if (deleteId.type === "file") {
          return await deleteQuoteFile({
            variables: {
              ids: [deleteId.id],
            },
          });
        }
      };

      setIsLoadingDelete(true);

      await onDeleteHandler()
        .then((res) => {
          if (!res) return;

          const { data } = res;

          if (!data) return;

          const { deleteQuoteExpense, deleteQuoteFile } = data;

          if (deleteId.type === "expense" && deleteId.id === currentExpensesId) {
            setCurrentExpensesId(null);
          }

          if (deleteId.type === "expense" && deleteQuoteExpense) {
            setIsNeedUpdateMiscInfoByQuote(true);
            setNotification([
              {
                type: "SUCCESS",
                message: `${capitalize(deleteId.type)} deleted successfully`,
              },
            ]);
          }

          if (deleteId.type === "file" && deleteId.id === currentFileId) {
            setCurrentExpensesId(null);
          }

          if (deleteId.type === "file" && deleteQuoteFile) {
            setIsNeedUpdateMiscInfoByQuote(true);
            setNotification([
              {
                type: "SUCCESS",
                message: `${capitalize(deleteId.type)} deleted successfully`,
              },
            ]);
          }
        })
        .finally(() => {
          setIsLoadingDelete(false);
          setDeleteId(undefined);
        });
    }, [deleteId]);

    const [isNeedUpdateMiscInfoByQuote, setIsNeedUpdateMiscInfoByQuote] = useState(false);

    const onSaveCashback = useCallback(async () => {
      setIsLoadingSaveCashback(true);
      await updateCashback({
        variables: {
          id: quoteEditData?.client?.id,
          cashbackAmount: cashback
        }
      }).finally(() => {
        setIsLoadingSaveCashback(false);
      });
    }, [cashback, quoteEditData?.client?.id, updateCashback]);

    const onSaveExpense = useCallback(async () => {
      const onSave = async () => {
        if (currentExpensesId) {
          return await updateQuoteExpense({
            variables: {
              id: currentExpensesId,
              quoteId: quoteEditData.id,
              expenseId: currentExpense.expensesId,
              value: currentExpense.value,
            },
          });
        }

        return await createQuoteExpense({
          variables: {
            quoteId: quoteEditData.id,
            expenseId: currentExpense.expensesId,
            value: currentExpense.value,
          },
        });
      };

      setIsLoadingUpdateExpense(true);

      await onSave()
        .then((res) => {
          const { data } = res;
          if (!data) return;

          const { updateQuoteExpense, createQuoteExpense } = data;

          if (
            (!!currentExpensesId && updateQuoteExpense.id) ||
            (!currentExpensesId && createQuoteExpense.id)
          ) {
            setIsNeedUpdateMiscInfoByQuote(true);
            setNotification([
              {
                type: "SUCCESS",
                message: `Expense successfully ${currentExpensesId ? "updated" : "created"
                  }`,
              },
            ]);
          }
        })
        .finally(() => {
          setIsLoadingUpdateExpense(false);
        });
    }, [currentExpense, currentExpensesId, quoteEditData]);

    const onUpdateQuote = useCallback(async () => {
      setIsLoadingUpdatedQuote(true);

      await onUpdateQuotePartial({
        supplierId: quoteEditData.supplierId || undefined,
        useInventory: quoteEditData.useInventory,
        requiredDeliveryDate: quoteEditData.requiredDeliveryDate || null,
      }).finally(() => {
        setIsLoadingUpdatedQuote(false);
      });
    }, [quoteEditData]);

    const onUpdateMiscInfoByQuote = useCallback(async () => {
      setIsLoadingUpdateMiscInfoByQuote(true);

      await updateMiscInfoByQuote({
        variables: {
          quoteId: quoteEditData.id,
        },
      })
        .then((res) => {
          const { data } = res;

          if (!data) return;

          const {
            quoteCommentsByQuote,
            quoteCreditsByQuote,
            quoteExpensesByQuote,
            quoteFilesByQuote,
            quoteMessagesByQuote,
          } = data;
          if (quoteExpensesByQuote) {
            setExpensesByQuoteList(
              quoteExpensesByQuote.length
                ? quoteExpensesByQuote.map((expense: QuoteExpenses) => ({
                  id: expense.id,
                  expensesId: expense.expense.id,
                  expenseName: expense.expense.name,
                  value: expense.value,
                }))
                : [],
            );
          }

          if (quoteCreditsByQuote) {
            setCreditByQuoteList(
              quoteCreditsByQuote.length ? quoteCreditsByQuote : [],
            );
          }

          if (quoteCommentsByQuote) {
            setCommentsByQuoteList(
              quoteCommentsByQuote.length ? quoteCommentsByQuote : [],
            );
          }

          if (quoteMessagesByQuote) {
            setMessageByQuoteList(
              quoteMessagesByQuote.length ? quoteMessagesByQuote : [],
            );
          }

          if (quoteFilesByQuote) {
            setFilesByQuoteList(
              quoteFilesByQuote.length ? quoteFilesByQuote : [],
            );
          }
        })
        .finally(() => {
          setIsLoadingUpdateMiscInfoByQuote(false);

          if (isNeedUpdateMiscInfoByQuote) {
            setIsNeedUpdateMiscInfoByQuote(false);
          }
        });
    }, [quoteEditData, isNeedUpdateMiscInfoByQuote]);

    const [isLoadingUpdateMiscInfoByQuote, setIsLoadingUpdateMiscInfoByQuote] =
      useState(false);
    const [isLoadingUpdatedQuote, setIsLoadingUpdatedQuote] = useState(false);
    const [isLoadingSaveCashback, setIsLoadingSaveCashback] = useState(false);
    const useInventory = useMemo(
      () => quoteEditData?.useInventory,
      [quoteEditData],
    );
    const supplierId = useMemo(() => quoteEditData?.supplierId, [quoteEditData]);

    const requiredDeliveryDate = useMemo(
      () =>
        quoteEditData?.requiredDeliveryDate
          ? new Date(quoteEditData?.requiredDeliveryDate)
          : null,
      [quoteEditData],
    );

    const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});

    useEffect(() => {
      const currentParams = {
        id: quoteEditData.id,
      };

      onChangeParamsCallback(
        prevFetchSettings,
        currentParams,
        (newParams) => setPrevFetchSettings(newParams),
        () => {
          setIsNeedUpdateMiscInfoByQuote(true);
        },
      );
    }, [quoteEditData.id, prevFetchSettings]);

    useEffect(() => {
      if (isNeedUpdateMiscInfoByQuote) {
        onUpdateMiscInfoByQuote();
      }
    }, [isNeedUpdateMiscInfoByQuote]);

    const onSetCurrentFileId = useCallback(
      (id: number) => {
        if (
          filesList.length &&
          window.confirm(
            "When you switch to edit mode, all added files will be deleted, are you sure?",
          )
        ) {
          setFilesList([]);
        }

        setCurrentFileId(id);
      },
      [filesList],
    );
    return (
      <section>
        {!!accessHeader && (
          <div className="row-fluid edit-quote__centered-items justify-start">
            {!!accessHeaderFieldsSupplier && (
              <SelectFromServerData
                value={supplierId}
                onChange={(value) => {

                  setQuoteEditData(prevState => {
                    const tmpEditData = cloneDeep(prevState);

                    let currentValue = value;

                    if (typeof currentValue === "string") {
                      currentValue = +currentValue;
                    }

                    tmpEditData.supplierId = currentValue;

                    return tmpEditData;
                  })
                }}
                type="suppliers"
                className="box-max"
                label="Supplier"
                selectWidth="200px"
                disabled={!accessHeaderFieldsSupplier.editable}
              />
            )}
            {!!accessHeaderFieldsRequiredDeliveryDate && (
              <Calendar
                label="Required Delivery Date:"
                className="box-max"
                name="required-delievery-date"
                onStartDateChange={(value) => {
                  setQuoteEditData(prevState => {
                    const tmpEditData = cloneDeep(prevState);

                    tmpEditData.requiredDeliveryDate = value;

                    return tmpEditData;
                  })
                }}
                type="default"
                startDate={quoteEditData.requiredDeliveryDate}
                inputWidth="200px"
                disabled={!accessHeaderFieldsRequiredDeliveryDate.editable}
              />
            )}
            {!!accessHeaderFieldsUseInventory && (
              <CheckboxNew
                value={useInventory}
                onChange={(value) => {
                  setQuoteEditData(prevState => {
                    const tmpEditData = cloneDeep(prevState);

                    tmpEditData.useInventory = value;

                    return tmpEditData;
                  })
                }}
                name="quote-use-inventory"
                label="Use Inventory"
                className="box-max"
                disabled={!accessHeaderFieldsUseInventory.editable}
              />
            )}
            {accessHeaderButtons?.includes("save") && hasEditAndDeleteAccess && (
              <div className="box-max">
                <button
                  className="btn btn-success btn-small"
                  onClick={() => {
                    onUpdateQuote();
                  }}
                  disabled={isLoadingUpdatedQuote}
                >
                  <i
                    className={`${isLoadingUpdatedQuote
                      ? "inline-block animate-spin icon-spin4"
                      : "icon-floppy"
                      } pr-1`}
                  />
                  Save
                </button>
              </div>
            )}
          </div>
        )}
        <div className="row-fluid">
          {!!accessFormEditExpenses && (
            <div className="box-3 edit-quote__misc-card">
              <div className="edit-quote__misc-card-header">Edit Expenses</div>
              <div className="edit-quote__misc-card-content">
                {isLoadingUpdateMiscInfoByQuote && (
                  <LoadingDesign smallLoading={true} className="h-max" />
                )}
                {!isLoadingUpdateMiscInfoByQuote &&
                  !!expensesBuQuoteList.length &&
                  expensesBuQuoteList.map((expense) => (
                    <div
                      className="edit-quote__misc-card-content-item"
                      key={`quote-expense-id-${expense.id}`}
                    >
                      <div className="value">
                        <div>{expense.expenseName}</div>
                        <div>{expense.value}</div>
                      </div>
                      {hasEditAndDeleteAccess && (
                        <div className="btn-group actions">
                          <button
                            className="btn btn-success btn-small"
                            onClick={() => {
                              setCurrentExpensesId(expense.id);
                            }}
                          >
                            <i className={`icon-pencil`} />
                          </button>
                          <button
                            className="btn btn-warning btn-small"
                            onClick={() => {
                              setDeleteId({
                                type: "expense",
                                id: expense.id,
                              });
                            }}
                          >
                            <i className={`icon-trash-empty`} />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                {hasEditAndDeleteAccess && (
                  <div className="selects">
                    <SelectFromServerData
                      value={currentExpense.expensesId}
                      onChange={(value) => {
                        let currentValue = value;

                        if (typeof currentValue === "string") {
                          currentValue = +currentValue;
                        }

                        if (currentValue) {
                          setCurrentExpense({
                            ...currentExpense,
                            expensesId: currentValue,
                          });
                        }
                      }}
                      type="expenses"
                      disabled={!accessFieldsSelectExpense}
                    />
                    <Input
                      value={currentExpense.value}
                      onChange={(newValue) => {
                        setCurrentExpense({
                          ...currentExpense,
                          value: newValue.toString().length ? +newValue : "",
                        });
                      }}
                      name="expense-quote-value"
                      type="number"
                      pattern={regExpNumber}
                      disabled={!accessFieldsValueExpense}
                    />
                  </div>
                )}
                {!!accessButtonsExpense && hasEditAndDeleteAccess && (
                  <div className="btn-group">
                    {!!accessButtonsExpense?.includes("saveExpense") && (
                      <button
                        className="btn btn-success btn-small"
                        disabled={
                          isLoadingUpdateExpense ||
                          !currentExpense.value.toString()
                        }
                        onClick={() => {
                          onSaveExpense();
                        }}
                      >
                        <i
                          className={`${isLoadingUpdateExpense
                            ? "inline-block animate-spin icon-spin4"
                            : "icon-floppy"
                            } pr-1`}
                        />
                        {`${currentExpensesId ? "Update" : "Save"} expense`}
                      </button>
                    )}
                    {!!accessButtonsExpense?.includes("clear") && (
                      <button
                        className="btn btn-small"
                        onClick={() => {
                          clearEditDataExpense();
                        }}
                      >
                        Clear
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {!!accessFormOrderCredit && (
            <div className="box-3 edit-quote__misc-card">
              <div className="edit-quote__misc-card-header">
                <i className="icon-mail pr-1" />
                Other Credits
              </div>
              <div className="edit-quote__misc-card-content">
                {isLoadingUpdateMiscInfoByQuote && (
                  <LoadingDesign smallLoading={true} className="h-max" />
                )}
                {!isLoadingUpdateMiscInfoByQuote &&
                  !!creditByQuoteList.length &&
                  creditByQuoteList.map((quoteCreditsByQuoteItem) => (
                    <div
                      key={`credit-by-quote-${quoteCreditsByQuoteItem.id}`}
                      className="edit-quote__misc-card-content-item"
                    >
                      {quoteCreditsByQuoteItem.credit}
                    </div>
                  ))}
              </div>
            </div>
          )}
          {accessFormFiles && (
            <div className="box-6 edit-quote__misc-card">
              <div className="edit-quote__misc-card-header">
                <i className="icon-doc pr-1" />
                Files
              </div>
              <div className="edit-quote__misc-card-content">
                {isLoadingUpdateMiscInfoByQuote && (
                  <LoadingDesign smallLoading={true} className="h-max" />
                )}
                {!isLoadingUpdateMiscInfoByQuote &&
                  !!filesByQuoteList.length &&
                  filesByQuoteList.map((file) => (
                    <div
                      className={`edit-quote__misc-card-content-item ${currentFileId === file.id
                        ? "underline decoration-2 decoration-orange-400"
                        : ""
                        }`}
                      key={`file-${file.id}`}
                    >
                      <div className="value">
                        <div>
                          <span className="font-bold">{`${file.user.userName}: `}</span>
                          <span>
                            {!!file.fileName &&
                              (!!file.fileURL ||
                                !!file.fileUid ||
                                !!Base64toObject(file.file)) && (
                                <a
                                  href={
                                    file.fileURL ||
                                    file.fileUid ||
                                    Base64toObject(file.file)
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {file.fileName}
                                </a>
                              )}
                            {!!file.fileName &&
                              !file.fileURL &&
                              !file.fileUid &&
                              !Base64toObject(file.file) && (
                                <Fragment>{file.fileName}</Fragment>
                              )}
                            {!file.fileName && "-"}
                            {` (${file.comment || "no comment"})`}
                          </span>
                        </div>
                      </div>
                      {hasEditAndDeleteAccess && (
                        <div className="btn-group actions">
                          <button
                            className="btn btn-success btn-small"
                            onClick={() => {
                              onSetCurrentFileId(file.id);
                            }}
                          >
                            <i className={`icon-pencil`} />
                          </button>
                          <button
                            className="btn btn-warning btn-small"
                            onClick={() => {
                              setDeleteId({
                                type: "file",
                                id: file.id,
                              });
                            }}
                          >
                            <i className={`icon-trash-empty`} />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                {hasEditAndDeleteAccess && (
                  <Fragment>
                    <div>Upload New File</div>
                    <EditQuoteInputFiles
                      onChange={(newFiles) => {
                        setFilesList(newFiles);
                      }}
                      fileComment={{
                        value: fileComment,
                        onChange: (value) => {
                          setFileComment(value);
                        },
                      }}
                      filesList={filesList}
                    />
                    <div className="btn-group">
                      {!currentFileId && (
                        <button
                          className="btn btn-small btn-success"
                          disabled={!filesList.length || isLoadingUpdateFiles}
                          onClick={() => {
                            onCreateFiles();
                          }}
                        >
                          <i
                            className={`${isLoadingUpdateFiles
                              ? "inline-block animate-spin icon-spin4"
                              : "icon-floppy"
                              } pr-1`}
                          />
                          Add File
                        </button>
                      )}

                      {!!currentFileId && (
                        <Fragment>
                          <button
                            className="btn btn-small btn-success"
                            disabled={isLoadingUpdateFiles}
                          >
                            <i
                              className={`${isLoadingUpdateFiles
                                ? "inline-block animate-spin icon-spin4"
                                : "icon-edit"
                                } pr-1`}
                            />
                            Update comment
                          </button>
                          <button
                            className="btn btn-small"
                            onClick={() => {
                              setCurrentFileId(undefined);
                            }}
                          >
                            <i className="icon-cancel pr-1" />
                            Clear Selection
                          </button>
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        {!!accessFormOrderCredit && (
          <div className="row-fluid">
            <div className="box-3 edit-quote__misc-card">
              <div className="edit-quote__misc-card-header">
                <i className="icon-mail pr-1" />
                Cashback
              </div>
              <div className="edit-quote__misc-card-content">
                {isLoadingUpdateMiscInfoByQuote && (
                  <LoadingDesign smallLoading={true} className="h-max" />
                )}
                {!isLoadingUpdateMiscInfoByQuote &&
                  <div className="flex gap-1">
                    <Input
                      value={cashback}
                      onChange={(newValue) => {
                        if (Number(newValue).toString().length > 0)
                          setCashback(Number(newValue));
                      }}
                      name="cashback-value"
                      type="number"
                      pattern={regExpNumber}
                    />
                    <button
                      className="btn btn-success btn-small"
                      disabled={
                        isLoadingSaveCashback
                      }
                      onClick={() => {
                        onSaveCashback();
                      }}
                    >
                      <i
                        className={`${isLoadingSaveCashback
                          ? "inline-block animate-spin icon-spin4"
                          : "icon-floppy"
                          } pr-1`}
                      />
                      Save
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        )}
        <div className="row-fluid">
          {!!accessFormComments && (
            <div className="box-6 edit-quote__misc-card">
              <div className="edit-quote__misc-card-header">
                <i className="icon-comment pr-1" />
                Comments
              </div>

              <div className="edit-quote__misc-card-content">
                {isLoadingUpdateMiscInfoByQuote && (
                  <LoadingDesign smallLoading={true} className="h-max" />
                )}
                {!isLoadingUpdateMiscInfoByQuote &&
                  !!commentsByQuoteList.length &&
                  commentsByQuoteList.map((comment) => (
                    <div
                      className="edit-quote__misc-card-content-item"
                      key={`quote-comment-id-${comment.id}`}
                    >
                      <div className="comment">
                        <span className="dsc">{`[${convertDateToUSFormat(
                          comment.createdAt,
                        )}] ${comment.user.userName}: `}</span>
                        <span>{`${comment.text}`}</span>
                      </div>
                    </div>
                  ))}
                {hasEditAndDeleteAccess && (
                  <Fragment>
                    <Input
                      value={commentText}
                      onChange={(value) => {
                        setCommentText(value.toString());
                      }}
                      name="quote-comment-input"
                      isTextarea={true}
                      rowCount={2}
                      disabled={!accessFormFieldsValueComments}
                    />
                    {!!accessButtonsComments && (
                      <div className="btn-group">
                        {accessButtonsComments?.includes("addComment") && (
                          <button
                            className="btn btn-success btn-small"
                            disabled={isLoadingUpdateComment || !commentText}
                            onClick={() => {
                              onCreateComment();
                            }}
                          >
                            <i
                              className={`${isLoadingUpdateComment
                                ? "inline-block animate-spin icon-spin4"
                                : "icon-plus"
                                } pr-1`}
                            />
                            Add Comment
                          </button>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!accessFormMessage && (
            <div className="box-6 edit-quote__misc-card">
              <div className="edit-quote__misc-card-header">
                <i className="icon-comment pr-1" />
                Message Board
              </div>

              <div className="edit-quote__misc-card-content">
                {isLoadingUpdateMiscInfoByQuote && (
                  <LoadingDesign smallLoading={true} className="h-max" />
                )}
                {!isLoadingUpdateMiscInfoByQuote &&
                  !!messageByQuoteList.length &&
                  messageByQuoteList.map((message) => (
                    <div
                      className="edit-quote__misc-card-content-item"
                      key={`quote-comment-id-${message.id}`}
                    >
                      <div className="comment">
                        <span className="dsc">{`[${convertDateToUSFormat(
                          message.createdAt,
                        )}] ${message.user.userName}: `}</span>
                        <span>{`${message.text}`}</span>
                      </div>
                    </div>
                  ))}
                {hasEditAndDeleteAccess && (
                  <Fragment>
                    <Input
                      value={messageText}
                      onChange={(value) => {
                        setMessageText(value.toString());
                      }}
                      name="quote-comment-input"
                      isTextarea={true}
                      rowCount={2}
                      disabled={!accessFormFieldsValueMessages}
                    />
                    {!!accessButtonsMessages && (
                      <div className="btn-group">
                        {accessButtonsMessages?.includes("addMessage") && (
                          <button
                            className="btn btn-success btn-small"
                            disabled={isLoadingUpdateMessage || !messageText}
                            onClick={() => {
                              onCreateMessage();
                            }}
                          >
                            <i
                              className={`${isLoadingUpdateMessage
                                ? "inline-block animate-spin icon-spin4"
                                : "icon-plus"
                                } pr-1`}
                            />
                            Add Message
                          </button>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        {!!deleteId && (
          <QuoteDeleteModal
            setShowDeleteModal={() => {
              setDeleteId(undefined);
            }}
            deleteTitle={`Delete quote ${deleteId.type}`}
            onDelete={onDelete}
            loading={isLoadingDelete}
          />
        )}
      </section>
    );
  };

export default EditQuoteMisc;
