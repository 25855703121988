import { FC, useCallback, useState } from "react";
import EditOrCreateUserTemplate from "../EditOrCreateUserTemplate";
import { ApolloError } from "@apollo/client";
import { setNotification } from "../../../../../helpers/cache";

const EditOrCreateUserModal: FC<{
  currentId?: number;
  closeModal: () => void;
  afterSave?: () => void;
}> = ({ afterSave, closeModal, currentId }) => {
  const [isNeedSave, setIsNeedSave] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
  const onSaved = useCallback(() => {
    if (isSaveLoading) {
      setIsSaveLoading(false);
    }

    if (isNeedSave) {
      setIsNeedSave(false);
    }

    if (afterSave) {
      afterSave();
    }

    closeModal();
  }, [closeModal, afterSave, isNeedSave, isSaveLoading]);

  const onError = useCallback(
    (error: ApolloError) => {
      if (isSaveLoading) {
        setIsSaveLoading(false);
      }

      if (isNeedSave) {
        setIsNeedSave(false);
      }

      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    [isNeedSave, isSaveLoading],
  );
  return (
    <div className="admin-dasboard__modal">
      <div className="admin-dashboard__modal-wrapper">
        <div className="admin-dasboard__modal-header">
          <button
            className="btn btn-transparent btn-small"
            onClick={() => {
              closeModal();
            }}
          >
            <i className="icon-cancel icon-medium" />
          </button>
        </div>
        <div className="admin-dasboard__modal-content">
          <EditOrCreateUserTemplate
            isNeedSave={isNeedSave}
            afterSave={onSaved}
            labelWidth={"160px"}
            inputWidth={"400px"}
            setIsSaveLoading={setIsSaveLoading}
            setDisabledSaveButton={setDisabledSaveBtn}
            onErrorSave={onError}
            currentId={currentId}
          />
        </div>
        <div className="admin-dasboard__modal-actions">
          <button
            className="btn btn-small btn-success"
            disabled={disabledSaveBtn || isSaveLoading}
            onClick={() => {
              setIsNeedSave(true);
            }}
          >
            <i
              className={`${
                isSaveLoading
                  ? "inline-block animate-spin icon-spin4"
                  : "icon-ok"
              }  pr-1`}
            />
            Save
          </button>
          <button
            className="btn btn-small btn-warning"
            onClick={() => {
              closeModal();
            }}
          >
            <i className="icon-close pr-1" />
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditOrCreateUserModal;
