import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  AdminDashboardIdsForSelect,
  DataCollectionItem,
} from "../../../types/AdminDashboard";
import { capitalize } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteItemsByType,
  getLabelByIdFromDataCollection,
} from "./AdminDashboardHandler";

const AdminDashboardDeleteItems: FC<{
  idsForSelect: AdminDashboardIdsForSelect[];
  setIsNeedUpdateDataCollection: () => void;
  clearIdsSelect: () => void;
  type: string;
  defaultPath: string;
  currentId: number;
  setIdsForSelect: (ids: { id: number; label: string }[]) => void;
  currentDataCollectionItem?: DataCollectionItem;
}> = ({
  idsForSelect,
  type,
  defaultPath,
  setIsNeedUpdateDataCollection,
  clearIdsSelect,
  setIdsForSelect,
  currentId,
  currentDataCollectionItem,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (currentId && currentDataCollectionItem) {
      setIdsForSelect([
        {
          id: currentId,
          label: getLabelByIdFromDataCollection(type, currentId, [
            currentDataCollectionItem,
          ]),
        },
      ]);
    }
  }, [currentId, currentDataCollectionItem]);

  const idOnlyNum = useMemo(
    () => idsForSelect.map((el) => el.id),
    [idsForSelect],
  );

  const navigateToBack = useCallback(() => {
    navigate(`${defaultPath}/${type}`);
  }, [defaultPath, type]);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const onDelete = useCallback(async () => {
    setIsLoadingDelete(true);

    await deleteItemsByType(type, idOnlyNum)
      .then((res) => {
        const { success } = res;

        if (success) {
          clearIdsSelect();
          setIsNeedUpdateDataCollection();
          navigateToBack();
        }
      })
      .finally(() => {
        setIsLoadingDelete(false);
      });
  }, [idOnlyNum]);

  const onCancel = useCallback(() => {
    if (currentId) {
      clearIdsSelect();
    }
    navigateToBack();
  }, []);
  return (
    <section>
      <div className="admin-dashboard__subtitle">
        The following objects will be deleted, which may delete or orphan some
        of their related dependencies:
      </div>
      <div className="admin-dashboard__delete-items-list">
        {!idsForSelect.length && <div>No selected items</div>}
        {!!idsForSelect.length &&
          idsForSelect.map((el, index) => (
            <div
              className="admin-dashboard__delete-item"
              key={`admin-dashboard__delete-item-${el.id}-${index}`}
            >
              <div className="type">{capitalize(type)}</div>
              <Link
                target="_blank"
                to={`${defaultPath}/${type}/${el.id}`}
                className="label"
              >
                {capitalize(
                  currentDataCollectionItem
                    ? getLabelByIdFromDataCollection(type, currentId, [
                        currentDataCollectionItem,
                      ])
                    : el.label,
                )}
              </Link>
            </div>
          ))}
      </div>
      <div className="admin-dashboard__filter-wrapper admin-dashboard__action-item-btn-wrapper">
        <button
          className="btn btn-small btn-warning"
          onClick={onDelete}
          disabled={!idsForSelect.length}
        >
          <i
            className={`${
              isLoadingDelete
                ? "inline-block animate-spin icon-spin4"
                : "icon-ok"
            } pr-1`}
          />
          Yes, I am sure
        </button>
        <button className="btn btn-small" onClick={onCancel}>
          <i className="icon-cancel pr-1" />
          Cancel
        </button>
      </div>
    </section>
  );
};

export default AdminDashboardDeleteItems;
