import { useMutation, useQuery } from "@apollo/client";
import { ActionButton } from "components/Buttons";
import { catchNotification, setNotification } from "helpers/cache";
import { CRMSETTINGS_PAYMENT_EMAIL_TPLS, CRMSETTINGS_PAYMENT_EMAIL_TPLS_RESULT } from "modules/AdminDashboard/Services/Queries/Queries";
import { CREATE_QUOTE_PAYMENT_LINK_TPL } from "modules/Quotes/Services/Mutations/Mutations";
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { QuoteEditData } from "../../../../../types/Quote";
import { QUOTE_IMAGES_COLLECTION_QUERY } from "../../../Services/Queries/Queries";
import AttachFile from "./AttachFile";

interface IPaymentEmailTpl {
  isRequiredDeliveryDate: boolean;
  invoiceTemplate: string;
  artworks: number[];
  ccEmail: string;
  bccEmail: string;
  cardHolderName: string,
  cardHolderLastName: string,
  isDebugMode: boolean;
}

export interface IQuoteImage {
  id: number;
  approved: boolean;
  comment: string;
  createdAt: string;
  imageName: string;
  imageURL: string;
  imageUid: string;
  quote: any;
  title: string;
  updatedAt: string;
}

const NewPaymentLink: FC<{
  quote: QuoteEditData;
  refetchQuote: Function;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stateTax: number | null;
}> = ({ quote, refetchQuote, loading, setLoading, stateTax }) => {
  const [totalAmount, setTotalAmount] = useState<string>(); // possible '12.'
  const [createQuotePaymentLink] = useMutation(CREATE_QUOTE_PAYMENT_LINK_TPL);
  const [amountDisabled, setAmountDisabled] = useState<boolean>(true);
  const [emailOptions, setEmailOptions] = useState<IPaymentEmailTpl>({
    isRequiredDeliveryDate: !!quote?.requiredDeliveryDate,
    invoiceTemplate: "umd",
    artworks: [], // [id1, id2, ... , idN]
    ccEmail: "",
    bccEmail: "",
    cardHolderName: quote?.client?.clientContacts?.firstName || "",
    cardHolderLastName: quote?.client?.clientContacts?.lastName || "",
    isDebugMode: false,
  });
  const [dontChancgeOrderStatus, setDontChangeOrderStatus] = useState<boolean>(false);

  // --
  // -- Get payment emails templates
  // --
  const [paymentTplId, setPaymentTplId] = useState<string>();
  const [receivedTplId, setReceivedTplId] = useState<string>();
  const { data: emailTpls } = useQuery(CRMSETTINGS_PAYMENT_EMAIL_TPLS);
  const { data: emailTplsResult } = useQuery(CRMSETTINGS_PAYMENT_EMAIL_TPLS_RESULT);

  // -- init tpl list when emailTpls loads
  useEffect(() => {
    emailTpls?.paymentEmailTpls?.[0]?.id && setPaymentTplId(() => String(emailTpls.paymentEmailTpls[0].id));
  }, [emailTpls?.paymentEmailTpls]);

  useEffect(() => {
    emailTplsResult?.paymentEmailTplsResult?.[0]?.id && setReceivedTplId(() => String(emailTplsResult.paymentEmailTplsResult[0].id));
  }, [emailTplsResult?.paymentEmailTplsResult]);
  

  const onToggleArtwork = (id: number) => () =>
    setEmailOptions((prevState) => ({
      ...prevState,
      artworks: emailOptions.artworks.find((artworkId) => artworkId === id)
        ? emailOptions.artworks.filter((rtwrkId) => rtwrkId !== id)
        : [...emailOptions.artworks, id],
    }));

  const { data: quoteImages, loading: imageLoading, refetch } = useQuery(
    QUOTE_IMAGES_COLLECTION_QUERY,
    {
      variables: {
        quoteId: quote?.id,
      },
    },
  );

  useEffect(() => {
    refetch()
  }, [])
  
  useEffect(() => {
    setEmailOptions((prevState) => ({
      ...prevState,
	  cardHolderName: quote?.client?.clientContacts?.firstName || "",
      cardHolderLastName: quote?.client?.clientContacts?.lastName || "",
    }));
  }, [quote])
  
  // --
  // -- Determine total amount when the component starts
  const calculateTotalAmount: string = useMemo(() => {
    if (!quote?.orderProducts?.length) return "0.00";

    const totalWithoutTax = quote?.orderProducts.reduce(
      (acc, { quantity, unitPrice }) =>
        Number(quantity) && Number(unitPrice)
          ? acc + Number(quantity) * Number(unitPrice)
          : acc,
      0,
    );

    return !quote?.withoutTax && Number(stateTax)
      ? String(
        (
          totalWithoutTax +
          (totalWithoutTax * Number(stateTax)) / 100
        ).toFixed(2),
      )
      : totalWithoutTax.toFixed(2);
  }, []);

  useEffect(() => {
    calculateTotalAmount && setTotalAmount(() => calculateTotalAmount);
  }, [calculateTotalAmount]);

  const isEmailsStr = (emailOptions = "", separator = ","): boolean =>
    !emailOptions
      .trim()
      .split(separator)
      .find(
        (emailTest) =>
          !emailTest
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            ),
      );

  // --
  // -- Unchecked isDebugMode if the re isn't any correct cc email
  useEffect(() => {
    if (!emailOptions.ccEmail || !isEmailsStr(emailOptions.ccEmail))
      setEmailOptions((prevState) => ({
        ...prevState,
        isDebugMode: false,
      }));
  }, [emailOptions.ccEmail]);

  // --
  // -- Handle to send the customer a payment link
  const handleSendLink = () => {
    if (!quote?.id || !+quote?.id || !totalAmount) return false;

    if (!Number(totalAmount))
      return setNotification([
        {
          type: "ERROR",
          message: "Payment link amount should be greater than 0!",
        },
      ]);

    // Check email strings
    if (!isEmailsStr(emailOptions.ccEmail))
      return setNotification([
        { type: "ERROR", message: "Wrong CC email value!" },
      ]);
    if (!isEmailsStr(emailOptions.bccEmail))
      return setNotification([
        { type: "ERROR", message: "Wrong BCC email value!" },
      ]);

    if (emailOptions.isDebugMode && !isEmailsStr(emailOptions.ccEmail))
      return setNotification([
        {
          type: "ERROR",
          message: "Correct CC email is necessary for debug mode!",
        },
      ]);

    if (!paymentTplId || !receivedTplId)
      return setNotification([
        {
          type: "ERROR",
          message: "You have to choose email templates",
        },
      ]);

    setLoading(() => true);
    createQuotePaymentLink({
      variables: {
        quoteId: +quote?.id,
        totalAmount: parseFloat(totalAmount),
        isRequiredDeliveryDate: emailOptions.isRequiredDeliveryDate,
        invoiceTemplate: emailOptions.invoiceTemplate,
        artworks: emailOptions.artworks.join(","),
        ccEmail: emailOptions.ccEmail,
        bccEmail: emailOptions.bccEmail,
        cardHolderName: emailOptions.cardHolderName,
        cardHolderLastName: emailOptions.cardHolderLastName,
        isDebugMode: emailOptions.isDebugMode,
        emailTplId: Number(paymentTplId),
        receivedEmailTplId: Number(receivedTplId),
        dontChancgeOrderStatus,
      },
    })
      .then(() =>
        setNotification([
          {
            type: "SUCCESS",
            message: "Payment link was created and sended to the customer!",
          },
        ]),
      )
      .catch(catchNotification)
      .finally(() => {
        refetchQuote && refetchQuote();
        setLoading(() => false);
      });
  };

  const getImageUrl = useCallback((imageURL: string|null, imageUid: string|null) => {
    let fileUrl = '';

    if (imageURL && !imageURL.toLowerCase().includes('file missing')) {
      fileUrl = imageURL
    }
    if (!fileUrl && imageUid) {
      fileUrl = imageUid
    }

    if (fileUrl) {
      return fileUrl
    }

    return ''
  },[])

  return (
    <>
      <div
        className={`
        p-2 bg-[#f7f7f7] flex justify-between border border-[#dddddd] 
        ${loading ? "animate-pulse" : ""}
      `}
      >
        <h2 className="text-[18px] leading-[30px] text-[#669FC7] ">
          New payment link
        </h2>
        <div className="flex gap-4">
          <label
            className={`
            cursor-pointer text-[13px] leading-5 text-[#393939] flex items-end gap-2 mt-2
            ${!emailOptions.ccEmail || !isEmailsStr(emailOptions.ccEmail)
                ? "opacity-50"
                : ""
              }
          `}
          >
            <input
              type="checkbox"
              className="hidden"
              name="test"
              disabled={
                !emailOptions.ccEmail || !isEmailsStr(emailOptions.ccEmail)
              }
              checked={emailOptions.isDebugMode}
              onChange={() =>
                setEmailOptions((prevState) => ({
                  ...prevState,
                  isDebugMode: !prevState.isDebugMode,
                }))
              }
            />
            <span className="w-[16px] h-[16px] flex justify-center items-end bg-white cursor-pointer border border-[#ccc] hover:border-[#F59942]">
              <i
                className={`icon-ok text-xs text-[#32A3CE] ${emailOptions.isDebugMode ? "" : "hidden"
                  }`}
              ></i>
            </span>
            <span>Send only to CC email</span>
          </label>

          <ActionButton
            buttonName="Save and Send to customer"
            icon="icon-floppy"
            className={`h-[32px] ${totalAmount
              ? "bg-[#87B87F] border-[#87B87F] hover:bg-[#499249]"
              : "bg-[#AECEA9] border-[#AECEA9] cursor-not-allowed"
              }`}
            buttonType="button"
            onClick={handleSendLink}
            loading={loading}
          />
        </div>
      </div>
      <div className="pl-3 grid md:flex-row pt-3 border-x border-b border-[#dddddd]">
        <div className=" grid grid-cols-12 gap-4 justify-between w-full pr-2 py-2 items-end">
          <div className="col-span-6 space-y-2">
            <label className="text-[14px] leading-5 text-[#393939] cursor-pointer">
              Total Amount
            </label>
            <div className="flex gap-[10px] items-end">
              <input
                name="totalAmount"
                value={totalAmount || ""}
                disabled={!isEmailsStr(emailOptions.ccEmail) || loading}
                onChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) =>
                  /^([0-9]*(\.){0,1}[0-9]{0,2})$/.test(String(value)) &&
                  setTotalAmount(() => value)
                }
                className="w-full max-w-[190px] border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica pr-6"
              />
              {!amountDisabled ? (
                <ActionButton
                  buttonName="reset"
                  icon="icon-arrows-cw"
                  className="h-[32px] bg-rose-600 border-rose-600 hover:bg-rose-700 transition"
                  buttonType="button"
                  loading={loading}
                  onClick={() => {
                    setTotalAmount(() => calculateTotalAmount);
                    setAmountDisabled(() => true);
                  }}
                />
              ) : (
                <ActionButton
                  buttonName="enter custom amount"
                  icon="icon-edit"
                  className="h-[32px] bg-blue-500 border-blue-500 hover:bg-blue-600 transition"
                  buttonType="button"
                  loading={loading}
                  onClick={() => setAmountDisabled(() => false)}
                />
              )}
            </div>
          </div>

          <div className="col-span-6 space-y-2">
            <label className="text-[14px] leading-5 text-[#393939] cursor-pointer">
              Invoice in customer email
            </label>
            <div className="w-full max-w-[300px]">
              <select
                disabled={quote?.status === 0}
                id="shippingInfo.paymentType"
                name="shippingInfo.paymentType"
                className="select-field w-full h-[30px] cursor-pointer truncate border border-[#cccccc] focus:outline-none pl-[6px] text-sm leading-8 text-[#555555] bg-[#ccc] focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)]"
                value={emailOptions.invoiceTemplate}
                onChange={(e) =>
                  setEmailOptions((prevState) => ({
                    ...prevState,
                    invoiceTemplate: e?.target?.value,
                  }))
                }
              >
                {/* <option className="max-w-[500px] truncate" value="">Do not attach Invoice</option> */}
                <option className="max-w-[500px] truncate" value="umd">
                  UMD template
                </option>
                <option className="max-w-[500px] truncate" value="ep">
                  EP template
                </option>
              </select>
            </div>
          </div>          

          <div className="col-span-6 space-y-2">
            <label className="text-[14px] leading-5 text-[#393939] cursor-pointer">
              Card holder name
            </label>
            <div className="flex gap-[10px] items-end">
              <input
                name="cardHolderName"
                value={emailOptions.cardHolderName}
                disabled={loading}
                onChange={(e) =>
                  setEmailOptions((prevState) => ({
                    ...prevState,
                    cardHolderName: e?.target?.value || "",
                  }))
                }
                className="w-full border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica pr-6"
              />
            </div>
          </div>

          <div className="col-span-6 space-y-2">
            <label className="text-[14px] leading-5 text-[#393939] cursor-pointer">
              Card Holder Last Name
            </label>
            <div className="flex gap-[10px] items-end">
              <input
                name="cardHolderLastName"
                value={emailOptions.cardHolderLastName}
                disabled={loading}
                onChange={(e) =>
                  setEmailOptions((prevState) => ({
                    ...prevState,
                    cardHolderLastName: e?.target?.value || "",
                  }))
                }
                className="w-full border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica pr-6"
              />
            </div>
          </div>

          <div className="col-span-6 space-y-2">
            <label className="text-[14px] leading-5 text-[#393939] cursor-pointer">
              CC emails
            </label>
            <div className="flex gap-[10px] items-end">
              <input
                name="ccEmail"
                value={emailOptions.ccEmail}
                disabled={loading}
                onChange={(e) =>
                  setEmailOptions((prevState) => ({
                    ...prevState,
                    ccEmail: e?.target?.value || "",
                  }))
                }
                className="w-full border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica pr-6"
              />
            </div>
          </div>

          <div className="col-span-6 space-y-2">
            <label className="text-[14px] leading-5 text-[#393939] cursor-pointer">
              BCC emails
            </label>
            <div className="flex gap-[10px] items-end">
              <input
                name="bccEmail"
                value={emailOptions.bccEmail}
                disabled={loading}
                onChange={(e) =>
                  setEmailOptions((prevState) => ({
                    ...prevState,
                    bccEmail: e?.target?.value || "",
                  }))
                }
                className="w-full border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica pr-6"
              />
            </div>
          </div>

          <div className="col-span-6 space-y-2">
            <label className="text-[14px] leading-5 text-[#393939] cursor-pointer">
              Payment link email template
            </label>
            <div className="flex gap-[10px] items-end">
              <select
                disabled={quote?.status === 0}
                id="shippingInfo.paymentEmailTpl"
                name="shippingInfo.paymentEmailTpl"
                className="select-field w-full h-[30px] cursor-pointer truncate border border-[#cccccc] focus:outline-none pl-[6px] text-sm leading-8 text-[#555555]  focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)]"
                value={paymentTplId}
                onChange={(e) => setPaymentTplId(() => e?.target?.value)}
              >
                {(
                  Array.isArray(emailTpls?.paymentEmailTpls)
                    ? emailTpls.paymentEmailTpls
                    : []
                  ).map(({ id, value }: any, i: any) => (
                  <option key={id} className="max-w-[500px] truncate" value={id}>
                    {!i
                      ? `${value} (DEFAULT)`
                      : value}
                  </option>
                ))}                
              </select>
            </div>
          </div> 

          <div className="col-span-6 space-y-2">
            <label className="text-[14px] leading-5 text-[#393939] cursor-pointer">
              Received Payment email template
            </label>
            <div className="flex gap-[10px] items-end">
              <select
                disabled={quote?.status === 0}
                id="shippingInfo.paymentEmailTpl"
                name="shippingInfo.paymentEmailTpl"
                className="select-field w-full h-[30px] cursor-pointer truncate border border-[#cccccc] focus:outline-none pl-[6px] text-sm leading-8 text-[#555555]  focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)]"
                value={receivedTplId}
                onChange={(e) => setReceivedTplId(() => e?.target?.value)}
              >
                {(
                  Array.isArray(emailTplsResult?.paymentEmailTplsResult)
                    ? emailTplsResult.paymentEmailTplsResult
                    : []
                  ).map(({ id, value }: any, i: any) => (
                  <option key={id} className="max-w-[500px] truncate" value={id}>
                    {!i
                      ? `${value} (DEFAULT)`
                      : value}
                  </option>
                ))}                
              </select>
            </div>
          </div> 

          <div className="col-span-6 flex justify-between w-full pr-2 py-2 items-end">
            <label
              className={`
              cursor-pointer text-[13px] leading-5 text-[#393939] flex items-end gap-2 mt-2
              ${!quote?.requiredDeliveryDate ? "opacity-50" : ""}
            `}
            >
              <input
                type="checkbox"
                className="hidden"
                name="test"
                disabled={!quote?.requiredDeliveryDate}
                checked={emailOptions.isRequiredDeliveryDate}
                onChange={() =>
                  setEmailOptions((prevState) => ({
                    ...prevState,
                    isRequiredDeliveryDate: !prevState.isRequiredDeliveryDate,
                  }))
                }
              />
              <span className="w-[16px] h-[16px] flex justify-center items-end bg-white cursor-pointer border border-[#ccc] hover:border-[#F59942]">
                <i
                  className={`icon-ok text-xs text-[#32A3CE] ${emailOptions.isRequiredDeliveryDate ? "" : "hidden"
                    }`}
                ></i>
              </span>
              <span>
                Attach Required delivery date{" "}
                {!quote?.requiredDeliveryDate && "(is blank)"}
              </span>
            </label>
          </div>

          <div className="col-span-6 flex justify-between w-full pr-2 py-2 items-end">
            <label
              className={`
              cursor-pointer text-[13px] leading-5 text-[#393939] flex items-end gap-2 mt-2
            `}
            >
              <input
                type="checkbox"
                className="hidden"
                name="dontChangeOrderStatus"
                checked={dontChancgeOrderStatus}
                onChange={() => setDontChangeOrderStatus((prevState) => !prevState)}
              />
              <span className="w-[16px] h-[16px] flex justify-center items-end bg-white cursor-pointer border border-[#ccc] hover:border-[#F59942]">
                <i
                  className={`icon-ok text-xs text-[#32A3CE] ${dontChancgeOrderStatus ? "" : "hidden"
                    }`}
                ></i>
              </span>
              <span>
                Don't change the order status                 
              </span>
            </label>
          </div>

        </div>
      </div>

      {Array.isArray(quoteImages?.quoteImagesByQuote) &&
        !!quoteImages?.quoteImagesByQuote?.length && (
          <div className="grid grid-cols-12 gap-2 p-3 border-x border-b border-[#dddddd]">
            <div className="col-span-12 mt-4">
              <div className="bg-[#307ECC] flex gap-1 items-center">
                <span>
                  <i className="icon-doc pl-[10px] text-[#fff]"></i>
                </span>
                <h1 className="text-[#fff] text-[16px] leading-5 py-[6px]">
                  Attach files
                </h1>
              </div>
              <div className="border border-[#CCC px-3 pt-3 pb-4">
                <div className="col-span-12 flex flex-wrap gap-4 w-full pr-2 py-2">
                  {(quoteImages?.quoteImagesByQuote as IQuoteImage[]).map(
                    ({ id, title, imageURL, imageUid, imageName }) => (
                      <AttachFile
                        key={id}
                        title={title || imageName || `file-${id}`}
                        url={getImageUrl(imageURL, imageUid)}
                        isChecked={
                          !!emailOptions.artworks.find(
                            (artWork) => Number(artWork) === Number(id),
                          )
                        }
                        onToggle={onToggleArtwork(id)}
                      />
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default NewPaymentLink;
