import { gql } from "@apollo/client";

export const SET_USER_SETTINGS = gql`
  mutation setUserSetting($userId: Int!, $settings: [setUserSettings]!) {
    setUserSetting(userId: $userId, settings: $settings) {
      id
      name
      value
      user {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;
