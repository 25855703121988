import React, { FC } from "react";

const OopsNoDataDesign: FC<{ className?: string }> = ({ className }) => {
  return (
    <p
      className={`w-full h-[400px] grid place-content-center text-gray-400 ${
        className ? className : ""
      }`}
    >
      Oops, No data
    </p>
  );
};

export default OopsNoDataDesign;
