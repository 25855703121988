import { FC } from "react";
import Nestable from "helpers/ReactNestable";
import Handler from "helpers/ReactNestable/Handler";

const NestableDragandDrop: FC<{
  item: any;
  RenderItem: any;
  showEditModal: boolean;
  showDeleteModal: boolean;
  setShowDeleteModal: Function;
  setShowEditModal: Function;
  setEditProduct: Function;
  setDragId: Function;
}> = ({
  item,
  RenderItem,
  showEditModal,
  showDeleteModal,
  setShowDeleteModal,
  setShowEditModal,
  setEditProduct,
  setDragId,
}) => {
  return (
    <div className="w-[300px] max-w-[500px] sm:w-[500px] transition-all duration-300 px-1 sm:ml-7">
      <Nestable
        items={item}
        renderItem={RenderItem}
        handler={<Handler />}
        maxDepth={3}
        threshold={20}
        onChange={(items: any) => {
          localStorage.setItem("dragId", items?.dragItem?.id);
          localStorage.setItem("changedItem", JSON.stringify(items?.items));
        }}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        setEditProduct={setEditProduct}
        setDragId={setDragId}
      />
    </div>
  );
};

export default NestableDragandDrop;
