import { FC, ReactNode, useState } from "react";

import { camelize } from "helpers/utils";
import type { TableHeading } from "types/Table";
import TablePagination from "./TablePagination";
import { LoadingDesign } from "helpers";

const Table: FC<{
  heading: TableHeading;
  currentItemsPerPage?: number;
  currentPage?: number;
  countPages?: number;
  setCurrentPage?: Function;
  setPageSize?: Function;
  setSortOrder?: Function;
  sortOrder?: {
    columnName: string;
    orderBy: "DESC" | "ASC";
  };
  loading?: boolean;
  children?: ReactNode;
  colorHeader?: "gray" | "blue";
  tableType?: "default" | "collapse";
  disabled?: boolean;
  isSelectedAll?: boolean;
  onHandleSelectAll?: () => void;
}> = ({
  heading,
  currentItemsPerPage,
  countPages,
  currentPage,
  setCurrentPage,
  setPageSize,
  setSortOrder,
  sortOrder,
  loading,
  children,
  colorHeader = "gray",
  tableType = "collapse",
  disabled,
  isSelectedAll,
  onHandleSelectAll,
}) => {
  const itemsPerPage = [10, 25, 50, 100];
  const [orderBy, setOrderBy] = useState(false);

  const getColumnName = (heading: string) => {
    if (
      camelize(heading) !== "name" &&
      camelize(heading) !== "company" &&
      camelize(heading) !== "trackingNumber" &&
      camelize(heading) !== "phoneNumber" &&
      camelize(heading) !== "orderWasPlaced" &&
      camelize(heading) !== "orderWasCompleted" &&
      camelize(heading) !== "created at" &&
      camelize(heading) !== "lastAccessDate"
    ) {
      return camelize(heading);
    } else if (camelize(heading) === "name") {
      return "firstName";
    } else if (camelize(heading) === "company") {
      return "company";
    } else if (camelize(heading) === "trackingNumber") {
      // check
      return "trackingNumber1";
    } else if (camelize(heading) === "phoneNumber") {
      return "phone";
    } else if (camelize(heading) === "orderWasPlaced") {
      // check
      return "dateOrderWasPlaced";
    } else if (camelize(heading) === "orderWasCompleted") {
      // check
      return "dateCompleted";
    } else if (camelize(heading) === "created at") {
      return "createdAt";
    } else if (camelize(heading) === "lastAccessDate") {
      // check
      return "temporaryAccessDate";
    }
  };

  return (
    <div className="table__wrapper">
      {loading && (
        <div className="table_info-content">
          <LoadingDesign />
        </div>
      )}
      {!loading && !countPages && (
        <div className="table_info-content">No Data</div>
      )}
      {!loading && !!countPages && (
        <div className="table__content-wrapper">
          <table className={`table table_${tableType}`}>
            <thead className={`table__thead_${colorHeader}`}>
              <tr>
                {heading.map((headingItem, index) => {
                  return (
                    <th
                      key={`th-${headingItem.name}-${index}`}
                      onClick={() => {
                        if (
                          headingItem.sortable &&
                          setOrderBy &&
                          sortOrder &&
                          setSortOrder
                        ) {
                          setOrderBy(!orderBy);

                          setSortOrder({
                            columnName: getColumnName(headingItem.name),
                            orderBy: orderBy ? "DESC" : "ASC",
                          });
                        }
                      }}
                      className={headingItem.isSmall ? "small" : ""}
                    >
                      {headingItem.label}
                      {headingItem.sortable && (
                        <span className="table__sort-icon">
                          <i className={`icon-sort`} />
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>{children && children}</tbody>
          </table>
        </div>
      )}
      {!!countPages && setPageSize && (
        <div className="table__page-size">
          {itemsPerPage.map((itemsPerPageValue, index) => {
            return (
              <button
                key={`table-page-size-${itemsPerPageValue}-${index}`}
                className={`btn btn-mini btn-group ${
                  currentItemsPerPage === itemsPerPageValue ? "active" : ""
                }`}
                onClick={() => {
                  if (currentItemsPerPage !== itemsPerPageValue) {
                    setPageSize(itemsPerPageValue);
                  }
                }}
              >
                {itemsPerPageValue}
              </button>
            );
          })}
        </div>
      )}
      {!!countPages &&
        !!currentPage &&
        countPages !== 1 &&
        typeof setCurrentPage === "function" && (
          <div className="table__pagination-wrapper">
            <TablePagination
              countPages={countPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
    </div>
  );
};

export default Table;
