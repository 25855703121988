import { FC } from "react";
import type { TableHeading } from "types/Table";
import { ChildrenTable } from "types/Table";
import TableNew from "./TableNew";

const TableWithHeader: FC<{
  heading: TableHeading;
  currentItemsPerPage?: number;
  currentPage?: number;
  countPages?: number;
  setCurrentPage?: Function;
  setPageSize?: Function;
  setSortOrder?: Function;
  sortOrder?: {
    columnName: string;
    orderBy: "DESC" | "ASC";
  };
  loading?: boolean;
  children?: ChildrenTable[];
  colorHeader?: "gray" | "blue";
  tableType?: "default" | "collapse";
  disabled?: boolean;
  isEnabledSelectItems?: boolean;
  selectedIds?: number[];
  setSelectedIds?: (ids: number[]) => void;
  setIsNeedUpdateTableData: () => void;
  showTryAgainMenu?: boolean;
  header: string;
}> = ({
  heading,
  currentItemsPerPage,
  countPages,
  currentPage,
  setCurrentPage,
  setPageSize,
  setSortOrder,
  sortOrder,
  loading,
  children,
  colorHeader = "gray",
  tableType = "collapse",
  disabled,
  selectedIds,
  setSelectedIds,
  isEnabledSelectItems,
  setIsNeedUpdateTableData,
  showTryAgainMenu = true,
  header,
}) => {
  return (
    <div className="history-table">
      <div className="history-table__title">{header}</div>
      <TableNew
        currentItemsPerPage={currentItemsPerPage}
        countPages={countPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        selectedIds={selectedIds}
        disabled={disabled}
        tableType={tableType}
        colorHeader={colorHeader}
        children={children}
        loading={loading}
        heading={heading}
        setIsNeedUpdateTableData={setIsNeedUpdateTableData}
        isEnabledSelectItems={isEnabledSelectItems}
        setSelectedIds={setSelectedIds}
        showTryAgainMenu={showTryAgainMenu}
      />
    </div>
  );
};

export default TableWithHeader;
