import { gql } from "@apollo/client";

export const CLIENT_ACCESS_LEVEL = gql`
  query clientAccessLevels {
    clientAccessLevels {
      label
      value
    }
  }
`;

export const CASHBACK_QUERY = gql`
  query cashbackQuery ($id: Int!) {
    cashbackQuery(id: $id)
  }
`;


export const GENDER_QUERY = gql`
  query genders {
    genders {
      label
      value
    }
  }
`;

export const SHIPPING_ADDRESS_ITEM_QUERY = gql`
  query shippingAddress($id: Int!) {
    shippingAddress(id: $id) {
      id
      address
      city
      state
      country {
        id
        name
        createdAt
        updatedAt
      }
      zip
      attentionTo
      address2
      typeAddress
      createdAt
      isDelete
      updatedAt
      client {
        id
        user {
          id
        }
        firstName
        lastName
        company
        email
      }
    }
  }
`;

export const SHIPPING_ADDRESS_COLLECTION_QUERY = gql`
  query shippingAddresses($limit: Int, $page: Int) {
    shippingAddresses(limit: $limit, page: $page) {
      dataCollection {
        id
        address
        city
        state
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        address2
        typeAddress
        createdAt
        isDelete
        updatedAt
        client {
          id
          user {
            id
          }
          firstName
          lastName
          company
          email
        }
      }
      totalNumberOfItems
    }
  }
`;

export const CLIENT_CREDIT_CARD_ITEM_QUERY = gql`
  query clientCreditCard($id: Int!) {
    clientCreditCard(id: $id) {
      id
      holderName
      expirationDate
      cardNumber
      verificationNumber
      creditCardType
      isDelete
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        company
        email
      }
    }
  }
`;

export const CLIENT_CREDIT_CARD_COLLECTION_QUERY = gql`
  query clientCreditCards($limit: Int, $page: Int) {
    clientCreditCards(limit: $limit, page: $page) {
      dataCollection {
        id
        holderName
        expirationDate
        cardNumber
        verificationNumber
        creditCardType
        isDelete
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          company
          email
        }
      }
      totalNumberOfItems
    }
  }
`;
export const FILTER_SINGLE_QUERY = gql`
  query filter($id: Int!) {
    filter(id: $id) {
      id
      data
      name
      page
    }
  }
`;

export const FILTER_COLLECTION_QUERY = gql`
  query filters {
    filters {
      id
      data
      name
      page
    }
  }
`;

export const CLIENT_LOGS_QUERY = gql`
  query clientLogs($clientId: Int!, $limit: Int, $page: Int) {
    clientLogs(clientId: $clientId, limit: $limit, page: $page) {
      dataCollection {
        id
        itemId
        itemType
        data
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
        }
        quote {
          id
        }
        client {
          id
        }
      }
      totalNumberOfItems
    }
  }
`;

export const CLIENT_LOGS_COLLECTION_QUERY = gql`
  query logs {
    logs {
      id
      itemId
      itemType
      createdAt
      data
      updatedAt
      user {
        id
        firstName
        lastName
      }
      quote {
        id
      }
      client {
        id
      }
    }
  }
`;

export const CLIENTS_FILTER_QUERIES = gql`
  query filterQueries($userId: Int!, $page: String!) {
    filters {
      id
      data
      name
      page
    }
    userDefaultFilter(userId: $userId, page: $page) {
      id
      data
      page
      name
    }
  }
`;
export const CLIENT_QUOTE_HISTORY = gql`
  query clientsQuoteHistory($clientId: Int!, $limit: Int, $page: Int) {
    clientsQuoteHistory(clientId: $clientId, limit: $limit, page: $page) {
      dataCollection {
        id
        name
        phone1
        company
        nextFollowUpTime
        createdAt
      }
      totalNumberOfItems
    }
  }
`;
