import { FC, useEffect, useState } from "react";

const ProductCheckbox: FC<{
  setValue: Function;
  register: Function;
  name: string;
  defaultChecked?: boolean;
}> = ({ setValue, register, name, defaultChecked }) => {
  const [checkbox, setCheckBox] = useState<boolean>(false);
  useEffect(() => {
    if (defaultChecked) {
      setCheckBox(defaultChecked);
    }
  }, [defaultChecked]);

  if (checkbox) {
    setValue(name, true);
  } else {
    setValue(name, false);
  }

  return (
    <div>
      <input id={name} type="checkbox" className="hidden" {...register(name)} />
      <span
        className={`w-[15px] h-[15px] flex justify-center items-center bg-[#f5f8fc] cursor-pointer border border-[#ccc] hover:border-[#F59942]`}
        onClick={() => {
          setCheckBox(!checkbox);
        }}
      >
        <i
          className={`icon-ok text-[#32a3ce] text-xs ${
            checkbox ? "block" : "hidden"
          }`}
        />
      </span>
    </div>
  );
};

export default ProductCheckbox;
