const FilledButton: React.FC<{
  buttonName: string;
  onClick?: () => void;
  buttonType?: "button" | "submit" | "reset" | undefined;
  loading?: boolean;
  className?: string;
}> = ({ buttonName, onClick, buttonType, loading, className }) => {
  return (
    <button
      type={buttonType ? buttonType : "button"}
      className={`w-full border-4 border-[#2283C5] bg-[#2283C5] flex items-center justify-center hover:bg-[#045E9F] text-[13px] leading-5 text-white ${
        className ? className : ""
      }`}
      onClick={onClick}
    >
      <i
        className={`${
          loading ? "inline-block animate-spin icon-spin4" : "icon-key"
        } text-white text-sm leading-[14px] mr-1`}
      />
      {buttonName}
    </button>
  );
};

export default FilledButton;
