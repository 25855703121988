import { gql } from "@apollo/client";

export const CLIENT_MASS_UPDATE = gql`
  mutation updateClientModule(
    $client: clientModule!
    $clientContacts: [clientModuleClientContacts]
    $clientCreditCards: [clientModuleClientCreditCards]
    $clientShippingAddresses: [clientModuleClientShippingAddress]
  ) {
    updateClientModule(
      client: $client
      clientContacts: $clientContacts
      clientCreditCards: $clientCreditCards
      clientShippingAddresses: $clientShippingAddresses
    ) 
    {
      id
      user {
        id
        userName
        firstName
        lastName
        email
      }
      firstName
      lastName
      company
      email
      phone
      fax
      sex
      anumber
      phone1
      jobTitle
      accountName
      webAdress
      active
      createdAt
      updatedAt
      lastBillingAddress {
        id
        address
        city
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        address2
        typeAddress
        isDelete
        createdAt
        updatedAt
      }
      defaultContact {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        defaultShipping
        defaultBilling
        createdAt
        updatedAt
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        sex
        isDelete
        phone1Search
        phone2Search
        birthday
      }
      accessLevelCd
      temporaryAccessDate
      hasOrdered
      quoteQuantity
      cQuoteCoast
      isEnableAutomaticEmails
      clientContacts {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        defaultShipping
        defaultBilling
        createdAt
        updatedAt
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        sex
        isDelete
        phone1Search
        phone2Search
        birthday
      }
      clientCreditCards {
        id
        holderName
        expirationDate
        cardNumber
        verificationNumber
        creditCardType
        isDelete
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          company
          email
        }
      }
      shippingAddresses {
        id
        address
        city
        state
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        address2
        typeAddress
        createdAt
        isDelete
        updatedAt
        client {
          id
          user {
            id
          }
          firstName
          lastName
          company
          email
        }
      }
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient(
    $userId: Int!
    $firstName: String
    $lastName: String
    $company: String
    $email: String
    $phone: String
    $fax: String
    $sex: String
    $anumber: String
    $phone1: String
    $jobTitle: String
    $accountName: String!
    $webAdress: String
    $lastBillingAddressId: Int
    $defaultContactId: Int
    $hasOrdered: Boolean
    $quoteQuantity: Int
    $industryTag: Int
    $cQuoteCoast: Int
    $isEnableAutomaticEmails: Boolean
    $accessLevelCd: Int!
  ) {
    createClient(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      company: $company
      email: $email
      phone: $phone
      fax: $fax
      sex: $sex
      anumber: $anumber
      phone1: $phone1
      jobTitle: $jobTitle
      accountName: $accountName
      webAdress: $webAdress
      lastBillingAddressId: $lastBillingAddressId
      defaultContactId: $defaultContactId
      hasOrdered: $hasOrdered
      quoteQuantity: $quoteQuantity
      cQuoteCoast: $cQuoteCoast
      accessLevelCd: $accessLevelCd
      industryTag: $industryTag
      isEnableAutomaticEmails: $isEnableAutomaticEmails
    ) {
      id
      user {
        id
        userName
        firstName
        lastName
        email
      }
      firstName
      lastName
      company
      email
      phone
      fax
      sex
      anumber
      phone1
      jobTitle
      accountName
      webAdress
      active
      createdAt
      updatedAt
      lastBillingAddress {
        id
        address
        city
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        address2
        typeAddress
        isDelete
        createdAt
        updatedAt
      }
      defaultContact {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        defaultShipping
        defaultBilling
        createdAt
        updatedAt
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        sex
        isDelete
        phone1Search
        phone2Search
        birthday
      }
      accessLevelCd
      temporaryAccessDate
      hasOrdered
      quoteQuantity
      cQuoteCoast
      isEnableAutomaticEmails
      clientContacts {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        defaultShipping
        defaultBilling
        createdAt
        updatedAt
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        sex
        isDelete
        phone1Search
        phone2Search
        birthday
      }
      clientCreditCards {
        id
        holderName
        expirationDate
        cardNumber
        verificationNumber
        creditCardType
        isDelete
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          company
          email
        }
      }
      shippingAddresses {
        id
        address
        city
        state
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        address2
        typeAddress
        createdAt
        isDelete
        updatedAt
        client {
          id
          user {
            id
          }
          firstName
          lastName
          company
          email
        }
      }
      industryTag {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $id: Int!
    $userId: Int
    $firstName: String
    $lastName: String
    $company: String
    $email: String
    $phone: String
    $fax: String
    $sex: String
    $anumber: String
    $phone1: String
    $jobTitle: String
    $accountName: String
    $webAdress: String
    $active: Boolean
    $lastBillingAddressId: Int
    $defaultContactId: Int
    $hasOrdered: Boolean
    $quoteQuantity: Int
    $cQuoteCoast: Int
    $isEnableAutomaticEmails: Boolean
    $industryTag: Int
    $accessLevelCd: Int
  ) {
    updateClient(
      id: $id
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      company: $company
      email: $email
      phone: $phone
      fax: $fax
      sex: $sex
      anumber: $anumber
      phone1: $phone1
      jobTitle: $jobTitle
      accountName: $accountName
      webAdress: $webAdress
      active: $active
      lastBillingAddressId: $lastBillingAddressId
      defaultContactId: $defaultContactId
      hasOrdered: $hasOrdered
      quoteQuantity: $quoteQuantity
      cQuoteCoast: $cQuoteCoast
      industryTag: $industryTag
      isEnableAutomaticEmails: $isEnableAutomaticEmails
      accessLevelCd: $accessLevelCd
    ) {
      id
      user {
        id
        userName
        firstName
        lastName
        email
      }
      firstName
      lastName
      company
      email
      phone
      fax
      sex
      anumber
      phone1
      jobTitle
      accountName
      webAdress
      active
      createdAt
      updatedAt
      lastBillingAddress {
        id
        address
        city
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        address2
        typeAddress
        isDelete
        createdAt
        updatedAt
      }
      defaultContact {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        defaultShipping
        defaultBilling
        createdAt
        updatedAt
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        sex
        isDelete
        phone1Search
        phone2Search
        birthday
      }
      accessLevelCd
      temporaryAccessDate
      hasOrdered
      quoteQuantity
      cQuoteCoast
      isEnableAutomaticEmails
      clientContacts {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        defaultShipping
        defaultBilling
        createdAt
        updatedAt
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        sex
        isDelete
        phone1Search
        phone2Search
        birthday
      }
      clientCreditCards {
        id
        holderName
        expirationDate
        cardNumber
        verificationNumber
        creditCardType
        isDelete
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          company
          email
        }
      }
      shippingAddresses {
        id
        address
        city
        state
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        address2
        typeAddress
        createdAt
        isDelete
        updatedAt
        client {
          id
          user {
            id
          }
          firstName
          lastName
          company
          email
        }
      }
      industryTag {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($ids: [Int]!) {
    deleteClient(ids: $ids)
  }
`;

export const CREATE_CLIENT_CONTACT = gql`
  mutation createClientContact(
    $firstName: String!
    $lastName: String
    $email: String!
    $phone1: String
    $phone2: String
    $jobTitle: String
    $fax: String
    $note: String
    $shippingAddressId: Int
    $billingAddressId: Int
    $defaultShipping: Boolean
    $defaultBilling: Boolean
    $clientId: Int
    $sex: Int!
    $phone1Search: Int
    $phone2Search: Int
    $birthday: String
  ) {
    createClientContact(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone1: $phone1
      phone2: $phone2
      jobTitle: $jobTitle
      fax: $fax
      note: $note
      shippingAddressId: $shippingAddressId
      billingAddressId: $billingAddressId
      defaultShipping: $defaultShipping
      defaultBilling: $defaultBilling
      clientId: $clientId
      sex: $sex
      phone1Search: $phone1Search
      phone2Search: $phone2Search
      birthday: $birthday
    ) {
      id
      firstName
      lastName
      email
      phone1
      phone2
      jobTitle
      fax
      note
      shippingAddress {
        id
        address
        city
        state
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
      }
      billingAddress {
        id
        address
        city
        state
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
      }
      defaultShipping
      defaultBilling
      createdAt
      updatedAt
      client {
        id
        user {
          id
          userName
          firstName
          lastName
          email
        }
        firstName
        lastName
        company
        email
      }
      sex
      isDelete
      phone1Search
      phone2Search
      birthday
    }
  }
`;

export const UPDATE_CLIENT_CONTACT = gql`
  mutation updateClientContact(
    $id: Int!
    $firstName: String
    $lastName: String
    $email: String
    $phone1: String
    $phone2: String
    $jobTitle: String
    $fax: String
    $note: String
    $shippingAddressId: Int
    $billingAddressId: Int
    $defaultShipping: Boolean
    $defaultBilling: Boolean
    $sex: Int
    $phone1Search: Int
    $isDelete: Boolean
    $phone2Search: Int
    $birthday: String
    $isDefault: Boolean
  ) {
    updateClientContact(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone1: $phone1
      phone2: $phone2
      jobTitle: $jobTitle
      isDelete: $isDelete
      fax: $fax
      note: $note
      shippingAddressId: $shippingAddressId
      billingAddressId: $billingAddressId
      defaultShipping: $defaultShipping
      defaultBilling: $defaultBilling
      sex: $sex
      phone1Search: $phone1Search
      phone2Search: $phone2Search
      birthday: $birthday
      isDefault: $isDefault
    ) {
      id
      firstName
      lastName
      email
      phone1
      phone2
      jobTitle
      fax
      note
      shippingAddress {
        id
        address
        city
        state
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
      }
      billingAddress {
        id
        address
        city
        state
        country {
          id
          name
          createdAt
          updatedAt
        }
        zip
        attentionTo
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
      }
      defaultShipping
      defaultBilling
      createdAt
      updatedAt
      client {
        id
        user {
          id
          userName
          firstName
          lastName
          email
        }
        firstName
        lastName
        company
        email
      }
      sex
      isDelete
      phone1Search
      phone2Search
      birthday
    }
  }
`;

export const DELETE_CLIENT_CONTACT = gql`
  mutation deleteClientContact($ids: [Int]!) {
    deleteClientContact(ids: $ids)
  }
`;

export const CREATE_SHIPPING_ADDRESS = gql`
  mutation createShippingAddress(
    $address: String!
    $city: String
    $state: String
    $countryId: Int!
    $zip: String
    $attentionTo: String
    $clientId: Int!
    $address2: String
    $typeAddress: String!
    $createBothAddress: Boolean
  ) {
    createShippingAddress(
      address: $address
      city: $city
      state: $state
      countryId: $countryId
      zip: $zip
      attentionTo: $attentionTo
      clientId: $clientId
      address2: $address2
      typeAddress: $typeAddress
      createBothAddress: $createBothAddress
    ) {
      id
      address
      city
      state
      country {
        id
        name
        createdAt
        updatedAt
      }
      zip
      attentionTo
      address2
      typeAddress
      createdAt
      isDelete
      updatedAt
      client {
        id
        user {
          id
        }
        firstName
        lastName
        company
        email
      }
    }
  }
`;

export const UPDATE_SHIPPING_ADDRESS = gql`
  mutation updateShippingAddress(
    $id: Int!
    $address: String
    $city: String
    $state: String
    $countryId: Int
    $zip: String
    $attentionTo: String
    $address2: String
    $typeAddress: String
    $isDelete: Boolean
    $isDefault: Boolean
  ) {
    updateShippingAddress(
      id: $id
      address: $address
      city: $city
      state: $state
      countryId: $countryId
      zip: $zip
      attentionTo: $attentionTo
      address2: $address2
      typeAddress: $typeAddress
      isDelete: $isDelete
      isDefault: $isDefault
    ) {
      id
      address
      city
      state
      country {
        id
        name
        createdAt
        updatedAt
      }
      zip
      attentionTo
      address2
      typeAddress
      createdAt
      isDelete
      updatedAt
      client {
        id
        user {
          id
        }
        firstName
        lastName
        company
        email
      }
    }
  }
`;

export const DELETE_SHIPPING_ADDRESS = gql`
  mutation deleteShippingAddress($ids: [Int]!) {
    deleteShippingAddress(ids: $ids)
  }
`;

export const CREATE_CLIENT_CREDIT_CARD = gql`
  mutation createClientCreditCard(
    $holderName: String
    $expirationDate: String
    $cardNumber: String!
    $verificationNumber: Int
    $creditCardType: String
    $clientId: Int
  ) {
    createClientCreditCard(
      holderName: $holderName
      expirationDate: $expirationDate
      cardNumber: $cardNumber
      verificationNumber: $verificationNumber
      creditCardType: $creditCardType
      clientId: $clientId
    ) {
      id
      holderName
      expirationDate
      cardNumber
      verificationNumber
      creditCardType
      isDelete
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        company
        email
      }
    }
  }
`;

export const UPDATE_CLIENT_CREDIT_CARD = gql`
  mutation updateClientCreditCard(
    $id: Int!
    $holderName: String
    $expirationDate: String
    $cardNumber: String
    $verificationNumber: Int
    $creditCardType: String
    $isDelete: Boolean
  ) {
    updateClientCreditCard(
      id: $id
      holderName: $holderName
      expirationDate: $expirationDate
      cardNumber: $cardNumber
      verificationNumber: $verificationNumber
      creditCardType: $creditCardType
      isDelete: $isDelete
    ) {
      id
      holderName
      expirationDate
      cardNumber
      verificationNumber
      creditCardType
      isDelete
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        company
        email
      }
    }
  }
`;

export const DELETE_CLIENT_CREDIT_CARD = gql`
  mutation deleteClientCreditCard($ids: [Int]!) {
    deleteClientCreditCard(ids: $ids)
  }
`;

export const GRANT_TEMPORARY_ACCESS = gql`
  mutation grantTemporaryAccess($clientId: Int!, $userId: Int!) {
    grantTemporaryAccess(clientId: $clientId, userId: $userId)
  }
`;
export const CREATE_FILTER = gql`
  mutation createFilter(
    $userId: Int!
    $data: String
    $name: String
    $page: String!
  ) {
    createFilter(userId: $userId, data: $data, name: $name, page: $page) {
      id
      data
      page
      name
    }
  }
`;
export const UPDATE_FILTER = gql`
  mutation updateFilter(
    $id: Int!
    $data: String
    $name: String
    $page: String
  ) {
    updateFilter(id: $id, data: $data, name: $name, page: $page) {
      id
      data
    }
  }
`;
