import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_HIGHLIGHT,
  UPDATE_HIGHLIGHT,
} from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { setNotification } from "../../../../helpers/cache";
import { HIGHLIGHT_TYPES } from "../../Services/Queries/Queries";
import SearchBox from "../../../../components/Form/El/SearchBox";
import { getIntegerNumberFromString } from "../../../../helpers/utils";
import { isValidInteger } from "../../../../helpers/Validation";

const AdminDashboardEditHighligth: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentName("");
    setCurrentColor("");
    setCurrentPriority(null);
    setCurrentHighlightType(undefined);
  }, []);

  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_HIGHLIGHT, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { updateHighlight } = data;

      if (!updateHighlight) return;

      const { id } = updateHighlight;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_HIGHLIGHT, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createHighlight } = data;

      if (!createHighlight) return;

      const { id } = createHighlight;

      onAfterSave(id || 0);
    },
  });
  const [getHighlightTypes] = useLazyQuery(HIGHLIGHT_TYPES, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      const { highlightTypes } = data;

      if (!highlightTypes) return;

      setHighlightTypes(highlightTypes);
    },
  });

  const [currentName, setCurrentName] = useState("");
  const [currentColor, setCurrentColor] = useState("");
  const [currentPriority, setCurrentPriority] = useState<string | null>(null);
  const [currentHighlightType, setCurrentHighlightType] = useState<number>();
  const [highlightTypes, setHighlightTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [isLoadingHighlightTypes, setIsLoadingHighlightTypes] = useState(false);

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { name, color, priority, highlightType } = currentDataCollectionItem;

    if (name) {
      setCurrentName(name);
    }

    if (color) {
      setCurrentColor(color);
    }

    if (typeof priority === "number") {
      setCurrentPriority(priority.toString());
    }

    if (highlightType) {
      setCurrentHighlightType(highlightType);
    }
  }, [currentDataCollectionItem]);

  const onGetHighlightTypes = useCallback(async () => {
    setIsLoadingHighlightTypes(true);

    await getHighlightTypes().finally(() => {
      setIsLoadingHighlightTypes(false);
    });
  }, []);

  useEffect(() => {
    onGetHighlightTypes();
  }, []);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);

    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            name: currentName,
            highlightType: currentHighlightType,
            color: currentColor,
            priority: getIntegerNumberFromString(currentPriority),
          },
        });
      }
      return await createItems({
        variables: {
          name: currentName,
          highlightType: currentHighlightType,
          color: currentColor,
          priority: getIntegerNumberFromString(currentPriority),
        },
      });
    };

    await handleSaveById();
  }, [
    currentName,
    currentId,
    currentHighlightType,
    currentColor,
    currentPriority,
  ]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  const isValidPriority = useMemo(() => {
    return currentPriority === null || isValidInteger(currentPriority);
  }, [currentPriority]);

  useEffect(() => {
    const isValidForm = !!currentName && isValidPriority;
    setDisabledSaveButton(!isValidForm);
  }, [currentName, isValidPriority]);

  return (
    <Fragment>
      <Input
        value={currentName}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentName(value);
          }
        }}
        name="highlight-name"
        labelWidth={labelWidth}
        label="Name"
        placeholder="Name"
        inputWidth={inputWidth}
        isIncorrectValue={!currentName}
      />
      <Input
        value={currentColor}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentColor(value);
          }
        }}
        name="highlight-color"
        labelWidth={labelWidth}
        label="Color"
        placeholder="Color"
        inputWidth={inputWidth}
      />
      <SearchBox
        value={currentHighlightType || ""}
        onChange={(value) => {
          if (typeof value === "number") {
            setCurrentHighlightType(value);
          }
        }}
        options={highlightTypes}
        loading={isLoadingHighlightTypes}
        label="Highlights Type"
        labelWidth={labelWidth}
        inputWidth={inputWidth}
      />
      <Input
        value={
          currentPriority !== null &&
          typeof +currentPriority === "number" &&
          !isNaN(+currentPriority)
            ? currentPriority
            : ""
        }
        onChange={(value) => {
          const stringValue = value.toString();
          setCurrentPriority(stringValue ? stringValue : null);
        }}
        name="highlight-priority"
        labelWidth={labelWidth}
        label="Priority"
        placeholder="Priority"
        inputWidth={inputWidth}
        type={"number"}
        isIncorrectValue={!isValidPriority}
      />
    </Fragment>
  );
};

export default AdminDashboardEditHighligth;
