import { FC } from "react";
import { ReactComponent as DoubleArrowRight } from "assets/double-arrow-right.svg";
import { ReactComponent as SingleArrowRight } from "assets/single-arrow-right.svg";
import { ReactComponent as DoubleArrowLeft } from "assets/double-arrow-left.svg";
import { ReactComponent as SingleArrowLeft } from "assets/single-arrow-left.svg";

const TablePagination: FC<{
  countPages: number;
  currentPage: number;
  setCurrentPage: Function;
}> = ({ countPages, currentPage, setCurrentPage }) => {
  const getPaginationArr = () => {
    const paginationArr = [];

    if (countPages <= 12) {
      for (let count = 1; count <= countPages; count++) {
        paginationArr.push(count);
      }
    } else if (countPages > 12 && currentPage <= 12) {
      for (let count = 1; count <= 12; count++) {
        paginationArr.push(count);
      }
    } else if (countPages - currentPage < 10 && countPages > 12) {
      paginationArr.push(...[1, "..."]);

      const difference = countPages - currentPage;

      for (
        let count = currentPage - (10 - difference);
        count < currentPage;
        count++
      ) {
        paginationArr.push(count);
      }

      for (
        let count = currentPage;
        count <= currentPage + difference;
        count++
      ) {
        paginationArr.push(count);
      }
    } else {
      paginationArr.push(...[1, "..."]);

      for (let count = currentPage; count < currentPage + 10; count++) {
        paginationArr.push(count);
      }
    }

    return paginationArr;
  };

  return (
    <div className="table__pagination">
      <button
        disabled={currentPage === 1}
        className="double-arrow"
        onClick={() => setCurrentPage(1)}
      >
        <DoubleArrowLeft />
      </button>
      <button
        disabled={currentPage === 1}
        className="single-arrow"
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <SingleArrowLeft />
      </button>
      {getPaginationArr()?.map((count) => {
        return (
          <button
            key={count}
            disabled={count === "..."}
            className={currentPage === count ? "active" : ""}
            onClick={() => {
              if (currentPage !== count) {
                setCurrentPage(count);
              }
            }}
          >
            {count}
          </button>
        );
      })}
      <button
        disabled={currentPage === countPages}
        className="single-arrow"
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <SingleArrowRight />
      </button>
      <button
        disabled={currentPage === countPages}
        className="double-arrow"
        onClick={() => setCurrentPage(countPages)}
      >
        <DoubleArrowRight />
      </button>
    </div>
  );
};

export default TablePagination;
