import React, { FC } from "react";

const LoadingDesign: FC<{ className?: string; smallLoading?: boolean }> = ({
  className,
  smallLoading,
}) => {
  return (
    <div
      className={`${
        className
          ? className
          : "bg-white min-h-[400px] flex justify-center items-center"
      }`}
    >
      <div
        className={`${smallLoading ? "loader-design-small" : "loader-design"} `}
      />
    </div>
  );
};

export default LoadingDesign;
