import {
  AdminDashboardFilterObjForServer,
  AdminDashboardSelectValues,
  AdminDashboardType,
  AdminDashboardTypeField,
  DataCollectionItem,
} from "../../../types/AdminDashboard";
import { ChildrenTable, SortOrder, TableHeading } from "../../../types/Table";
import {
  COUNTRIES_FILTER_QUERY,
  CRMSETTING_FILTER_QUERY,
  DOMAIN_IGNORE_LIST_FILTER_QUERY,
  EXPENSES_FILTER_QUERY,
  HIGHLIGHT_FILTER_QUERY,
  IGNORE_WORD_SEARCHES_FILTER_QUERY,
  INDUSTRY_TAGS_FILTER_QUERY,
  OLD_USER_NEW_USERS_FILTER_QUERY,
  PRODUCT_FILTER_QUERY,
  QUICK_QUOTE_ADDITIONAL_INFOS_FILTER_QUERY,
  QUICK_QUOTE_LINE_FILTER_QUERY,
  ROLE_FILTER_QUERY,
  STATES_FILTER_QUERY,
  SYSTEM_ERRORS_FILTER_QUERY,
  TIMERZONES_FILTER_QUERY,
  USER_EMAIL_DATA_FILTER_QUERY,
  USER_SETTINGS_FILTER_QUERY,
  USERS_FILTER_QUERY,
} from "../Services/Queries/Queries";
import { client } from "../../../Services/ClientConfig";
import { convertDateToUSFormat } from "../../../helpers/utils";
import { setNotification } from "../../../helpers/cache";
import { ApolloError } from "@apollo/client";
import {
  DELETE_COUNTRY,
  DELETE_DOMAIN_IGNORE_LIST,
  DELETE_EXPENSE,
  DELETE_IGNORE_WORD_SEARCH,
  DELETE_INDUSTRY_TAG,
  DELETE_OLD_USER_NEW_USER,
  DELETE_PRODUCT,
  DELETE_QUICK_QUOTE_LINE,
  DELETE_ROLE,
  DELETE_STATE,
  DELETE_SYSTEM_ERROR,
  DELETE_TIMEZONE,
  DELETE_USER,
  DELETE_USER_EMAIL_DATA,
  DELETE_USER_SETTING,
} from "../Services/Mutations/Mutaions";

const apolloClient = client;

const types: AdminDashboardType[] = [
  {
    name: "country",
    label: "countries",
    isEnableDeleteItems: true,
    fetchListOptions: {
      query: COUNTRIES_FILTER_QUERY,
      nameData: "filterCountries",
    },
    deleteOptions: {
      query: DELETE_COUNTRY,
      nameData: "deleteCountry",
    },
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
  },
  {
    name: "crm_settings",
    label: "crm settings",
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "value",
        label: "Value",
        type: "string",
      },
      {
        name: "valueText",
        label: "Value Text",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: CRMSETTING_FILTER_QUERY,
      nameData: "filterCrmSettings",
    },
  },
  {
    name: "domain_ignore_list",
    label: "domain ignore list",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: DOMAIN_IGNORE_LIST_FILTER_QUERY,
      nameData: "filterDomainIgnoreLists",
    },
    deleteOptions: {
      query: DELETE_DOMAIN_IGNORE_LIST,
      nameData: "deleteDomainIgnoreList",
    },
  },
  {
    name: "expense",
    label: "expenses",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: EXPENSES_FILTER_QUERY,
      nameData: "filterExpenses",
    },
    deleteOptions: {
      query: DELETE_EXPENSE,
      nameData: "deleteExpense",
    },
  },
  {
    name: "highlight",
    label: "highlights",
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "color",
        label: "Color",
        type: "string",
      },
      {
        name: "highlightType",
        label: "Highlight Type",
        type: "number",
      },
      {
        name: "priority",
        label: "Priority",
        type: "number",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: HIGHLIGHT_FILTER_QUERY,
      nameData: "filterHighlights",
    },
  },
  {
    name: "ignore_word_search",
    label: "ignore word searches",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "word",
        label: "Word",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: IGNORE_WORD_SEARCHES_FILTER_QUERY,
      nameData: "filterIgnoreWordSearches",
    },
    deleteOptions: {
      query: DELETE_IGNORE_WORD_SEARCH,
      nameData: "deleteIgnoreWordSearch",
    },
  },
  {
    name: "industry_tag",
    label: "industry tags",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: INDUSTRY_TAGS_FILTER_QUERY,
      nameData: "filterIndustryTags",
    },
    deleteOptions: {
      query: DELETE_INDUSTRY_TAG,
      nameData: "deleteIndustryTag",
    },
  },
  {
    name: "old_user_new_user",
    label: "old users & new users",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "oldUser",
        label: "OLd User",
        type: "number",
      },
      {
        name: "user",
        label: "User",
        type: "number",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: OLD_USER_NEW_USERS_FILTER_QUERY,
      nameData: "filterOldUserNewUsers",
    },
    deleteOptions: {
      query: DELETE_OLD_USER_NEW_USER,
      nameData: "removeOldUserNewUser",
    },
  },
  {
    name: "product",
    label: "products",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "price",
        label: "Price",
        type: "number",
      },
      {
        name: "description",
        label: "Description",
        type: "string",
      },
      {
        name: "priceMethod",
        label: "Price Method",
        type: "string",
      },
      {
        name: "priceOffset",
        label: "Price Offset",
        type: "string",
      },
      {
        name: "coverImageName",
        label: "Cover Image",
        type: "string",
      },
      {
        name: "link",
        label: "Link",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: PRODUCT_FILTER_QUERY,
      nameData: "filterProducts",
    },
    deleteOptions: {
      query: DELETE_PRODUCT,
      nameData: "deleteProduct",
    },
  },
  {
    name: "quick_quote_additional_info",
    label: "quick quote additional infos",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "description",
        label: "Description",
        type: "string",
      },
      {
        name: "price",
        label: "Price",
        type: "number",
      },
      {
        name: "count",
        label: "Count",
        type: "number",
      },
      {
        name: "isDefault",
        label: "Is Default",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: QUICK_QUOTE_ADDITIONAL_INFOS_FILTER_QUERY,
      nameData: "filterQuickQuoteAdditionalInfos",
    },
    deleteOptions: {
      query: DELETE_QUICK_QUOTE_LINE,
      nameData: "deleteQuickQuoteAdditionalInfo",
    },
  },
  {
    name: "quick_quote_line",
    label: "quick quote lines",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "description",
        label: "Description",
        type: "string",
      },
      {
        name: "price",
        label: "Price",
        type: "number",
      },
      {
        name: "count",
        label: "Count",
        type: "number",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: QUICK_QUOTE_LINE_FILTER_QUERY,
      nameData: "filterQuickQuoteLines",
    },
    deleteOptions: {
      query: DELETE_QUICK_QUOTE_LINE,
      nameData: "deleteQuickQuoteLine",
    },
  },
  {
    name: "role",
    label: "roles",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "users",
        label: "Users",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: ROLE_FILTER_QUERY,
      nameData: "filterRoles",
    },
    deleteOptions: {
      query: DELETE_ROLE,
      nameData: "deleteRole",
    },
  },
  {
    name: "state",
    label: "states",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "tax",
        label: "Tax",
        type: "number",
      },
      {
        name: "alias",
        label: "Alias",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: STATES_FILTER_QUERY,
      nameData: "filterStates",
    },
    deleteOptions: {
      query: DELETE_STATE,
      nameData: "deleteState",
    },
  },
  {
    name: "system_error",
    label: "system errors",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "text",
        label: "Text",
        type: "string",
      },
      {
        name: "data",
        label: "Data",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: SYSTEM_ERRORS_FILTER_QUERY,
      nameData: "filterSystemErrors",
    },
    deleteOptions: {
      query: DELETE_SYSTEM_ERROR,
      nameData: "deleteSystemError",
    },
  },
  {
    name: "time_zones",
    label: "time zones",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "code",
        label: "Code",
        type: "number",
      },
      {
        name: "zone",
        label: "Zone",
        type: "number",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: TIMERZONES_FILTER_QUERY,
      nameData: "filterTimeZones",
    },
    deleteOptions: {
      query: DELETE_TIMEZONE,
      nameData: "deleteTimeZone",
    },
  },
  {
    name: "user",
    label: "users",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "userName",
        label: "User Name",
        type: "string",
      },
      {
        name: "firstName",
        label: "FirstName",
        type: "string",
      },
      {
        name: "lastName",
        label: "LastName",
        type: "string",
      },
      {
        name: "email",
        label: "Email",
        type: "string",
      },
      {
        name: "phone",
        label: "Phone",
        type: "string",
      },
      {
        name: "emailSignature",
        label: "Email Signature",
        type: "string",
      },
      {
        name: "isDeleted",
        label: "Is Deleted",
        type: "string",
      },
      {
        name: "signInCount",
        label: "Sign In Count",
        type: "number",
      },
      {
        name: "lastSignInIp",
        label: "Last Sign In Ip",
        type: "string",
      },
      {
        name: "lastSignInAt",
        label: "Last Sign In At",
        type: "date",
      },
      {
        name: "currentSignInIp",
        label: "Current Sign In Ip",
        type: "string",
      },
      {
        name: "currentSignInAt",
        label: "Current Sign In At",
        type: "date",
      },
      {
        name: "rememberCreatedAt",
        label: "Remember Created In At",
        type: "date",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: USERS_FILTER_QUERY,
      nameData: "filterUsers",
    },
    deleteOptions: {
      query: DELETE_USER,
      nameData: "deleteUser",
    },
  },
  {
    name: "user_email_data",
    label: "user email data",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "user",
        label: "User",
        type: "string",
      },
      {
        name: "domain",
        label: "Domain",
        type: "string",
      },
      {
        name: "userName",
        label: "User Name",
        type: "string",
      },
      {
        name: "address",
        label: "Address",
        type: "string",
      },
      {
        name: "port",
        label: "Port",
        type: "number",
      },
      {
        name: "authentication",
        label: "Authentication",
        type: "string",
      },
      {
        name: "sender",
        label: "From",
        type: "string",
      },
      {
        name: "lastUid",
        label: "Last UID",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: USER_EMAIL_DATA_FILTER_QUERY,
      nameData: "filterUserEmailData",
    },
    deleteOptions: {
      query: DELETE_USER_EMAIL_DATA,
      nameData: "deleteUserEmailData",
    },
  },
  {
    name: "user_settings",
    label: "user settings",
    isEnableDeleteItems: true,
    fields: [
      {
        name: "id",
        label: "Id",
        type: "number",
      },
      {
        name: "user",
        label: "User",
        type: "string",
      },
      {
        name: "name",
        label: "Name",
        type: "string",
      },
      {
        name: "value",
        label: "Value",
        type: "string",
      },
      {
        name: "createdAt",
        label: "Created At",
        type: "date",
      },
      {
        name: "updatedAt",
        label: "Update At",
        type: "date",
      },
    ],
    fetchListOptions: {
      query: USER_SETTINGS_FILTER_QUERY,
      nameData: "filterUserSettings",
    },
    deleteOptions: {
      query: DELETE_USER_SETTING,
      nameData: "deleteUserEmailData",
    },
  },
];

const defaultPath = "/new/admin";

const getCurrentType = (currentType: string) =>
  types.find((type) => type.name === currentType);

const isCorrectType = (currentType: string) => !!getCurrentType(currentType);
const getNumberId = (id: string) => parseInt(id);
const isCorrectId = (id: number) => !!id && !isNaN(id);

const getTypeLabel = (currentType: string) => {
  let label = "";
  if (isCorrectType(currentType)) {
    const currentTypeObj = getCurrentType(currentType);
    label = currentTypeObj!.label;
  }

  return label;
};

const getFields = (currentType: string) => {
  let fields: AdminDashboardType["fields"] = [];

  if (isCorrectType(currentType)) {
    const currentTypeObj = getCurrentType(currentType);
    fields = currentTypeObj!.fields;
  }

  return fields;
};

const numberValuesSelect: AdminDashboardSelectValues[] = [
  {
    id: "number",
    value: "Number ...",
  },
  {
    id: "between",
    value: "Between ... and ...",
  },
  {
    id: "isPresent",
    value: "Is present",
  },
  {
    id: "isBlank",
    value: "Is blank",
  },
];

const dateValuesSelect: AdminDashboardSelectValues[] = [
  {
    id: "date",
    value: "Date ...",
  },
  {
    id: "between",
    value: "Between ... and ...",
  },
  {
    id: "yesterday",
    value: "Yesterday",
  },
  {
    id: "today",
    value: "Today",
  },
  {
    id: "thisWeek",
    value: "This week",
  },
  {
    id: "lastWeek",
    value: "Last week",
  },
  {
    id: "isPresent",
    value: "Is present",
  },
  {
    id: "isBlank",
    value: "Is blank",
  },
];

const stringValuesSelect: AdminDashboardSelectValues[] = [
  {
    id: "contains",
    value: "Contains",
  },
  {
    id: "isExactly",
    value: "Is exactly",
  },
  {
    id: "startsWith",
    value: "Start with",
  },
  {
    id: "endsWith",
    value: "End with",
  },
  {
    id: "isPresent",
    value: "Is present",
  },
  {
    id: "isBlank",
    value: "Is blank",
  },
];

const isEnableDeleteItems = (currentType: string) =>
  !!currentType && !!getCurrentType(currentType)?.isEnableDeleteItems;

const isBooleanFilterSelectName = (name: string) =>
  [
    "lastWeek",
    "isBlank",
    "isPresent",
    "lastWeek",
    "thisWeek",
    "today",
    "yesterday",
  ].includes(name);

const getTableHeading = (currentType: string): TableHeading => {
  if (!isCorrectType(currentType)) return [];

  const tableHeadingList: TableHeading = [];
  const fields = getFields(currentType);

  fields.forEach((field) => {
    tableHeadingList.push({
      name: field.name,
      label: field.label,
      sortable: true,
    });
  });

  tableHeadingList.push({
    name: "helper",
    label: "",
  });

  return tableHeadingList;
};

const getFetchOptionListByType = (currentType: string) => {
  const currentTypeObj = getCurrentType(currentType);

  return isCorrectType(currentType) && currentTypeObj
    ? currentTypeObj.fetchListOptions
    : undefined;
};

const getDeleteOptionByType = (currentType: string) => {
  const currentTypeObj = getCurrentType(currentType);

  return isCorrectType(currentType) && currentTypeObj
    ? currentTypeObj.deleteOptions
    : undefined;
};

const getChildrenForTableByType = (
  currentType: string,
  dataCollection: DataCollectionItem[],
): ChildrenTable[] => {
  const currentTypeObj = getCurrentType(currentType);
  const childrenForTable: ChildrenTable[] = [];
  const getValueByType = (
    typeField: AdminDashboardTypeField["type"],
    name: string,
    dataCollectionItem: DataCollectionItem,
  ) => {
    const valueInDataCollection = dataCollectionItem[name];
    let resultValue: string | number = "-";

    if (
      (valueInDataCollection || typeof valueInDataCollection === "number") &&
      name !== "user"
    ) {
      switch (typeField) {
        case "string":
          resultValue = valueInDataCollection.toString().trim()
            ? valueInDataCollection.toString()
            : "-";
          break;
        case "number":
          const numberValue = parseFloat(valueInDataCollection.toString());
          resultValue = !isNaN(numberValue) ? numberValue : 0;
          break;
        case "date":
          const dateFormatted = convertDateToUSFormat(
            valueInDataCollection.toString(),
          );
          resultValue = dateFormatted.includes("undefined")
            ? "-"
            : dateFormatted;
          break;
      }
    }

    if (typeof valueInDataCollection === "boolean") {
      resultValue = valueInDataCollection ? "\u2705 " : "\u274c";
    }

    if (
      valueInDataCollection &&
      typeof valueInDataCollection === "object" &&
      name === "user"
    ) {
      resultValue = JSON.stringify({
        id: valueInDataCollection?.id,
        name: valueInDataCollection?.userName,
      });
    }

    if (
      valueInDataCollection &&
      typeof valueInDataCollection === "object" &&
      name === "users"
    ) {
      resultValue = JSON.stringify(
        valueInDataCollection.map((el: any) => ({
          id: el?.id,
          name: el?.userName,
        })),
      );
    }

    return resultValue;
  };

  if (isCorrectType(currentType) && dataCollection.length && currentTypeObj) {
    dataCollection.forEach((dataCollectionItem) => {
      let id = getValueByType("number", "id", dataCollectionItem);
      let numId =
        typeof id === "string" ? (isNaN(parseInt(id)) ? 0 : parseInt(id)) : id;
      let childrenValue: ChildrenTable = {
        id: numId,
        row: {},
      };

      currentTypeObj.fields.forEach((field) => {
        childrenValue.row[field.name] = getValueByType(
          field.type,
          field.name,
          dataCollectionItem,
        );
      });

      childrenForTable.push(childrenValue);
    });
  }

  return childrenForTable;
};

const getDataCollectionByType = async (
  currentType: string,
  filterObject: AdminDashboardFilterObjForServer,
  pageSize: number,
  currentPage: number,
  sortOrder: SortOrder,
): Promise<{
  childrenForTable: ChildrenTable[];
  countPages: number;
  dataCollection: DataCollectionItem[];
}> => {
  const currentFetchOptionsList = getFetchOptionListByType(currentType);

  let childrenForTable: ChildrenTable[] = [];
  let countPages = 0;
  let dataCollection: DataCollectionItem[] = [];
  const result = {
    childrenForTable,
    countPages,
    dataCollection,
  };

  if (!currentFetchOptionsList) return result;

  const { query, nameData } = currentFetchOptionsList;

  await apolloClient
    .query({
      query,
      variables: {
        ...filterObject,
        limit: pageSize,
        page: currentPage,
        sortBy: {
          column: sortOrder.columnName,
          order: sortOrder.orderBy,
        },
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      const dataByNameData = res.data?.[nameData];

      if (!dataByNameData) return;

      const { dataCollection, totalNumberOfItems } = dataByNameData;

      if (!dataCollection || !totalNumberOfItems) return;

      result.childrenForTable = getChildrenForTableByType(
        currentType,
        dataCollection,
      );
      result.countPages = Math.ceil(totalNumberOfItems / pageSize);
      result.dataCollection = dataCollection;
    })
    .catch((err: ApolloError) => {
      setNotification([{ type: "ERROR", message: err?.message }]);
    });

  return result;
};

const getLabelByIdFromDataCollection = (
  type: string,
  id: number | string,
  dataCollection: DataCollectionItem[],
) => {
  let numId = id;

  if (typeof numId === "string") {
    numId = +numId;
  }

  if (!numId || isNaN(numId)) return "";

  const selectedItem = dataCollection.find((el) => el.id === numId);
  let defaultLabel = `#${numId}`;
  let keyName;

  if (selectedItem) {
    keyName = Object.keys(selectedItem).find((el) => {
      const lowerString = el.toString().toLowerCase();
      return lowerString.includes("name") && el !== "__typename";
    });
  }

  if (keyName && selectedItem?.[keyName]) {
    defaultLabel = selectedItem[keyName]?.toString() || defaultLabel;
  }

  return defaultLabel;
};

const deleteItemsByType = async (currentType: string, ids: number[]) => {
  const deleteOptions = getDeleteOptionByType(currentType);
  let success = false;

  if (!deleteOptions) return { success };

  const { query, nameData } = deleteOptions;

  await apolloClient
    .mutate({
      mutation: query,
      variables: {
        ids,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      const dataByNameData = res.data?.[nameData];

      if (!dataByNameData) return;

      if (dataByNameData.toString().toLowerCase().includes("successfully")) {
        success = true;
        setNotification([
          {
            type: "SUCCESS",
            message: `${ids.length} ${currentType} successfully deleted`,
          },
        ]);
      }
    })
    .catch((err: ApolloError) => {
      setNotification([{ type: "ERROR", message: err?.message }]);
    });

  return { success };
};

const getDataCollectionItemById = async (
  id: number,
  currentType: string,
  dataCollection: DataCollectionItem[],
) => {
  const currentItem = dataCollection.find((el) => el.id === id);
  let currentDataCollectionItem = undefined;
  let currentChildrenForTable = undefined;

  if (currentItem) {
    currentDataCollectionItem = currentItem;
  }

  const result = await getDataCollectionByType(
    currentType,
    { id: { number: id } },
    10,
    1,
    { columnName: "id", orderBy: "DESC" },
  );

  if (result.dataCollection[0]) {
    currentDataCollectionItem = result.dataCollection[0];
  }

  if (result.childrenForTable[0]) {
    currentChildrenForTable = result.childrenForTable[0];
  }

  return {
    currentDataCollectionItem,
    currentChildrenForTable,
  };
};

const getTypeByLabel = (currentLabel: string) =>
  types.find((el) => el.label.toLowerCase() === currentLabel);

const getNameByLabel = (currentLabel: string) => {
  const currentTypeObj = getTypeByLabel(currentLabel);

  return currentTypeObj?.name || "";
};

export {
  types,
  isCorrectType,
  getTypeLabel,
  isEnableDeleteItems,
  getFields,
  stringValuesSelect,
  dateValuesSelect,
  numberValuesSelect,
  isCorrectId,
  getNumberId,
  isBooleanFilterSelectName,
  getTableHeading,
  getDataCollectionByType,
  getLabelByIdFromDataCollection,
  deleteItemsByType,
  getDataCollectionItemById,
  getNameByLabel,
};
