import { FC, useCallback, useEffect, useMemo, useState } from "react";
import type { TableHeading } from "types/Table";
import { ChildrenTable } from "types/Table";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { CLIENT_QUOTE_HISTORY } from "../../Services/Queries/Queries";
import { Params } from "../../../../types/Main";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { Link } from "react-router-dom";
import { convertDateToUSFormat } from "../../../../helpers/utils";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";

const EditClientHistoryNew: FC<{
  clientId?: number;
}> = ({ clientId }) => {
  const [fetchClientHistory] = useLazyQuery(CLIENT_QUOTE_HISTORY, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const [isNeedUpdateData, setIsNeedUpdateData] = useState(false);

  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "id",
        label: "Id",
      },
      {
        name: "name",
        label: "Name",
      },
      {
        name: "company",
        label: "Company",
      },
      {
        name: "phone1",
        label: "Phone_1",
      },
      {
        name: "lastFollowUp",
        label: "Last_follow_up",
      },
      {
        name: "dateCreated",
        label: "Date_created",
      },
    ],
    [],
  );
  const userDetails: any = useReactiveVar(userContextData);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const [countPages, setCountPages] = useState(0);
  const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});

  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const currentParams = {
      pageSize,
      currentPage,
      clientId,
      isNeedUpdateData,
    };

    onChangeParamsCallback(
      prevFetchSettings,
      currentParams,
      (newParams) => setPrevFetchSettings(newParams),
      () => {
        setIsNeedUpdateData(true);
      },
    );
  }, [pageSize, currentPage, clientId, prevFetchSettings]);

  useEffect(() => {
    if (isNeedUpdateData) {
      onFetchHistory();
    }
  }, [isNeedUpdateData]);

  const onErrorFetch = useCallback(() => {
    setCountPages(0);
    setDataCollection([]);
  }, []);

  const onFetchHistory = useCallback(async () => {
    setIsLoadingHistory(true);
    await fetchClientHistory({
      variables: {
        clientId,
        limit: pageSize,
        page: currentPage,
      },
    })
      .then((res) => {
        const { data } = res;

        if (!data) {
          onErrorFetch();
          return;
        }

        const { clientsQuoteHistory } = data;

        if (!clientsQuoteHistory) {
          onErrorFetch();
          return;
        }

        const { dataCollection, totalNumberOfItems } = clientsQuoteHistory;

        if (!dataCollection || !totalNumberOfItems) {
          onErrorFetch();
          return;
        }

        const newCountPages = Math.ceil(totalNumberOfItems / pageSize);

        setDataCollection(dataCollection);
        setCountPages(newCountPages);
      })
      .finally(() => {
        setIsLoadingHistory(false);

        if (isNeedUpdateData) {
          setIsNeedUpdateData(false);
        }
      });
  }, [isNeedUpdateData, pageSize, clientId, currentPage]);

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    dataCollection.forEach((dataCollectionItem) => {
      const { id, name, company, phone1, createdAt, nextFollowUpTime } =
        dataCollectionItem;
      currentChildrenForTable.push({
        id: id,
        row: {
          id: <Link to={`/quotes/${id}/quote_info`}>{id}</Link>,
          name: name || "-",
          company: company || "-",
          phone1: phone1 || "-",
          lastFollowUp: nextFollowUpTime
            ? convertDateToUSFormat(nextFollowUpTime)
            : "-",
          dateCreated: createdAt ? convertDateToUSFormat(createdAt) : "-",
        },
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);
  return (
    <section>
      <TableWithHeaderNew
        header="Quote history"
        heading={tableHeading}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={isLoadingHistory}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateData(true);
        }}
      />
    </section>
  );
};

export default EditClientHistoryNew;
