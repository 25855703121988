import { gql } from "@apollo/client";

export const CREATE_PRODUCT_CATEGORY = gql`
  mutation createProductCategory($name: String!, $parentId: Int) {
    createProductCategory(name: $name, parentId: $parentId) {
      id
      name
      createdAt
      updatedAt
      lft
      rgt
      depth
      parentId
    }
  }
`;
export const UPDATE_PRODUCT_CATEGORY = gql`
  mutation updateProductCategory(
    $id: Int!
    $name: String
    $parentId: Int
    $previousId: Int
    $nextId: Int
  ) {
    updateProductCategory(
      id: $id
      name: $name
      parentId: $parentId
      previousId: $previousId
      nextId: $nextId
    ) {
      id
      name
      createdAt
      updatedAt
      lft
      rgt
      depth
      parentId
    }
  }
`;
export const DELETE_PRODUCT_CATEGORY = gql`
  mutation deleteProductCategory($ids: [Int]!) {
    deleteProductCategory(ids: $ids)
  }
`;

export const CREATE_PRODUCT_OPTION = gql`
  mutation createProductOption($title: String!, $parentId: Int) {
    createProductOption(title: $title, parentId: $parentId) {
      id
      title
      createdAt
      updatedAt
      lft
      rgt
      parentId
    }
  }
`;
export const UPDATE_PRODUCT_OPTION = gql`
  mutation updateProductOption(
    $id: Int!
    $title: String
    $parentId: Int
    $previousId: Int
    $nextId: Int
  ) {
    updateProductOption(
      id: $id
      title: $title
      parentId: $parentId
      previousId: $previousId
      nextId: $nextId
    ) {
      id
      title
      createdAt
      updatedAt
      lft
      rgt
      parentId
    }
  }
`;
export const DELETE_PRODUCT_OPTION = gql`
  mutation deleteProductOption($ids: [Int]!) {
    deleteProductOption(ids: $ids)
  }
`;

export const CREATE_PRODUCT_PRICE_TABLE = gql`
  mutation createProductPriceTable(
    $name: String
    $isDefault: Boolean
    $productOptionId: Int!
    $productCategoryId: Int!
    $productPriceTableValues: [createProductPriceTableValues]
  ) {
    createProductPriceTable(
      name: $name
      isDefault: $isDefault
      productOptionId: $productOptionId
      productCategoryId: $productCategoryId
      productPriceTableValues: $productPriceTableValues
    ) {
      id
      name
      isDefault
      createdAt
      updatedAt
      productOption {
        id
        title
      }
      productCategory {
        id
        name
      }
      productPriceTableValues {
        id
        productOption {
          id
          title
        }
        count
        price
        createdAt
        updatedAt
      }
    }
  }
`;
export const UPDATE_PRODUCT_PRICE_TABLE = gql`
  mutation updateProductPriceTable(
    $id: Int!
    $isDefault: Boolean
    $name: String
    $productOptionId: Int
    $productCategoryId: Int
    $productPriceTableValues: [updateProductPriceTableValues]
  ) {
    updateProductPriceTable(
      id: $id
      isDefault: $isDefault
      name: $name
      productOptionId: $productOptionId
      productCategoryId: $productCategoryId
      productPriceTableValues: $productPriceTableValues
    ) {
      id
      name
      isDefault
      createdAt
      updatedAt
      productOption {
        id
        title
      }
      productCategory {
        id
        name
      }
      productPriceTableValues {
        id
        productOption {
          id
          title
        }
        count
        price
        createdAt
        updatedAt
      }
    }
  }
`;
export const DELETE_PRODUCT_PRICE_TABLE = gql`
  mutation deleteProductPriceTable($ids: [Int]!) {
    deleteProductPriceTable(ids: $ids)
  }
`;
export const CREATE_PRODUCT_LIST = gql`
  mutation createProduct(
    $name: String
    $price: Float
    $description: String
    $priceMethod: String
    $priceOffset: Float
    $link: String
    $coverImageFile: String
    $coverImageFileName: String
    $productCategoryId: Int
  ) {
    createProduct(
      name: $name
      priceOffset: $priceOffset
      price: $price
      priceMethod: $priceMethod
      link: $link
      description: $description
      coverImageFile: $coverImageFile
      coverImageFileName: $coverImageFileName
      productCategoryId: $productCategoryId
    ) {
      id
      name
      price
      description
      createdAt
      priceMethod
      priceOffset
      productCategory {
        id
        name
      }
      coverImageUid
      coverImageName
      coverImageFile
      link
    }
  }
`;

export const UPDATE_PRODUCT_LIST = gql`
  mutation updateProduct(
    $id: Int!
    $name: String
    $price: Float
    $description: String
    $priceMethod: String
    $priceOffset: Float
    $link: String
    $coverImageFileName: String
    $coverImageFile: String
    $productCategoryId: Int
    $productOptionsIds: [Int]
    $deleteCoverImage: Boolean
  ) {
    updateProduct(
      id: $id
      name: $name
      priceOffset: $priceOffset
      price: $price
      priceMethod: $priceMethod
      link: $link
      description: $description
      coverImageFile: $coverImageFile
      coverImageFileName: $coverImageFileName
      productCategoryId: $productCategoryId
      productOptionsIds: $productOptionsIds
      deleteCoverImage: $deleteCoverImage
    ) {
      id
      name
      price
      description
      priceMethod
      priceOffset
      productCategory {
        id
        name
      }
      productOptionsProducts {
        id
        productOption {
          id
          title
        }
        createdAt
      }
      coverImageName
      coverImageUid
      coverImageFile
      link
    }
  }
`;

export const DELETE_PRODUCT_LIST = gql`
  mutation deleteProduct($ids: [Int]!) {
    deleteProduct(ids: $ids)
  }
`;
