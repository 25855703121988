import { FC, useState } from "react"
import { ActionButton } from "components/Buttons";
import { useMutation } from "@apollo/client";
import { CHECK_PAYMENT_STATUS } from "modules/Quotes/Services/Mutations/Mutations";
import { catchNotification, setNotification } from "helpers/cache";

const CheckStatusByTId: FC<{
  tplId: string,
  refetchQuote?: Function,
}> = ({
  tplId,
  refetchQuote,
}) => {
  const [transactionId, setTransactionId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>();
  const [checkPaymentStatus] = useMutation(CHECK_PAYMENT_STATUS);

  const handleCheckStatus = () => {
    if (!transactionId.trim()) return false;
    setLoading(() => true);

    checkPaymentStatus({
    variables: { 
      tplId,
      transactionId: transactionId.trim(),
    }})
      .then(() => {
        setNotification([{
            type: "SUCCESS",
            message: "The payment status was successfully updated",
        }]);
        refetchQuote && refetchQuote();
      })
      .catch(catchNotification)
      .finally(() => setLoading(() => false));
  }

  return (
    <div className="flex gap-2">
      <input
        name="bccEmail"
        value={transactionId}
        onChange={(e) => setTransactionId(() => e.target.value)}
        placeholder="Transaction ID"
        disabled={loading}
        className="max-w-[157px] border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica"
      />      
      <ActionButton
        buttonName="Check Status"
        icon="icon-search"
        className="h-[32px] bg-blue-500 border-blue-500 hover:bg-blue-600 transition"
        buttonType="button"
        loading={loading}
        disabled={loading}
        onClick={handleCheckStatus}
      />
    </div>
    
  );
}

export default CheckStatusByTId;


