import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Logout from "modules/User/Logout";

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, path }) => {
      console.error(`[GraphQL Error]: Message: ${message}, Path: ${path}`);
    });

  if (networkError) {
    if (networkError.message.slice(-3) === "401") {
      Logout();
      console.error(`[Network Error]: Message: ${networkError.message}`);
    } else {
      console.error(`[Network Error]: Message: ${networkError.message}`);
    }
  }
});

export const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

export const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization:
        localStorage?.token || sessionStorage?.token
          ? `Bearer ${
              localStorage?.token ? localStorage?.token : sessionStorage?.token
            }`
          : null,
    },
  };
});

export const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});
