import { FC } from "react";
import ActionButton from "components/Buttons/ActionButton";

const clientDeleteModal: FC<{
  setShowDeleteModal: Function;
  deleteTitle: string;
  onDelete: () => void;
  loading: boolean;
}> = ({ setShowDeleteModal, deleteTitle, onDelete, loading }) => {
  return (
    <div className="fixed inset-0 bg-transparent z-50 p-2 md:flex md:flex-row md:justify-center md:items-center">
      <div
        className="fixed inset-0 bg-black/60 -z-20"
        onClick={() => {
          if (!loading) {
            setShowDeleteModal(false);
          }
        }}
      />
      <div className="w-[300px] sm:w-[560px] bg-[#e4e6e9] shadow-[0_0_64px_#000] mx-auto h-fit rounded-b-[6px]">
        <h3 className="bg-[#0078AE] max-w-[600px] text-[#000] font-semibold flex justify-between py-2 px-2">
          <span className="text-[16px] leading-5">
            {deleteTitle === "Delete client" && "Delete client"}
            {deleteTitle === "Delete client contact" && "Delete client contact"}
            {deleteTitle === "Delete client address" && "Delete client address"}
            {deleteTitle === "Delete client credit card" &&
              "Delete client credit card"}
          </span>
          <span className="inline-flex justify-center items-center text-red-600">
            <i
              onClick={() => {
                if (!loading) {
                  setShowDeleteModal(false);
                }
              }}
              className={`${
                loading ? "cursor-not-allowed" : "cursor-pointer"
              } icon-cancel cursor-pointer text-white`}
            />
          </span>
        </h3>
        <p className="font-semibold text-sm text-center p-6">
          Are you sure want delete this{" "}
          {deleteTitle === "Delete client" && "client"}
          {deleteTitle === "Delete client contact" && "client contact"}
          {deleteTitle === "Delete client address" && "client address"}
          {deleteTitle === "Delete client credit card" && "client credit card"}?
        </p>
        <div className="flex justify-center gap-4 p-3">
          <ActionButton
            buttonName="Cancel"
            className={`${
              loading ? "cursor-not-allowed" : "hover:bg-[#888] cursor-pointer"
            } bg-[#A0A0A0]  border-[#A0A0A0]  whitespace-nowrap`}
            buttonType="button"
            onClick={() => {
              if (!loading) {
                setShowDeleteModal(false);
              }
            }}
          />
          <ActionButton
            buttonName="Delete"
            buttonType="button"
            className={`${
              loading
                ? "bg-[#cf7869] border-[#cf7869] cursor-not-allowed"
                : "bg-[#D15B47] border-[#D15B47] hover:bg-[#B74635]"
            }`}
            onClick={onDelete}
            icon={loading ? "icon-spin4" : ""}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default clientDeleteModal;
