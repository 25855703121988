import ActionButton from "components/Buttons/ActionButton";
import { Fragment, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  PRODUCT_CATEGORY_COLLECTION_QUERY,
  PRODUCT_OPTION_COLLECTION_QUERY,
  PRODUCT_PRICE_TABLE_COLLECTION_QUERY,
  PRODUCT_PRICE_TABLE_ITEM_QUERY,
} from "modules/Products/Services/Queries/Queries";
import ProductDeleteModal from "helpers/ProductDeleteModal";
import {
  CREATE_PRODUCT_PRICE_TABLE,
  DELETE_PRODUCT_PRICE_TABLE,
  UPDATE_PRODUCT_PRICE_TABLE,
} from "modules/Products/Services/Mutations/Mutation";
import ProductPriceTableForm from "./ProductPriceTableForm";
import ProductNavMenu from "helpers/ProductNavMenu";
import { getParent, productsOptionConvertionHandler } from "helpers/utils";
import useDisableBodyScroll from "helpers/DisableBodyScroll";
import { LoadingDesign } from "helpers";
import ProductPriceTableContent from "./ProductPriceTableContent";

interface FormType {
  name: string;
  productCategory: number | string;
  default: boolean;
  productsOption: number | string;
  productsPrice: any[];
}

interface IProductOption {
  __typename: string; // 'productOption'
  id: number;
  title: string;
}

interface IProductPriceTableValue {
  count?: number | string;
  createdAt?: string;
  id?: number;
  price?: number;
  productOption?: IProductOption;
  updatedAt?: string;
  __typename?: string;
}

const ProductsPriceTable = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [activeLine, setActiveLine] = useState<number>();
  const [showNewForm, setShowNewForm] = useState<boolean>(false);
  const [showUpdateForm, setShowUpdateForm] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [checkbox, setCheckBox] = useState<boolean>(false);
  const [editProduct, setEditProduct] = useState<{
    id: number;
    name: string;
  }>();
  const [productOptionsSubOptions, setProductOptionsSubOptions] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [productOptions, setProductOptions] = useState<
    {
      id: number;
      name: string;
      children: [] | any[];
    }[]
  >([]);
  const [productCategories, setProductCategories] = useState<
    {
      id: number;
      name: string;
      children: [] | any[];
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormType>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "productsPrice",
  });

  const [
    fetchProductPriceTable,
    { data: productPriceTable, loading: loadingProductPriceTable },
  ] = useLazyQuery(PRODUCT_PRICE_TABLE_ITEM_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const [
    fetchProductPriceTables,
    {
      data: productPriceTablesList,
      refetch: reftechProductPriceTablesList,
      loading: loadingProductPriceTables,
    },
  ] = useLazyQuery(PRODUCT_PRICE_TABLE_COLLECTION_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const { data: productOptionslist } = useQuery(
    PRODUCT_OPTION_COLLECTION_QUERY,
    {
      variables: {
        requiredFields: ["id", "title"],
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const { data: productCategoriesList } = useQuery(
    PRODUCT_CATEGORY_COLLECTION_QUERY,
    {
      variables: {
        requiredFields: ["id", "name"],
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const [createProductPriceTable] = useMutation(CREATE_PRODUCT_PRICE_TABLE);
  const [updateProductPriceTable] = useMutation(UPDATE_PRODUCT_PRICE_TABLE);
  const [deleteProductPriceTable] = useMutation(DELETE_PRODUCT_PRICE_TABLE);

  useEffect(() => {
    fetchProductPriceTables({
      variables: {
        limit: +pageSize,
        page: +currentPage,
      },
    });
  }, [pageSize, currentPage, fetchProductPriceTables]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  useEffect(() => {
    if (productOptionslist) {
      setProductOptions(
        productsOptionConvertionHandler(productOptionslist?.productOptions),
      );
    }
  }, [productOptionslist]);

  useEffect(() => {
    if (productCategoriesList) {
      setProductCategories(productCategoriesList?.productCategories);
    }
  }, [productCategoriesList]);

  let selectOptionsProductOptions = productOptions?.map(
    (option: { id: number; name: string }) => ({
      id: option?.id,
      name: option?.name,
    }),
  );
  let selectOptionsProductCategories: any[] = [];
  const getSelectOptionsHandler = (treeData: any[]) => {
    if (treeData?.length === 0) return null;
    treeData?.map((treeData) => {
      selectOptionsProductCategories.push({
        id: treeData?.id,
        name: `${
          getParent(productCategories, treeData?.id)?.length === 1 ? "" : ""
        }${
          getParent(productCategories, treeData?.id)?.length === 2 ? "- " : ""
        }${
          getParent(productCategories, treeData?.id)?.length === 3 ? "- - " : ""
        }${treeData?.name}`,
      });
      if (treeData?.children?.length > 0) {
        getSelectOptionsHandler(treeData?.children);
      }
      return treeData;
    });
  };
  getSelectOptionsHandler(productCategories);
  const [productOptionId, setProductOptionId] = useState<any>(
    selectOptionsProductOptions ? selectOptionsProductOptions[0]?.id : [],
  );

  useEffect(() => {
    if (productPriceTable?.productPriceTable?.productOption?.id) {
      setProductOptionId(
        productPriceTable?.productPriceTable?.productOption?.id,
      );
    }
    if (watch("productsOption")) {
      setProductOptionId(+watch("productsOption"));
    } else if (selectOptionsProductOptions) {
      setProductOptionId(+selectOptionsProductOptions[0]?.id);
    }
  }, [watch, productPriceTable, selectOptionsProductOptions]);

  useEffect(() => {
    if (productOptionId) {
      let products = productOptions?.filter((item) => {
        return item?.id === +productOptionId;
      });
      setProductOptionsSubOptions(
        products[0]?.children?.map((children) => ({
          id: children?.id,
          name: children?.name,
        })),
      );
    }
  }, [productOptionId, productOptions]);

  useEffect(() => {
    if (productPriceTable?.productPriceTable?.productOption?.id) {
      setValue(
        "productsOption",
        productPriceTable?.productPriceTable?.productOption?.id,
      );
    }
    if (productPriceTable?.productPriceTable?.name) {
      setValue("name", productPriceTable?.productPriceTable?.name);
    }
    if (productPriceTable?.productPriceTable?.productCategory?.id) {
      setValue(
        "productCategory",
        productPriceTable?.productPriceTable?.productCategory?.id,
      );
    }
  }, [productPriceTable, setValue, loadingProductPriceTable]);
  useEffect(() => {
    if (productPriceTable?.productPriceTable) {
      setCheckBox(productPriceTable?.productPriceTable?.isDefault);
    }
  }, [productPriceTable]);

  if (checkbox) {
    setValue("default", true);
  } else {
    setValue("default", false);
  }

  const priceTableSubmitHandler = (data: FormType) => {
    if (!loading) {
      let productPriceTableValues: {
        productChildOptionId?: number;
        count: number;
        price?: number;
      }[] = [];
      let onlyQuantity: { count: number; productChildOptionId: number }[] = [];
      data?.productsPrice?.map(({ count, ...item }: any) => {
        onlyQuantity.push({
          count: +count,
          productChildOptionId: +data?.productsOption,
        });
        Object.entries(item)?.map(([key, value]: any) => {
          productPriceTableValues.push({
            productChildOptionId: +key,
            count: +count,
            price: +value,
          });
          return null;
        });
        return null;
      });
      if (showNewForm) {
        setLoading(true);
        createProductPriceTable({
          variables: {
            name: data?.name,
            isDefault: data?.default,
            productOptionId: +data?.productsOption,
            productCategoryId: +data?.productCategory,
            productPriceTableValues:
              productPriceTableValues?.length > 0
                ? productPriceTableValues
                : onlyQuantity?.length > 0
                  ? onlyQuantity
                  : [],
          },
        })
          .then(() => {
            reftechProductPriceTablesList();
            setLoading(false);
            setShowNewForm(false);
          })
          .catch((errors: any) => {
            setLoading(false);
            alert(errors?.message);
          });
      }
      if (showUpdateForm) {
        setLoading(true);
        updateProductPriceTable({
          variables: {
            id: productPriceTable?.productPriceTable?.id,
            name: data?.name,
            isDefault: data?.default,
            productOptionId: +data?.productsOption,
            productCategoryId: +data?.productCategory,
            productPriceTableValues:
              productPriceTableValues?.length > 0
                ? productPriceTableValues
                : onlyQuantity?.length > 0
                  ? onlyQuantity
                  : [],
          },
        })
          .then(() => {
            reftechProductPriceTablesList();
            setLoading(false);
            setShowUpdateForm(false);
          })
          .catch((errors: any) => {
            setLoading(false);
            // alert(error?.message);(errors?.message);
          });
      }
    }
  };

  useDisableBodyScroll(showDeleteModal);
  const [changeOnDoubleClick, setChangeOnDoubleClick] = useState(0);

  // --
  // -- Update form according to the price values
  // --
  useEffect(() => {
    reset({ productsPrice: [] });

    // --
    // -- Group price values by count: { count: { optionId1: price, ... , optionIdN: price }}
    // --
    const currentProductTableValues = productPriceTable?.productPriceTable
      ?.productPriceTableValues as IProductPriceTableValue[];
    const priceByCount =
      !!currentProductTableValues?.length &&
      currentProductTableValues.reduce(
        (acc, { count = "", price, productOption }) => ({
          ...acc,
          [count]: {
            ...(acc?.[count] || {}),
            ...(productOption?.id ? { [productOption.id]: price } : {}),
          },
        }),
        {} as Record<string, Record<string, string | number>>,
      );

    // --
    // -- Add fields for price values
    // --
    append(
      Object.entries(priceByCount).map(([count, optionPrices]) => ({
        count,
        ...optionPrices,
      })),
    );
  }, [productPriceTable, reset, append, productOptionId, changeOnDoubleClick]);

  useDisableBodyScroll(showDeleteModal);

  return (
    <div className="bg-white h-full">
      <ProductNavMenu />
      <Fragment>
        <div className="grid grid-cols-1">
          <h3 className="text-[32px] py-[10px] px-3 text-[#393939] leading-10">
            Products Price Table
          </h3>
          <ActionButton
            buttonName="Add"
            icon="icon-plus"
            className="ml-3 mb-4 pr-4 bg-[#87B87F] border-[#87B87F] hover:bg-[#629B58] w-min whitespace-nowrap"
            onClick={() => {
              setShowNewForm(true);
              setShowUpdateForm(false);
              setCheckBox(false);
              reset({
                name: "",
                default: false,
                productsPrice: [],
                productsOption: selectOptionsProductOptions
                  ? +selectOptionsProductOptions[0]?.id
                  : "",
                productCategory: selectOptionsProductCategories
                  ? +selectOptionsProductCategories[0]?.id
                  : "",
              });
            }}
          />
          {loadingProductPriceTables && <LoadingDesign />}
          {!loadingProductPriceTables && (
            <div className="py-[10px] px-3">
              <ProductPriceTableContent
                activeLine={activeLine}
                currentPage={currentPage}
                fetchProductPriceTable={fetchProductPriceTable}
                pageSize={pageSize}
                paginate={paginate}
                setActiveLine={setActiveLine}
                setCurrentPage={setCurrentPage}
                setEditProduct={setEditProduct}
                setPageSize={setPageSize}
                setShowDeleteModal={setShowDeleteModal}
                setShowNewForm={setShowNewForm}
                setShowUpdateForm={setShowUpdateForm}
                showDeleteModal={showDeleteModal}
                productPriceTablesList={productPriceTablesList}
                setChangeOnDoubleClick={setChangeOnDoubleClick}
              />
            </div>
          )}
        </div>
        {showUpdateForm && (
          <ProductPriceTableForm
            handleSubmit={handleSubmit}
            errors={errors}
            priceTableSubmitHandler={priceTableSubmitHandler}
            singleProductPriceTable={productPriceTable}
            register={register}
            selectOptionsProductCategories={selectOptionsProductCategories}
            append={append}
            checkbox={checkbox}
            fields={fields}
            productOptionsSubOptions={productOptionsSubOptions}
            remove={remove}
            selectOptionsProductOptions={selectOptionsProductOptions}
            setCheckBox={setCheckBox}
            showUpdateForm={showUpdateForm}
            loadingProductPriceTable={loadingProductPriceTable}
            loading={showDeleteModal && editProduct ? false : loading}
          />
        )}
        {showNewForm && (
          <ProductPriceTableForm
            handleSubmit={handleSubmit}
            errors={errors}
            priceTableSubmitHandler={priceTableSubmitHandler}
            singleProductPriceTable={productPriceTable}
            register={register}
            selectOptionsProductCategories={selectOptionsProductCategories}
            append={append}
            checkbox={checkbox}
            fields={fields}
            productOptionsSubOptions={productOptionsSubOptions}
            remove={remove}
            selectOptionsProductOptions={selectOptionsProductOptions}
            setCheckBox={setCheckBox}
            showUpdateForm={showUpdateForm}
            loading={showDeleteModal && editProduct ? false : loading}
          />
        )}
        {showDeleteModal && editProduct ? (
          <ProductDeleteModal
            setShowDeleteModal={setShowDeleteModal}
            productType="productPriceTable"
            loading={loading}
            onDelete={() => {
              if (!loading) {
                if (editProduct?.id) {
                  setLoading(true);
                  deleteProductPriceTable({
                    variables: {
                      ids: [editProduct?.id],
                    },
                  })
                    .then(() => {
                      setLoading(false);
                      reftechProductPriceTablesList();
                      setShowDeleteModal(false);
                      if (
                        productPriceTablesList?.productPriceTables
                          ?.dataCollection?.length === 1
                      ) {
                        setCurrentPage(1);
                      }
                      setShowNewForm(false);
                      setShowUpdateForm(false);
                    })
                    .catch((errors: any) => {
                      setLoading(false);
                      // alert(error?.message);(errors?.message);
                    });
                }
              }
            }}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default ProductsPriceTable;
