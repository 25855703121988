import { FC, ReactNode, useMemo } from "react";
import { FormFields } from "../../../types/Form";
import Input from "./Input";
import SelectFromServerData from "./SelectFromServerData";
import Calendar from "./Calendar";
import CheckboxNew from "./CheckboxNew";
import StateBox from "./StateBox";
import Select from "./Select";

const FormCard: FC<{
  name: string;
  className?: string;
  additionalButtons?: {
    label: string | ReactNode;
    icon?: string;
    color: "default" | "success" | "warning" | "yellow";
    name: string;
    onClick: () => void;
    disabled?: boolean;
    hidden?: boolean;
  }[];
  label?: string;
  formFields: FormFields[];
  additionalErrors?: Record<string, React.ReactNode>;
}> = ({
  className,
  additionalButtons,
  name,
  label,
  formFields,
  additionalErrors,
}) => {
    const labelWidth = useMemo(() => "135px", []);

    const expYearList = useMemo(() => {
      const currentYear = new Date().getFullYear() + 14;
      return Array.from({ length: 16 }, (_, i) => {
        if (i === 0) {
          return "";
        }

        return (currentYear - i + 1).toString();
      });
    }, []);

    const expMonthList = useMemo(
      () =>
        Array.from({ length: 13 }, (_, i) => {
          if (i === 0) {
            return "";
          }

          if (i < 10) {
            return `0${i}`;
          }

          return i.toString();
        }),
      [],
    );

    return (
      <div className={`form-card ${className || ""}`}>
        {(label || additionalButtons) && (
          <div className="form-card__header">
            {label && <div className="label"> {label} </div>}
            {additionalButtons && !!additionalButtons.length && (
              <div className="control">
                {additionalButtons
                  .filter((filterBtnItem) => !filterBtnItem.hidden)
                  .map((button, index) => (
                    <button
                      disabled={button.disabled}
                      onClick={button.onClick}
                      key={`button-${name}-${button.name}-${index}`}
                      className={`btn btn-small btn-${button.color} header-with-reactive-value-and-save-btn__btn`}
                    >
                      {button.icon && <i className={button.icon} />}
                      {button.label}
                    </button>
                  ))}
              </div>
            )}
          </div>
        )}
        <div className="form-card__content-list">
          {formFields.map((formFieldList, index) => (
            <div
              className={`form-card__content ${formFields.length > 1 ? "form-card__content_semi" : ""
                }`}
              key={`form-card-content-${name}-${index}`}
            >
              {formFieldList
                .filter((formField) => !!formField.accessedFields)
                .map((filteredFormField, index) => (
                  <div
                    className="form-card__edit-el-wrapper"
                    key={`form-card-edit-el-${filteredFormField.name}-${filteredFormField.type}-${index}`}
                  >
                    {(filteredFormField.type === "input" || filteredFormField.type === "number") && (
                      <Input
                        label={filteredFormField.label}
                        value={
                          typeof filteredFormField.value === "number" ||
                            typeof filteredFormField.value === "string"
                            ? filteredFormField.value
                            : ""
                        }
                        onChange={(value) => {
                          filteredFormField.onChange(value);
                        }}
                        rowCount={filteredFormField.inputOptions?.rowCount}
                        isTextarea={filteredFormField.inputOptions?.isTextarea}
                        name={filteredFormField.name}
                        pattern={filteredFormField.inputOptions?.pattern}
                        labelWidth={labelWidth}
                        type={filteredFormField.type === "number" ? "number" : "text"}
                        isIncorrectValue={filteredFormField.isIncorrectValue}
                        disabled={!filteredFormField.accessedFields?.editable}
                      />
                    )}
                    {filteredFormField.type === "select" &&
                      (filteredFormField.subType === "users" ||
                        filteredFormField.subType === "countries" ||
                        filteredFormField.subType === "genders" ||
                        filteredFormField.subType === "clientAccessLevels" ||
                        filteredFormField.subType === "industryTags" ||
                        filteredFormField.subType === "shippingAddressTypes" ||
                        filteredFormField.subType === "paymentTypes" ||
                        filteredFormField.subType === "suppliers" ||
                        filteredFormField.subType === "shippingMethods" ||
                        filteredFormField.subType === "configQuotesStatuses" ||
                        filteredFormField.subType === "paymentStatuses") && (
                        <SelectFromServerData
                          label={filteredFormField.label}
                          value={
                            typeof filteredFormField.value === "number" ||
                              typeof filteredFormField.value === "string"
                              ? filteredFormField.value
                              : undefined
                          }
                          onChange={(value) => {
                            if (value !== undefined) {
                              filteredFormField.onChange(value);
                            }
                          }}
                          type={filteredFormField.subType}
                          isIncorrectValue={filteredFormField.isIncorrectValue}
                          labelWidth={labelWidth}
                          disabled={!filteredFormField.accessedFields?.editable}
                        />
                      )}
                    {filteredFormField.type === "date" && (
                      <Calendar
                        label={filteredFormField.label}
                        labelWidth={labelWidth}
                        name={filteredFormField.name}
                        startDate={filteredFormField.dateOptions?.startDate}
                        endDate={filteredFormField.dateOptions?.endDate}
                        onStartDateChange={(value) => {
                          filteredFormField.dateOptions?.onStartDateChange(value);
                        }}
                        onEndDateChange={(value) => {
                          if (filteredFormField.dateOptions?.onEndDateChange) {
                            filteredFormField.dateOptions?.onEndDateChange(value);
                          }
                        }}
                        disabled={!filteredFormField.accessedFields?.editable}
                        type={
                          filteredFormField.subType === "range-date"
                            ? "range"
                            : "default"
                        }
                      />
                    )}
                    {filteredFormField.type === "checkbox" && (
                      <CheckboxNew
                        value={!!filteredFormField.value}
                        onChange={filteredFormField.onChange}
                        name={filteredFormField.name}
                        labelWidth={labelWidth}
                        label={filteredFormField.label}
                        disabled={!filteredFormField.accessedFields?.editable}
                      />
                    )}
                    {filteredFormField.type === "select" &&
                      filteredFormField.subType === "states" && (
                        <StateBox
                          disabled={!filteredFormField.accessedFields?.editable}
                          value={
                            typeof filteredFormField.value === "string"
                              ? filteredFormField.value
                              : ""
                          }
                          onChange={filteredFormField.onChange}
                          labelWidth={labelWidth}
                          onChangeTax={(value) => {
                            if (filteredFormField.stateOptions?.onChangeTax) {
                              filteredFormField.stateOptions.onChangeTax(value);
                            }
                          }}
                        />
                      )}
                    {filteredFormField.type === "select" &&
                      !filteredFormField.subType && (
                        <Select
                          disabled={!filteredFormField.accessedFields?.editable}
                          value={
                            typeof filteredFormField.value === "string" ||
                              typeof filteredFormField.value === "number"
                              ? filteredFormField.value
                              : undefined
                          }
                          optionsGroups={
                            filteredFormField.selectOptions?.optionsGroup || []
                          }
                          onChange={(value) => {
                            if (
                              typeof value === "string" ||
                              typeof value === "number"
                            ) {
                              filteredFormField.onChange(value);
                            }
                          }}
                          name={filteredFormField.name}
                          label={filteredFormField.label}
                          labelWidth={labelWidth}
                          isIncorrectValue={filteredFormField.isIncorrectValue}
                        />
                      )}
                    {filteredFormField.type === "select" &&
                      filteredFormField.subType === "exp-date" && (
                        <Select
                          disabled={!filteredFormField.accessedFields?.editable}
                          value={filteredFormField.expDateOptions?.yearValue}
                          optionsGroups={[{ options: expYearList }]}
                          onChange={(value) => {
                            if (
                              typeof value === "string" &&
                              filteredFormField.expDateOptions?.onYearChange
                            ) {
                              filteredFormField.expDateOptions?.onYearChange(
                                value,
                              );
                            }
                          }}
                          name="exp-year"
                          label={filteredFormField.label}
                          labelWidth={labelWidth}
                          selectWidth="calc(50% - 4px)"
                          isIncorrectValue={filteredFormField.isIncorrectValue}
                        >
                          <Select
                            disabled={!filteredFormField.accessedFields?.editable}
                            value={filteredFormField.expDateOptions?.monthValue}
                            optionsGroups={[{ options: expMonthList }]}
                            onChange={(value) => {
                              if (
                                typeof value === "string" &&
                                filteredFormField.expDateOptions?.onMonthChange
                              ) {
                                filteredFormField.expDateOptions?.onMonthChange(
                                  value,
                                );
                              }
                            }}
                            name="exp-month"
                            className="form-card__exp-date-select"
                          />
                        </Select>
                      )}
                    {additionalErrors?.[filteredFormField.name] && (
                      <div className="bg-[#C94D32] text-white col-span-2 rounded-sm p-2 text-sm">
                        {additionalErrors?.[filteredFormField.name]}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

export default FormCard;
