import { Combobox, Transition } from "@headlessui/react";
import { FC, Fragment, ReactNode, useEffect } from "react";

const SelectCombobox: FC<{
  value: string;
  onChange: (value: string) => void;
  isIncorrectValue?: boolean;
  labelWidth?: string;
  selectWidth?: string;
  selectOptions: string[];
  label?: string | ReactNode;
  name: string;
  color?: "gray" | "blue" | "transparent";
  displayValue?: string | ReactNode;
  disabled?: boolean;
}> = ({
  value,
  onChange,
  isIncorrectValue,
  labelWidth,
  selectWidth,
  selectOptions,
  label,
  name,
  color = "gray",
  displayValue,
  disabled,
}) => {
  useEffect(() => {
    if (!value && selectOptions.length) {
      onChange(selectOptions[0]);
    }
  }, [selectOptions]);
  return (
    <div className="select-combobox">
      {label && (
        <div
          className="select-combobox__label"
          style={{ maxWidth: `${labelWidth || "max-content"}` }}
        >
          {label}
          {isIncorrectValue && <i className="icon-cancel-circled red" />}
        </div>
      )}

      <div style={{ width: `${selectWidth || "120px"}` }}>
        <Combobox
          value={value}
          onChange={(value) => onChange(value)}
          name={name}
        >
          <div className="select-combobox__wrapper">
            <Combobox.Button
              className="select-combobox__button"
              disabled={disabled}
            >
              <div className="btn-group">
                <div className={`btn btn-small btn-${color}`}>
                  {displayValue || value}
                </div>
                <div className={`btn btn-small btn-${color}`}>
                  <i className="icon-angle-down" />
                </div>
              </div>
            </Combobox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options static className="select-combobox__options">
                {selectOptions.map((selectOption) => (
                  <Combobox.Option
                    key={selectOption}
                    value={selectOption}
                    className="select-combobox__option"
                  >
                    {selectOption}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
};

export default SelectCombobox;
