import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useMutation } from "@apollo/client";
import {
  CREATE_QUICK_QUOTE_ADDITIONAL_INFO,
  UPDATE_QUICK_QUOTE_ADDITIONAL_INFO,
} from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import CheckboxNew from "../../../../components/Form/El/CheckboxNew";
import { isValidInteger } from "../../../../helpers/Validation";
import { getIntegerNumberFromString } from "../../../../helpers/utils";

const AdminDashboardEditQuickQuoteAdditionalInfo: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentDescription("");
    setCurrentPrice(null);
    setCurrentCount(null);
    setCurrentIsDefault(false);
  }, []);
  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_QUICK_QUOTE_ADDITIONAL_INFO, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { updateQuickQuoteAdditionalInfo } = data;

      if (!updateQuickQuoteAdditionalInfo) return;

      const { id } = updateQuickQuoteAdditionalInfo;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_QUICK_QUOTE_ADDITIONAL_INFO, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createQuickQuoteAdditionalInfo } = data;

      if (!createQuickQuoteAdditionalInfo) return;

      const { id } = createQuickQuoteAdditionalInfo;

      onAfterSave(id || 0);
    },
  });

  const [currentDescription, setCurrentDescription] = useState("");
  const [currentPrice, setCurrentPrice] = useState<string | null>(null);
  const [currentCount, setCurrentCount] = useState<string | null>(null);
  const [currentIsDefault, setCurrentIsDefault] = useState(false);

  const isValidPrice = useMemo(() => {
    return currentPrice === null || isValidInteger(currentPrice);
  }, [currentPrice]);

  const isValidCount = useMemo(() => {
    return currentCount === null || isValidInteger(currentCount);
  }, [currentCount]);

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { description, price, count, isDefault } = currentDataCollectionItem;

    if (description) {
      setCurrentDescription(description);
    }

    if (typeof count === "number") {
      setCurrentCount(count.toString());
    }

    if (typeof price === "number") {
      setCurrentPrice(price.toString());
    }

    if (isDefault) {
      setCurrentIsDefault(isDefault);
    }
  }, [currentDataCollectionItem]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            description: currentDescription,
            count: getIntegerNumberFromString(currentCount),
            price: getIntegerNumberFromString(currentPrice),
            default: currentIsDefault,
          },
        });
      }
      return await createItems({
        variables: {
          description: currentDescription,
          count: getIntegerNumberFromString(currentCount),
          price: getIntegerNumberFromString(currentPrice),
          default: currentIsDefault,
        },
      });
    };

    await handleSaveById();
  }, [
    currentDescription,
    currentId,
    currentCount,
    currentPrice,
    currentIsDefault,
  ]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm = !!currentDescription && isValidCount && isValidPrice;
    setDisabledSaveButton(!isValidForm);
  }, [currentDescription, isValidCount, isValidPrice]);

  return (
    <Fragment>
      <Input
        value={currentDescription}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentDescription(value);
          }
        }}
        name="quick-qoute-add-info-dsc"
        labelWidth={labelWidth}
        label="Description"
        placeholder="Description"
        inputWidth={inputWidth}
        isIncorrectValue={!currentDescription}
      />
      <Input
        value={
          currentPrice !== null &&
          typeof +currentPrice === "number" &&
          !isNaN(+currentPrice)
            ? currentPrice
            : ""
        }
        onChange={(value) => {
          const stringValue = value.toString();
          setCurrentPrice(stringValue ? stringValue : null);
        }}
        name="quick-qoute-add-info-price"
        labelWidth={labelWidth}
        label="Price"
        placeholder="Price"
        inputWidth={inputWidth}
        type="number"
        isIncorrectValue={!isValidPrice}
      />
      <Input
        value={
          currentCount !== null &&
          typeof +currentCount === "number" &&
          !isNaN(+currentCount)
            ? currentCount
            : ""
        }
        onChange={(value) => {
          const stringValue = value.toString();
          setCurrentCount(stringValue ? stringValue : null);
        }}
        name="quick-qoute-add-info-count"
        labelWidth={labelWidth}
        label="Count"
        placeholder="Count"
        inputWidth={inputWidth}
        type="number"
        isIncorrectValue={!isValidCount}
      />
      <CheckboxNew
        value={currentIsDefault}
        onChange={setCurrentIsDefault}
        name="quick-qoute-add-info-default"
        labelWidth={labelWidth}
        label="Default"
      />
    </Fragment>
  );
};

export default AdminDashboardEditQuickQuoteAdditionalInfo;
