import { FC, ReactNode } from "react";

const CheckboxNew: FC<{
  labelWidth?: string;
  label?: string | ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  isIncorrectValue?: boolean;
  disabled?: boolean;
  name: string;
  children?: ReactNode;
}> = ({
  labelWidth,
  label,
  value,
  onBlur,
  onFocus,
  onChange,
  disabled,
  isIncorrectValue,
  className,
  name,
  children,
}) => {
  return (
    <div
      className={`checker-wrapper ${className || ""} ${
        label ? "checker-wrapper_with-label" : ""
      }`}
    >
      {label && (
        <div
          className="checker-wrapper__label"
          style={{ width: `${labelWidth || "max-content"}` }}
        >
          {label}
          {isIncorrectValue && <i className="icon-cancel-circled red" />}
        </div>
      )}
      <div className="checker-wrapper__input-wrapper">
        {children && children}
        <button
          className={`checker-wrapper__input ${
            value ? "checker-wrapper__input_checked" : ""
          }`}
          onClick={() => {
            onChange(!value);
          }}
          name={name}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {value && <i className={`icon-ok`} />}
        </button>
      </div>
    </div>
  );
};

export default CheckboxNew;
