import React from "react";

const NotFoundPage = () => {
  return (
    <div className="w-full h-[95vh] flex justify-center items-center text-gray-400">
      You don't have privilege to see this.
    </div>
  );
};

export default NotFoundPage;
