import { FC, ReactNode } from "react";
import type { HeaderItemList } from "types/Header";

const HeaderWithReactiveValueAndSaveBtn: FC<{
  headerItemList: HeaderItemList;
  onClickSaveButton?: Function;
  className?: string;
  isShowSaveButton?: boolean;
  disabledSaveButton?: boolean;
  additionalButtons?: {
    label: string | ReactNode;
    icon?: string;
    color: "default" | "success" | "warning" | "yellow";
    name: string;
    onClick: () => void;
    disabled?: boolean;
    hidden?: boolean;
  }[];
}> = ({
  headerItemList,
  onClickSaveButton,
  className,
  isShowSaveButton,
  disabledSaveButton,
  additionalButtons,
}) => {
  const visibleHeaderItemList = headerItemList.filter(
    (headerFilterItem) => headerFilterItem.isVisible,
  );

  return (
    <div
      className={`header-with-reactive-value-and-save-btn ${className || ""}`}
    >
      <div className="header-with-reactive-value-and-save-btn__values">
        {visibleHeaderItemList.map((headerItem) => {
          return (
            <div
              key={`header-item-${headerItem.name}}`}
              className="header-with-reactive-value-and-save-btn__value-item"
            >
              <div className="label">{`${headerItem.label}:`}</div>
              <div
                className={`value ${headerItem.onClick ? "link" : ""}`}
                onClick={() => {
                  if (headerItem.onClick) {
                    headerItem.onClick();
                  }
                }}
              >
                {headerItem.value}
              </div>
            </div>
          );
        })}
      </div>
      <div className="header-with-reactive-value-and-save-btn__buttons">
        {isShowSaveButton && (
          <button
            onClick={() => {
              if (onClickSaveButton) {
                onClickSaveButton();
              }
            }}
            className="btn btn-small btn-success header-with-reactive-value-and-save-btn__btn"
            disabled={disabledSaveButton || false}
          >
            <i className="icon-floppy" />
            Save
          </button>
        )}
        {additionalButtons &&
          additionalButtons
            .filter((filterBtnItem) => !filterBtnItem.hidden)
            .map((button, index) => (
              <button
                disabled={button.disabled}
                onClick={button.onClick}
                key={`button-header-${button.name}-${index}`}
                className={`btn btn-small btn-${button.color} header-with-reactive-value-and-save-btn__btn`}
              >
                {button.icon && <i className={button.icon} />}
                {button.label}
              </button>
            ))}
      </div>
    </div>
  );
};

export default HeaderWithReactiveValueAndSaveBtn;
