import { Params } from "../types/Main";

export function onChangeParamsCallback(
  prevParams: Params,
  currentParams: Params,
  onUpdatePrevParamsCallback: (newParams: Params) => void,
  afterUpdateParamsCallback: () => void,
) {
  const keys = Object.keys(currentParams);
  let isNeedUpdatePrevValue: boolean;
  let isNeedUseAfterUpdateParamsCallback: boolean;

  isNeedUpdatePrevValue = keys.some((key) => {
    const currentValue = currentParams[key];
    const prevValue = prevParams[key];

    return JSON.stringify(currentValue) !== JSON.stringify(prevValue);
  });

  if (isNeedUpdatePrevValue) {
    onUpdatePrevParamsCallback(currentParams);
  }

  isNeedUseAfterUpdateParamsCallback = keys.every(
    (key) => prevParams[key] !== undefined && currentParams[key] !== undefined,
  );
  if (isNeedUseAfterUpdateParamsCallback) {
    afterUpdateParamsCallback();
  }
}
