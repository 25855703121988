import {
  FC,
  Fragment,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { TableHeading } from "types/Table";
import { ChildrenTable } from "types/Table";
import TablePagination from "./TablePagination";
import { LoadingDesign } from "helpers";
import CheckboxNew from "../Form/El/CheckboxNew";
import TableRow from "./TableRow";
import { cloneDeep } from "lodash";

const TableNew: FC<{
  heading: TableHeading;
  currentItemsPerPage?: number;
  currentPage?: number;
  countPages?: number;
  setCurrentPage?: Function;
  setPageSize?: Function;
  setSortOrder?: Function;
  sortOrder?: {
    columnName: string;
    orderBy: "DESC" | "ASC";
  };
  loading?: boolean;
  children?: ChildrenTable[];
  colorHeader?: "gray" | "blue";
  tableType?: "default" | "collapse";
  disabled?: boolean;
  showTryAgainMenu?: boolean;
  isEnabledSelectItems?: boolean;
  selectedIds?: number[];
  setSelectedIds?: (ids: number[]) => void;
  setIsNeedUpdateTableData: () => void;
  draggable?: boolean;
  onDragEnter?: (currentIndex: number, overIndex: number) => void;
}> = ({
  heading,
  currentItemsPerPage,
  countPages,
  currentPage,
  setCurrentPage,
  setPageSize,
  setSortOrder,
  sortOrder,
  loading,
  children,
  colorHeader = "gray",
  tableType = "collapse",
  disabled,
  selectedIds,
  setSelectedIds,
  isEnabledSelectItems,
  setIsNeedUpdateTableData,
  showTryAgainMenu,
  draggable,
  onDragEnter,
}) => {
  const itemsPerPage = [10, 25, 50, 100];
  const [orderBy, setOrderBy] = useState(false);

  const ids = useMemo(() => {
    if (!children || !children.length) return [];

    return children.map((childrenItem) => childrenItem.id).sort();
  }, [children]);

  const isAllItemSelected = useMemo(() => {
    if (!selectedIds || !selectedIds?.length) return false;

    return ids.every((e) => selectedIds.includes(e));
  }, [ids, selectedIds]);

  const onSelect = useCallback(
    (ids: number[], isActive: boolean) => {
      if (!selectedIds || !setSelectedIds) return;

      const tmpSelectedIds: number[] = [];

      ids.forEach((id) => {
        const index = selectedIds.findIndex((el) => el === id);

        if ((isActive && index === -1) || (!isActive && index !== -1)) {
          tmpSelectedIds.push(id);
        }
      });

      setSelectedIds(tmpSelectedIds.sort());
    },
    [selectedIds, setSelectedIds],
  );
  const [draggingItem, setDraggingItem] = useState<number | undefined>();
  const [dragOverItem, setDragOverItem] = useState<number | undefined>();

  const tBodyRed = useRef<HTMLTableSectionElement | null>();

  const onDragStartHandle = useCallback(
    (evtTarget: EventTarget, position: number) => {
      setDraggingItem(position);
    },
    [draggingItem],
  );

  const onDragEnterHandle = useCallback(
    (evtTarget: EventTarget, position: number) => {
      if (draggingItem !== undefined &&
          draggingItem === position) {
        return;
      }
      setDragOverItem(position);

      /*if (tBodyRed.current && tBodyRed.current.children
      ) {
        console.log(tBodyRed.current.children, 'tBodyRed.current.children')
        const childrenArr = Array.from(tBodyRed.current.children);
        const tmpChildren = cloneDeep(childrenArr);
        const insertedChildrenIndex =  childrenArr.findIndex(el => {
          return el.id === `${draggingItem}`
        })
console.log(insertedChildrenIndex, 'insertedChildrenIndex')
        tmpChildren.splice(insertedChildrenIndex, 1);
        tmpChildren.splice(position, 0, childrenArr[insertedChildrenIndex]);
        console.log(tmpChildren)
        tBodyRed.current.replaceChildren(...tmpChildren)
      }*/

    },
    [draggingItem, dragOverItem, tBodyRed],
  );

  return (
    <div className="table__wrapper">
      <div className="table__content-wrapper">
        <table className={`table table_${tableType}`}>
          <thead className={`table__thead_${colorHeader}`}>
            <tr>
              {children && children.some((el) => !!el.expandRow) && <th />}
              {isEnabledSelectItems && !!selectedIds && (
                <th>
                  <CheckboxNew
                    value={isAllItemSelected}
                    onChange={() => {
                      if (isAllItemSelected) {
                        onSelect(ids, false);
                        return;
                      }
                      onSelect(ids, true);
                    }}
                    name={`table-row-select-all`}
                  />
                </th>
              )}
              {heading.map((headingItem, index) => {
                return (
                  <th
                    key={`th-${headingItem.name}-${index}`}
                    onClick={() => {
                      if (
                        headingItem.sortable &&
                        setOrderBy &&
                        sortOrder &&
                        setSortOrder
                      ) {
                        setOrderBy(!orderBy);

                        setSortOrder({
                          columnName: headingItem.name,
                          orderBy: orderBy ? "DESC" : "ASC",
                        });
                      }
                    }}
                    className={headingItem.isSmall ? "small" : ""}
                    style={{ width: `${headingItem.minWidth || "unset"}` }}
                  >
                    {headingItem.label}
                    {headingItem.sortable && (
                      <span className="table__sort-icon">
                        <i className={`icon-sort`} />
                      </span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody
            ref={(el) => {
              tBodyRed.current = el;
            }}
          >
            {(loading || (!loading && !countPages)) && (
              <tr>
                <td colSpan={20}>
                  {loading && (
                    <div className="table__info table__info__loading">
                      <LoadingDesign className="max-h-max" />
                    </div>
                  )}
                  {!loading && !countPages && showTryAgainMenu && (
                    <div className="table__info">
                      <div className="table__info-title">Data is empty.</div>
                      <div>
                        If you used a filter, try changing it, or try again.
                      </div>
                      <button
                        className="btn btn-warning btn-small"
                        onClick={setIsNeedUpdateTableData}
                      >
                        Try again
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            )}
            {!loading &&
              !!countPages &&
              children &&
              children.map((childrenItem, index) => (
                <TableRow
                    index={index}
                  key={`table-row-${index}-${childrenItem.id}`}
                  row={childrenItem.row}
                  id={childrenItem.id}
                  additionalCall={childrenItem.additionalClassRow}
                  expandRow={childrenItem.expandRow}
                  headingList={heading}
                  isShowSelect={isEnabledSelectItems && !!selectedIds}
                  selectedOptions={{
                    onSelect: (id: number, isActive: boolean) => {
                      onSelect([id], isActive);
                    },
                    isSelected: !!selectedIds?.includes(childrenItem.id),
                  }}
                  draggable={draggable && !childrenItem.blockDraggable}
                  onDragStart={(evtTarget) => {
                    onDragStartHandle(evtTarget, index);
                  }}
                  onDragEnter={(evtTarget) => {
                    onDragEnterHandle(evtTarget, index);
                  }}
                  onDrop={() => {
                    if (
                      draggingItem !== undefined &&
                      dragOverItem !== undefined &&
                      draggingItem !== dragOverItem &&
                      onDragEnter
                    ) {
                      onDragEnter(draggingItem, dragOverItem);
                    }
                    setDragOverItem(undefined);
                    setDraggingItem(undefined);
                  }}
                  hidden={
                    draggable &&
                    dragOverItem !== undefined &&
                    index === dragOverItem &&
                    dragOverItem !== draggingItem
                  }
                    clearDropHandle={() => {
                      setDragOverItem(undefined);
                      setDraggingItem(undefined);
                    }}
                />
              ))}
          </tbody>
        </table>
      </div>
      {!!countPages && setPageSize && (
        <div className="table__page-size">
          {itemsPerPage.map((itemsPerPageValue, index) => {
            return (
              <button
                key={`table-page-size-${itemsPerPageValue}-${index}`}
                className={`btn btn-mini btn-group ${
                  currentItemsPerPage === itemsPerPageValue ? "active" : ""
                }`}
                onClick={() => {
                  if (currentItemsPerPage !== itemsPerPageValue) {
                    setPageSize(itemsPerPageValue);
                  }
                }}
              >
                {itemsPerPageValue}
              </button>
            );
          })}
        </div>
      )}
      {!!countPages &&
        !!currentPage &&
        countPages !== 1 &&
        typeof setCurrentPage === "function" && (
          <div className="table__pagination-wrapper">
            <TablePagination
              countPages={countPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
    </div>
  );
};

export default TableNew;
