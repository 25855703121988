import React, { useEffect, useState } from "react";
import { defaultStyles, FileIcon } from "react-file-icon";
import { getFileExtensionUsingName } from "./utils";

const QuoteFileIcon = ({ imageName, imageURL }) => {
  const [ext, setExt] = useState("");
  useEffect(() => {
    setExt(getFileExtensionUsingName(imageName).toLowerCase());
  }, [imageName]);
  if (ext === "jpg" || ext === "jpeg" || ext === "png")
    return (
      <div
        className={`max-w-16-px max-h-16-px truncate object-center${
          imageName ? "block" : "hidden"
        }`}
      >
        <img src={imageURL} alt={imageName} className="shadow" />
      </div>
    );
  return <FileIcon extension={ext} {...defaultStyles[ext || "jpeg"]} />;
};

export default QuoteFileIcon;
