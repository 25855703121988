import { useMutation } from "@apollo/client";
import ProductDeleteModal from "helpers/ProductDeleteModal";
import { DELETE_PRODUCT_LIST } from "modules/Products/Services/Mutations/Mutation";
import React, { FC, Fragment, useState } from "react";
import Table from "../../../../components/Table/Table";
import { TableHeading } from "../../../../types/Table";

const tableHeading = ["#", "Product Name", "Description", "Delete"];

const ProductListTable: FC<{
  currentPage: number;
  setCurrentPage: Function;
  pageSize: number;
  setPageSize: Function;
  setShowEditModal: Function;
  setEditProductList: Function;
  length: number;
  productList: {
    id: number;
    name: string;
    description: string;
  }[];
  fetchSingleProduct: Function;
  refetchProductListCategorywise: Function;
}> = ({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  setShowEditModal,
  setEditProductList,
  length,
  productList,
  fetchSingleProduct,
  refetchProductListCategorywise,
}) => {
  const [activeLine, setActiveLine] = useState<number>();
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [deleteProduct] = useMutation(DELETE_PRODUCT_LIST);
  const [loading, setLoading] = useState(false);
  const tableHeading = ["#", "Product Name", "Description", "Delete"];
  const tableHeadingNew: TableHeading = [
    {
      label: "#",
      name: "#",
    },
    {
      label: "Product Name",
      name: "productName",
    },
    {
      label: "Description",
      name: "dsc",
    },
    {
      label: "Delete",
      name: "delete",
    },
  ];
  return (
    <Fragment>
      <Table
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currentItemsPerPage={pageSize}
        setPageSize={setPageSize}
        heading={tableHeadingNew}
        colorHeader="blue"
        tableType="default"
        countPages={Math.ceil(length / pageSize)}
      >
        {productList?.map((tableContent, index) => (
          <tr
            key={index}
            onClick={() => {
              setActiveLine(index);
            }}
            onDoubleClick={() => {
              setShowEditModal(true);
              setEditProductList(tableContent?.id);
              fetchSingleProduct({
                variables: {
                  id: tableContent?.id,
                },
              });
            }}
            className={activeLine === index ? "active" : "inactive"}
          >
            <td>{index + 1}</td>
            <td>{tableContent?.name ? tableContent?.name : "-"}</td>
            <td>
              {tableContent?.description ? tableContent?.description : "-"}
            </td>
            <td>
              <span
                className={`block bg-[url('./assets/delete-red-image.png')] cursor-pointer w-5 h-5  bg-no-repeat bg-center`}
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                  setDeleteId(tableContent?.id);
                }}
              />
            </td>
          </tr>
        ))}
      </Table>
      {showDeleteModal && (
        <ProductDeleteModal
          setShowDeleteModal={setShowDeleteModal}
          productType="productList"
          loading={loading}
          onDelete={() => {
            if (deleteId) {
              setLoading(true);
              deleteProduct({
                variables: {
                  ids: [+deleteId],
                },
              })
                .then(() => {
                  setLoading(false);
                  setShowDeleteModal(false);
                  refetchProductListCategorywise();
                  setCurrentPage(1);
                })
                .catch((errors: any) => {
                  setLoading(true);
                  alert(errors.message);
                });
            }
          }}
        />
      )}
    </Fragment>
  );
};

export default ProductListTable;
