import { FC, Fragment, useCallback, useEffect, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useMutation } from "@apollo/client";
import {
  CREATE_DOMAIN_IGNORE_LIST,
  UPDATE_DOMAIN_IGNORE_LIST,
} from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";

const AdminDashboardEditDomainIgnoreList: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentName("");
  }, []);
  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_DOMAIN_IGNORE_LIST, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { updateDomainIgnoreList } = data;

      if (!updateDomainIgnoreList) return;

      const { id } = updateDomainIgnoreList;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_DOMAIN_IGNORE_LIST, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createDomainIgnoreList } = data;

      if (!createDomainIgnoreList) return;

      const { id } = createDomainIgnoreList;

      onAfterSave(id || 0);
    },
  });

  const [currentName, setCurrentName] = useState("");

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { name } = currentDataCollectionItem;

    if (name) {
      setCurrentName(name);
    }
  }, [currentDataCollectionItem]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            name: currentName,
          },
        });
      }
      return await createItems({
        variables: {
          name: currentName,
        },
      });
    };

    await handleSaveById();
  }, [currentName, currentId]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm = !!currentName;
    setDisabledSaveButton(!isValidForm);
  }, [currentName]);

  return (
    <Fragment>
      <Input
        value={currentName}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentName(value);
          }
        }}
        name="domain-ignore-list-name"
        labelWidth={labelWidth}
        label="Name"
        placeholder="Name"
        inputWidth={inputWidth}
        isIncorrectValue={!currentName}
      />
    </Fragment>
  );
};

export default AdminDashboardEditDomainIgnoreList;
