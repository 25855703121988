import React, { FC } from "react";

const NormalButton: FC<{
  buttonName: string;
  onClick?: () => void;
  icon?: string;
  buttonType?: "button" | "submit" | "reset" | undefined;
  className?: string;
  loading?: boolean;
  children?: any;
}> = ({
  buttonName,
  buttonType,
  className,
  icon,
  onClick,
  children,
  loading,
}) => {
  return (
    <button
      type={buttonType ? buttonType : "button"}
      className={`cursor-pointer  h-[30px] p-[2px] ${
        className ? className : ""
      } border bg-gradient-to-b gap-x-1 rounded-[4px] text-sm leading-5 shadow-[inset_0_1px_0_rgb(255_255_255/20%),0_1px_2px_rgb(0_0_0/5%)]`}
      onClick={onClick}
    >
      {icon && (
        <i
          className={`${
            icon
              ? loading
                ? "inline-block animate-spin icon-spin4"
                : icon
              : "hidden"
          } pr-1`}
        />
      )}
      {children}
      {buttonName}
    </button>
  );
};

export default NormalButton;
