import { gql } from "@apollo/client";

export const CLIENT_ITEM_QUERY = gql`
  query client($id: Int!) {
    client(id: $id) {
      id
      user {
        id
      }
      company
      accountName
      webAdress
      defaultContact {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
            }
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
            }
          }
        }
        defaultShipping
        defaultBilling
        client {
          id
          user {
            id
          }
        }
        sex
        isDelete
        birthday
      }
      accessLevelCd
      clientContacts {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
        }
        defaultShipping
        defaultBilling
        sex
        isDelete
        birthday
      }
      clientCreditCards {
        id
        holderName
        cardNumber
      }
      shippingAddresses {
        id
        address
        city
        state
        country {
          id
          name
        }
        zip
        attentionTo
        address2
        typeAddress
        isDelete
      }
      industryTag {
        id
        name
      }
    }
  }
`;
