const Select: React.FC<{
  register: Function;
  name: string;
  errors: any;
  optionsWithObject?: { id: number | string; name: string }[] | any;
  optionsWithString?: string[];
  disable?: boolean;
  defaultSelectValue?: string | number;
  required?: boolean;
  onChange?: Function;
  hideError?: boolean;
  className?: string;
  hideSelect?: boolean;
  classForSelect?: string;
  loading?: boolean;
  disableNoResutlFound?: boolean;
}> = ({
  register,
  name,
  errors,
  optionsWithObject,
  optionsWithString,
  disable,
  defaultSelectValue,
  required,
  onChange,
  hideError,
  className,
  hideSelect,
  classForSelect,
  loading,
  disableNoResutlFound,
}) => {
  return (
    <div className={`w-full ${className ? className : ""}`}>
      <select
        id={name}
        defaultValue={defaultSelectValue ? defaultSelectValue : ""}
        {...register(name, {
          required: required ? "This is required field." : false,
          onChange: onChange,
        })}
        disabled={disable ? disable : false}
        className={`select-field w-full h-[30px] cursor-pointer truncate border border-[#cccccc] focus:outline-none pl-[6px] text-sm leading-8 text-[#555555] ${
          classForSelect ? classForSelect : "bg-white rounded"
        }`}
      >
        {hideSelect ? null : (
          <option className="max-w-[500px] truncate" value="">
            Select
          </option>
        )}
        {loading ? (
          <option className="max-w-[500px] truncate" value="" disabled>
            Loading...
          </option>
        ) : optionsWithObject ? (
          optionsWithObject?.length > 0 ? (
            optionsWithObject?.map(
              (
                option: { id: number | string; name: string },
                index: number,
              ) => (
                <option
                  className="max-w-[500px] truncate"
                  value={option.id}
                  key={index}
                >
                  {option?.name}
                </option>
              ),
            )
          ) : (
            <option
              value=""
              className="max-w-[500px] truncate"
              disabled={disableNoResutlFound ? disableNoResutlFound : false}
            >
              No results found.
            </option>
          )
        ) : null}

        {optionsWithString ? (
          optionsWithString?.length > 0 ? (
            optionsWithString?.map((option: string) => (
              <option
                className="max-w-[500px] truncate"
                value={option}
                key={option}
              >
                {option}
              </option>
            ))
          ) : (
            <option
              value=""
              className="max-w-[500px] truncate"
              disabled={disableNoResutlFound ? disableNoResutlFound : false}
            >
              No results found.
            </option>
          )
        ) : null}
      </select>
      <div className={`w-full ${hideError ? "hidden" : "block"}`}>
        {errors[name] ? (
          <span className="text-[#FF647C] text-xs leading-[15px]">
            {errors[name].message}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};
export default Select;
