import {
	Navigate,
	Route,
	Routes,
} from "react-router-dom";
import AdminUnitingClients from "./Pages/Utils/AdminUnitingClients";

const AdminUtils = () => {
	return (
		<div className="w-full">
			<Routes>
				<Route
					path={`merge-clients/*`}
					element={<AdminUnitingClients/>}
				/>
				<Route
					path="/*"
					element={
						<Navigate
							to={"/admin"}
							replace
						/>
					}
				/>
			</Routes>
		</div>
	);
};

export default AdminUtils;
