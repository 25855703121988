import {
  FC,
  Fragment,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Input from "../../../../components/Form/El/Input";
import { cloneDeep } from "lodash";

const EditQuoteInputFiles: FC<{
  onChange: (files: File[]) => void;
  fileComment: {
    value: string;
    onChange: (newVal: string) => void;
  };
  filesList: File[];
}> = ({ onChange, fileComment, filesList }) => {
  const [isDragging, setIsDragging] = useState(false);
  const currentInputPlaceholder = useMemo(() => {
    if (isDragging) {
      return 'Drop files to upload...'
    }

    return 'Drop files or click to upload...'
  }, [isDragging]);

  const onUpdateFiles = useCallback((newFilesList: FileList|null) => {
    if (newFilesList && !!newFilesList.length) {
      onChange([...filesList, ...Array.from(newFilesList)]);
    }
  }, [filesList, onChange])

  return (
    <div
      onDragLeave={(event) => {
        event.preventDefault();
        setIsDragging(false);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setIsDragging(true);
      }}
      onDrop={(event) => {
        event.preventDefault();
        onUpdateFiles(event.dataTransfer.files);
          setIsDragging(false);
      }}
      className={`edit-quote__files-wrapper ${
          isDragging ? "edit-quote__files-wrapper_dragging" : ""
      }`}
    >
      <label
        htmlFor="quote-file-input"
        className='edit-quote__file-input'
      >
        <span className="btn btn-small">
          <i className="icon-upload" />
        </span>
        <span>{currentInputPlaceholder}</span>
        <span className="btn btn-small btn-blue">Add File</span>
        <input
          type="file"
          hidden
          id="quote-file-input"
          multiple
          onChange={(value) => {
            const newFiles = value.target.files;

            onUpdateFiles(newFiles);
          }}
        />
      </label>
      <Input
        value={fileComment.value}
        onChange={(value) => {
          fileComment.onChange(value.toString());
        }}
        name="file-comment-input"
        placeholder="Type comment here..."
        isTextarea={true}
        rowCount={2}
      />

      {!!filesList.length && (
        <div className="edit-quote__file-list">
          {filesList.map((file, index) => (
            <div
              className="edit-quote__file-list-item"
              key={`edit-quote__file-list-item-${index}-${file.name}`}
            >
              <span className="font-bold">{file.name}</span>
              <span>{fileComment.value || "no comment"}</span>
              <button
                className="btn btn-mini btn-warning"
                onClick={() => {
                  const newFilesList = cloneDeep(filesList);
                  newFilesList.splice(index, 1);

                  onChange(newFilesList);
                }}
              >
                <i className="icon-trash-empty" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EditQuoteInputFiles;
