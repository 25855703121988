import { FC, useCallback, useMemo, useState } from "react";
import { QuoteEditData } from "../../../../types/Quote";
import { CREATE_TRANSACTON } from "../../Services/Mutations/Mutations";
import { useMutation } from "@apollo/client";
import { setNotification } from "../../../../helpers/cache";
import { TransactionDetail } from "../../../../types/TransactionDetail";
import { LoadingDesign } from "../../../../helpers";
import CheckboxNew from "../../../../components/Form/El/CheckboxNew";
import { ActionButton } from "../../../../components/Buttons";

const EditQuoteTransactionsPopup: FC<{
  quoteEditData: QuoteEditData;
  transactionDetail: TransactionDetail;
  onCloseModal: () => void;
}> = ({ quoteEditData, transactionDetail, onCloseModal }) => {
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [addOrderCredits, setAddOrderCredits] = useState(false);

  const orderProductUnitPriceArray = useMemo(
    (): number[] =>
      quoteEditData?.orderProducts?.map(
        (orderProducts: any) =>
          orderProducts?.unitPrice * orderProducts?.quantity,
      ),
    [quoteEditData],
  );

  const total = useMemo(
    () =>
      orderProductUnitPriceArray?.reduce((unitPrice, a) => unitPrice + a, 0) ||
      0,
    [orderProductUnitPriceArray],
  );

  const tax = useMemo(
    () => total * (+(quoteEditData?.shippingTax || 0) / 100),
    [quoteEditData, total],
  );

  const [createTransaction] = useMutation(CREATE_TRANSACTON, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const amount = useMemo(() => {
    if (typeof transactionDetail.amount === "undefined") {
      const currentTax = !!tax && !quoteEditData.withoutTax ? tax : 0;

      return (total + currentTax).toFixed(2);
    }

    return !!transactionDetail.amount ? transactionDetail.amount : 0;
  }, [transactionDetail, total, tax, quoteEditData]);

  const onCreateTransaction = useCallback(async () => {
    setIsLoadingTransaction(true);

    await createTransaction({
      variables: {
        id: transactionDetail.id || undefined,
        type: transactionDetail.type,
        quoteId: quoteEditData.id,
        amount: +amount,
        addOrderCredits,
      },
    })
      .then((res) => {
        const { data } = res;

        if (!data) return;

        const { createTransaction } = data;

        if (createTransaction) {
          setNotification([
            {
              type: "SUCCESS",
              message: `${createTransaction}`,
            },
          ]);
        }
      })
      .finally(() => {
        setIsLoadingTransaction(false);
      });
  }, [transactionDetail, amount, addOrderCredits]);
  return (
    <div className="fixed z-50 inset-0 bg-black/70 px-3 sm:px-6">
      {isLoadingTransaction ? (
        <div className="z-50 animate-fade bg-white sm:w-[600px] md:w-[700px] lg:w-[750px] mx-auto mt-24">
          <LoadingDesign />
        </div>
      ) : (
        <div className="h-fit z-50 animate-fade bg-white sm:w-[600px] md:w-[700px] lg:w-[750px] mx-auto mt-24">
          <h2 className="text-[22px] leading-[30px] text-[#393939] px-[15px] py-[9px] border-b border-[#eee] flex justify-between items-center">
            {transactionDetail?.title}
            <span
              onClick={() => {
                onCloseModal();
              }}
              className={`text-4xl cursor-pointer hover:text-black/50"  `}
            >
              ×
            </span>
          </h2>
          {quoteEditData?.order?.clientCreditCardId || transactionDetail.id ? (
            <div
              className={`flex flex-col ${
                transactionDetail?.type.includes("refund")
                  ? "px-[15px] pt-[15px]"
                  : "p-[15px]"
              }  max-h-[500px] overflow-y-auto`}
            >
              <span className="text-[13px] text-[#393939] mb-2">
                Transaction data:
              </span>
              <span className="text-[13px] text-[#393939]">
                Type:{" "}
                <span className="font-bold text-[13px] leading-5 text-[#393939]">
                  {transactionDetail?.type}
                </span>
              </span>
              <span className="text-[13px] text-[#393939]">
                Amount:{" "}
                <span className="font-bold text-[13px] leading-5 text-[#393939]">
                  $
                  {transactionDetail?.amount !== undefined
                    ? !transactionDetail?.amount
                      ? 0
                      : transactionDetail?.amount
                    : tax && !quoteEditData?.withoutTax
                      ? (total + tax)?.toFixed(2)
                      : total?.toFixed(2)}
                </span>
              </span>
              <span className="text-[13px] text-[#393939]">
                Credit card:{" "}
                <span className="font-bold text-[13px] leading-5 text-[#393939]">
                  {transactionDetail?.cardNumber}
                </span>
              </span>
              <span className="text-[13px] text-[#393939">
                Holder name:{" "}
                <span className="font-bold text-[13px] leading-5 text-[#393939]">
                  {transactionDetail?.holderName || ""}
                </span>
              </span>
            </div>
          ) : (
            <div
              className={`${
                transactionDetail?.type.includes("refund")
                  ? "px-[15px] pt-[15px]"
                  : "p-[15px]"
              }`}
            >
              <p className="text-[13px] leading-5 text-[#393939]">
                Credit card not choose!
              </p>
            </div>
          )}
          {transactionDetail?.type.includes("refund") && (
            <div className="pl-[15px] pb-[15px]">
              <CheckboxNew
                value={addOrderCredits}
                onChange={(value) => {
                  setAddOrderCredits(value);
                }}
                name={`add-order-credits-chekcbox`}
                label="Add order credits"
              />
            </div>
          )}

          <div className="bg-[#EFF3F8] p-[15px] border border-[#ccc] flex justify-end gap-2">
            <ActionButton
              buttonName="Close"
              onClick={onCloseModal}
              className={`${
                isLoadingTransaction
                  ? "bg-[#ABBAC3]/50 border-none cursor-not-allowed"
                  : "bg-[#ABBAC3] border-[5px] border-[#ABBAC3] hover:bg-[#8B9AA3]"
              }  py-[5px] font-normal text-base leading-5 px-[19px] font-heltivica`}
            />
            <ActionButton
              buttonName="Send transaction"
              className={`${
                quoteEditData?.order?.clientCreditCardId &&
                !isLoadingTransaction
                  ? "bg-[#87B87F]  border-[#87B87F] hover:bg-[#629B58]"
                  : "bg-[#87B87F]  border-[#87B87F] opacity-50 cursor-not-allowed"
              } border-[5px] py-[5px] font-normal text-base leading-5 px-[19px] font-heltivica`}
              onClick={onCreateTransaction}
              loading={isLoadingTransaction}
              icon={isLoadingTransaction ? "icon-spin4" : ""}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditQuoteTransactionsPopup;
