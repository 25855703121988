import {gql} from "@apollo/client";

export const DOWNLOAD_CSV_ALL_PRODUCTS = gql`
  query exportAllProducts {
    exportAllProducts {
      file
      fileName
    }
  }
`;
