import { menuList } from "layouts/Menu/Menulist";
import React, { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

const ProductNavMenu = () => {
  const slider = useRef<any>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;
  const { pathname } = useLocation();
  return (
    <nav
      className={`flex m-2 px-2 flex-col md:flex-row items-center bg-[#e7f2f8] rounded-[4px] ${menuList?.filter(
        (menu) =>
          menu.name === "Product" || menu.name === "Products price table",
      )} ? "hidden" : "block"`}
    >
      <div className="whitespace-nowrap space-x-1 flex justify-center items-center">
        <i className="icon-home text-[19px] text-[#555555] pb-1" />
        <NavLink
          to={"/"}
          className="hover:underline text-[13px] text-[#4c8fbd]"
        >
          Home
        </NavLink>
        <i className="icon-angle-right" />
        <span className="text-[#555555] text-[13px]">Products</span>
      </div>
      <div
        ref={slider}
        onMouseDown={(e) => {
          isDown = true;
          startX = e?.pageX - slider.current?.offsetLeft;
          scrollLeft = slider?.current?.scrollLeft;
        }}
        onMouseLeave={() => {
          isDown = false;
        }}
        onMouseUp={() => {
          isDown = false;
        }}
        onMouseMove={(e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider?.current?.offsetLeft;
          const walk = (x - startX) * 1;
          slider.current.scrollLeft = scrollLeft - walk;
        }}
        className="flex md:justify-end overflow-x-auto w-full scrollbarHide"
      >
        {menuList
          .filter((menu) => menu.name === "Product")
          ?.map((menu) => menu.subMenus)[0]
          .map((subMenu, index) => {
            return (
              <NavLink
                key={index}
                to={subMenu.subMenuLink}
                className={`${
                  pathname === `${subMenu.subMenuLink}`
                    ? "bg-white border-t-2 border-t-[#4C8FBD] border-x border-x-[#ddd] cursor-default"
                    : ""
                } py-2 px-2 group cursor-pointer flex items-center  space-x-2 whitespace-nowrap`}
              >
                <p
                  className={`text-[13px] leading-4 text-[#999] ${
                    pathname === `${subMenu.subMenuLink}`
                      ? ""
                      : "group-hover:text-[#4c8fbd]"
                  }  ${
                    pathname === `${subMenu.subMenuLink}`
                      ? "text-[#576373]"
                      : ""
                  }`}
                >
                  {subMenu.subMenuName}
                </p>
              </NavLink>
            );
          })}
      </div>
    </nav>
  );
};

export default ProductNavMenu;
