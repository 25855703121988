import { gql } from "@apollo/client";

export const FILTER_CLIENTS = gql`
  query filterClients(
    $filterType: String!
    $firstName: FilterClientFirstName
    $lastName: FilterClientLastName
    $company: FilterClientCompany
    $email: FilterClientEmail
    $fax: FilterClientFax
    $phone1: FilterClientPhone1
    $createdAt: FilterClientsDateCreatedAt
    $limit: Int
    $page: Int
    $sortBy: ClientSortBy
    $hasOrdered: Boolean
    $quoteQuantity: FilterClientQuoteQuantity
    $quoteValue: FilterClientQuoteValue
  ) {
    filterClients(
      filterType: $filterType
      firstName: $firstName
      lastName: $lastName
      company: $company
      email: $email
      phone1: $phone1
      fax: $fax
      createdAt: $createdAt
      limit: $limit
      page: $page
      sortBy: $sortBy
      hasOrdered: $hasOrdered
      quoteQuantity: $quoteQuantity
      quoteValue: $quoteValue
    ) {
      dataCollection {
        id
        user {
          id
          userName
          firstName
          lastName
          email
        }
        firstName
        lastName
        company
        email
        phone
        fax
        sex
        anumber
        phone1
        jobTitle
        accountName
        webAdress
        active
        createdAt
        updatedAt
        lastBillingAddress {
          id
          address
          city
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
          address2
          typeAddress
          isDelete
          createdAt
          updatedAt
        }
        defaultContact {
          id
          firstName
          lastName
          email
          phone1
          phone2
          jobTitle
          fax
          note
          shippingAddress {
            id
            address
            city
            state
            country {
              id
              name
              createdAt
              updatedAt
            }
            zip
            attentionTo
            client {
              id
              user {
                id
                userName
                firstName
                lastName
                email
              }
              firstName
              lastName
              company
              email
            }
          }
          billingAddress {
            id
            address
            city
            state
            country {
              id
              name
              createdAt
              updatedAt
            }
            zip
            attentionTo
            client {
              id
              user {
                id
                userName
                firstName
                lastName
                email
              }
              firstName
              lastName
              company
              email
            }
          }
          defaultShipping
          defaultBilling
          createdAt
          updatedAt
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
          sex
          isDelete
          phone1Search
          phone2Search
          birthday
        }
        accessLevelCd
        temporaryAccessDate
        hasOrdered
        quoteQuantity
        cQuoteCoast
        isEnableAutomaticEmails
        clientContacts {
          id
          firstName
          lastName
          email
          phone1
          phone2
          jobTitle
          fax
          note
          shippingAddress {
            id
            address
            city
            state
            country {
              id
              name
              createdAt
              updatedAt
            }
            zip
            attentionTo
            client {
              id
              user {
                id
                userName
                firstName
                lastName
                email
              }
              firstName
              lastName
              company
              email
            }
          }
          billingAddress {
            id
            address
            city
            state
            country {
              id
              name
              createdAt
              updatedAt
            }
            zip
            attentionTo
            client {
              id
              user {
                id
                userName
                firstName
                lastName
                email
              }
              firstName
              lastName
              company
              email
            }
          }
          defaultShipping
          defaultBilling
          createdAt
          updatedAt
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
          sex
          isDelete
          phone1Search
          phone2Search
          birthday
        }
        clientCreditCards {
          id
          holderName
          expirationDate
          cardNumber
          verificationNumber
          creditCardType
          isDelete
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            company
            email
          }
        }
        shippingAddresses {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          address2
          typeAddress
          createdAt
          isDelete
          updatedAt
          client {
            id
            user {
              id
            }
            firstName
            lastName
            company
            email
          }
        }
      }
      totalNumberOfItems
    }
    paymentStatuses {
      label
      value
    }
    leadSource {
      label
      value
    }
    quoteStatus {
      label
      value
    }
  }
`;
