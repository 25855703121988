import { gql } from "@apollo/client";

export const LOGIN_QUERY = gql`
  query login($userName: String!, $password: String!, $rememberMe: Boolean!) {
    login(userName: $userName, password: $password, rememberMe: $rememberMe) {
      token
    }
  }
`;
export const USER_QUERY = gql`
  query userByToken {
    userByToken {
      id
      email
      rememberCreatedAt
      signInCount
      currentSignInAt
      lastSignInAt
      currentSignInIp
      lastSignInIp
      createdAt
      updatedAt
      login
      userName
      firstName
      lastName
      phone
      emailSignature
      isDeleted
      roles {
        name
      }
      userSettings {
        id
        name
        value
      }
      accessControlStructure
    }
  }
`;
