import { useMutation } from "@apollo/client";
import { ActionButton } from "components/Buttons";
import { catchNotification, setNotification } from "helpers/cache";
import { ADD_PAYMENT_TRANSACTION } from "modules/Quotes/Services/Mutations/Mutations";
import { FC, useState } from "react";
import { QuoteEditData } from "types/Quote";

const NewTransaction: FC<{
  quote: QuoteEditData;
  refetchQuote: Function;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ quote, refetchQuote, loading, setLoading }) => {
  const [transactionId, setTransactionId] = useState<string>('');
  const [addPaymentTransaction] = useMutation(ADD_PAYMENT_TRANSACTION);  

  const handleSendId = () => {
    if (!transactionId.trim()) return false;
    setLoading(() => true);

    addPaymentTransaction({
      variables: {
        transactionId: transactionId.trim(),
        quoteId: quote?.id
      }
    })
      .then(() => {
        setNotification([{
          type: "SUCCESS",
          message: "The payment transacrtion was successfully created",
        }]);
        refetchQuote && refetchQuote();
      })
      .catch(catchNotification)
      .finally(() => {
        setTransactionId(() => '');
        setLoading(() => false);
      });
  }

  return (
    <>
      <div
        className={`
        p-2 bg-[#f7f7f7] flex justify-between border border-[#dddddd] 
        ${loading ? "animate-pulse" : ""}
      `}
      >
        <h2 className="text-[18px] leading-[30px] text-[#669FC7] ">
          Transaction processed manually
        </h2>
        <div className="flex gap-4">
          <ActionButton
            buttonName="Save Transaction Manually"
            icon="icon-floppy"
            className={`h-[32px] ${transactionId
              ? "bg-[#87B87F] border-[#87B87F] hover:bg-[#499249]"
              : "bg-[#AECEA9] border-[#AECEA9] cursor-not-allowed"
              }`}
            buttonType="button"
            onClick={handleSendId}
            loading={loading}
          />
        </div>
      </div>

      <div className="pl-3 grid md:flex-row pt-3 border-x border-b border-[#dddddd]">
        <div className="flex gap-4 items-center w-full pr-2 py-2">
          <label className="text-[14px] leading-5 text-[#393939] cursor-pointer col-span-2">
            Transaction ID:
          </label>
          <div className="flex gap-[10px] items-end  col-span-10">
            <input
              name="bccEmail"
              value={transactionId}
              onChange={(e) => setTransactionId(() => e.target.value)}
              placeholder="Transaction ID"
              disabled={loading}
              className="max-w-[157px] border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTransaction;
