import AddImage from "assets/add-image-blue.png";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import RenderItem from "helpers/ReactNestable/RenderItem";
import {
  CREATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
} from "modules/Products/Services/Mutations/Mutation";
import { PRODUCT_CATEGORY_COLLECTION_QUERY } from "modules/Products/Services/Queries/Queries";
import ProductShowModal from "helpers/ProductShowModal";
import ProductDeleteModal from "helpers/ProductDeleteModal";
import useDisableBodyScroll from "helpers/DisableBodyScroll";
import { findParentNextAndPrevioutId, getParent } from "helpers/utils";
import { LoadingDesign, OopsNoDataDesign } from "helpers";
import NestableDragandDrop from "./NestableDragandDrop";
import checkIsTowObjectEqual from "lodash";

interface FormType {
  title: string;
  parent: number;
}

const ProductsCategory = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editProduct, setEditProduct] = useState<{
    id: number;
    name: string;
  }>();
  const [item, setItem] = useState<
    {
      id: number;
      name: string;
      children: [] | any[];
    }[]
  >([]);
  const [
    fetchProductCategories,
    { data: productCategories, loading: LoadingProductCategory, refetch },
  ] = useLazyQuery(PRODUCT_CATEGORY_COLLECTION_QUERY, {
    variables: {
      requiredFields: ["id", "name", "depth"],
    },
    notifyOnNetworkStatusChange: true,
  });

  const [loading, setLoading] = useState(false);
  const [dragId, setDragId] = useState<any>();
  const [updatedArrayObject, setUpdatedArrayObject] = useState<any>();
  const [currentArrayObject, setcurrentArrayObject] = useState<any>();
  const [loadingUpdateProductCategory, setLoadingUpdateProductCategory] =
    useState(false);
  const [createProductCategory] = useMutation(CREATE_PRODUCT_CATEGORY);
  const [updateProductCategory] = useMutation(UPDATE_PRODUCT_CATEGORY);
  const [deleteProductCategory] = useMutation(DELETE_PRODUCT_CATEGORY);

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
    reset,
  } = useForm<FormType>();
  const createProductCategoryHandler = (data: FormType) => {
    if (!loading) {
      setLoading(true);
      createProductCategory({
        variables: {
          name: data?.title,
          parentId: +data.parent ? +data.parent : undefined,
        },
      })
        .then(() => {
          setLoading(false);
          setShowModal(!showModal);
          refetch();
        })
        .catch((errors: any) => {
          setLoading(false);
          alert(errors.message);
        });
    }
  };

  const updateProductCategoryHandler = (data: FormType) => {
    if (!loading) {
      setLoading(true);
      setLoadingUpdateProductCategory(true);
      updateProductCategory({
        variables: {
          id: editProduct?.id,
          name: data?.title ? data?.title : "",
        },
      })
        .then(() => {
          setLoading(false);
          setLoadingUpdateProductCategory(false);
          setShowEditModal(!showEditModal);
          refetch();
        })
        .catch((errors: any) => {
          setLoading(false);
          setLoadingUpdateProductCategory(false);
          // alert(error?.message);(errors.message);
        });
    }
  };

  useEffect(() => {
    reset();
    clearErrors("title");
  }, [clearErrors, showModal, reset, showEditModal]);

  useEffect(() => {
    if (productCategories) {
      setItem(productCategories?.productCategories);
    }
  }, [productCategories]);
  useEffect(() => {
    fetchProductCategories({
      variables: {
        requiredFields: ["id", "name", "depth"],
      },
    });
  }, [fetchProductCategories]);

  let selectOptions: any[] = [];

  const getSelectOptionsHandler = (productCategories: any[]) => {
    if (productCategories?.length === 0) return null;
    productCategories?.map((productCategories) => {
      if (productCategories?.depth !== 2) {
        selectOptions.push({
          id: productCategories?.id,
          name: `${
            getParent(item, productCategories?.id)?.length === 1 ? "" : ""
          }${
            getParent(item, productCategories?.id)?.length === 2 ? "- " : ""
          } ${productCategories?.name}`,
        });
      }
      if (productCategories?.children?.length > 0) {
        getSelectOptionsHandler(productCategories?.children);
      }
      return productCategories;
    });
  };

  getSelectOptionsHandler(item);
  useDisableBodyScroll(showModal);
  useEffect(() => {
    const getPath = (object: any, search: any) => {
      if (object?.id === search) return [object];
      else if (object?.children || Array.isArray(object)) {
        let children = Array.isArray(object) ? object : object?.children;
        for (let child of children) {
          let result: any = getPath(child, search);
          if (result) {
            if (object?.id) result?.unshift(object);
            return result;
          }
        }
      }
    };

    if (dragId) {
      let lengthForCurrent = getPath(item, dragId?.dragItem?.id)?.length;
      let parentforCurrentArray =
        lengthForCurrent === 3
          ? getPath(item, dragId?.dragItem?.id)[1]
          : lengthForCurrent === 2
            ? getPath(item, dragId?.dragItem?.id)[0]
            : lengthForCurrent === 1
              ? undefined
              : lengthForCurrent;

      if (parentforCurrentArray) {
        setcurrentArrayObject(
          findParentNextAndPrevioutId(
            parentforCurrentArray.children,
            dragId?.dragItem?.id,
            parentforCurrentArray?.id,
          ),
        );
      }
      if (parentforCurrentArray === undefined) {
        setcurrentArrayObject(
          findParentNextAndPrevioutId(item, dragId?.dragItem?.id),
        );
      }
      let length = getPath(dragId?.items, dragId?.dragItem?.id)?.length;
      let parentArray =
        length === 3
          ? getPath(dragId?.items, dragId?.dragItem?.id)[1]
          : length === 2
            ? getPath(dragId?.items, dragId?.dragItem?.id)[0]
            : length === 1
              ? undefined
              : length;
      if (parentArray) {
        setUpdatedArrayObject(
          findParentNextAndPrevioutId(
            parentArray.children,
            dragId?.dragItem?.id,
            parentArray?.id,
          ),
        );
      }
      if (parentArray === undefined) {
        setUpdatedArrayObject(
          findParentNextAndPrevioutId(dragId?.items, dragId?.dragItem?.id),
        );
      }
    }
  }, [dragId, item]);

  useEffect(() => {
    if (updatedArrayObject && dragId && currentArrayObject) {
      if (
        checkIsTowObjectEqual.isEqual(updatedArrayObject, currentArrayObject)
      ) {
        setUpdatedArrayObject(undefined);
        setcurrentArrayObject(undefined);
      }

      if (
        !checkIsTowObjectEqual.isEqual(updatedArrayObject, currentArrayObject)
      ) {
        setLoadingUpdateProductCategory(true);
        const { id, nextId, parentId, previousId } = updatedArrayObject;
        updateProductCategory({
          variables: {
            id: id,
            nextId: nextId,
            parentId: parentId,
            previousId: previousId,
          },
        })
          .then(() => {
            refetch();
            setLoadingUpdateProductCategory(false);
            setUpdatedArrayObject(undefined);
            setcurrentArrayObject(undefined);
            setDragId(undefined);
          })
          .catch((error) => {
            alert(error?.message);
            setLoadingUpdateProductCategory(false);
            setUpdatedArrayObject(undefined);
            setcurrentArrayObject(undefined);
            setDragId(undefined);
          });
      }
    }
  }, [
    updatedArrayObject,
    updateProductCategory,
    dragId,
    refetch,
    currentArrayObject,
  ]);

  return (
    <div className="px-[10px]">
      <h1 className="text-[#393939] text-[32px] leading-10 pt-[10px]">
        Products Category
      </h1>
      <div className="flex items-center w-fit">
        <img
          src={AddImage}
          alt="add-icon"
          className="my-[25px] cursor-pointer"
          onClick={() => setShowModal(!showModal)}
        />
        <span
          className="text-[19px] text-[#0088cc] pl-1 cursor-pointer hover:underline hover:text-[#005580]"
          onClick={() => setShowModal(!showModal)}
        >
          Create
        </span>
      </div>
      {LoadingProductCategory || loadingUpdateProductCategory ? (
        <LoadingDesign />
      ) : item?.length <= 0 ? (
        <OopsNoDataDesign />
      ) : (
        <NestableDragandDrop
          RenderItem={RenderItem}
          item={item}
          setEditProduct={setEditProduct}
          setShowDeleteModal={setShowDeleteModal}
          setShowEditModal={setShowEditModal}
          showDeleteModal={showDeleteModal}
          showEditModal={showEditModal}
          setDragId={setDragId}
        />
      )}

      {showModal ? (
        <ProductShowModal
          errors={errors}
          handleSubmit={handleSubmit}
          productsCategoryFormHandler={createProductCategoryHandler}
          register={register}
          setShowModal={setShowModal}
          productType="productCategory"
          selectOptions={selectOptions}
          loading={loading}
        />
      ) : null}
      {showEditModal && editProduct ? (
        <ProductShowModal
          errors={errors}
          handleSubmit={handleSubmit}
          productsCategoryFormHandler={updateProductCategoryHandler}
          register={register}
          setShowEditModal={setShowEditModal}
          id={editProduct?.id}
          defaultValue={editProduct?.name}
          productType="productCategory"
          selectOptions={selectOptions}
          loading={loading}
        />
      ) : null}
      {showDeleteModal && editProduct ? (
        <ProductDeleteModal
          setShowDeleteModal={setShowDeleteModal}
          productType="productCategory"
          loading={loading}
          onDelete={() => {
            if (!loading) {
              if (editProduct?.id) {
                setLoading(true);
                deleteProductCategory({
                  variables: {
                    ids: [editProduct?.id],
                  },
                })
                  .then(() => {
                    setLoading(false);
                    setShowDeleteModal(false);
                    refetch();
                  })
                  .catch((errors: any) => {
                    setLoading(false);
                    // alert(error?.message);(errors.message);
                  });
              }
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default ProductsCategory;
