import { useMutation, useQuery } from "@apollo/client";
import { OopsNoDataDesign } from "helpers";
import { catchNotification, setNotification } from "helpers/cache";
import {
  DELETE_QUOTE_PAYMENT_LINK_TPL,
  REFRESH_QUOTE_PAYMENT_LINK_TPL,
  SEND_QUOTE_CHECK_PAYMENT,
} from "modules/Quotes/Services/Mutations/Mutations";
import { FC } from "react";
import CreditCardExpiry from "./CreditCardExpiry";
import CreditCardIcon from "./CreditCardIcon";
import CreditCardNumber from "./CreditCardNumber";
import { IQuotePaymentPrivilage } from "./EditQuotePayment";
import { IPaymentLinkTpl } from "../../../../../types/IPaymentLinkTpl";
import { TCreditCardTypes } from "../../../../../types/TCreditCardTypes";
import CheckStatusByTId from "./CheckStatusByTId";
import { CRMSETTINGS_PAYMENT_EMAIL_TPLS, CRMSETTINGS_PAYMENT_EMAIL_TPLS_RESULT } from "modules/AdminDashboard/Services/Queries/Queries";

const tableHeading = [
  "Status",
  "Link",
  "Amount",
  "Created at",
  "Updated at",
  "Card",
  "Card number",
  "Expiry",
  "Transaction",
  "Auth",
  "Ref num",
  "Transaction at",
  "Email",
  "",
];

const statuses = {
  0: <i className="icon-coffee text-green-600 text-[18px]" />,
  1: <i className="icon-ok text-green-700 text-[18px]" />,
  2: <i className="icon-cancel text-red-500 text-[18px]" />,
  3: <i className="icon-cancel-circled  text-red-600 text-[18px]" />,
};

const PaymentLinkTpls: FC<{
  tpls: IPaymentLinkTpl[];
  refetchQuote: Function;
  accessControlStructure: IQuotePaymentPrivilage;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ tpls, refetchQuote, accessControlStructure, loading, setLoading }) => {
  const [deleteQuotePaymentLink] = useMutation(DELETE_QUOTE_PAYMENT_LINK_TPL);
  const [sendQuoteCheckToCustomer] = useMutation(SEND_QUOTE_CHECK_PAYMENT);
  const [refreshQuotePaymentLink] = useMutation(REFRESH_QUOTE_PAYMENT_LINK_TPL);

  // --
  // -- Get payment emails templates
  // --

  const { data: emailTpls } = useQuery(CRMSETTINGS_PAYMENT_EMAIL_TPLS);
  const { data: emailTplsResult } = useQuery(CRMSETTINGS_PAYMENT_EMAIL_TPLS_RESULT);

  const handleDeletePaymentLinkTpl = (idx: number) => () => {
    if (!tpls?.[idx] || tpls[idx]?.status !== 0 || !tpls[idx]?.id) return false;
    setLoading(() => true);

    deleteQuotePaymentLink({
      variables: {
        tplId: tpls[idx].id,
      },
    })
      .then(() => refetchQuote && refetchQuote())
      .catch(catchNotification)
      .finally(() => setLoading(() => false));
  };

  const handleSendCheck = (idx: number) => () => {
    if (!tpls?.[idx] || tpls[idx]?.status !== 1 || !tpls[idx]?.id) return false;

    sendQuoteCheckToCustomer({
      variables: {
        tplId: tpls[idx].id,
      },
    })
      .then(() =>
        setNotification([
          {
            type: "SUCCESS",
            message: "The receipt was successfully sent to the customer",
          },
        ]),
      )
      .catch(catchNotification)
      .finally(() => setLoading(() => false));
  };

  const handleRefreshPaymentLinkTpl = (idx: number) => () =>
    tpls?.[idx] &&
    tpls[idx]?.id &&
    refreshQuotePaymentLink({
      variables: {
        tplId: tpls[idx].id,
      },
    })
      .then(() => refetchQuote && refetchQuote())
      .catch(catchNotification);

  return (
    <>
      <div
        className={`
        p-2 bg-[#f7f7f7] flex justify-between border border-[#dddddd]
        ${loading ? "animate-pulse" : ""}    
      `}
      >
        <h2 className="text-[18px] leading-[30px] text-[#669FC7] ">
          Payment links
        </h2>
        {/* <span
          role="button"
          tabIndex={-1}
          onClick={() => refetchQuote && refetchQuote()}
          className="flex gap-1 justify-center text-[14px] items-center text-gray-400 hover:underline hover:text-gray-500 transition"
        >
          <i className="icon-arrows-cw text-[16px]" />
          Refresh
        </span> */}
      </div>
      {!!tpls?.length ? (
        <table className="w-full border-collapse">
          <thead className="bg-[#E4E6E9] ">
            <tr className="[background:linear-gradient(to_bottom,#F8F8F8_0%,#ECECEC_100%)]">
              {tableHeading?.map((heading, index) => (
                <th
                  key={`tplHead-${index}`}
                  className={`text-[13px] leading-5 text-[#707070] border p-2 border-[#DDD] whitespace-nowrap ${
                    index === 0 && "w-[20px]"
                  }`}
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tpls?.map((tpl, i) => (
              <tr
                key={`tplBody-${i}`}
                className={`
                      border-[1px] border-[#ccc] hover:bg-[#f1f1f1] ${
                        (tpl.status === 3 && "opacity-50") ||
                        (tpl.status === 1 && "bg-green-50 text-green-500") ||
                        ""
                      }
                    `}
              >
                {[
                  // -- Status
                  statuses?.[tpl.status] || statuses[0],
                  // -- Link
                  tpl.status === 0 ? (
                    <span
                      role="button"
                      tabIndex={-1}
                      className="flex gap-1 justify-center text-green-600 hover:underline"
                      onClick={() => {
                        navigator.clipboard.writeText(tpl.linkGeneratorHref);
                        setNotification([
                          {
                            type: "SUCCESS",
                            message: "Payment link was copied",
                          },
                        ]);
                      }}
                    >
                      <i className="icon-export text-[18px]" />
                      Copy
                    </span>
                  ) : (
                    ""
                  ),
                  // -- Amount
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(tpl.amount),
                  // -- Created at
                  tpl.createdAt,
                  // -- Updated at
                  tpl.updatedAt,
                  // -- Card
                  <div className="flex justify-center">
                    <CreditCardIcon
                      type={
                        String(
                          tpl.transactionCardType,
                        ).toLowerCase() as TCreditCardTypes
                      }
                    />
                  </div>,
                  // -- Card number',
                  <CreditCardNumber
                    first={tpl.transactionCardNumberFirst}
                    last={tpl.transactionCardNumberLast}
                  />,
                  // -- Expiry',
                  <CreditCardExpiry expiry={tpl.transactionCardExpiry} />,
                  // -- Transaction',
                  tpl.transactionType,
                  // -- Auth'
                  tpl.transactionAuth,
                  // -- Ref num',
                  tpl.transactionRefNum,
                  // -- Transaction at',
                  tpl.transactionAt,
                  // -- email tpl
                  <div>
                    {(() => {
                      const email = (emailTpls?.paymentEmailTpls || []).find(({ id }: any) => id === tpl.emailTplId);
                      const receiptEmail = (emailTplsResult?.paymentEmailTplsResult || []).find(({ id }: any) => id === tpl.receivedEmailTplId);
                      const changeOrderStatus = tpl?.dontChangeOrderStatus ? 'no' : 'yes';

                      return (
                        <div>
                          Email: {email ? `${email.value} (#${email.id})` : '-'}<br/>
                          Receipt Email: {receiptEmail ? `${receiptEmail.value} (#${receiptEmail.id})` : '-'}<br/>
                          Change Order Status: {changeOrderStatus}
                        </div>
                      );
                    })()}
                  </div>,
                  // -- Actions
                  (tpl.status === 3 && "Deleted") ||
                    (tpl.status === 1 && (
                      <span
                        role="button"
                        tabIndex={-1}
                        onClick={handleSendCheck(i)}
                        className="flex gap-1 justify-center text-green-600 hover:underline"
                      >
                        <i className="icon-mail text-[18px]" />
                        Send Receipt
                      </span>
                    )) ||
                    (!!accessControlStructure?.deletePayment && (
                      <div className="flex gap-2 justify-center">
                        <span
                          role="button"
                          tabIndex={-1}
                          onClick={handleDeletePaymentLinkTpl(i)}
                          className="flex gap-1 justify-center text-red-600 hover:underline"
                        >
                          <i className="icon-cancel text-[18px]" />
                        </span>
                        {!!tpl.transactionId && (
                          <span
                            role="button"
                            tabIndex={-1}
                            onClick={handleRefreshPaymentLinkTpl(i)}
                            className="flex gap-1 justify-center text-blue-600 hover:underline"
                          >
                            <i className="icon-arrows-cw text-[16px]" />
                          </span>
                        )}
                        <CheckStatusByTId tplId={tpl.id} refetchQuote={refetchQuote} />
                      </div>
                    )) ||
                    "",
                ].map((item, indx) => (
                  <td
                    key={`tplBodyItem-${tpl.id}-${indx}`}
                    className="p-2 border-[1px] text-center border-[#ccc] text-[13px] leading-5 text-[#393939]"
                  >
                    {item ?? ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <OopsNoDataDesign />
      )}
    </>
  );
};

export default PaymentLinkTpls;
