import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_ROLE, UPDATE_ROLE } from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { setNotification } from "../../../../helpers/cache";
import { USERS_COLLECTION_QUERY } from "../../Services/Queries/Queries";
import SearchBox from "../../../../components/Form/El/SearchBox";
import { User } from "../../../../types/User";
import { cloneDeep } from "lodash";
import EditOrCreateUserModal from "./Modal/EditOrCreateUserModal";

const AdminDashboardEditRole: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentName("");
    setIdsUserList([]);
  }, []);

  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_ROLE, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      const { updateRole } = data;

      if (!updateRole) return;

      const { id } = updateRole;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_ROLE, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createRole } = data;

      if (!createRole) return;

      const { id } = createRole;

      onAfterSave(id || 0);
    },
  });
  const [getUsers] = useLazyQuery(USERS_COLLECTION_QUERY, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { users } = data;

      if (!users) return;

      setUsersList(users);
    },
  });

  const [currentName, setCurrentName] = useState("");
  const [idsUserList, setIdsUserList] = useState<number[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isShowAddEditUserModal, setIsShowAddEditUserModal] = useState(false);

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { name, users } = currentDataCollectionItem;

    if (name) {
      setCurrentName(name);
    }

    if (users) {
      setIdsUserList(users.map((user: User) => user.id));
    }
  }, [currentDataCollectionItem]);

  const onGetUsers = useCallback(async () => {
    setIsLoadingUsers(true);

    await getUsers().finally(() => {
      setIsLoadingUsers(false);
    });
  }, []);

  useEffect(() => {
    onGetUsers();
  }, []);

  const usersForSelect = useMemo(() => {
    const newUserListForSelect = usersList.map((userItem) => {
      const userInUserList = usersList.find((user) => user.id === userItem.id);

      return {
        id: userInUserList?.id || 0,
        name: userInUserList?.userName || "",
      };
    });

    return newUserListForSelect.filter(
      (user) => !idsUserList.includes(user.id),
    );
  }, [usersList, idsUserList]);

  const selectedIdsForSelect = useMemo(() => {
    return idsUserList.map((id) => {
      const userInUserList = usersList.find((user) => user.id === id);

      return {
        id: userInUserList?.id || 0,
        name: userInUserList?.userName || "",
      };
    });
  }, [usersList, idsUserList]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            name: currentName,
            user: idsUserList,
          },
        });
      }
      return await createItems({
        variables: {
          id: currentId,
          name: currentName,
          user: idsUserList,
        },
      });
    };

    await handleSaveById();
  }, [currentName, currentId, idsUserList]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm = !!currentName;
    setDisabledSaveButton(!isValidForm);
  }, [currentName]);

  const onChangeIdsUsersList = useCallback(
    (id: number) => {
      const isExistValueIndex = idsUserList.findIndex(
        (idUserList) => idUserList === id,
      );
      const tmpIdsUserList = cloneDeep(idsUserList);
      if (isExistValueIndex !== -1) {
        tmpIdsUserList.splice(isExistValueIndex, 1);
      }

      if (isExistValueIndex === -1) {
        tmpIdsUserList.push(id);
      }

      setIdsUserList(tmpIdsUserList);
    },
    [idsUserList],
  );

  return (
    <Fragment>
      <div className="btn-group flex justify-end mb-1">
        <button
          className="btn btn-small btn-blue"
          onClick={() => {
            setIsShowAddEditUserModal(true);
          }}
        >
          <i className="icon-plus pr=1" /> Add New User
        </button>
      </div>
      <Input
        value={currentName}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentName(value);
          }
        }}
        name="highlight-name"
        labelWidth={labelWidth}
        label="Name"
        placeholder="Name"
        inputWidth={inputWidth}
        isIncorrectValue={!currentName}
      />
      <SearchBox
        value={""}
        onChange={(value) => {
          if (typeof value === "number") {
            onChangeIdsUsersList(value);
          }
        }}
        options={usersForSelect}
        loading={isLoadingUsers}
        label="User"
        labelWidth={labelWidth}
        inputWidth={inputWidth}
        selectedValues={selectedIdsForSelect}
      />
      {isShowAddEditUserModal && (
        <EditOrCreateUserModal
          closeModal={() => {
            setIsShowAddEditUserModal(false);
          }}
          afterSave={() => {
            onGetUsers();
          }}
        />
      )}
    </Fragment>
  );
};

export default AdminDashboardEditRole;
