import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { ApolloError, useMutation } from "@apollo/client";
import {
  CREATE_CRMSETTING,
  UPDATE_CRMSETTING,
} from "../../Services/Mutations/Mutaions";
import Input from "../../../../components/Form/El/Input";

const AdminDashboardCrmSettings: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const [currentName, setCurrentName] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [currentValueText, setCurrentValueText] = useState("");

  const clearForm = useCallback(() => {
    setCurrentName("");
    setCurrentValue("");
    setCurrentValueText("");
  }, []);

  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_CRMSETTING, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { updateCrmSetting } = data;

      if (!updateCrmSetting) return;

      const { id } = updateCrmSetting;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_CRMSETTING, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createCrmSetting } = data;

      if (!createCrmSetting) return;

      const { id } = createCrmSetting;

      onAfterSave(id || 0);
    },
  });

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { name, value, valueText } = currentDataCollectionItem;

    if (name) {
      setCurrentName(name);
    }

    if (value) {
      setCurrentValue(value);
    }

    if (valueText) {
      setCurrentValueText(valueText);
    }
  }, [currentDataCollectionItem]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            name: currentName,
            value: currentValue,
            valueText: currentValueText,
          },
        });
      }
      return await createItems({
        variables: {
          name: currentName,
          value: currentValue,
          valueText: currentValueText,
        },
      });
    };

    await handleSaveById();
  }, [currentName, currentId, currentValue, currentValueText]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm = !!currentName;
    setDisabledSaveButton(!isValidForm);
  }, [currentName]);
  return (
    <Fragment>
      <Input
        value={currentName}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentName(value);
          }
        }}
        name="crm-name"
        labelWidth={labelWidth}
        label="Name"
        placeholder="Name"
        inputWidth={inputWidth}
        isIncorrectValue={!currentName}
      />
      <Input
        value={currentValue}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentValue(value);
          }
        }}
        name="crm-value"
        labelWidth={labelWidth}
        label="Value"
        placeholder="Value"
        inputWidth={inputWidth}
      />
      <Input
        value={currentValueText}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentValueText(value);
          }
        }}
        name="crm-value-text"
        labelWidth={labelWidth}
        label="Value Text"
        placeholder="Value Text"
        inputWidth={inputWidth}
        isTextarea={true}
        rowCount={2}
      />
    </Fragment>
  );
};

export default AdminDashboardCrmSettings;
