import { setNotification } from "helpers/cache";
import { FC, useCallback, useEffect, useState } from "react";

const Notification: FC<{
  type: string;
  message: string;
}> = ({ type, message }) => {
  const [closeNotification, setCloseNotification] = useState(false);
  const [width, setWidth] = useState(0);
  const [intervalID, setIntervalID] = useState<number>();

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setWidth((prev) => {
        if (prev < 100) {
          return prev + 0.5;
        }
        clearInterval(id);
        return prev;
      });
    }, 20);
    setIntervalID(+id);
  };

  const handlePauseTimer = useCallback(() => {
    clearInterval(intervalID);
  }, [intervalID]);

  const handleCloseNotification = useCallback(() => {
    handlePauseTimer();
    setCloseNotification(true);
    setTimeout(() => {
      setNotification([]);
      if (!message) {
        setCloseNotification(false);
      }
    }, 400);
  }, [handlePauseTimer, message]);

  useEffect(() => {
    if (width === 100) {
      handleCloseNotification();
    }
  }, [width, handleCloseNotification]);

  useEffect(() => {
    handleStartTimer();
  }, []);

  return (
    <div
      className={`notification-item ${
        type === "SUCCESS"
          ? "border-[#d6e9c6] text-[#468847] bg-[#dff0d8]"
          : "border-[#eed3d7] text-[#b94a48]  bg-[#f2dede]"
      } ${
        closeNotification ? "exit" : ""
      } flex items-center px-2 rounded w-full justify-between py-1`}
    >
      <i
        className={`${
          type === "SUCCESS"
            ? "icon-ok  text-[#468847]"
            : "icon-cancel text-[#b94a48]"
        } `}
      />
      <p className="max-h-[200px] overflow-y-auto overflow-x-hidden">
        {message}
      </p>
      <i
        onClick={() => {
          handleCloseNotification();
        }}
        className="icon-cancel cursor-pointer text-xl text-black/20 hover:text-black/50 transition-all duration-700 ease-in-out font-bold"
      />
    </div>
  );
};

export default Notification;
