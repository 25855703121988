import React, { FC, Fragment } from "react";
import NormalButton from "components/Buttons/NormalButton";
import { Select, Text } from "components/Form";
import Add from "assets/add.png";
import Delete from "assets/delete.png";
import { LoadingDesign } from "helpers";

const ProductPriceTableForm: FC<{
  handleSubmit: Function;
  priceTableSubmitHandler: Function;
  errors: any;
  register: Function;
  singleProductPriceTable: any;
  selectOptionsProductCategories: { id: number; name: string }[];
  selectOptionsProductOptions: { id: number; name: string }[];
  fields: any;
  productOptionsSubOptions: {
    id: number;
    name: string;
  }[];
  append: Function;
  remove: Function;
  showUpdateForm: boolean;
  checkbox: boolean;
  setCheckBox: Function;
  loadingProductPriceTable?: boolean;
  loading?: boolean;
}> = ({
  handleSubmit,
  priceTableSubmitHandler,
  errors,
  register,
  singleProductPriceTable,
  selectOptionsProductCategories,
  selectOptionsProductOptions,
  fields,
  productOptionsSubOptions,
  append,
  remove,
  showUpdateForm,
  setCheckBox,
  checkbox,
  loadingProductPriceTable,
  loading,
}) => {
  return (
    <Fragment>
      {loadingProductPriceTable ? (
        <LoadingDesign />
      ) : (
        <form
          onSubmit={handleSubmit(priceTableSubmitHandler)}
          className="px-5 space-y-2 pb-4"
        >
          <div className="grid md:grid-cols-[220px_220px_50px] gap-x-3">
            <div className="space-y-2">
              <label
                htmlFor="name"
                className="cursor-pointer text-sm text-[#393939]"
              >
                Name
              </label>
              <Text
                errors={errors}
                name="name"
                register={register}
                hideError
                hideIcon
                className="bg-[#ffffff] max-w-[220px]"
                classForInput="h-[30px]"
                defaultName={
                  singleProductPriceTable?.productPriceTable?.name
                    ? singleProductPriceTable?.productPriceTable?.name
                    : ""
                }
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="productCategory"
                className="cursor-pointer text-sm text-[#393939]"
              >
                Product category
              </label>
              <Select
                errors={errors}
                name="productCategory"
                register={register}
                hideError
                classForSelect="focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] bg-[#dcdcdc]"
                optionsWithObject={selectOptionsProductCategories}
                className="max-w-[220px]"
                hideSelect
              />
            </div>
            <div className="space-y-[14px]">
              <label
                htmlFor="default"
                className="cursor-pointer text-sm text-[#393939]"
                onClick={() => {
                  setCheckBox(!checkbox);
                }}
              >
                Default
              </label>
              <input
                id="default"
                type="checkbox"
                className="hidden"
                {...register("default")}
                defaultChecked={checkbox}
              />
              <span
                className={`w-[14px] h-[14px] flex justify-center items-center bg-[#f5f8fc] cursor-pointer border border-[#ccc] hover:border-[#F59942]`}
                onClick={() => {
                  setCheckBox(!checkbox);
                }}
              >
                <i
                  className={`icon-ok text-[#32a3ce] text-xs ${
                    checkbox ? "block" : "hidden"
                  }`}
                />
              </span>
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <table>
              <tbody>
                <tr className="hover:bg-[#f9f9f9]">
                  <td className="border-t border-x">
                    <Select
                      errors={errors}
                      name="productsOption"
                      register={register}
                      hideError
                      classForSelect="focus:border-[#F59942 focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] bg-[#dcdcdc]"
                      optionsWithObject={selectOptionsProductOptions}
                      className="w-[130px] p-2 bg-[#f9f9f9]"
                      hideSelect
                    />
                  </td>
                  {fields?.map((item: any, index: any) => (
                    <td
                      key={item.id}
                      className="border-t border-x bg-[#f9f9f9]"
                    >
                      <Text
                        errors={errors}
                        name={`productsPrice.${index}.count`}
                        register={register}
                        hideError
                        hideIcon
                        className="p-2 w-[110px]"
                        classForInput="h-[30px]"
                        onKeyPress={(event: any) => {
                          if (!/[0-9.]/.test(event?.key))
                            event?.preventDefault();
                        }}
                      />
                    </td>
                  ))}
                </tr>
                <tr
                  className={`hover:bg-[#f1f1f1] ${
                    productOptionsSubOptions?.length > 0 ? "" : "border-b"
                  }`}
                >
                  <td className="border-x py-1 px-2">
                    <img
                      src={Add}
                      alt="add-icon"
                      className="cursor-pointer"
                      onClick={() =>
                        append(
                          Object.assign(
                            {},
                            { count: "" },
                            ...productOptionsSubOptions?.map(
                              (item: { id: any }) => ({
                                [`${item?.id}`]: "0.00",
                              }),
                            ),
                          ),
                        )
                      }
                    />
                  </td>
                  {fields?.map((item: any, index: any) => (
                    <td key={item.id} className="border-x py-1 px-2">
                      <img
                        src={Delete}
                        onClick={() => remove(index)}
                        alt="delete-icon"
                        className="cursor-pointer"
                      />
                    </td>
                  ))}
                </tr>
                {productOptionsSubOptions?.map(
                  (
                    productSubOption: {
                      name: string;
                      id: number;
                    },
                    index: number,
                  ) => {
                    return (
                      <tr
                        key={index}
                        className="hover:bg-[#F1F1F1] odd:bg-[#f9f9f9] last:border-b"
                      >
                        <td className="text-[13px] leading-5  border-x h-[46px] px-2 text-[#393939] max-w-[120px] truncate">
                          {productSubOption?.name}
                        </td>
                        {fields?.map((item: any, index: number) => {
                          return (
                            <td key={index} className="border-x">
                              <Text
                                errors={errors}
                                name={`productsPrice.${index}.${productSubOption?.id}`}
                                register={register}
                                hideError
                                hideIcon
                                className="p-2 w-[110px]"
                                classForInput="h-[30px]"
                                onKeyPress={(event: any) => {
                                  if (!/[0-9]/.test(event?.key))
                                    event?.preventDefault();
                                }}
                                defaultName="0.00"
                              />
                            </td>
                          );
                        })}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
          <NormalButton
            buttonName={`${showUpdateForm ? "Update" : "Save"}`}
            className="border border-black rounded px-2 bg-[#efefef]"
            buttonType="submit"
            icon={loading ? "icon-spin4" : ""}
            loading={loading}
          />
        </form>
      )}
    </Fragment>
  );
};

export default ProductPriceTableForm;
