import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_OLD_USER_NEW_USER,
  UPDATE_OLD_USER_NEW_USER,
} from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { setNotification } from "../../../../helpers/cache";
import { USERS_COLLECTION_QUERY } from "../../Services/Queries/Queries";
import SearchBox from "../../../../components/Form/El/SearchBox";
import { User } from "../../../../types/User";
import EditOrCreateUserModal from "./Modal/EditOrCreateUserModal";
import { regExpNumber } from "../../../../helpers/RegExp";
import { getIntegerNumberFromString } from "../../../../helpers/utils";

const AdminDashboardEditOldUserNewUser: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentOldUser(null);
    setCurrentUserId(undefined);
  }, []);

  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_OLD_USER_NEW_USER, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      const { updateOldUserNewUser } = data;

      if (!updateOldUserNewUser) return;

      const { id } = updateOldUserNewUser;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_OLD_USER_NEW_USER, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createOldUserNewUser } = data;

      if (!createOldUserNewUser) return;

      const { id } = createOldUserNewUser;

      onAfterSave(id || 0);
    },
  });
  const [getUsers] = useLazyQuery(USERS_COLLECTION_QUERY, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { users } = data;

      if (!users) return;

      setUsersList(users);
    },
  });

  const [currentOldUser, setCurrentOldUser] = useState<string | null>(null);
  const [currentUserId, setCurrentUserId] = useState<number | undefined>();
  const [currentUserName, setCurrentUserName] = useState<string>("");
  const [usersList, setUsersList] = useState<User[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isShowAddEditUserModal, setIsShowAddEditUserModal] = useState("");

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { oldUser, user } = currentDataCollectionItem;

    if (typeof oldUser === "number") {
      setCurrentOldUser(oldUser.toString());
    }

    if (user) {
      setCurrentUserId(user.id);
      setCurrentUserName(user.userName);
    }
  }, [currentDataCollectionItem]);

  const onGetUsers = useCallback(async () => {
    setIsLoadingUsers(true);

    await getUsers().finally(() => {
      setIsLoadingUsers(false);
    });
  }, []);

  useEffect(() => {
    onGetUsers();
  }, []);

  const usersForSelect = useMemo(() => {
    const newUserListForSelect = usersList.map((userItem) => {
      const userInUserList = usersList.find((user) => user.id === userItem.id);

      return {
        id: userInUserList?.id || 0,
        name: userInUserList?.userName || "",
      };
    });

    return newUserListForSelect;
  }, [usersList, currentUserId]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            oldUserId: getIntegerNumberFromString(currentOldUser),
            userId: currentUserId,
          },
        });
      }
      return await createItems({
        variables: {
          id: currentId,
          oldUserId: getIntegerNumberFromString(currentOldUser),
          userId: currentUserId,
        },
      });
    };

    await handleSaveById();
  }, [currentOldUser, currentId, currentUserId]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm = !!currentUserId;
    setDisabledSaveButton(!isValidForm);
  }, [currentUserId]);

  return (
    <Fragment>
      <div className="btn-group flex justify-end mb-1">
        <button
          className="btn btn-small btn-blue"
          onClick={() => {
            setIsShowAddEditUserModal("new");
          }}
        >
          <i className="icon-plus pr-1" /> Add New User
        </button>
        <button
          className="btn btn-small btn-blue"
          onClick={() => {
            setIsShowAddEditUserModal("edit");
          }}
          disabled={!currentUserId}
        >
          <i className="icon-pencil pr-1" /> Edit Current User
        </button>
      </div>
      <Input
        value={
          currentOldUser !== null &&
          typeof +currentOldUser === "number" &&
          !isNaN(+currentOldUser)
            ? currentOldUser
            : ""
        }
        onChange={(value) => {
          const stringValue = value.toString();
          setCurrentOldUser(stringValue ? stringValue : null);
        }}
        name="old-user-new-user-old-user-id"
        labelWidth={labelWidth}
        label="Old User"
        placeholder="Old User"
        inputWidth={inputWidth}
        type={"number"}
        pattern={regExpNumber}
      />
      <SearchBox
        value={currentUserId || ""}
        onChange={(value) => {
          if (typeof value === "number") {
            setCurrentUserId(value);
          }
        }}
        options={usersForSelect}
        loading={isLoadingUsers}
        label="User"
        labelWidth={labelWidth}
        inputWidth={inputWidth}
        isIncorrectValue={!currentUserId}
        defaultDisplayValue={currentUserName}
      />
      {!!isShowAddEditUserModal && (
        <EditOrCreateUserModal
          closeModal={() => {
            setIsShowAddEditUserModal("");
          }}
          afterSave={() => {
            onGetUsers();
          }}
          currentId={
            isShowAddEditUserModal === "edit" ? currentUserId : undefined
          }
        />
      )}
    </Fragment>
  );
};

export default AdminDashboardEditOldUserNewUser;
