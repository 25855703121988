import { FC, useCallback, useEffect, useMemo } from "react";
import { TabListNew } from "types/Tabs";
import { useNavigate } from "react-router-dom";

const TabsNew: FC<{
  tabs: TabListNew;
  children?: any;
  currentTab: string;
  defaultPath: string;
}> = ({ tabs, children, currentTab, defaultPath }) => {
  const visibleTabs = useMemo(() => tabs.filter((tab) => !tab.hidden), [tabs]);

  const isVisibleTabs = useCallback(
    (tabsName: string) => {
      return visibleTabs.find((tab) => tab.name === tabsName);
    },
    [visibleTabs],
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentTab && !!visibleTabs.length) {
      updateCurrentTab(visibleTabs[0].name, true);
    }
  }, [currentTab, visibleTabs]);

  const updateCurrentTab = useCallback(
    (newTab: string, replace?: boolean) => {
      navigate(`${defaultPath}/${newTab}`, { replace: !!replace });
    },
    [defaultPath],
  );

  return (
    <div className="tabs">
      <div className="test">
        <div className="tabs__buttons">
          {visibleTabs.map((tab) => {
            return (
              <button
                key={`tab-${tab.name}`}
                className={`button ${currentTab === tab.name ? "active" : ""}`}
                onClick={() => {
                  if (currentTab !== tab.name && !tab.onClickWithoutNavigate) {
                    updateCurrentTab(tab.name);
                    return;
                  }

                  if (tab.onClickWithoutNavigate) {
                      tab.onClickWithoutNavigate()
                  }
                }}
              >
                {tab.iconName && <i className={tab.iconName} />}
                {tab.label}
              </button>
            );
          })}
        </div>
      </div>

      <div className="tabs__content">
        {!!currentTab &&
          isVisibleTabs(currentTab) &&
          !!children?.[currentTab] &&
          children[currentTab]}
        {!!currentTab && !isVisibleTabs(currentTab) && (
          <div>
            You don't have access to {currentTab}, please select another tab.
          </div>
        )}
      </div>
    </div>
  );
};

export default TabsNew;
