import { FC, Fragment, ReactNode, useState } from "react";
import { ChildrenTable, TableHeading } from "../../types/Table";
import CheckboxNew from "../Form/El/CheckboxNew";

const TableRow: FC<{
  row: ChildrenTable["row"];
  expandRow?: ReactNode;
  headingList: TableHeading;
  selectedOptions?: {
    onSelect: (id: number, isSelected: boolean) => void;
    isSelected: boolean;
  };
  clearDropHandle?: () => void;
  id: number;
  isShowSelect?: boolean;
  additionalCall?: string;
  draggable?: boolean;
  onDragStart?: (evtTarget: EventTarget) => void;
  onDragEnter?: (evtTarget: EventTarget) => void;
  onDrop?: () => void;
  hidden?: boolean;
  index: number;
}> = ({
  additionalCall,
  row,
  expandRow,
  headingList,
  selectedOptions,
  id,
  isShowSelect,
  draggable,
  onDragStart,
  onDragEnter,
  onDrop,
  hidden,
  index,
  clearDropHandle,
}) => {
  const [showExpandRow, setShowExpandRow] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  return (
    <Fragment>
      <tr
        className={`${additionalCall || ""} ${
          isDragActive ? "less-size" : ""
        } ${hidden ? "no-opacity" : ""}`}
        draggable={draggable}
        onDragStart={(evt) => {
          if (draggable && onDragStart) {
            onDragStart(evt.target);
            setIsDragActive(true);
          }
        }}
        onDragEnter={(evt) => {
          if (draggable && onDragEnter) {
            onDragEnter(evt.target);
          }
        }}
        onDrop={() => {
          if (onDrop) {
            onDrop();
          }
          setIsDragActive(false);
        }}
        onDragOver={(e) => e.preventDefault()}
        id={`${index}`}
        onDragEnd={() => {
          setIsDragActive(false);
          if (clearDropHandle) {
            clearDropHandle();
          }
        }}
      >
        {isShowSelect && selectedOptions && (
          <td>
            <CheckboxNew
              value={selectedOptions.isSelected}
              onChange={(value) => {
                selectedOptions.onSelect(id, value);
              }}
              name={`table-row-select-${id}`}
            />
          </td>
        )}
        {!!expandRow && (
          <td className="w-0">
            <button
              className="btn btn-blue btn-mini"
              onClick={() => {
                setShowExpandRow(!showExpandRow);
              }}
            >
              <i
                className={`${
                  showExpandRow ? "icon-minus" : "icon-plus"
                } icon-medium`}
              />
            </button>
          </td>
        )}
        {headingList.map(
          (headingItem) =>
            row.hasOwnProperty(headingItem.name) && (
              <td
                key={`table-row-item-${id}-${headingItem.name}`}
                className="overflow-ellipsis"
              >
                {row[headingItem.name]}
              </td>
            ),
        )}
      </tr>
      {expandRow && showExpandRow && (
        <tr>
          <td colSpan={20}>{expandRow}</td>
        </tr>
      )}
    </Fragment>
  );
};

export default TableRow;
