import React, { FC, useCallback, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { useMutation } from "@apollo/client";
import { CREATE_OLD_USER_NEW_USER, MERGE_CLIENTS } from "../../Services/Mutations/Mutaions";
import { setNotification } from "../../../../helpers/cache";
import CheckboxNew from "../../../../components/Form/El/CheckboxNew";

const AdminUnitingClients: FC<{}> = ({ }) => {
	const [oldClientId, setOldClientId] = useState<number>(0);
	const [newClientId, setNewClientId] = useState<number>(0);
	const [withDeleteClient, setWithDeleteClient] = useState(false);
	const [isLoadingMergeClients, setIsLoadingMergeClients] = useState(false);

	const [mergeClients] = useMutation(MERGE_CLIENTS, {
		onError: (error) => {
			setNotification([{ type: "ERROR", message: error?.message }]);
		},
	});

	const clearAllData = useCallback(() => {
		setOldClientId(0);
		setNewClientId(0);
	}, [])

	const onMergeClients = useCallback(async () => {
		setIsLoadingMergeClients(true);

		await mergeClients({
			variables: {
				oldClientId,
				newClientId,
				withDeleteClient
			}
		}).then((res) => {
			const { data } = res;

			if (!data) return;

			const { mergeClients } = data;

			if (!mergeClients) return;

			setNotification([{ type: "SUCCESS", message: `Client merged successfully!` }]);
			clearAllData();
		})

		setIsLoadingMergeClients(false);
	}, [oldClientId, newClientId, withDeleteClient])

	return (
		<div className="admin-unitig-clients">
			<div className="admin-unitig-clients__content">
				<Input
					value={newClientId}
					onChange={(value) => {
						let currentValue = value;

						if (typeof currentValue === "string") {
							currentValue = +currentValue;
						}
						setNewClientId(currentValue)
					}}
					name={"new-merge-client-id"}
					label={"Existing Client Id"}
					type={"number"}
					labelWidth={'150px'}
				/>
				<Input
					value={oldClientId}
					onChange={(value) => {
						let currentValue = value;

						if (typeof currentValue === "string") {
							currentValue = +currentValue;
						}
						setOldClientId(currentValue)
					}}
					name={"old-merge-client-id"}
					label={"Client to remove"}
					type={"number"}
					labelWidth={'150px'}
				/>
				<CheckboxNew
					value={withDeleteClient}
					onChange={(value) => {
						setWithDeleteClient(value)
					}}
					name={'delete-client-after-merge'}
					label="Delete Old Client Profile"
					labelWidth={'150px'}
				/>
				<button
					className="btn btn-success btn-small"
					disabled={!oldClientId || !newClientId || isLoadingMergeClients}
					onClick={onMergeClients}
				>
					{
						isLoadingMergeClients && (
							<i
								className={`inline-block animate-spin icon-spin4`}
							/>
						)
					}
					{
						!isLoadingMergeClients && "Merge"
					}
				</button>
			</div>
		</div>
	)
}

export default AdminUnitingClients;