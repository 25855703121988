import React, { useState } from "react";
import { Password, Text } from "components/Form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { LOGIN_QUERY } from "../Services/Queries/loginQueries";
import { FilledButton } from "components/Buttons";

interface FormType {
  userName: string;
  password: string;
  customCheckbox: boolean;
}

const Login = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormType>();
  const [checkbox, setCheckBox] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  if (checkbox) {
    setValue("customCheckbox", true);
  } else {
    setValue("customCheckbox", false);
  }
  const [loginQuery] = useLazyQuery(LOGIN_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const onsubmit = (data: FormType) => {
    setLoading(true);
    loginQuery({
      variables: {
        userName: data.userName,
        password: data.password,
        rememberMe: data.customCheckbox,
      },
    })
      .then((response) => {
        if (response?.data?.login !== null) {
          if (checkbox) {
            localStorage.token = response?.data?.login.token;
          } else {
            sessionStorage.token = response?.data?.login.token;
          }
          setLoading(false);
          navigate("/");
        } else {
          setLoading(false);
          alert("Please enter the valid user name and password.");
        }
      })
      .catch((error) => {
        setLoading(false);
        alert(error?.message);
      });
  };

  return (
    <div className="border-4 md:border-[6px] border-[#394557] bg-[#f7f7f7] mx-auto w-min my-2 transition-all duration-150 font-['Open_Sans']">
      <div className="px-4 pb-4 pt-2  md:px-9 md:pt-4">
        <h4 className="border-b text-[19px] leading-7 text-[#393939] whitespace-nowrap md:tracking-[0.15px] my-[18px] pb-1">
          <i className="icon-coffee green text-[#69aa46] text-xl" /> Please
          Enter Your Information!
        </h4>
        <form onSubmit={handleSubmit(onsubmit)}>
          <Text
            register={register}
            errors={errors}
            name={"userName"}
            placeholderText={"Username"}
            required={true}
            classForInput="min-h-[30px] placeholder:font-heltivica"
            className="font-heltivica"
          />
          <Password
            register={register}
            errors={errors}
            name={"password"}
            placeholderText={"Password"}
            required={true}
            classForInput="min-h-[30px] placeholder:font-heltivica"
            className="font-heltivica"
          />
          <div className="my-[12px] flex justify-between items-center">
            <div className="flex justify-start items-center gap-2">
              <input
                type="checkbox"
                className="hidden"
                {...register("customCheckbox")}
              />
              <span
                className={`w-[16px] h-[16px] flex justify-center items-center bg-white cursor-pointer border border-[#ccc] hover:border-[#F59942]`}
                onClick={() => {
                  setCheckBox(!checkbox);
                }}
              >
                <i
                  className={`icon-ok text-[#39a3cf] text-xs ${
                    checkbox ? "block" : "hidden"
                  }`}
                />
              </span>
              <span
                className="text-sm leading-5 text-[#393939] self-start align-top cursor-pointer"
                onClick={() => {
                  setCheckBox(!checkbox);
                }}
              >
                Remember Me
              </span>
            </div>
            <div className="w-[96px] hidden md:block">
              <FilledButton
                buttonName="Login"
                buttonType="submit"
                loading={loading}
                className="font-heltivica"
              />
            </div>
          </div>
          <div className="w-full md:hidden">
            <FilledButton
              buttonName="Login"
              buttonType="submit"
              loading={loading}
              className="font-heltivica"
            />
          </div>
        </form>
      </div>
      <div className="bg-[#5090C1] border-t-2 border-[#597597]">
        <h6 className="whitespace-nowrap text-[12px] md:text-[13px] md:leading-5 text-[#393939] py-[10px] px-2">
          © Copyright 2009 - 2022 Comtread Inc. All rights reserved.
        </h6>
      </div>
    </div>
  );
};

export default Login;
