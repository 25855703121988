import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLazyQuery } from "@apollo/client";
import { ADMIN_DASHBOARD } from "../../Services/Queries/Queries";
import { setNotification } from "../../../../helpers/cache";
import TableNew from "../../../../components/Table/TableNew";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { Link, useNavigate } from "react-router-dom";
import { getNameByLabel } from "../AdminDashboardHandler";

const AdminDashboardDefault: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [getDashboardInfo] = useLazyQuery(ADMIN_DASHBOARD, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      const { adminDashBoard } = data;

      if (!adminDashBoard || !adminDashBoard?.length) return;

      setDataCollection(adminDashBoard);
    },
  });

  const [isLoadingDashboardInfo, setIsLoadingDashboardInfo] = useState(false);
  const [isNeedUpdateTableData, setIsNeedUpdateTableData] = useState(true);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const onGetDashboardInfo = useCallback(async () => {
    setIsLoadingDashboardInfo(true);
    await getDashboardInfo().finally(() => {
      setIsLoadingDashboardInfo(false);

      if (isNeedUpdateTableData) {
        setIsNeedUpdateTableData(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isNeedUpdateTableData) {
      onGetDashboardInfo();
    }
  }, [isNeedUpdateTableData]);

  const tableHeading = useMemo((): TableHeading => {
    return [
      {
        name: "modelName",
        label: "Model Name",
      },
      {
        name: "lastUsed",
        label: "Last Used",
      },
      {
        name: "records",
        label: "Records",
      },
      {
        name: "navigation",
        label: "",
      },
    ];
  }, []);

  const getColorByPercent = useCallback((percent: number) => {
    if (percent <= 25) {
      return "rgb(51, 155, 185)";
    }

    if (percent <= 75) {
      return "rgb(87, 169, 87)";
    }

    return "rgb(196, 60, 53)";
  }, []);

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    if (dataCollection && dataCollection.length) {
      dataCollection.forEach((dataCollectionItem, index) => {
        const currentName = getNameByLabel(
          dataCollectionItem.modelName.toLowerCase(),
        );
        currentChildrenForTable.push({
          id: index + 1,
          row: {
            modelName: (
              <Link to={`/admin/${currentName}`}>
                {dataCollectionItem.modelName}
              </Link>
            ),
            lastUsed: dataCollectionItem.lastUsed || "-",
            records: (
              <div
                className={`admin-dashboard__progress-bar `}
                style={
                  {
                    background: `linear-gradient(90deg, ${getColorByPercent(
                      dataCollectionItem.progressBarPercent,
                    )} ${dataCollectionItem.progressBarPercent}%, #cccccc 0%)`,
                  } as CSSProperties
                }
              >
                {dataCollectionItem.count}
              </div>
            ),
            navigation: (
              <div className="btn-group">
                <button
                  className="btn btn-mini btn-blue"
                  onClick={() => {
                    navigate(`/admin/${currentName}/list`);
                  }}
                >
                  <i className="icon-list icon-medium" />
                </button>
                <button
                  className="btn btn-mini btn-success"
                  onClick={() => {
                    navigate(`/admin/${currentName}/new`);
                  }}
                >
                  <i className="icon-plus icon-medium" />
                </button>
              </div>
            ),
          },
        });
      });
    }
    return currentChildrenForTable;
  }, [dataCollection]);
  return (
    <section>
      <TableNew
        heading={tableHeading}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateTableData(true);
        }}
        loading={isLoadingDashboardInfo}
        countPages={1}
        children={childrenForTable}
      />
    </section>
  );
};

export default AdminDashboardDefault;
