import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Header from "./Header/Header";
import Menu from "./Menu/Menu";
import decode from "jwt-decode";
import { menuList } from "./Menu/Menulist";
import { useQuery } from "@apollo/client";
import { LoadingDesign } from "helpers";
import { setNotification, userContextData } from "helpers/cache";
import { USER_QUERY } from "modules/User/Services/Queries/loginQueries";

const isAuthenticated = () => {
  const token: any = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : sessionStorage?.getItem("token");

  // const refreshToken: any = localStorage.getItem("refreshToken");
  try {
    decode(token);
  } catch (error) {
    window.location.replace("/users/sign_in");
  }
};

const PrivateLayout: FC<{
  setAdminDashBoardSideBarClicked: Function;
  fetchCommission: Function;
  salesCommission: any;
  refetchSalesCommission: any;
  salesCommissionLoading: boolean;
}> = ({
  setAdminDashBoardSideBarClicked,
  fetchCommission,
  salesCommission,
  refetchSalesCommission,
  salesCommissionLoading,
}) => {
  isAuthenticated();
  const slider = useRef<any>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;

  const [showMenu, setShowMenu] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const path = useLocation();
  let pathName = path.pathname;
  let title = "";

  const quoteId = useMemo(() => {
    const currentSecondPath = pathName.split("/")[2];
    if (!pathName.includes(`/quotes/${currentSecondPath}`)) {
      return;
    }

    if (!isNaN(+currentSecondPath)) {
      return currentSecondPath;
    }

    if (currentSecondPath === "new_quotes") {
      return "new";
    }
  }, [pathName]);

  const clientId = useMemo(() => {
    const currentSecondPath = pathName.split("/")[2];
    if (!pathName.includes(`/clients/${currentSecondPath}`)) {
      return;
    }

    if (!isNaN(+currentSecondPath)) {
      return currentSecondPath;
    }

    if (currentSecondPath === "new_client") {
      return "new";
    }
  }, [pathName]);

  menuList.map((menuName) =>
    pathName?.includes(`${menuName?.link}`) ? (title = menuName.name) : null,
  );

  const {
    data: userDetails,
    loading: loadingUser,
    refetch,
  } = useQuery(USER_QUERY, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
  });

  useEffect(() => {
    if (userDetails) {
      userContextData({
        user: userDetails?.userByToken,
        refetchUserData: refetch,
        loadingUser: loadingUser,
      });
    }
  }, [refetch, userDetails, loadingUser]);

  const clientThemeFilter = userDetails?.userByToken?.userSettings?.filter(
    (color: any) => color.name === "clients_theme",
  );

  const clientTheme = clientThemeFilter ? clientThemeFilter[0]?.value : "";
  const availableThemeColors = ["skin-1", "skin-2", "skin-3", "default"];

  const [color, setColor] = useState(
    availableThemeColors.includes(clientTheme) ? clientTheme : "skin-3",
  );

  useEffect(() => {
    const availableThemeColors = ["skin-1", "skin-2", "skin-3", "default"];
    setColor(
      availableThemeColors.includes(clientTheme) ? clientTheme : "skin-3",
    );
  }, [clientTheme]);

  let themeColor: any;

  if (color === "skin-3") {
    themeColor = "default";
  } else if (color === "skin-1") {
    themeColor = "black";
  } else if (color === "skin-2") {
    themeColor = "pink";
  } else if (color === "default") {
    themeColor = "blue";
  } else {
    themeColor = "default";
  }
  const accessControlStructure =
    userDetails?.userByToken?.accessControlStructure?.modules;

  return (
    <div className="bg-white font-['Open_Sans']">
      {loadingUser ? (
        <div className="flex justify-center items-center h-screen w-full">
          <LoadingDesign />
        </div>
      ) : (
        <Fragment>
          <header
            className={` ${themeColor} sticky xl:relative top-0 bg-skin-header left-0 right-0 z-50`}
          >
            <Header
              setShowMenu={setShowMenu}
              showMenu={showMenu}
              userName={userDetails?.userByToken?.userName}
              fetchCommission={fetchCommission}
              salesCommission={salesCommission}
              refetchSalesCommission={refetchSalesCommission}
              salesCommissionLoading={salesCommissionLoading}
            />
          </header>
          <main className="flex">
            <div
              className={`${
                showMenu ? "block" : "hidden"
              } fixed xl:hidden z-40 inset-0`}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            />
            <aside
              className={`${themeColor} fixed xl:sticky z-50 top-0 bottom-0 transition-[left] duration-500 ease-in-out ${
                showMenu
                  ? "left-0 mt-[38px] md:mt-[46px] xl:mt-0"
                  : "-left-full mt-0 hidden xl:block"
              } xl:left-0 xl:bottom-0 xl:top-0 bg-skin-sideBarBottomBackground`}
            >
              <div
                className={`h-full xl:h-[95vh] ${
                  showIcon ? "overflow-y-auto" : ""
                }`}
              >
                <Menu
                  showIcon={showIcon}
                  setShowIcon={setShowIcon}
                  themeColor={themeColor}
                  setAdminDashBoardSideBarClicked={
                    setAdminDashBoardSideBarClicked
                  }
                />
              </div>
            </aside>
            <section className={`w-full bg-white h-full overflow-hidden`}>
              {(title === "" || title === "Quotes" || title === "Clients") &&
                pathName !== "/price_table/index" &&
                pathName !== "/user_settings" && (
                  <nav
                    ref={slider}
                    onMouseDown={(e) => {
                      isDown = true;
                      startX = e?.pageX - slider.current?.offsetLeft;
                      scrollLeft = slider?.current?.scrollLeft;
                    }}
                    onMouseLeave={() => {
                      isDown = false;
                    }}
                    onMouseUp={() => {
                      isDown = false;
                    }}
                    onMouseMove={(e) => {
                      if (!isDown) return;
                      e.preventDefault();
                      const x = e.pageX - slider?.current?.offsetLeft;
                      const walk = (x - startX) * 1;
                      slider.current.scrollLeft = scrollLeft - walk;
                    }}
                    className="scrollbarHide flex mt-2 mx-2 mb-0 pr-3 relative min-h-[40px] xl:justify-end bg-[#e7f2f8] rounded-[4px] overflow-visible"
                  >
                    <div className="flex absolute bottom-[-5px] right-[22px]">
                      {accessControlStructure?.quote?.tabs?.editQuote && (
                        <div
                          className={`${
                            quoteId ? "block" : "hidden"
                          } py-2 px-3 group cursor-pointer flex items-center space-x-1 whitespace-nowrap bg-white border-t-2 border-t-[#4C8FBD] border-x border-x-[#ddd]`}
                        >
                          <i className="icon-users text-[#478FCA] text-xs" />
                          <p className={`text-[13px] leading-4 text-[#576373]`}>
                            Edit Quote #{quoteId}
                          </p>
                        </div>
                      )}
                      {accessControlStructure?.client?.tabs?.editClient && (
                        <div
                          className={`${
                            clientId ? "block" : "hidden"
                          } py-2 px-3 group cursor-pointer flex items-center space-x-1 whitespace-nowrap bg-white border-t-2 border-t-[#4C8FBD] border-x border-x-[#ddd]`}
                        >
                          <i className="icon-users text-[#478FCA] text-xs" />
                          <p className={`text-[13px] leading-4 text-[#576373]`}>
                            Edit Client #{clientId}
                          </p>
                        </div>
                      )}
                      {menuList
                        .filter((menu) => menu.name === "Quotes")
                        ?.map((menu) => menu.subMenus)[0]
                        .map((subMenu, index) => {
                          return (
                            accessControlStructure?.quote?.tabs &&
                            Object.entries(accessControlStructure?.quote?.tabs)
                              ?.map(
                                ([key, value]: any) =>
                                  key?.toLowerCase() ===
                                  subMenu.aclName?.toLowerCase(),
                              )
                              ?.includes(true) && (
                              <NavLink
                                key={index}
                                to={subMenu.subMenuLink}
                                className={`py-2 px-3 group cursor-pointer flex items-center space-x-1 whitespace-nowrap ${
                                  (pathName?.includes(
                                    `${subMenu.subMenuLink}`,
                                  ) &&
                                    !quoteId) ||
                                  (title === "" &&
                                    subMenu.subMenuName === "History")
                                    ? "bg-white border-t-2 border-t-[#4C8FBD] border-x border-x-[#ddd] cursor-default"
                                    : "border border-transparent"
                                }`}
                              >
                                <i className="icon-bookmark text-[#69AA46] text-sm leading-3 w-4" />
                                <p
                                  className={`text-[13px] leading-4 text-[#999] ${
                                    pathName?.includes(
                                      `${subMenu.subMenuLink}`,
                                    ) ||
                                    (title === "" &&
                                      subMenu.subMenuName === "History")
                                      ? ""
                                      : "group-hover:text-[#4c8fbd]"
                                  }  ${
                                    pathName?.includes(
                                      `${subMenu.subMenuLink}`,
                                    ) ||
                                    (title === "" &&
                                      subMenu.subMenuName === "History")
                                      ? "text-[#576373]"
                                      : ""
                                  }`}
                                >
                                  {subMenu.subMenuName}
                                </p>
                              </NavLink>
                            )
                          );
                        })}
                      {menuList
                        .filter((menu) => menu.name === "Clients")
                        ?.map((menu) => menu.subMenus)[0]
                        .map((subMenu, index) => {
                          return (
                            accessControlStructure?.client?.tabs &&
                            Object.entries(accessControlStructure?.client?.tabs)
                              ?.map(
                                ([key, value]: any) =>
                                  key?.toLowerCase() ===
                                  subMenu?.aclName?.toLowerCase(),
                              )
                              ?.includes(true) && (
                              <NavLink
                                key={index}
                                to={subMenu?.subMenuLink}
                                className={`py-2 px-3 group cursor-pointer flex items-center space-x-1 whitespace-nowrap ${
                                  pathName?.includes(
                                    `${subMenu?.subMenuLink}`,
                                  ) && !clientId
                                    ? "bg-white border border-b-transparent border-t-2 border-t-[#4C8FBD] border-x border-x-[#ddd] cursor-default"
                                    : "border border-transparent"
                                }`}
                              >
                                <i className="icon-users text-[#478FCA] text-sm leading-3 w-4" />
                                <p
                                  className={`text-[13px] leading-4 text-[#999] ${
                                    pathName?.includes(`${subMenu.subMenuLink}`)
                                      ? ""
                                      : "group-hover:text-[#4c8fbd]"
                                  } ${
                                    pathName?.includes(`${subMenu.subMenuLink}`)
                                      ? "text-[#576373]"
                                      : ""
                                  }`}
                                >
                                  {subMenu.subMenuName === "Clients Pool list"
                                    ? "Pool List"
                                    : subMenu.subMenuName}
                                </p>
                              </NavLink>
                            )
                          );
                        })}
                    </div>
                  </nav>
                )}
              <Outlet />
            </section>
          </main>
        </Fragment>
      )}
    </div>
  );
};

export default PrivateLayout;
