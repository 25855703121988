import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <section className="bg-[#1d2024] w-full h-screen text-white font-['Open_Sans']">
      <h1 className="text-center py-[10px] space-x-2 cursor-text font-['Open_Sans']">
        <i className="icon-leaf green text-[#69aa46] text-[32px]" />
        <span className="font-normal text-[32px] leading-10 text-[#dd5a43] font-['Open_Sans']">
          Crm
        </span>
        <span className="font-normal text-[32px] leading-10 text-white font-['Open_Sans']">
          Application
        </span>
      </h1>
      <Outlet />
    </section>
  );
};

export default PublicLayout;
