import React, { FC, useCallback, useMemo, useState } from "react";
import FormCard from "../../../../components/Form/El/FormCard";
import { QuoteEditData } from "../../../../types/Quote";
import { FormFields, FormSelectOptionItem } from "../../../../types/Form";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER, CREATE_ORDER } from "../../Services/Mutations/Mutations";
import { setNotification } from "../../../../helpers/cache";
import { editQuoteOrderSetObjForSending } from "./EditQuoteSetObjForSending";
import {cloneDeep} from "lodash";

const EditQuoteShippingInfo: FC<{
  quoteEditData: QuoteEditData;
  setQuoteEditData:  React.Dispatch<React.SetStateAction<QuoteEditData>>;
  creditCardList: FormSelectOptionItem[];
  setIsNeedUpdateQuote: () => void;
  updateClientCreditCard: (id: number) => void;
  tabShippingAndAdditionalInfoAccess: any;
  hasEditAndDeleteAccess: boolean;
  quote?: any;
}> = ({
  quoteEditData,
  setQuoteEditData,
  creditCardList,
  setIsNeedUpdateQuote,
  updateClientCreditCard,
  tabShippingAndAdditionalInfoAccess,
  hasEditAndDeleteAccess,
  quote,
}) => {
    const formAccess = useMemo(() => tabShippingAndAdditionalInfoAccess?.forms, [tabShippingAndAdditionalInfoAccess]);

    const formEditOrderAccess = useMemo(() => formAccess?.editOrder, [formAccess]);

    const formEditOrderButtonsAccess = useMemo(() => formEditOrderAccess?.buttons, [formEditOrderAccess]);
    const formEditOrderFieldsAccess = useMemo(() => formEditOrderAccess?.fields, [formEditOrderAccess]);

    const formEditOrderFieldsPaymentTypeAccess = useMemo(() => formEditOrderFieldsAccess?.paymentType, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsClientCreditCardAccess = useMemo(() => formEditOrderFieldsAccess?.clientCreditCard, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsShippingMethodAccess = useMemo(() => formEditOrderFieldsAccess?.shippingMethod, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsTrackingNumber1Access = useMemo(() => formEditOrderFieldsAccess?.trackingNumber1, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsTrackingNumber2Access = useMemo(() => formEditOrderFieldsAccess?.trackingNumber2, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsWatchTrackingNumber1Access = useMemo(() => formEditOrderFieldsAccess?.watchTracking1, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsWatchTrackingNumber2Access = useMemo(() => formEditOrderFieldsAccess?.watchTracking2, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsPaymentStatusAccess = useMemo(() => formEditOrderFieldsAccess?.paymentStatus, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsSpecialInstructionAccess = useMemo(() => formEditOrderFieldsAccess?.specialInstruction, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsMiscellaneousInfoAccess = useMemo(() => formEditOrderFieldsAccess?.miscellaneousInfo, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsNoteAccess = useMemo(() => formEditOrderFieldsAccess?.note, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsUuseUsFedexAcctAccess = useMemo(() => formEditOrderFieldsAccess?.useUpsFedexAcct, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsCustomerPaysShippingAccess = useMemo(() => formEditOrderFieldsAccess?.customerPaysShipping, [formEditOrderFieldsAccess]);

    const order = useMemo(() => quoteEditData?.order, [quoteEditData]);

    const [updateOrderServerData] = useMutation(UPDATE_ORDER, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [createOrderServerData] = useMutation(CREATE_ORDER, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [isLoadingUpdateOrderServerData, setIsLoadingUpdateOrderServerData] =
      useState(false);

    const onUpdateOrderServerData = useCallback(async () => {
      setIsLoadingUpdateOrderServerData(true);

      if (order.id) {
        await updateOrderServerData({
          variables: {
            ...editQuoteOrderSetObjForSending(order),
            quoteId: quoteEditData.id,
          },
        })
          .then((res) => {
            const { data } = res;

            if (!data) return;

            const { updateOrder } = data;

            if (!updateOrder) return;

            setIsNeedUpdateQuote();
            setNotification([
              {
                type: "SUCCESS",
                message: "Shipping & Additional info successfully saved",
              },
            ]);
          })
          .finally(() => {
            setIsLoadingUpdateOrderServerData(false);
          });
        return
      }

      await createOrderServerData({
        variables: {
          ...editQuoteOrderSetObjForSending(order),
          quoteId: quoteEditData.id
        },
      })
        .then((res) => {
          const { data } = res;

          if (!data) return;

          const { createOrder } = data;

          if (!createOrder) return;

          setIsNeedUpdateQuote();
          setNotification([
            {
              type: "SUCCESS",
              message: "Shipping & Additional info successfully saved",
            },
          ]);
        })
        .finally(() => {
          setIsLoadingUpdateOrderServerData(false);
        });
    }, [order]);

    const updateOrder = useCallback(
      (data: Partial<QuoteEditData["order"]>) => {
        setQuoteEditData(prevState => {
          const tmpEditData = cloneDeep(prevState);
          tmpEditData.order = {
            ...tmpEditData.order,
            ...data
          }


          return tmpEditData;
        })
      },
      [setQuoteEditData],
    );
    const ShippingInfoFormField = useMemo(
      (): FormFields[] => [
        [
          {
            name: "paymentType",
            label: "PaymentType",
            onChange: (value) => {
              if (typeof value === "number") {
                updateOrder({
                  paymentType: value,
                });
              }
            },
            type: "select",
            subType: "paymentTypes",
            value: order?.paymentType,
            accessedFields: formEditOrderFieldsPaymentTypeAccess,
          },
          {
            name: "creditCardId",
            label: "Client Credit Card",
            onChange: (value) => {
              if (typeof value === "number") {
                updateClientCreditCard(value);
              }
            },
            type: "select",
            selectOptions: {
              optionsGroup: [{ options: creditCardList }],
            },
            value: order?.clientCreditCardId,
            accessedFields: formEditOrderFieldsClientCreditCardAccess,
          },
          {
            name: "shippingMethod",
            label: "Shipping Method",
            onChange: (value) => {
              if (typeof value === "number") {
                updateOrder({
                  shippingMethod: value,
                });
              }
            },
            type: "select",
            subType: "shippingMethods",
            value: order?.shippingMethod,
            accessedFields: formEditOrderFieldsShippingMethodAccess,
          },
          {
            name: "trackingNumber1",
            label: "Tracking Number 1",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  trackingNumber1: value,
                });
              }
            },
            type: "input",
            value: order?.trackingNumber1,
            accessedFields: formEditOrderFieldsTrackingNumber1Access,
          },
          {
            name: "watchTracking1",
            label: "Watch Tracking 1",
            onChange: (value) => {
              if (typeof value === "boolean") {
                updateOrder({
                  watchTracking1: value,
                });
              }
            },
            type: "checkbox",
            value: order?.watchTracking1,
            accessedFields: formEditOrderFieldsWatchTrackingNumber1Access,
          },
          {
            name: "trackingNumber2",
            label: "Tracking Number 2",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  trackingNumber2: value,
                });
              }
            },
            type: "input",
            value: order?.trackingNumber2,
            accessedFields: formEditOrderFieldsTrackingNumber2Access,
          },
          {
            name: "watchTracking2",
            label: "Watch Tracking 2",
            onChange: (value) => {
              if (typeof value === "boolean") {
                updateOrder({
                  watchTracking2: value,
                });
              }
            },
            type: "checkbox",
            value: order?.watchTracking2,
            accessedFields: formEditOrderFieldsWatchTrackingNumber2Access,
          },
          {
            name: "paymentStatus",
            label: "Payment Status",
            onChange: (value) => {
              if (typeof value === "number") {
                updateOrder({
                  paymentStatus: value,
                });
              }
            },
            type: "select",
            subType: "paymentStatuses",
            value: order?.paymentStatus,
            accessedFields: formEditOrderFieldsPaymentStatusAccess,
          },
        ],
        [
          {
            name: "specialInstructions",
            label: "Special Instructions",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  specialInstructions: value,
                });
              }
            },
            type: "input",
            inputOptions: {
              isTextarea: true,
              rowCount: 2,
            },
            value: order?.specialInstructions,
            accessedFields: formEditOrderFieldsSpecialInstructionAccess,
          },
          {
            name: "misc",
            label: "Miscellaneous Info",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  misc: value,
                });
              }
            },
            type: "input",
            inputOptions: {
              isTextarea: true,
              rowCount: 2,
            },
            value: order?.misc,
            accessedFields: formEditOrderFieldsMiscellaneousInfoAccess,
          },
          {
            name: "note",
            label: "Note",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  note: value,
                });
              }
            },
            type: "input",
            inputOptions: {
              isTextarea: true,
              rowCount: 2,
            },
            value: order?.note,
            accessedFields: formEditOrderFieldsNoteAccess,
          },
          {
            name: "useUpsFedexAcct",
            label: "Use Ups Fedex Acct",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  useUpsFedexAcct: value,
                });
              }
            },
            type: "input",
            value: order?.useUpsFedexAcct,
            accessedFields: formEditOrderFieldsUuseUsFedexAcctAccess,
          },
          {
            name: "customerPaysShipping",
            label: "Customer Pays Shipping",
            onChange: (value) => {
              if (typeof value === "boolean") {
                updateOrder({
                  customerPaysShipping: value,
                });
              }
            },
            type: "checkbox",
            value: order?.customerPaysShipping,
            accessedFields: formEditOrderFieldsCustomerPaysShippingAccess,
          },
        ],
      ],
      [
        creditCardList,
        order,
        formEditOrderFieldsPaymentTypeAccess,
        formEditOrderFieldsClientCreditCardAccess,
        formEditOrderFieldsShippingMethodAccess,
        formEditOrderFieldsTrackingNumber1Access,
        formEditOrderFieldsTrackingNumber2Access,
        formEditOrderFieldsWatchTrackingNumber1Access,
        formEditOrderFieldsWatchTrackingNumber2Access,
        formEditOrderFieldsPaymentStatusAccess,
        formEditOrderFieldsSpecialInstructionAccess,
        formEditOrderFieldsMiscellaneousInfoAccess,
        formEditOrderFieldsNoteAccess,
        formEditOrderFieldsUuseUsFedexAcctAccess,
        formEditOrderFieldsCustomerPaysShippingAccess
      ],
    );
    return (
      <section>
        <div className="row-fluid">
          <FormCard
            name="edit-order"
            formFields={ShippingInfoFormField}
            label="Edit Order"
            className="w-full"
            additionalButtons={[
              {
                label: "Save",
                icon: `${isLoadingUpdateOrderServerData
                    ? "inline-block animate-spin icon-spin4"
                    : "icon-floppy"
                  } pr-1`,
                color: "success",
                name: "sameAsBilling",
                onClick: () => {
                  onUpdateOrderServerData();
                },
                hidden: !quoteEditData.id || !formEditOrderButtonsAccess.includes('save') || !hasEditAndDeleteAccess,
                disabled: isLoadingUpdateOrderServerData,
              },
            ]}
          />
        </div>
      </section>
    );
  };

export default EditQuoteShippingInfo;
