import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  ClearEditClientData,
  EditClientDataShippingAddress,
} from "../../../../types/Client";
import { useMutation, useReactiveVar } from "@apollo/client";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { FormField } from "../../../../types/Form";
import FormCard from "../../../../components/Form/El/FormCard";
import {
  CREATE_SHIPPING_ADDRESS,
  UPDATE_SHIPPING_ADDRESS,
} from "../../Services/Mutations/Mutation";
import ClientDeleteModal from "./ClientDeleteModal";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import { isValidNonEmpty } from "../../../../helpers/Validation";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";

const EditClientAddressNew: FC<{
  clientEditData: ClearEditClientData;
  setNewClientData: (newClientData: ClearEditClientData) => void;
  clearShippingAddressesData: EditClientDataShippingAddress;
  currentShippingAddressesId: number;
  tabsAccess: any;
  defaultBillingAddressId: number;
  defaultShippingAddressId: number;
  setCurrentShippingAddressesId: (id: number) => void;
  setDefaultBillingAddressId: (id: number) => void;
  setDefaultShippingAddressId: (id: number) => void;
  setIsNeedUpdateClient: (value: boolean) => void;
  clientId?: number;
}> = ({
  clientEditData,
  setNewClientData,
  clearShippingAddressesData,
  currentShippingAddressesId,
  tabsAccess,
  defaultBillingAddressId,
  defaultShippingAddressId,
  setCurrentShippingAddressesId,
  setDefaultBillingAddressId,
  setDefaultShippingAddressId,
  setIsNeedUpdateClient,
  clientId,
}) => {
  const userDetails: any = useReactiveVar(userContextData);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteAddressId, setDeleteAddressId] = useState<number>();
  const [isLoadingDeleteAddress, setIsLoadingDeleteAddress] = useState(false);
  const [isLoadingUpdateAddress, setIsLoadingUpdateAddress] = useState(false);
  const [updateShippingAddress] = useMutation(UPDATE_SHIPPING_ADDRESS, {
    onError: (error) => {
      setNotification({ type: "ERROR", message: error.message });
    },
  });
  const [isLoadingUpdateDefaultAddressId, setIsLoadingUpdateDefaultAddressId] =
    useState<number | undefined>();

  const [createShippingAddress] = useMutation(CREATE_SHIPPING_ADDRESS, {
    onError: (error) => {
      setNotification({ type: "ERROR", message: error.message });
    },
  });

  const addressFormAccess = useMemo(
    () => tabsAccess?.address?.forms?.shippingAddress,
    [tabsAccess],
  );

  const addressFormTypeAddressFieldAccess = useMemo(
    () => addressFormAccess?.fields?.typeAddress,
    [addressFormAccess],
  );
  const addressFormCreateBothAddressFieldAccess = useMemo(
    () => addressFormAccess?.fields?.createBothAddress,
    [addressFormAccess],
  );
  const addressFormAddressFieldAccess = useMemo(
    () => addressFormAccess?.fields?.address,
    [addressFormAccess],
  );
  const addressFormAddress2FieldAccess = useMemo(
    () => addressFormAccess?.fields?.address2,
    [addressFormAccess],
  );
  const addressFormCityFieldAccess = useMemo(
    () => addressFormAccess?.fields?.city,
    [addressFormAccess],
  );
  const addressFormStateFieldAccess = useMemo(
    () => addressFormAccess?.fields?.state,
    [addressFormAccess],
  );
  const addressFormCountryFieldAccess = useMemo(
    () => addressFormAccess?.fields?.country,
    [addressFormAccess],
  );
  const addressFormZipFieldAccess = useMemo(
    () => addressFormAccess?.fields?.zip,
    [addressFormAccess],
  );
  const addressFormAttentionToFieldAccess = useMemo(
    () => addressFormAccess?.fields?.attentionTo,
    [addressFormAccess],
  );

  const currentAddressIndex: number = useMemo(() => {
    const { clientShippingAddresses } = clientEditData;
    if (!clientShippingAddresses || !clientShippingAddresses.length) return 0;

    const searchIndex = clientShippingAddresses.findIndex((address) =>
      currentShippingAddressesId
        ? address.id === currentShippingAddressesId
        : !address.id,
    );

    return searchIndex === -1 ? clientShippingAddresses.length : searchIndex;
  }, [clientEditData, currentShippingAddressesId]);

  const currentAddressData = useMemo(() => {
    const { clientShippingAddresses } = clientEditData;
    return clientShippingAddresses?.[currentAddressIndex]
      ? { ...clientShippingAddresses[currentAddressIndex] }
      : clearShippingAddressesData;
  }, [currentAddressIndex, clientEditData, clearShippingAddressesData]);

  const setModifiedAddressData = useCallback(
    (modifiedAddressData: Partial<EditClientDataShippingAddress>) => {
      const { clientShippingAddresses } = clientEditData;

      let currentAddressesData = clientShippingAddresses
        ? [...clientShippingAddresses]
        : [];

      if (!currentAddressesData?.length) {
        currentAddressesData = [
          {
            ...clearShippingAddressesData,
          },
        ];
      }

      currentAddressesData[currentAddressIndex] = {
        ...currentAddressData,
        ...modifiedAddressData,
      };

      setNewClientData({
        ...clientEditData,
        clientShippingAddresses: currentAddressesData,
      });
    },
    [clientEditData, currentAddressIndex, setNewClientData],
  );

  const addressFormFields = useMemo((): FormField[] => {
    if (!currentAddressData) return [];

    return [
      {
        name: "typeAddress",
        label: "Type address",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedAddressData({
              typeAddress: value,
            });
          }
        },
        type: "select",
        subType: "shippingAddressTypes",
        value: currentAddressData?.typeAddress,
        accessedFields: addressFormTypeAddressFieldAccess,
      },
      {
        name: "createBothAddress",
        label: "Create both address",
        onChange: (value) => {
          if (typeof value === "boolean") {
            setModifiedAddressData({
              createBothAddress: value,
            });
          }
        },
        type: "checkbox",
        value: currentAddressData?.createBothAddress,
        accessedFields:addressFormCreateBothAddressFieldAccess,
      },
      {
        name: "address",
        label: "Address",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedAddressData({
              address: value,
            });
          }
        },
        isIncorrectValue: !isValidNonEmpty(currentAddressData?.address),
        type: "input",
        value: currentAddressData?.address,
        accessedFields:addressFormAddressFieldAccess,
      },
      {
        name: "address2",
        label: "Address2",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedAddressData({
              address2: value,
            });
          }
        },
        type: "input",
        value: currentAddressData?.address2,
        accessedFields: addressFormAddress2FieldAccess,
      },
      {
        name: "city",
        label: "City",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedAddressData({
              city: value,
            });
          }
        },
        type: "input",
        value: currentAddressData?.city,
        accessedFields:addressFormCityFieldAccess,
      },
      {
        name: "state",
        label: "State",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedAddressData({
              state: value,
            });
          }
        },
        type: "select",
        subType: "states",
        value: currentAddressData?.state,
        accessedFields: addressFormStateFieldAccess,
      },
      {
        name: "country",
        label: "Country",
        onChange: (value) => {
          if (typeof value === "number") {
            setModifiedAddressData({
              countryId: value,
            });
          }
        },
        type: "select",
        subType: "countries",
        value: currentAddressData?.countryId,
        accessedFields: addressFormCountryFieldAccess,
      },
      {
        name: "zip",
        label: "Zip",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedAddressData({
              zip: value,
            });
          }
        },
        type: "input",
        value: currentAddressData?.zip,
        accessedFields: addressFormZipFieldAccess,
      },
      {
        name: "attentionTo",
        label: "Attention To",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedAddressData({
              attentionTo: value,
            });
          }
        },
        type: "input",
        value: currentAddressData?.attentionTo,
        accessedFields: addressFormAttentionToFieldAccess,
      },
    ];
  }, [
    currentAddressData,
    addressFormTypeAddressFieldAccess,
    addressFormCreateBothAddressFieldAccess,
    addressFormAddressFieldAccess,
    addressFormAddress2FieldAccess,
    addressFormCityFieldAccess,
    addressFormStateFieldAccess,
    addressFormCountryFieldAccess,
    addressFormZipFieldAccess,
    addressFormAttentionToFieldAccess,
    setModifiedAddressData,
  ]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<
    EditClientDataShippingAddress[]
  >([]);
  const [countPages, setCountPages] = useState(0);

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const { clientShippingAddresses } = clientEditData;
    const countItems = clientShippingAddresses?.length || 0;

    setCountPages(Math.ceil(countItems / pageSize));
    setDataCollection(
      clientShippingAddresses?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize - 1,
      ) || [],
    );
  }, [clientEditData, pageSize, currentPage]);

  const onUpdateDefaultAddressId = useCallback(
    async (id: number, type: string) => {
      setIsLoadingUpdateDefaultAddressId(id);

      await updateShippingAddress({
        variables: {
          id: id,
          isDefault: true,
        },
      })
        .then(() => {
          setIsNeedUpdateClient(true);
        })
        .finally(() => {
          setIsLoadingUpdateDefaultAddressId(undefined);
        });
    },
    [],
  );

  const onDeleteAddressId = useCallback(async () => {
    setIsLoadingDeleteAddress(true);
    await updateShippingAddress({
      variables: {
        id: deleteAddressId,
        isDelete: true,
      },
    })
      .then((res) => {
        const { data } = res;

        if (data?.updateShippingAddress?.id) {
          setNotification([
            {
              type: "SUCCESS",
              message: "Address successfully deleted.",
            },
          ]);

          setIsNeedUpdateClient(true);
        }
      })
      .finally(() => {
        setIsLoadingDeleteAddress(false);
        setDeleteAddressId(0);
      });
  }, [deleteAddressId]);

  useEffect(() => {
    setShowDeleteModal(!!deleteAddressId);
  }, [deleteAddressId]);

  useEffect(() => {
    if (!showDeleteModal && deleteAddressId) {
      setDeleteAddressId(0);
    }
  }, [showDeleteModal]);

  const tableHeading = useMemo((): TableHeading => {
    return [
      {
        name: "buttons",
        label: "Edit",
      },
      {
        name: "address",
        label: "Address_1",
      },
      {
        name: "address2",
        label: "Address_2",
      },
      {
        name: "city",
        label: "City",
      },
      {
        name: "state",
        label: "State",
      },
      {
        name: "zip",
        label: "Zip",
      },
      {
        name: "typeAddress",
        label: "Type",
      },
    ];
  }, []);

  const indexAddressWithoutId = useMemo(() => {
    const { clientShippingAddresses } = clientEditData;

    return clientShippingAddresses?.findIndex((address) => !address.id);
  }, [clientEditData]);

  const onCreateNewAddress = useCallback(() => {
    setCurrentShippingAddressesId(0);
  }, [clientEditData]);

  const onSaveAddress = useCallback(async () => {
    setIsLoadingUpdateAddress(true);

    const onSaveCallback = async () => {
      return currentShippingAddressesId
        ? await updateShippingAddress({
            variables: {
              clientId,
              id: currentShippingAddressesId,
              ...currentAddressData,
            },
          })
        : await createShippingAddress({
            variables: {
              clientId,
              ...currentAddressData,
            },
          });
    };
    await onSaveCallback()
      .then((res) => {
        const { data } = res;

        if (
          data?.[
            currentShippingAddressesId
              ? "updateShippingAddress"
              : "createShippingAddress"
          ]?.[0]?.id
        ) {
          setIsNeedUpdateClient(true);

          setNotification([
            {
              type: "SUCCESS",
              message: `Address successfully ${
                currentShippingAddressesId ? "updated" : "saved"
              }.`,
            },
          ]);
        }
      })
      .finally(() => {
        setIsLoadingUpdateAddress(false);
      });
  }, [clientId, currentShippingAddressesId, currentAddressData]);

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    const isDefaultAddress = (id: number, typeAddress: string) => {
      if (typeAddress === "Billing") {
        return id === defaultBillingAddressId;
      }

      if (typeAddress === "Shipping") {
        return id === defaultShippingAddressId;
      }

      return false;
    };

    dataCollection.forEach((dataCollectionItem) => {
      const {
        id,
        address,
        address2,
        typeAddress,
        zip,
        createBothAddress,
        attentionTo,
        city,
        state,
        countryId,
      } = dataCollectionItem;

      currentChildrenForTable.push({
        id: id || 0,
        row: {
          buttons: (
            <div className="btn-group">
              <button
                className="btn btn-small btn-success"
                onClick={() => {
                  setCurrentShippingAddressesId(id || 0);
                }}
              >
                <i className="icon-edit icon-medium" />
              </button>
              <button
                className="btn btn-small btn-warning"
                onClick={() => {
                  setDeleteAddressId(id || 0);
                }}
                disabled={!id}
              >
                <i className="icon-trash-empty icon-medium" />
              </button>
              <button
                className="btn btn-small btn-blue"
                onClick={async () => {
                  onUpdateDefaultAddressId(id || 0, typeAddress);
                }}
                disabled={!id || !!(id && isDefaultAddress(id, typeAddress))}
              >
                <i
                  className={`${
                    !!id && isLoadingUpdateDefaultAddressId === id
                      ? "inline-block animate-spin icon-spin4"
                      : !!(id && isDefaultAddress(id, typeAddress))
                        ? "icon-bookmark"
                        : "icon-bookmark-empty"
                  } icon-medium`}
                />
              </button>
            </div>
          ),
          address: address || "-",
          address2: address2 || "-",
          city: city || "-",
          state: state || "-",
          zip: zip || "-",
          typeAddress: typeAddress || "-",
        },
        additionalClassRow: `${
          id && isDefaultAddress(id, typeAddress) ? "selected" : ""
        }`,
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);

  return (
    <section>
      <div className="row-fluid">
        {addressFormAccess && (
          <FormCard
            name="shipping-address"
            formFields={[addressFormFields]}
            className="box-4"
            label="Shipping address"
            additionalButtons={[
              {
                label: currentShippingAddressesId ? "Update" : "Save",
                icon: isLoadingUpdateAddress
                  ? "inline-block animate-spin icon-spin4"
                  : "icon-floppy",
                color: "success",
                name: "save",
                disabled:
                  isLoadingUpdateAddress ||
                  !currentAddressData.address ||
                  !currentAddressData.typeAddress,
                onClick: () => {
                  onSaveAddress();
                },
                hidden: !clientId,
              },
              {
                label: "New Address",
                color: "default",
                name: "createNewAddress",
                onClick: () => {
                  onCreateNewAddress();
                },
                hidden: !clientId,
                disabled:
                  indexAddressWithoutId !== -1 || !currentShippingAddressesId,
              },
            ]}
          />
        )}
      </div>
      <TableWithHeaderNew
        header="History"
        heading={tableHeading}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={false}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateClient(true);
        }}
      />
      {showDeleteModal && (
        <ClientDeleteModal
          setShowDeleteModal={setShowDeleteModal}
          deleteTitle={"Delete client address"}
          loading={isLoadingDeleteAddress}
          onDelete={() => {
            onDeleteAddressId();
          }}
        />
      )}
    </section>
  );
};

export default EditClientAddressNew;
