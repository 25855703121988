import React from "react";
import PublicLayout from "layouts/PublicLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login";

const Account = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/" element={<Login />} />
      </Route>
      <Route path="/*" element={<Navigate to="/users/sign_in" replace />} />
    </Routes>
  );
};

export default Account;
