import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { FormSelectGroupOption, FormSelectValue } from "../../../types/Form";

const Select: FC<{
  value: FormSelectValue;
  defaultValue?: FormSelectValue;
  optionsGroups: FormSelectGroupOption[];
  onChange: (value: FormSelectValue) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  classNameForSelect?: string;
  isIncorrectValue?: boolean;
  disabled?: boolean;
  name: string;
  loading?: boolean;
  labelWidth?: string;
  selectWidth?: string;
  label?: string | ReactNode;
  multiple?: boolean;
  children?: ReactNode;
  color?: "gray" | "white";
  withNullbaleValue?: boolean
}> = ({
  value,
  optionsGroups,
  onChange,
  onFocus,
  onBlur,
  className,
  classNameForSelect,
  isIncorrectValue,
  disabled,
  name,
  loading,
  labelWidth,
  label,
  defaultValue,
  multiple,
  selectWidth,
  children,
  color = "gray",
                      withNullbaleValue,
}) => {
  const loadingValue = "Loading...";
  const noDataValue = "No data";

  const [currentValue, setCurrentValue] =
    useState<FormSelectValue>("Loading...");

  useEffect(() => {
    let newVal: FormSelectValue = undefined;

    if (loading) {
      newVal = loadingValue;
    }

    if (!loading && !optionsGroups.length) {
      newVal = noDataValue;
    }

    if (!loading && optionsGroups.length) {
      newVal = typeof value === 'number' && isNaN(value) ? '' : value;
    }

    if ((!newVal && newVal !== '') && typeof newVal !== "number" && defaultValue !== undefined) {
      newVal = defaultValue;

      onChange(defaultValue);
    }

    setCurrentValue(newVal);
  }, [value, loading, defaultValue]);

  return (
    <label
      className={`select-box ${className || ""} ${
        label ? "select-box_with-label" : ""
      }`}
      htmlFor={name}
    >
      {label && (
        <div
          className="select-box__label"
          style={{ width: `${labelWidth || "max-content"}` }}
        >
          {label}
          {isIncorrectValue && <i className="icon-cancel-circled red" />}
        </div>
      )}
      <div className="select-box__select-wrapper">
        {children && children}
        <select
          name={name}
          id={name}
          multiple={multiple}
          className={`select-box__select select-box__select_${color} ${
            classNameForSelect || ""
          } ${multiple ? "select-box__select_multiple" : ""} ${
            isIncorrectValue ? "select-box__input_error" : ""
          }`}
          style={{
            maxWidth: `${selectWidth || (multiple ? "150px" : "unset")}`,
          }}
          disabled={disabled}
          value={
            multiple && currentValue !== undefined
              ? [currentValue.toString()]
              : currentValue
          }
          onChange={(event) => {
            let value: FormSelectValue = event.currentTarget.value;

            if (
              typeof optionsGroups?.[0]?.options?.[0] === "object" &&
              typeof optionsGroups[0].options[0].id === "number") {
              value = parseInt(value);
            }

            onChange(value);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {loading && <option disabled>{loadingValue}</option>}
          {!loading && !optionsGroups.length && (
            <option disabled>{noDataValue}</option>
          )}
          {
            !loading && optionsGroups.length && withNullbaleValue && (
                  <option value={""}>{""}</option>
              )
          }
          {!loading &&
            optionsGroups.length &&
            optionsGroups.map((optionsGroup, indexOptionsGroup) => {
              if (optionsGroups.length > 1) {
                return (
                  <optgroup
                    label={optionsGroup?.label || ""}
                    key={`select-option-group-${name}-${indexOptionsGroup}`}
                  >
                    {optionsGroup.options.map((selectOption, index) => {
                      return (
                        <option
                          key={`select-option-item-${name}-${indexOptionsGroup}-${index}`}
                          value={
                            typeof selectOption === "object"
                              ? selectOption?.id
                              : selectOption
                          }
                        >
                          {typeof selectOption === "object"
                            ? selectOption?.value
                            : selectOption}
                        </option>
                      );
                    })}
                  </optgroup>
                );
              } else {
                return optionsGroup.options.map((selectOption, index) => {
                  return (
                    <option
                      key={`select-option-item-${name}-${indexOptionsGroup}-${index}`}
                      value={
                        typeof selectOption === "object"
                          ? selectOption?.id
                          : selectOption
                      }
                    >
                      {typeof selectOption === "object"
                        ? selectOption?.value
                        : selectOption}
                    </option>
                  );
                });
              }
            })}
        </select>
      </div>
    </label>
  );
};

export default Select;
