import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  ClearEditClientData,
  EditClientDataCreditCard,
} from "../../../../types/Client";
import { FormField } from "../../../../types/Form";
import FormCard from "../../../../components/Form/El/FormCard";
import { regExpNumber } from "../../../../helpers/RegExp";
import {
  isValidCreditCard,
  isValidNonEmpty,
} from "../../../../helpers/Validation";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  CREATE_CLIENT_CREDIT_CARD,
  UPDATE_CLIENT_CREDIT_CARD,
} from "../../Services/Mutations/Mutation";
import ClientDeleteModal from "./ClientDeleteModal";
import { convertDateToUSFormat } from "../../../../helpers/utils";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";

const EditClientListOfCardsNew: FC<{
  clientEditData: ClearEditClientData;
  setNewClientData: (newClientData: ClearEditClientData) => void;
  clearCreditCardData: EditClientDataCreditCard;
  currentCreditCardId: number;
  tabsAccess: any;
  setIsNeedUpdateClient: (value: boolean) => void;
  setCurrentCreditCardId: (id: number) => void;
  clientId?: number;
}> = ({
  clientEditData,
  setNewClientData,
  clearCreditCardData,
  currentCreditCardId,
  tabsAccess,
  setIsNeedUpdateClient,
  setCurrentCreditCardId,
  clientId,
}) => {
  const listOfCardsFormAccess = useMemo(
    () => tabsAccess?.listOfCard?.forms?.creditCardInfo?.fields,
    [tabsAccess],
  );

  const listOfCardsHolderNameFormAccess = useMemo(
    () => listOfCardsFormAccess?.holderName,
    [listOfCardsFormAccess],
  );
  const listOfCardsExpDateFormAccess = useMemo(
    () => listOfCardsFormAccess?.expirationDate,
    [listOfCardsFormAccess],
  );
  const listOfCardsCardNumberFormAccess = useMemo(
    () => listOfCardsFormAccess?.cardNumber,
    [listOfCardsFormAccess],
  );
  const listOfCardsVerificationNumberFormAccess = useMemo(
    () => listOfCardsFormAccess?.verificationNumber,
    [listOfCardsFormAccess],
  );

  const currentCreditCardIndex: number = useMemo(() => {
    const { clientCreditCards } = clientEditData;
    if (!clientCreditCards || !clientCreditCards.length) return 0;

    const searchIndex = clientCreditCards.findIndex((creditCard) =>
      currentCreditCardId
        ? creditCard.id === currentCreditCardId
        : !creditCard.id,
    );
    return searchIndex === -1 ? clientCreditCards.length : searchIndex;
  }, [clientEditData, currentCreditCardId]);

  const currentCreditCardData = useMemo(() => {
    const { clientCreditCards } = clientEditData;

    return clientCreditCards?.[currentCreditCardIndex]
      ? { ...clientCreditCards?.[currentCreditCardIndex] }
      : clearCreditCardData;
  }, [currentCreditCardIndex, clientEditData, clearCreditCardData]);

  const setModifiedCreditCardData = useCallback(
    (modifiedCreditCardData: Partial<EditClientDataCreditCard>) => {
      const { clientCreditCards } = clientEditData;
      let currentCreditCardsData = clientCreditCards
        ? [...clientCreditCards]
        : [];

      if (!currentCreditCardsData?.length) {
        currentCreditCardsData = [
          {
            ...clearCreditCardData,
          },
        ];
      }

      currentCreditCardsData[currentCreditCardIndex] = {
        ...currentCreditCardData,
        ...modifiedCreditCardData,
      };

      setNewClientData({
        ...clientEditData,
        clientCreditCards: currentCreditCardsData,
      });
    },
    [clientEditData, currentCreditCardIndex, setNewClientData],
  );

  const creditCardFormFields = useMemo((): FormField[] => {
    if (!currentCreditCardData) return [];

    return [
      {
        name: "holderName",
        label: "Holder Name",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedCreditCardData({
              holderName: value,
            });
          }
        },
        isIncorrectValue: !isValidNonEmpty(currentCreditCardData?.holderName),
        type: "input",
        value: currentCreditCardData?.holderName,
        accessedFields:listOfCardsHolderNameFormAccess,
      },
      {
        name: "expDate",
        label: "Expiration Date",
        onChange: (value) => {
          return;
        },
        type: "select",
        subType: "exp-date",
        isIncorrectValue:
          !isValidNonEmpty(currentCreditCardData?.expirationYear) ||
          !isValidNonEmpty(currentCreditCardData?.expirationMonth),
        expDateOptions: {
          monthValue: currentCreditCardData.expirationMonth,
          yearValue: currentCreditCardData.expirationYear,
          onYearChange: (value) => {
            setModifiedCreditCardData({
              expirationYear: value,
            });
          },
          onMonthChange: (value) => {
            setModifiedCreditCardData({
              expirationMonth: value,
            });
          },
        },
        value: undefined,
        accessedFields: listOfCardsExpDateFormAccess,
      },
      {
        name: "cardNumber",
        label: "Card Number",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedCreditCardData({
              cardNumber: value,
            });
          }
        },
        type: "input",
        value: currentCreditCardData?.cardNumber,
        inputOptions: {
          pattern: regExpNumber,
        },
        isIncorrectValue:
          !isValidCreditCard(currentCreditCardData?.cardNumber) &&
          !currentCreditCardData.id,
        accessedFields: listOfCardsCardNumberFormAccess,
      },
      {
        name: "verificationNumber",
        label: "Verification Number",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedCreditCardData({
              verificationNumber: value,
            });
          }
        },
        type: "input",
        inputOptions: {
          pattern: regExpNumber,
        },
        isIncorrectValue: !isValidNonEmpty(
          currentCreditCardData?.verificationNumber,
        ),
        value: currentCreditCardData?.verificationNumber,
        accessedFields: listOfCardsVerificationNumberFormAccess,
      },
    ];
  }, [
    currentCreditCardData,
    listOfCardsHolderNameFormAccess,
    listOfCardsExpDateFormAccess,
    listOfCardsCardNumberFormAccess,
    listOfCardsVerificationNumberFormAccess,
    setModifiedCreditCardData,
  ]);

  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "buttons",
        label: "Edit",
      },
      {
        name: "holderName",
        label: "Holder Name",
      },
      {
        name: "cardType",
        label: "Card_type",
      },
      {
        name: "cardNumber",
        label: "Card Number",
      },
      {
        name: "createdAt",
        label: "Created At",
      },
    ],
    [],
  );

  const userDetails: any = useReactiveVar(userContextData);

  const [updateCreditCard] = useMutation(UPDATE_CLIENT_CREDIT_CARD, {
    onError: (error) => {
      setNotification({ type: "ERROR", message: error.message });
    },
  });

  const [createCreditCard] = useMutation(CREATE_CLIENT_CREDIT_CARD, {
    onError: (error) => {
      setNotification({ type: "ERROR", message: error.message });
    },
  });

  const [isLoadingUpdateCreditCard, setIsLoadingUpdateCreditCard] =
    useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteCreditCardId, setDeleteCreditCardId] = useState<number>();
  const [isLoadingDeleteCreditCard, setIsLoadingDeleteCreditCard] =
    useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<
    EditClientDataCreditCard[]
  >([]);
  const [countPages, setCountPages] = useState(0);

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const { clientCreditCards } = clientEditData;
    const countItems = clientCreditCards?.length || 0;

    setCountPages(Math.ceil(countItems / pageSize));
    setDataCollection(
      clientCreditCards?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize - 1,
      ) || [],
    );
  }, [clientEditData, pageSize, currentPage]);

  const onDeleteCreditCardId = useCallback(async () => {
    setIsLoadingDeleteCreditCard(true);
    await updateCreditCard({
      variables: {
        id: deleteCreditCardId,
        isDelete: true,
      },
    })
      .then((res) => {
        const { data } = res;

        if (data?.updateClientCreditCard?.id) {
          setNotification([
            {
              type: "SUCCESS",
              message: "Credit card successfully deleted.",
            },
          ]);

          setIsNeedUpdateClient(true);
        }
      })
      .finally(() => {
        setIsLoadingDeleteCreditCard(false);
        setDeleteCreditCardId(0);
      });
  }, [deleteCreditCardId]);

  useEffect(() => {
    setShowDeleteModal(!!deleteCreditCardId);
  }, [deleteCreditCardId]);

  useEffect(() => {
    if (!showDeleteModal && deleteCreditCardId) {
      setDeleteCreditCardId(0);
    }
  }, [showDeleteModal]);

  const indexCreditCardWithoutId = useMemo(() => {
    const { clientCreditCards } = clientEditData;

    return clientCreditCards?.findIndex((creditCard) => !creditCard.id);
  }, [clientEditData]);

  const onCreateNewCreditCard = useCallback(() => {
    setCurrentCreditCardId(0);
  }, [clientEditData]);

  const transformCurrentCreditCardDataToSendValue = useMemo(() => {
    return {
      holderName: currentCreditCardData.holderName,
      expirationDate:
        currentCreditCardData.expirationMonth &&
        currentCreditCardData.expirationYear
          ? `${currentCreditCardData.expirationYear}-${currentCreditCardData.expirationMonth}`
          : "",
      cardNumber: currentCreditCardData.id
        ? undefined
        : currentCreditCardData.cardNumber,
      verificationNumber: currentCreditCardData.verificationNumber
        ? parseInt(currentCreditCardData.verificationNumber)
        : "",
    };
  }, [currentCreditCardData, currentCreditCardId]);

  const onSaveCreditCard = useCallback(async () => {
    setIsLoadingUpdateCreditCard(true);

    const onSaveCallback = async () => {
      return currentCreditCardId
        ? await updateCreditCard({
            variables: {
              clientId,
              id: currentCreditCardId,
              ...transformCurrentCreditCardDataToSendValue,
            },
          })
        : await createCreditCard({
            variables: {
              clientId,
              ...transformCurrentCreditCardDataToSendValue,
            },
          });
    };
    await onSaveCallback()
      .then((res) => {
        const { data } = res;

        if (
          data?.[
            currentCreditCardId
              ? "updateClientCreditCard"
              : "createClientCreditCard"
          ]?.id
        ) {
          setIsNeedUpdateClient(true);

          setNotification([
            {
              type: "SUCCESS",
              message: `Client credit card successfully ${
                currentCreditCardId ? "updated" : "saved"
              }.`,
            },
          ]);
        }
      })
      .finally(() => {
        setIsLoadingUpdateCreditCard(false);
      });
  }, [clientId, currentCreditCardId, currentCreditCardData]);

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    dataCollection.forEach((dataCollectionItem) => {
      const {
        id,
        holderName,
        expirationYear,
        cardNumber,
        verificationNumber,
        cardType,
        expirationMonth,
        createdAt,
      } = dataCollectionItem;

      currentChildrenForTable.push({
        id: id || 0,
        row: {
          buttons: (
            <div className="btn-group">
              <button
                className="btn btn-small btn-success"
                onClick={() => {
                  setCurrentCreditCardId(id || 0);
                }}
              >
                <i className="icon-edit icon-medium" />
              </button>
              <button
                className="btn btn-small btn-warning"
                onClick={() => {
                  setDeleteCreditCardId(id || 0);
                }}
                disabled={!id}
              >
                <i className="icon-trash-empty icon-medium" />
              </button>
            </div>
          ),
          holderName: holderName || "-",
          cardType: cardType || "-",
          cardNumber: cardNumber || "-",
          createdAt: createdAt ? convertDateToUSFormat(createdAt) : "-",
        },
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);

  const handleGoCode = useCallback(() => {
    fetch(
      "https://sandbox.dev.clover.com/oauth/v2/authorize?client_id=3RMV3PVF8H54G",
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          apikey: "d89a04a2-155e-e157-2fe5-42a012fdd69e",
          "content-type": "application/json",
        },
      },
    ).then((data) => console.log(data || "error"));
  }, []);
  return (
    <section>
      <div className="row-fluid">
        {listOfCardsFormAccess && (
          <FormCard
            name="credit-cars"
            formFields={[creditCardFormFields]}
            className="box-4"
            label="Credit Card Info"
            additionalButtons={[
              {
                label: "Get Code",
                color: "default",
                name: "getCode",
                disabled: false,
                onClick: () => {
                  handleGoCode();
                },
                hidden: !clientId,
              },
              {
                label: currentCreditCardId ? "Update" : "Save",
                icon: isLoadingUpdateCreditCard
                  ? "inline-block animate-spin icon-spin4"
                  : "icon-floppy",
                color: "success",
                name: "save",
                disabled:
                  isLoadingUpdateCreditCard ||
                  !isValidCreditCard(currentCreditCardData.cardNumber) ||
                  !isValidNonEmpty(currentCreditCardData.verificationNumber) ||
                  !isValidNonEmpty(currentCreditCardData.holderName) ||
                  !isValidNonEmpty(currentCreditCardData.expirationYear) ||
                  !isValidNonEmpty(currentCreditCardData.expirationMonth),
                onClick: () => {
                  onSaveCreditCard();
                },
                hidden: !clientId,
              },
              {
                label: "New card",
                color: "default",
                name: "createNewCard",
                onClick: () => {
                  onCreateNewCreditCard();
                },
                hidden: !clientId,
                disabled:
                  indexCreditCardWithoutId !== -1 || !currentCreditCardId,
              },
            ]}
          />
        )}
      </div>
      <TableWithHeaderNew
        header="Client credit cards"
        heading={tableHeading}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={false}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateClient(true);
        }}
      />
      {showDeleteModal && (
        <ClientDeleteModal
          setShowDeleteModal={setShowDeleteModal}
          deleteTitle={"Delete client credit card"}
          loading={isLoadingDeleteCreditCard}
          onDelete={() => {
            onDeleteCreditCardId();
          }}
        />
      )}
    </section>
  );
};

export default EditClientListOfCardsNew;
