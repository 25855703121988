import { reject } from "lodash";

export const returnFlattenObject = (arr: any) => {
  const flatObject: any = {};
  for (let i = 0; i < arr.length; i++) {
    for (const property in arr[i]) {
      flatObject[property] = arr[i][property];
    }
  }
  return flatObject;
};
export const getParent: any = (
  object: { id: any; name: any; children: any[] | [] },
  search: any,
) => {
  if (object?.id === search) return [object?.id];
  else if (object?.children || Array.isArray(object)) {
    let children = Array.isArray(object) ? object : object?.children;
    for (let child of children) {
      let result = getParent(child, search);
      if (result) {
        if (object?.id) result.unshift(object?.id);
        return result;
      }
    }
  }
};
export const productsOptionConvertionHandler: any = (
  productOptions: {
    id: number;
    title: string;
    children: [] | any[];
  }[],
) => {
  if (productOptions?.length === 0) return null;

  return productOptions?.map(
    (productOptions: { id: number; title: string; children: [] | any[] }) => {
      return {
        id: productOptions?.id,
        name: productOptions?.title,
        children: productsOptionConvertionHandler(productOptions?.children)
          ? productsOptionConvertionHandler(productOptions?.children)
          : [],
      };
    },
  );
};
export const productsCategoryConvertionHandler: any = (
  productCategory: {
    parentNode: { id: number; name: string };
    childNodes: [] | any[];
  }[],
) => {
  if (productCategory?.length === 0) return null;
  return productCategory?.map(
    (productCategory: {
      parentNode: { id: number; name: string };
      childNodes: [] | any[];
    }) => {
      return {
        id: productCategory?.parentNode?.id,
        name: productCategory?.parentNode?.name,
        children: productsCategoryConvertionHandler(productCategory?.childNodes)
          ? productsCategoryConvertionHandler(productCategory?.childNodes)
          : [],
      };
    },
  );
};

export const splitArrayBasedOnLength = (a: string | any[], size: number) =>
  Array.from(new Array(Math.ceil(a?.length / size)), (_, i) =>
    a?.slice(i * size, i * size + size),
  );
const count: any = {};
export const getDuplicateProperties = (array: any[]) => {
  for (const element of array) {
    if (count[element]) {
      count[element] += 1;
    } else {
      count[element] = 1;
    }
  }
  return count;
};
export const getExtension = (filepath: string) => {
  return filepath?.slice(((filepath?.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const fileToBase64 = (file: any, cb: any) => {
  const reader = new FileReader();
  reader?.readAsDataURL(file);
  reader.onload = function () {
    cb(null, reader.result);
  };
  reader.onerror = function (error) {
    cb(error, null);
  };
};

export const fileToBase64Promise = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader?.readAsDataURL(file);
  });
};

export const Base64toObject = (data: string, mimeType?: string) => {
  if (data === null || data === undefined || data?.length <= 100) return "";
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  const blob = new Blob([out], {
    type: mimeType ? mimeType : "image/jpeg",
  });
  return URL.createObjectURL(blob);
};
export const getParticularValue = (item: any[], searchId: number) => {
  let result: any[] = [];

  const findResult = (treeData: any[], id: number) => {
    if (treeData?.length === 0) return null;
    treeData?.map((treeData) => {
      if (+treeData?.id === id) {
        result.push(treeData);
      }
      if (treeData?.children?.length > 0) {
        findResult(treeData?.children, id);
      }
      return null;
    });
  };
  findResult(item, searchId);

  return result[0];
};

export const capitalize = (input: string) => {
  input = input?.toLowerCase();
  input = input?.replace("." || "-", " ");
  let words = input?.split(" ");
  let capitalizedWords: Array<string> = [];
  words?.forEach((element: string) => {
    capitalizedWords.push(
      element[0]?.toUpperCase() + element?.slice(1, element.length),
    );
  });
  return capitalizedWords.join(" ");
};
export const isNumber = (value: any) => {
  return !isNaN(value);
};
export const camelize = (str: string) => {
  return str
    ?.trim()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};
const dateHash: any = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

export const dateFormatter = (date: Date) => {
  if (isNaN(Date.parse(`${date}`))) {
    return 'undefined'
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const fullYear = date.getFullYear();

  const prettyNumber = (num: number) => {
    if (num < 10) {
      return `0${num}`
    }
    return num
  }
  
  return `${fullYear}-${prettyNumber(month)}-${prettyNumber(day)}`

};


export const getParentId = (obj: any, id: any) => {
  let stack = obj?.map((item: any) => ({ path: [item.id], currObj: item }));
  while (stack.length) {
    const { path, currObj } = stack.pop();
    if (currObj.id === id) {
      return path;
    } else if (currObj.children?.length) {
      stack = stack.concat(
        currObj.children.map((item: any) => ({
          path: path.concat(item.id),
          currObj: item,
        })),
      );
    }
  }
  return null;
};
export const findParentNextAndPrevioutId = (
  arr: any[],
  id: number,
  parentId?: number,
) => {
  const index = arr?.findIndex((object) => {
    return object.id === id;
  });
  let previoustId;
  if (index <= 0) {
    previoustId = undefined;
  } else {
    previoustId = arr[index - 1].id;
  }
  let nextId;
  if (arr.length - 1 === index) {
    nextId = undefined;
  } else {
    nextId = arr[index + 1]?.id;
  }

  return {
    id: +id,
    parentId: parentId === undefined ? null : parentId,
    previousId: previoustId,
    nextId: nextId,
  };
};

export const getOptionsFormQuery = (
  queryName: any,
  queryObjectName: string,
) => {
  return queryName[queryObjectName]?.map(
    (queryObjectName: { label: string | number; value: string }) => ({
      id: +queryObjectName.label,
      name: queryObjectName.value,
    }),
  );
};
export const getOptionsFormQueryById = (
  queryName: any,
  queryObjectName: string,
) => {
  return queryName[queryObjectName]?.map(
    (queryObjectName: { id: string | number; name: string }) => ({
      id: +queryObjectName?.id,
      name: queryObjectName?.name,
    }),
  );
};
export const getOptionFormQuerySortForm = (
  queryValue: { label: string | number; value: string }[],
) =>
  queryValue?.map(
    (queryObjectName: { label: string | number; value: string }) => ({
      id: +queryObjectName.label,
      name: queryObjectName.value,
    }),
  );

export const getOptionsFormQueryByIdSortForm = (
  queryValue: { id: string | number; name: string }[],
) =>
  queryValue?.map((queryObjectName: { id: string | number; name: string }) => ({
    id: +queryObjectName?.id,
    name: queryObjectName?.name,
  }));
export const getSuppliers = (queryValue: any) => {
  return queryValue?.map(
    (queryObjectName: { id: number; userName: string }) => ({
      id: +queryObjectName?.id,
      name: queryObjectName?.userName,
    }),
  );
};

export const compactObject = (data: any) => {
  if (typeof data !== "object") {
    return data;
  }

  return Object?.keys(data)?.reduce(function (accumulator, key) {
    const isObject = typeof data[key] === "object";
    const value: any = isObject ? compactObject(data[key]) : data[key];
    const isEmptyObject = isObject && !Object.keys(value).length;
    if (value === undefined || isEmptyObject) {
      return accumulator;
    }

    return Object.assign(accumulator, { [key]: value });
  }, {});
};


export const convertDateToUSFormat = (date: string) => {
  try {
    const dateParsed = Date.parse(date.indexOf("Z")>=0?date:date+"Z");
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const foramtted = Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
      timeZone: currentTimeZone,
      month: "long",
    }).format(dateParsed);
    return foramtted;
  } catch (e) {}
  return "";
};

export const testJSON = (strJson: any) => {
  try {
    const parsed = JSON.parse(strJson);
    if (parsed && typeof parsed === "object") {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

export const allowOnlyPhoneNumbers = (e: any) => {
  if (!/[0-9+]/?.test(e.key)) {
    e.preventDefault();
  }
};

let signatures: any = {
  JVBERi0: "application/pdf",
  R0lGODdh: "image/gif",
  R0lGODlh: "image/gif",
  iVBORw0KGgo: "image/png",
  "/9j/": "image/jpg",
};
export const detectMimeType = (b64: string) => {
  for (var s in signatures) {
    if (b64?.indexOf(s) === 0) {
      return signatures[s];
    }
  }
};

export const getFileExtensionUsingName = (filepath: string) => {
  return filepath?.slice(((filepath?.lastIndexOf(".") - 1) >>> 0) + 2);
};

const mimeType = {
  html: "text/html",
  htm: "text/html",
  shtml: "text/html",
  css: "text/css",
  xml: "text/xml",
  gif: "image/gif",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  js: "application/x-javascript",
  atom: "application/atom+xml",
  rss: "application/rss+xml",
  mml: "text/mathml",
  txt: "text/plain",
  jad: "text/vnd.sun.j2me.app-descriptor",
  wml: "text/vnd.wap.wml",
  htc: "text/x-component",
  png: "image/png",
  tiff: "image/tiff",
  tif: "image/tiff",
  wbmp: "image/vnd.wap.wbmp",
  ico: "image/x-icon",
  jng: "image/x-jng",
  bmp: "image/x-ms-bmp",
  svg: "image/svg+xml",
  webp: "image/webp",
  ear: "application/java-archive",
  jar: "application/java-archive",
  war: "application/java-archive",
  hqx: "application/mac-binhex40",
  doc: "application/msword",
  pdf: "application/pdf",
  ai: "application/postscript",
  ps: "application/postscript",
  eps: "application/postscript",
  rtf: "application/rtf",
  xls: "application/vnd.ms-excel",
  ppt: "application/vnd.ms-powerpoint",
  wmlc: "application/vnd.wap.wmlc",
  kml: "application/vnd.google-earth.kml+xml",
  kmz: "application/vnd.google-earth.kmz",
  "7z": "application/x-7z-compressed",
  cco: "application/x-cocoa",
  jardiff: "application/x-java-archive-diff",
  jnlp: "application/x-java-jnlp-file",
  run: "application/x-makeself",
  pm: "application/x-perl",
  pl: "application/x-perl",
  pdb: "application/x-pilot",
  prc: "application/x-pilot",
  rar: "application/x-rar-compressed",
  rpm: "application/x-redhat-package-manager",
  sea: "application/x-sea",
  swf: "application/x-shockwave-flash",
  sit: "application/x-stuffit",
  tk: "application/x-tcl",
  tcl: "application/x-tcl",
  crt: "application/x-x509-ca-cert",
  der: "application/x-x509-ca-cert",
  pem: "application/x-x509-ca-cert",
  xpi: "application/x-xpinstall",
  xhtml: "application/xhtml+xml",
  zip: "application/zip",
  dll: "application/octet-stream",
  bin: "application/octet-stream",
  exe: "application/octet-stream",
  deb: "application/octet-stream",
  dmg: "application/octet-stream",
  eot: "application/octet-stream",
  img: "application/octet-stream",
  iso: "application/octet-stream",
  msm: "application/octet-stream",
  msi: "application/octet-stream",
  msp: "application/octet-stream",
  kar: "audio/midi",
  mid: "audio/midi",
  midi: "audio/midi",
  mp3: "audio/mpeg",
  ogg: "audio/ogg",
  ra: "audio/x-realaudio",
  "3gp": "video/3gpp",
  "3gpp": "video/3gpp",
  mpg: "video/mpeg",
  mpeg: "video/mpeg",
  mov: "video/quicktime",
  flv: "video/x-flv",
  mng: "video/x-mng",
  asf: "video/x-ms-asf",
  asx: "video/x-ms-asf",
  wmv: "video/x-ms-wmv",
  avi: "video/x-msvideo",
  mp4: "video/mp4",
  m4v: "video/mp4",
};
export const detectMimeTypeUsingExtensionType = (extensionType: string) => {
  let mimeTypeValue = Object.entries(mimeType)?.filter(([key, value]) => {
    if (extensionType === key) {
      return value;
    } else {
      return undefined;
    }
  });

  return mimeTypeValue
    ? mimeTypeValue?.length > 0
      ? mimeTypeValue[0]
        ? mimeTypeValue[0][1]
          ? mimeTypeValue[0][1]
          : undefined
        : undefined
      : undefined
    : undefined;
};

export const getFloatNumberFromString = (
  numberValue: string | number | undefined | null,
) => {
  if (typeof numberValue === "number") {
    return numberValue;
  }

  if (typeof numberValue === "string") {
    return +`${numberValue[0] === "." ? "0" : ""}${numberValue}`;
  }

  return null;
};

export const getIntegerNumberFromString = (
  numberValue: string | number | undefined | null,
) => {
  if (typeof numberValue === "number" || typeof numberValue === "string") {
    return parseInt(numberValue.toString());
  }

  return null;
};
