import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  FetchParamsFilterData,
  FilterDataList,
} from "../../../../types/Filter";
import {
  ChildrenTable,
  SortOrder,
  TableHeading,
} from "../../../../types/Table";
import { useReactiveVar } from "@apollo/client";
import { userContextData } from "../../../../helpers/cache";
import { QuoteStatusList } from "../../../../types/Quote";
import { getFilterTableData } from "../../../../helpers/Table";
import Filter from "../../../../components/Filter/Filter";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import { Params } from "../../../../types/Main";
import { Link } from "react-router-dom";
import { convertDateToUSFormat } from "../../../../helpers/utils";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";

const QuotesProcessing: FC<{}> = ({}) => {
  // constant variables
  const pageName = useMemo(() => "pending_orders", []);

  const clearFilterFieldsList = useMemo(
    (): FilterDataList => ({
      filterName: "",
      quoteId: {
        select: "=",
        value: "",
      },
      orderId: {
        select: "=",
        value: "",
      },
      firstName: {
        select: "contains",
        value: "",
      },
      lastName: {
        select: "contains",
        value: "",
      },
      company: {
        select: "contains",
        value: "",
      },
      followUp: "-",
      email: {
        select: "contains",
        value: "",
      },
      phone1: {
        select: "contains",
        value: "",
      },
      createdAt: {
        from: "",
        to: "",
        value: "",
        select: "",
      },
      dateOrderWasCompleted: {
        from: "",
        to: "",
        value: "",
        select: "",
      },
      dateOrderWasPlaced: {
        from: "",
        to: "",
        value: "",
        select: "",
      },
      withoutTax: "-",
      withoutTotal: "-",
      fax: {
        select: "contains",
        value: "",
      },
      paymentStatus: {
        select: "contains",
        value: "",
      },
      status: {
        select: "contains",
        value: "",
      },
    }),
    [],
  );

  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "id",
        label: "Id",
        sortable: true,
      },
      {
        name: "company",
        label: "Company Name",
        sortable: true,
      },
      {
        name: "status",
        label: "Status",
        sortable: true,
      },
      {
        name: "paymentStatus",
        label: "Payment Status",
        sortable: true,
      },
      {
        name: "trackingNumber1",
        label: "Tracking Number",
        sortable: true,
      },
      {
        name: "dateOrderWasPlaced",
        label: "Order Was Placed",
        sortable: true,
      },
      {
        name: "dateCompleted",
        label: "Order Was Completed",
        sortable: true,
      },
      {
        name: "supplier",
        label: "Supplier",
        sortable: true,
      },
    ],
    [],
  );

  // reactive variables
  const userDetails: any = useReactiveVar(userContextData);

  const [fetchParams, setFetchParams] = useState<FetchParamsFilterData>({});
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    columnName: "id",
    orderBy: "DESC",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isNeedUpdateData, setIsNeedUpdateData] = useState(false);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const [countPages, setCountPages] = useState(0);
  const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});
  const [quoteStatuses, setQuoteStatuses] = useState<QuoteStatusList>({});
  // loadings
  const [isLoadingCollectionList, setIsLoadingCollectionList] = useState(false);

  const quoteMainStatuses = useMemo(() => {
    return (
      quoteStatuses?.quoteStatus?.map((status) => ({
        id: parseInt(status.label),
        value: status.value,
      })) || []
    );
  }, [quoteStatuses]);

  const quotePaymentStatuses = useMemo(() => {
    return (
      quoteStatuses?.paymentStatuses?.map((status) => ({
        id: parseInt(status.label),
        value: status.value,
      })) || []
    );
  }, [quoteStatuses]);

  const filterFields = useMemo(
    () => [
      [
        {
          name: "quoteId",
          label: "Quote Id",
          type: "input",
          selectOptions: {
            options: [">", "<", "="],
          },
          numberValue: true,
        },
        {
          name: "orderId",
          label: "Order Id",
          type: "input",
          selectOptions: {
            options: [">", "<", "="],
          },
          numberValue: true,
        },
        {
          name: "firstName",
          label: "First Name",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "lastName",
          label: "Last Name",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "company",
          label: "Company",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },

        {
          name: "status",
          label: "Status",
          type: "multipleSelect",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
            optionsForValue: [
              {
                options: quoteMainStatuses,
              },
            ],
          },
          numberValue: true,
        },
        {
          name: "paymentStatus",
          label: "Payment Status",
          type: "multipleSelect",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
            optionsForValue: [
              {
                options: quotePaymentStatuses,
              },
            ],
          },
          numberValue: true,
        },
        {
          name: "followUp",
          label: "Follow Up",
          type: "yesOrNo",
        },
      ],
      [
        {
          name: "email",
          label: "Email",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "fax",
          label: "Fax",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "phone1",
          label: "Phone",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "createdAt",
          label: "Date Created",
          type: "dateBetween",
        },
        {
          name: "dateOrderWasCompleted",
          label: "Date order was placed",
          type: "dateBetween",
        },
        {
          name: "dateOrderWasPlaced",
          label: "Date order was completed",
          type: "dateBetween",
        },
        {
          name: "withoutTax",
          label: "Without Tax",
          type: "yesOrNo",
        },
        {
          name: "withoutTotal",
          label: "Without total",
          type: "yesOrNo",
        },
      ],
    ],
    [quoteMainStatuses, quotePaymentStatuses],
  );

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const currentParams = {
      pageSize,
      currentPage,
      sortOrder,
      fetchParams,
    };

    const isNeedClearPage =
      JSON.stringify(prevFetchSettings.fetchParams) !==
        JSON.stringify(currentParams.fetchParams) &&
      currentParams.currentPage !== 1;

    onChangeParamsCallback(
      prevFetchSettings,
      currentParams,
      (newParams) => setPrevFetchSettings(newParams),
      () => {
        if (isNeedClearPage) {
          setCurrentPage(1);
          return;
        }

        setIsNeedUpdateData(true);
      },
    );
  }, [pageSize, currentPage, sortOrder, fetchParams, prevFetchSettings]);

  useEffect(() => {
    if (isNeedUpdateData) {
      inUpdateClientListCollection();
    }
  }, [isNeedUpdateData]);

  // handles
  const onErrorFetch = useCallback(() => {
    setCountPages(0);
    setDataCollection([]);
  }, []);

  const inUpdateClientListCollection = useCallback(async () => {
    setIsLoadingCollectionList(true);

    await getFilterTableData(
      fetchParams,
      pageSize,
      currentPage,
      sortOrder,
      pageName,
    )
      .then((res) => {
        const { collectionList, statuses } = res;
        if (!collectionList || !statuses) {
          onErrorFetch();
          return;
        }

        setQuoteStatuses(statuses);

        const { filterDataCollection, countPages } = collectionList;

        if (filterDataCollection) {
          setDataCollection(filterDataCollection);
        }

        setCountPages(countPages);
      })
      .finally(() => {
        setIsLoadingCollectionList(false);

        if (isNeedUpdateData) {
          setIsNeedUpdateData(false);
        }
      });
  }, [
    isNeedUpdateData,
    fetchParams,
    pageSize,
    currentPage,
    sortOrder,
    pageName,
  ]);

  const accessControlStructureTabEditQuote = useMemo(
    () =>
      userDetails?.user?.accessControlStructure?.modules?.quote?.tabs
        ?.editQuote,
    [userDetails],
  );

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    dataCollection.forEach((dataCollectionItem) => {
      const {
        id,
        status,
        quoteOrder,
        clientContact,
        client,
        dateCompleted,
        supplier,
        trackShippingUrl,
      } = dataCollectionItem;

      let userName = clientContact?.firstName;

      if (clientContact?.lastName) {
        userName = userName.concat(` ${clientContact.lastName}`);
      }

      const getElByAccess = (text: string) => {
        if (accessControlStructureTabEditQuote) {
          return <Link to={`/quotes/${id}`}>{text}</Link>;
        }

        return text;
      };

      const getStatusName = () => {
        const currentStatus = quoteStatuses.quoteStatus.find(
          (currentStatus) => currentStatus.label === status?.toString(),
        );

        return currentStatus?.value || "-";
      };

      const getPaymentStatusName = () => {
        const currentStatus = quoteStatuses.paymentStatuses.find(
          (status) => status.label === quoteOrder?.paymentStatus?.toString(),
        );

        return currentStatus?.value || "-";
      };

      const getTrackingNumberContent = () => {
        if (quoteOrder?.trackingNumber1 && trackShippingUrl) {
          return (
            <a href={trackShippingUrl} target="_blank">
              {quoteOrder.trackingNumber1}
            </a>
          );
        }

        if (quoteOrder?.trackingNumber1) {
          return quoteOrder.trackingNumber1;
        }

        return "-";
      };

      currentChildrenForTable.push({
        id: id,
        row: {
          id: getElByAccess(id),
          firstName: userName ? getElByAccess(userName) : "-",
          company: client?.company ? getElByAccess(client?.company) : "-",
          status: getStatusName(),
          paymentStatus: getPaymentStatusName(),
          trackingNumber1: getTrackingNumberContent(),
          dateOrderWasPlaced: quoteOrder?.dateWasPlaced
            ? convertDateToUSFormat(quoteOrder.dateWasPlaced)
            : "-",
          dateCompleted: dateCompleted
            ? convertDateToUSFormat(dateCompleted)
            : "-",
          supplier: supplier?.id ? (
            <Link to={`/admin/user/${supplier.id}`}>{supplier?.userName}</Link>
          ) : (
            "-"
          ),
        },
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);
  return (
    <section className="layout-content">
      <Filter
        pageName={pageName}
        setFetchParams={(value) => {
          setFetchParams(value);
        }}
        filterFields={filterFields}
        clearFilterFieldsList={clearFilterFieldsList}
      />
      <TableWithHeaderNew
        header="History"
        heading={tableHeading}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={isLoadingCollectionList}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateData(true);
        }}
      />
    </section>
  );
};

export default QuotesProcessing;
