import { gql } from "@apollo/client";

export const TRANSACTIONlOGS_COLLECTION_QUERY = gql`
  query transactionLogsByQuote($quoteId: Int!, $limit: Int!, $page: Int!) {
    transactionLogsByQuote(quoteId: $quoteId, limit: $limit, page: $page) {
      dataCollection {
        id
        cardHolder
        cardType
        amount
        cardNumber
        status
        authorizationNum
        refNum
        customRefNum
        transactionType
        expiry
        message
        transactionTag
        bankAnswer
        createdAt
        updatedAt
        quote {
          id
        }
        user {
          id
        }
      }
      totalNumberOfItems
    }
  }
`;
