import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  FetchParamsFilterData,
  FilterDataList,
} from "../../../../types/Filter";
import {
  ChildrenTable,
  SortOrder,
  TableHeading,
} from "../../../../types/Table";
import { useReactiveVar } from "@apollo/client";
import { userContextData } from "../../../../helpers/cache";
import { QuoteStatusList } from "../../../../types/Quote";
import { getFilterTableData } from "../../../../helpers/Table";
import Filter from "../../../../components/Filter/Filter";
import { Params } from "../../../../types/Main";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { convertDateToUSFormat } from "../../../../helpers/utils";
import { Link } from "react-router-dom";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";

const QuotesActiveLeads: FC<{}> = ({}) => {
  const pageName = useMemo(() => "active_leads", []);
  const clearFilterFieldsList = useMemo(
    (): FilterDataList => ({
      filterName: "",
      quoteId: {
        select: "=",
        value: "",
      },
      orderId: {
        select: "=",
        value: "",
      },
      firstName: {
        select: "contains",
        value: "",
      },
      lastName: {
        select: "contains",
        value: "",
      },
      company: {
        select: "contains",
        value: "",
      },
      followUp: "-",
      email: {
        select: "contains",
        value: "",
      },
      phone1: {
        select: "contains",
        value: "",
      },
      createdAt: {
        from: "",
        to: "",
        value: "",
        select: "",
      },
    }),
    [],
  );

  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "id",
        label: "Id",
        sortable: true,
      },
      {
        name: "firstName",
        label: "Name",
        sortable: true,
      },
      {
        name: "company",
        label: "Company Name",
        sortable: true,
      },
      {
        name: "email",
        label: "Email",
        sortable: true,
      },
      {
        name: "phone",
        label: "Phone Number",
        sortable: true,
      },
      {
        name: "nextFollowUpTime",
        label: "Last Follow Up",
        sortable: true,
      },
      {
        name: "createdAt",
        label: "Date Created",
        sortable: true,
      },
    ],
    [],
  );

  // reactive variables
  const userDetails: any = useReactiveVar(userContextData);

  const [fetchParams, setFetchParams] = useState<FetchParamsFilterData>({});
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    columnName: "id",
    orderBy: "DESC",
  });
  const [isNeedUpdateData, setIsNeedUpdateData] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const [countPages, setCountPages] = useState(0);
  const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});
  const [quoteStatuses, setQuoteStatuses] = useState<QuoteStatusList>({});
  // loadings
  const [isLoadingCollectionList, setIsLoadingCollectionList] = useState(false);

  const filterFields = useMemo(
    () => [
      [
        {
          name: "quoteId",
          label: "Quote Id",
          type: "input",
          selectOptions: {
            options: [">", "<", "="],
          },
          numberValue: true,
        },
        {
          name: "orderId",
          label: "Order Id",
          type: "input",
          selectOptions: {
            options: [">", "<", "="],
          },
          numberValue: true,
        },
        {
          name: "firstName",
          label: "First Name",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "lastName",
          label: "Last Name",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "company",
          label: "Company",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "followUp",
          label: "Follow Up",
          type: "yesOrNo",
        },
      ],
      [
        {
          name: "email",
          label: "Email",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "phone1",
          label: "Phone",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "createdAt",
          label: "Date Created",
          type: "dateBetween",
        },
      ],
    ],
    [],
  );

  // effects
  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const currentParams = {
      pageSize,
      currentPage,
      sortOrder,
      fetchParams,
    };

    const isNeedClearPage =
      JSON.stringify(prevFetchSettings.fetchParams) !==
        JSON.stringify(currentParams.fetchParams) &&
      currentParams.currentPage !== 1;

    onChangeParamsCallback(
      prevFetchSettings,
      currentParams,
      (newParams) => setPrevFetchSettings(newParams),
      () => {
        if (isNeedClearPage) {
          setCurrentPage(1);
          return;
        }

        setIsNeedUpdateData(true);
      },
    );
  }, [pageSize, currentPage, sortOrder, fetchParams, prevFetchSettings]);

  useEffect(() => {
    if (isNeedUpdateData) {
      inUpdateClientListCollection();
    }
  }, [isNeedUpdateData]);

  // handles
  const onErrorFetch = useCallback(() => {
    setCountPages(0);
    setDataCollection([]);
  }, []);

  const inUpdateClientListCollection = useCallback(async () => {
    setIsLoadingCollectionList(true);

    await getFilterTableData(
      fetchParams,
      pageSize,
      currentPage,
      sortOrder,
      pageName,
    )
      .then((res) => {
        const { collectionList, statuses } = res;

        if (!collectionList || !statuses) {
          onErrorFetch();
          return;
        }

        setQuoteStatuses(statuses);

        const { filterDataCollection, countPages } = collectionList;

        if (filterDataCollection) {
          setDataCollection(filterDataCollection);
        }

        setCountPages(countPages);
      })
      .finally(() => {
        setIsLoadingCollectionList(false);

        if (isNeedUpdateData) {
          setIsNeedUpdateData(false);
        }
      });
  }, [
    fetchParams,
    pageSize,
    currentPage,
    sortOrder,
    pageName,
    isNeedUpdateData,
  ]);

  const accessControlStructureTabEditQuote = useMemo(
    () =>
      userDetails?.user?.accessControlStructure?.modules?.quote?.tabs
        ?.editQuote,
    [userDetails],
  );

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    dataCollection.forEach((dataCollectionItem) => {
      const { id, clientContact, client, nextFollowUpTime, createdAt } =
        dataCollectionItem;

      const getElByAccess = (text: string) => {
        if (accessControlStructureTabEditQuote) {
          return <Link to={`/quotes/${id}`}>{text}</Link>;
        }

        return text;
      };

      let userName = clientContact?.firstName;

      if (clientContact?.lastName) {
        userName = userName.concat(` ${clientContact.lastName}`);
      }

      currentChildrenForTable.push({
        id,
        row: {
          id: getElByAccess(id),
          firstName: userName ? getElByAccess(userName) : "-",
          company: client?.company ? getElByAccess(client?.company) : "-",
          email: clientContact?.email || "-",
          phone: clientContact?.phone1 || "-",
          nextFollowUpTime: nextFollowUpTime
            ? convertDateToUSFormat(nextFollowUpTime)
            : "-",
          createdAt: createdAt ? convertDateToUSFormat(createdAt) : "-",
        },
      });
    });
    return currentChildrenForTable;
  }, [dataCollection]);
  return (
    <section className="layout-content">
      <Filter
        pageName={pageName}
        setFetchParams={setFetchParams}
        filterFields={filterFields}
        clearFilterFieldsList={clearFilterFieldsList}
      />
      <TableWithHeaderNew
        header="History"
        heading={tableHeading}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={isLoadingCollectionList}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateData(true);
        }}
      />
    </section>
  );
};

export default QuotesActiveLeads;
