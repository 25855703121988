const Text: React.FC<{
  register: Function;
  name: string;
  errors: any;
  defaultName?: string | number;
  disable?: boolean;
  required?: boolean;
  placeholderText?: string;
  hideIcon?: boolean;
  hideError?: boolean;
  maxLength?: number;
  className?: string;
  classForInput?: string;
  inputType?: string;
  onKeyPress?: Function;
  onChange?: Function;
  onInput?: Function;
  pattern?: any;
}> = ({
  register,
  name,
  errors,
  defaultName,
  disable,
  required,
  placeholderText,
  hideIcon,
  hideError,
  maxLength,
  className,
  classForInput,
  inputType,
  onKeyPress,
  onChange,
  onInput,
  pattern,
}) => {
  return (
    <div className={`w-full ${className ? className : ""}`}>
      <div className="w-full h-full relative group">
        <input
          id={name}
          type={inputType ? inputType : "text"}
          placeholder={placeholderText}
          defaultValue={defaultName}
          onKeyPress={onKeyPress ? onKeyPress : undefined}
          disabled={disable}
          maxLength={maxLength ? maxLength : ""}
          onInput={onInput}
          autoComplete="off"
          className={`w-full border ${
            disable ? " cursor-not-allowed bg-[#EEE]" : ""
          } text-xs border-[#D5D5D5] leading-5 min-h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-1 placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica ${
            classForInput ? classForInput : ""
          } ${inputType ? "" : ""}`}
          {...register(name, {
            required: required ? "This is required field." : false,
            onChange: onChange,
            pattern: pattern ? pattern : "",
            shouldUnregister: true,
          })}
        />
        <label
          htmlFor={name}
          className={`cursor-pointer absolute right-2 top-[2px] text-[#909090] peer-focus:text-[#557799] ${
            hideIcon ? "hidden" : "block"
          }`}
        >
          <i className="icon-user text-base leading-7" />
        </label>
      </div>
      <div className={`w-full ${hideError ? "hidden" : "block"}`}>
        {errors[name] ? (
          <span className="text-[#FF647C] text-xs leading-[15px]">
            {errors[name]?.message}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};

export default Text;
