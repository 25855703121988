import { gql } from "@apollo/client";

export interface ICLIENT_ITEM_BYEMAIL_QUERY {
  clientsByEmail: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    company: string | null;
    user: {
      id: number;
      userName: string | null;
      email: string;
    };
  }[];
}

export const CLIENT_ITEM_BYEMAIL_QUERY = gql`
  query clientsByEmail($email: String!) {
    clientsByEmail(email: $email) {
      id
      firstName
      lastName
      company
      email
      user {
        id
        userName
        email
      }
    }
  }
`;
