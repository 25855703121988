import MasterCard from "assets/mastercard.png";
import MaestroCard from "assets/maestro-card.png";
import VisaCard from "assets/visa.png";
import AmexCard from "assets/amex.svg";
import VoyagerCard from "assets/voyager.png";
import DinersClubCard from "assets/diners-club.png";
import SoloCard from "assets/solo.png";
import JcbCard from "assets/jcb.png";
import EnrouteCard from "assets/enroute.png";

export const creditCardTypes = {
  mastercard: MasterCard,
  "maestro-card": MaestroCard,
  visa: VisaCard,
  amex: AmexCard,
  voyage: VoyagerCard,
  "diners-club": DinersClubCard,
  solo: SoloCard,
  jcb: JcbCard,
  enroute: EnrouteCard,
};

export type TCreditCardTypes = keyof typeof creditCardTypes;
