import {
  creditCardTypes,
  TCreditCardTypes,
} from "components/model/TCreditCardTypes";
import { FC } from "react";

const CreditCardIcon: FC<{ type?: TCreditCardTypes }> = ({ type }) =>
  type && creditCardTypes?.[type] ? (
    <img
      className="w-[22px] h-[22px] object-contain"
      src={creditCardTypes[type]}
      alt={type}
    />
  ) : null;

export default CreditCardIcon;
