import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DataCollectionItem } from "../../../types/AdminDashboard";
import AdminDashboardEditCountry from "./AdminDashboardEdit/AdminDashboardEditCountry";
import { useNavigate } from "react-router-dom";
import { Params } from "../../../types/Main";
import { LoadingDesign } from "../../../helpers";
import AdminDashboardEditCrmSettings from "./AdminDashboardEdit/AdminDashboardEditCrmSettings";
import AdminDashboardEditDomainIgnoreList from "./AdminDashboardEdit/AdminDashboardEditDomainIgnoreList";
import AdminDashboardEditExpense from "./AdminDashboardEdit/AdminDashboardEditExpense";
import AdminDashboardEditHighligth from "./AdminDashboardEdit/AdminDashboardEditHighligth";
import AdminDashboardEditIgnoreWordSearch from "./AdminDashboardEdit/AdminDashboardEditIgnoreWordSearch";
import AdminDashboardEditIndustryTag from "./AdminDashboardEdit/AdminDashboardEditIndustryTag";
import AdminDashboardEditQuickQuoteAdditionalInfo from "./AdminDashboardEdit/AdminDashboardEditQuickQuoteAdditionalInfo";
import { ApolloError } from "@apollo/client";
import { setNotification } from "../../../helpers/cache";
import AdminDashboardEditQuickQuoteLine from "./AdminDashboardEdit/AdminDashboardEditQuickQuoteLine";
import AdminDashboardEditState from "./AdminDashboardEdit/AdminDashboardEditState";
import AdminDashboardEditSystemError from "./AdminDashboardEdit/AdminDashboardEditSystemError";
import AdminDashboardEditTimeZone from "./AdminDashboardEdit/AdminDashboardEditTimeZone";
import AdminDashboardEditRole from "./AdminDashboardEdit/AdminDashboardEditRole";
import EditOrCreateUserTemplate from "./AdminDashboardEdit/EditOrCreateUserTemplate";
import AdminDashboardEditOldUserNewUser from "./AdminDashboardEdit/AdminDashboardEditOldUserNewUser";
import AdminDashboardEditEmailData from "./AdminDashboardEdit/AdminDashboardEditEmailData";
import AdminDashboardEditUserSettings from "./AdminDashboardEdit/AdminDashboardEditUserSettings";

const AdminDashboardEditItem: FC<{
  type: string;
  currentId?: number;
  defaultPath: string;
  setIsNeedUpdate: () => void;
  currentDataCollectionItem?: DataCollectionItem;
  loadingDataCollection: boolean;
}> = ({
  type,
  currentId,
  defaultPath,
  setIsNeedUpdate,
  currentDataCollectionItem,
  loadingDataCollection,
}) => {
  const navigate = useNavigate();
  const labelWidth = useMemo(() => "160px", []);
  const inputWidth = useMemo(() => "400px", []);

  const [isNeedSave, setIsNeedSave] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [typeSave, setTypeSave] = useState<"addNew" | "editNew" | "">("");
  const [prevTypeSave, setPrevTypeSave] = useState<Params>({});
  const [newId, setNewId] = useState<number | undefined>(undefined);
  const handleAfterSave = useCallback(
    (id: number) => {
      setIsNeedSave(false);
      setIsNeedUpdate();
      setNewId(id);
    },
    [typeSave, defaultPath, type],
  );
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  useEffect(() => {
    if (typeof newId === "undefined") return;

    if (!typeSave) {
      navigate(`${defaultPath}/${type}/list`);
      setNewId(undefined);
      return;
    }

    if (typeSave === "addNew") {
      navigate(`${defaultPath}/${type}/new`);
      setNewId(undefined);
      return;
    }

    if (typeSave === "editNew") {
      navigate(`${defaultPath}/${type}/${newId}/edit`);
      setNewId(undefined);
      return;
    }
  }, [newId]);

  const onErrorSave = useCallback((error: ApolloError) => {
    setIsSaveLoading(false);
    setIsNeedSave(false);
    setNotification([{ type: "ERROR", message: error?.message }]);
  }, []);

  const handleCancel = useCallback(() => {
    navigate(`${defaultPath}/${type}/list`);
  }, [defaultPath, type]);

  useEffect(() => {
    if (!loadingDataCollection && isSaveLoading) {
      setIsSaveLoading(false);
    }
  }, [loadingDataCollection]);
  return (
    <section>
      {loadingDataCollection && <LoadingDesign />}
      {!loadingDataCollection && type === "country" && (
        <AdminDashboardEditCountry
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "crm_settings" && (
        <AdminDashboardEditCrmSettings
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "domain_ignore_list" && (
        <AdminDashboardEditDomainIgnoreList
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "expense" && (
        <AdminDashboardEditExpense
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "highlight" && (
        <AdminDashboardEditHighligth
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "ignore_word_search" && (
        <AdminDashboardEditIgnoreWordSearch
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "industry_tag" && (
        <AdminDashboardEditIndustryTag
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "quick_quote_additional_info" && (
        <AdminDashboardEditQuickQuoteAdditionalInfo
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "quick_quote_line" && (
        <AdminDashboardEditQuickQuoteLine
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "state" && (
        <AdminDashboardEditState
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "system_error" && (
        <AdminDashboardEditSystemError
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "time_zones" && (
        <AdminDashboardEditTimeZone
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "role" && (
        <AdminDashboardEditRole
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          currentDataCollectionItem={currentDataCollectionItem}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "user" && (
        <EditOrCreateUserTemplate
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "old_user_new_user" && (
        <AdminDashboardEditOldUserNewUser
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          currentDataCollectionItem={currentDataCollectionItem}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "user_email_data" && (
        <AdminDashboardEditEmailData
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          currentDataCollectionItem={currentDataCollectionItem}
          onErrorSave={onErrorSave}
        />
      )}
      {!loadingDataCollection && type === "user_settings" && (
        <AdminDashboardEditUserSettings
          currentId={currentId}
          isNeedSave={isNeedSave}
          afterSave={handleAfterSave}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          setIsSaveLoading={setIsSaveLoading}
          setDisabledSaveButton={setDisabledSaveButton}
          onErrorSave={onErrorSave}
          currentDataCollectionItem={currentDataCollectionItem}
        />
      )}
      <div className="admin-dashboard__filter-wrapper admin-dashboard__action-item-btn-wrapper">
        <button
          className="btn btn-small btn-success"
          onClick={() => {
            setTypeSave("");
            setIsNeedSave(true);
          }}
          disabled={disabledSaveButton || isSaveLoading}
        >
          <i
            className={`${
              isSaveLoading ? "inline-block animate-spin icon-spin4" : "icon-ok"
            } pr-1`}
          />
          Save
        </button>
        <button
          className="btn btn-small btn-blue"
          onClick={() => {
            setTypeSave("addNew");
            setIsNeedSave(true);
          }}
          disabled={disabledSaveButton || isSaveLoading}
        >
          <i
            className={`${
              isSaveLoading
                ? "inline-block animate-spin icon-spin4"
                : "icon-plus"
            } pr-1`}
          />
          Save and add another
        </button>
        <button
          className="btn btn-small btn-blue"
          onClick={() => {
            setTypeSave("editNew");
            setIsNeedSave(true);
          }}
          disabled={disabledSaveButton || isSaveLoading}
        >
          <i
            className={`${
              isSaveLoading
                ? "inline-block animate-spin icon-spin4"
                : "icon-pencil"
            } pr-1`}
          />
          Save and edit
        </button>
        <button
          className="btn btn-small btn-warning"
          onClick={() => {
            handleCancel();
            setIsNeedSave(true);
          }}
        >
          <i className="icon-cancel pr-1" />
          Cancel
        </button>
      </div>
    </section>
  );
};

export default AdminDashboardEditItem;
