const ActionButton: React.FC<{
  buttonName: string;
  onClick?: () => void;
  icon?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  buttonType?: "button" | "submit" | "reset" | undefined;
}> = ({ buttonName, onClick, buttonType, icon, className, loading, disabled = false }) => {
  return (
    <button
      type={buttonType ? buttonType : "button"}
      className={`px-2 py-[2px] border-4 text-white text-[13px] leading-5 ${
        className ? className : ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <i
        className={`${
          icon ? (loading ? "inline-block animate-spin icon-spin4" : icon) : ""
        } text-white text-[13px] leading-4 mr-1`}
      />
      <span>{buttonName}</span>
    </button>
  );
};

export default ActionButton;
