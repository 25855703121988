import { FC, Fragment, useCallback, useEffect, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useMutation } from "@apollo/client";
import {
  CREATE_SYSTEM_ERROR,
  UPDATE_SYSTEM_ERROR,
} from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";

const AdminDashboardSystemError: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentData("");
    setCurrentText("");
    setCurrentName("");
  }, []);

  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_SYSTEM_ERROR, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { updateSystemError } = data;

      if (!updateSystemError) return;

      const { id } = updateSystemError;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_SYSTEM_ERROR, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createSystemError } = data;

      if (!createSystemError) return;

      const { id } = createSystemError;

      onAfterSave(id || 0);
    },
  });

  const [currentName, setCurrentName] = useState("");
  const [currentData, setCurrentData] = useState("");
  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { name, text, data } = currentDataCollectionItem;

    if (name) {
      setCurrentName(name);
    }

    if (text) {
      setCurrentText(text);
    }

    if (data) {
      setCurrentData(data);
    }
  }, [currentDataCollectionItem]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            name: currentName,
            text: currentText,
            data: currentData,
          },
        });
      }
      return await createItems({
        variables: {
          name: currentName,
          text: currentText,
          data: currentData,
        },
      });
    };

    await handleSaveById();
  }, [currentText, currentId, currentData, currentName]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm = !!currentName;
    setDisabledSaveButton(!isValidForm);
  }, [currentName]);

  return (
    <Fragment>
      <Input
        value={currentName}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentName(value);
          }
        }}
        name="system-error-name"
        labelWidth={labelWidth}
        label="Name"
        placeholder="Name"
        inputWidth={inputWidth}
        isIncorrectValue={!currentName}
      />
      <Input
        value={currentData}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentData(value);
          }
        }}
        name="system-error-data"
        labelWidth={labelWidth}
        label="Data"
        placeholder="Data"
        inputWidth={inputWidth}
        isTextarea={true}
        rowCount={2}
      />
      <Input
        value={currentText}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentText(value);
          }
        }}
        name="system-error-text"
        labelWidth={labelWidth}
        label="Text"
        placeholder="Text"
        inputWidth={inputWidth}
        isTextarea={true}
        rowCount={2}
      />
    </Fragment>
  );
};

export default AdminDashboardSystemError;
