import { useState } from "react";

const Password: React.FC<{
  register: Function;
  name: string;
  errors: any;
  defaultName?: string;
  disable?: boolean;
  required?: boolean;
  placeholderText?: string;
  classForInput?: string;
  autoComplete?: boolean;
  className?: string;
}> = ({
  register,
  name,
  errors,
  defaultName,
  disable,
  required,
  placeholderText,
  classForInput,
  autoComplete,
  className,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`w-full ${className ? className : ""}`}>
      <div className="w-full relative">
        <input
          id={name}
          type={showPassword ? "text" : "password"}
          placeholder={placeholderText}
          defaultValue={defaultName}
          disabled={disable}
          maxLength={50}
          formNoValidate={true}
          autoComplete={autoComplete ? "new-password" : ""}
          role="presentation"
          className={` ${
            classForInput ? classForInput : ""
          } w-full border border-[#D5D5D5] focus:border-[#F59942] focus:outline-none focus:ring-0 focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] pr-[24px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281]`}
          {...register(name, {
            minLength: {
              value: 2,
              message: "Minimum Length should be 2.",
            },
            required: required ? "This is required field." : false,
          })}
        />
        <label
          htmlFor={name}
          className="cursor-pointer absolute right-2 top-[2px] text-[#909090] peer-focus:text-[#557799]"
        >
          <i
            className={`${
              showPassword
                ? "icon-lock-open-alt block [transform:rotateY(195deg)]"
                : "icon-lock"
            } text-base leading-7`}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
        </label>
      </div>
      <div className="w-full">
        {errors[name] ? (
          <span className="text-[#FF647C] text-xs leading-[15px]">
            {errors[name].message}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};

export default Password;
