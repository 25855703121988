import React, { ReactNode, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";

export interface childNodesTypes {
  id: number;
  name: string;
  children: [] | any[];
}

const ProductRow: React.FC<{
  item: {
    id: number;
    name: string;
    children: childNodesTypes[] | any[];
  };
  level: number;
  setProductCategoriesId: Function;
  children: ReactNode;
}> = ({ item, level, children, setProductCategoriesId }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  let items = item;

  return (
    <div key={items?.id}>
      <div
        style={{ paddingLeft: `${level * 20}px` }}
        className="flex items-center w-min pb-2"
      >
        <ChevronRightIcon
          className={`w-3 h-4 flex-shrink-0  text-blue-900 cursor-pointer mx-1  ${
            isCollapsed ? "rotate-90" : ""
          } ${item?.children?.length === 0 ? "invisible" : "visible"}`}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />

        <p
          className={`${
            level > 0 || items?.children?.length > 0
              ? "text-[#0088cc] hover:text-red-500 hover:underline"
              : "text-[#0088cc] hover:underline"
          } whitespace-nowrap truncate max-w-[200px] cursor-pointer`}
          onClick={() => {
            setProductCategoriesId({ id: item?.id, name: item?.name });
          }}
        >
          {items?.name}
        </p>
      </div>
      <div className={`${isCollapsed ? "block" : "hidden"} transition-all`}>
        {children}
      </div>
    </div>
  );
};

export default ProductRow;
