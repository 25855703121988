import { useReactiveVar } from "@apollo/client";
import { LoadingDesign } from "helpers";
import { userContextData } from "helpers/cache";
import Logout from "modules/User/Logout";
import { FC, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

const Header: FC<{
  setShowMenu: Function;
  showMenu: boolean;
  userName: string;
  fetchCommission: Function;
  salesCommission: any;
  refetchSalesCommission: any;
  salesCommissionLoading: boolean;
}> = ({
  setShowMenu,
  showMenu,
  userName,
  fetchCommission,
  salesCommission,
  refetchSalesCommission,
  salesCommissionLoading,
}) => {
  const [showProfile, setShowProfile] = useState(false);
  let profileRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!profileRef.current?.contains(event.target)) {
        setShowProfile(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showProfile]);

  const userDetails: any = useReactiveVar(userContextData);

  let isSalesRole =
    userDetails?.user?.accessControlStructure?.header?.includes(
      "monthlyCommission",
    );
  useEffect(() => {
    if (isSalesRole) {
      fetchCommission();
    }
  }, [fetchCommission, isSalesRole]);

  return (
    <div className="flex justify-between items-center pl-2 md:px-5">
      <div
        className={`xl:hidden  ${
          salesCommission ? "self-start md:self-center mt-1" : ""
        }`}
      >
        <i
          onClick={() => setShowMenu(!showMenu)}
          className={`icon-menu text-2xl cursor-pointer text-white`}
        />
      </div>
      <h4>
        <NavLink to="/quotes/history" className="flex items-center space-x-2">
          <i className="icon-leaf green text-white text-xl" />
          <p className="text-white text-xl leading-5 whitespace-nowrap">
            Ace Admin
          </p>
        </NavLink>
      </h4>
      <div className="flex flex-col-reverse sm:flex-row ">
        {userDetails?.user?.accessControlStructure?.header?.includes(
          "monthlyCommission",
        ) && (
          <div className="flex flex-col md:flex-row">
            <div className="w-full whitespace-nowrap bg-[#2e8965] grid place-content-center border-r border-white px-[8px]">
              <div className="text-white text-sm sm:text-[20px] leading-8  flex items-center justify-center space-x-1">
                <span className="min-w-[50px]">Month:</span>
                {salesCommissionLoading ? (
                  <LoadingDesign className="v h-min w-min" smallLoading />
                ) : (
                  <span className="bg-[#82af6f] text-[13px] leading-4 rounded-full px-3 text-[#eee] sm:text-[18px] sm:leading-5">
                    ${salesCommission?.monthlyCommission?.toFixed(2)}
                  </span>
                )}
              </div>
            </div>
            <div className="w-full whitespace-nowrap bg-[#2e8965] grid place-content-center border-r border-white px-[8px]">
              <div className="text-white text-sm sm:text-[20px] leading-8 flex items-center justify-center space-x-1">
                <span className="min-w-[50px]">Week:</span>
                {salesCommissionLoading ? (
                  <LoadingDesign className="v h-min w-min" smallLoading />
                ) : (
                  <span className="bg-[#82af6f] text-[13px] leading-4 rounded-full px-3 text-[#eee] sm:text-[18px] sm:leading-5">
                    ${salesCommission?.weeklyCommission?.toFixed(2)}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          onClick={() => {
            setShowProfile(!showProfile);
          }}
          className="relative bg-[#62A8D1] p-1 md:p-2 flex justify-center items-center"
          ref={profileRef}
        >
          <div className="text-white cursor-pointer">
            <p className="text-[11.05px] leading-[15px]">Welcome,</p>
            <p className="block text-[13px] leading-[15px] truncate max-w-[100px] lg:max-w-[120px]">
              {userName}
            </p>
          </div>
          <i className="icon-sort-down green text-white text-xs sm:text-lg md:text-xl leading-[17px] cursor-pointer" />
          <div
            className={`${
              showProfile ? "block" : "hidden"
            } absolute top-9 md:top-10 z-20 right-1 lg:right-[6px] shadow-[0px_2px_4px_#cccccc] bg-white py-[5px] after:content-[''] after:absolute  after:-top-[6px] after:right-[2px] sm:after:right-[6px] lg:after:right-[4px] after:inline-block after:[border-right:6px_solid_transparent] after:[border-bottom:6px_solid_#ffffff] after:[border-left:6px_solid_transparent] md:after:[border-right:12px_solid_transparent] md:after:[border-bottom:12px_solid_#ffffff] md:after:[border-left:12px_solid_transparent]`}
          >
            <NavLink
              to="/user_settings"
              className="flex items-center space-x-3 py-1 px-3 my-[1px] hover:bg-[#fbe088] w-[160px] cursor-pointer"
            >
              <i className="icon-cog text-[#333]" />
              <p className="text-[13px] leading-5 text-[#333]">Settings</p>
            </NavLink>
            <div className="border-t my-[9px]" />
            <div
              onClick={Logout}
              className="flex items-center space-x-3 py-1 px-3 my-[1px] hover:bg-[#fbe088] w-[160px] cursor-pointer"
            >
              <i className="icon-off-1 text-[#333] text-[16px] leading-4 pl-[3px]" />
              <p className="text-[13px] leading-5 text-[#333]">Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
