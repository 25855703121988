import { FILTER_CLIENTS } from "../modules/Clients/Services/Queries/FilterQuery";
import { FetchParamsFilterData } from "../types/Filter";
import { SortOrder } from "../types/Table";
import { client } from "../Services/ClientConfig";
import { FILTER_QUOTES } from "../modules/Quotes/Services/Queries/Queries";

const apolloClient = client;

export async function getFilterTableData(
  fetchParams: FetchParamsFilterData,
  pageSize: number,
  currentPage: number,
  sortOrder: SortOrder,
  pageName: string,
) {
  const type = {
    quote: ["history", "pending_orders", "active_leads"],
    client: ["client_list", "pool_list"],
  };

  const getQuery = () => {
    if (type.quote.includes(pageName)) {
      return FILTER_QUOTES;
    }

    return FILTER_CLIENTS;
  };

  const query = getQuery();
  const getCollectionList = async () => {
    return await apolloClient.query({
      query: query,
      variables: {
        ...fetchParams,
        limit: pageSize,
        page: currentPage,
        sortBy: {
          column: sortOrder.columnName,
          order: sortOrder.orderBy,
        },
        filterType: pageName,
      },
      fetchPolicy: "no-cache",
    });
  };

  let statuses: Record<
    string,
    {
      value: string;
      label: string;
    }[]
  > = {
    paymentStatuses: [],
    leadSource: [],
    quoteStatus: [],
  };

  let collectionList: {
    filterDataCollection: any[];
    countPages: number;
  } = {
    filterDataCollection: [],
    countPages: 0,
  };

  await getCollectionList().then((res) => {
    const { data } = res;

    if (!data) return;

    const {
      filterClients,
      filterQuote,
      paymentStatuses,
      leadSource,
      quoteStatus,
    } = data;

    if (paymentStatuses) {
      statuses.paymentStatuses = paymentStatuses;
    }

    if (leadSource) {
      statuses.leadSource = leadSource;
    }

    if (quoteStatus) {
      statuses.quoteStatus = quoteStatus;
    }

    const getFilterData = () => {
      if (type.quote.includes(pageName)) {
        return filterQuote;
      }

      if (type.client.includes(pageName)) {
        return filterClients;
      }

      return undefined;
    };

    const filterData = getFilterData();

    if (!filterData) return;

    const { dataCollection, totalNumberOfItems } = filterData;

    if (dataCollection) {
      collectionList.filterDataCollection = dataCollection;
    }

    if (typeof totalNumberOfItems === "number") {
      collectionList.countPages = Math.ceil(totalNumberOfItems / pageSize);
    }
  });

  return { collectionList, statuses };
}
