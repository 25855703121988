import { useReactiveVar } from "@apollo/client";
import { userContextData } from "helpers/cache";
import { FC, useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import QuotesActiveLeads from "./Pages/ActiveLeads/QuotesActiveLeads";
import EditQuotes1 from "./Pages/EditQuotes/EditQuote";
import QuotesHistory from "./Pages/History/QuotesHistory";
import QuotesProcessing from "./Pages/Processing/QuotesProcessing";

const Quotes: FC<{ refetchSalesCommission: Function }> = ({
  refetchSalesCommission,
}) => {
  const userDetails: any = useReactiveVar(userContextData);
  let accessControlStructure: any =
    userDetails?.user?.accessControlStructure?.modules;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  let quoteId = useMemo(() => pathname.split("/")[2], [pathname]);
  const pathList = useMemo(
    () => ["history", "active_leads", "processing", "new_quotes"],
    [],
  );

  useEffect(() => {
    if (
      quoteId !== undefined &&
      isNaN(+quoteId) &&
      !pathList.includes(quoteId)
    ) {
      navigate("/quotes/history");
    }
  }, [quoteId]);

  return (
    <div className="w-full">
      <Routes>
        {accessControlStructure?.quote?.tabs?.history && (
          <Route path="/" element={<QuotesHistory />} />
        )}

        {accessControlStructure?.quote?.tabs?.history && (
          <Route path="history" element={<QuotesHistory />} />
        )}
        {accessControlStructure?.quote?.tabs?.activeLeads && (
          <Route path="active_leads" element={<QuotesActiveLeads />} />
        )}
        {accessControlStructure?.quote?.tabs?.processing && (
          <Route path="processing" element={<QuotesProcessing />} />
        )}
        {accessControlStructure?.quote?.tabs?.newQuote && (
          <Route
            path="new_quotes/*"
            element={
              <EditQuotes1 refetchSalesCommission={refetchSalesCommission} />
            }
          />
        )}
        {accessControlStructure?.quote?.tabs?.editQuote && (
          <Route
            path={`${pathname.split("/")[2]}/*`}
            element={
              <EditQuotes1
                refetchSalesCommission={refetchSalesCommission}
                quoteId={+quoteId}
              />
            }
          />
        )}
        {/*{(accessControlStructure?.quote?.tabs?.editQuote ||
          accessControlStructure?.quote?.tabs?.newQuote) && (
          <Route
            path="edit_quotes/*"
            element={
              <EditQuotes1 refetchSalesCommission={refetchSalesCommission} />
            }
          />
        )}*/}
        <Route path="/*" element={<Navigate to="/quotes/history" replace />} />
      </Routes>
    </div>
  );
};

export default Quotes;
