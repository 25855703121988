import { regExpEmail } from "./RegExp";
import { getFloatNumberFromString } from "./utils";

type Value = undefined | number | string | boolean | null;

export function isValidNumber(value: Value) {
  return typeof value === "number" && !isNaN(value);
}

export function isValidNonEmpty(value: Value) {
  return !!value;
}

export function isValidCreditCardDate(value: Value) {
  return (
    typeof value === "string" && !!value.split("-")[0] && !!value.split("-")[1]
  );
}

export function isValidEmail(value: Value) {
  return typeof value === "string" && regExpEmail.test(value);
}

export function isValidCreditCard(value: Value) {
  return isValidNonEmpty(value) && value?.toString().length === 16;
}

export function isValidInteger(value: number | string | undefined | null) {
  let currentValue = value;

  if (typeof value === "string") {
    currentValue = getFloatNumberFromString(value);
  }
  return Number.isInteger(currentValue);
}
