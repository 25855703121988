import { makeVar } from "@apollo/client";

interface NotificationPopUpType {
  type: string;
  message: string;
}

const userContextData:
  | {
      user: any;
      refetchUserData: Function;
      loadingUser: boolean;
    }
  | any = makeVar({});

const setNotification = makeVar<NotificationPopUpType[] | any>([]);

const catchNotification = (error: any) =>
  setNotification([
    {
      type: "ERROR",
      message: `${error?.message}`,
    },
  ]);

export { userContextData, setNotification, catchNotification };
