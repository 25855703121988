import { FC } from "react";

const CreditCardNumber: FC<{
  first?: string | number;
  last?: string | number;
}> = ({ first, last }) =>
  (!first || !+first) && (!last || !+last) ? (
    <></>
  ) : (
    <>
      {[
        ...(String(first) ? [String(first)] : []),
        "***",
        ...(String(last) ? [String(last)] : []),
      ].join(" ")}
    </>
  );

export default CreditCardNumber;
