import {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {setNotification} from "../../../helpers/cache";
import {DOWNLOAD_CSV_ALL_PRODUCTS} from "../Services/Queries";

const ProductsCSV: FC<{}> = ({}) => {
    const [isLoadingDownloadCSVProducts, setIsLoadingDownloadCSVProducts] = useState(false);
    const [isCSVProductsDownloaded, setIsProductsDownloaded] = useState(false);

    const [downloadCSVAllProducts] = useLazyQuery(DOWNLOAD_CSV_ALL_PRODUCTS, {
        onError: (error) => {
            setNotification([
                {
                    type: "ERROR",
                    message: `${error?.message}`,
                },
            ]);
        },
        notifyOnNetworkStatusChange: true,
    });

    const link = useMemo(() => document.createElement("a"), []);

    const onDownloadCSVAllProducts = useCallback(async () => {
       setIsLoadingDownloadCSVProducts(true);

        await downloadCSVAllProducts()
            .then((res) => {
                const { data } = res;

                if (!data) return;

                const { exportAllProducts } = data;
                if (!exportAllProducts) return;

                const { file, fileName } = exportAllProducts;

                if (!file || !fileName) return;

                link.href = `data:text/csv;base64,${file}`;
                link.download = fileName;
                link.click();
            })
            .finally(() => {
                setIsLoadingDownloadCSVProducts(false);
            });
    }, [])

    const hasStarted = useRef(false);

    useEffect(() => {
        if (!hasStarted.current) {
            hasStarted.current = true;
            setIsLoadingDownloadCSVProducts(true);
            onDownloadCSVAllProducts().then(() => {
                setIsLoadingDownloadCSVProducts(false);
            });
        }
    }, []);

    return (
        <div>
            
        </div>
    )
}

export default ProductsCSV;
