import { FC, useEffect } from "react";

const SelectCombobox: FC<{
  value: string;
  onChange: (value: string) => void;
  labelWidth?: string;
  label?: string;
}> = ({ value, onChange, labelWidth, label }) => {
  useEffect(() => {
    if (!value) {
      onChange("-");
    }
  }, [value]);

  return (
    <div className={`yes-or-no-box ${label ? "yes-or-no-box_with-label" : ""}`}>
      {label && (
        <div
          className="yes-or-no-box__label"
          style={{ width: `${labelWidth || "max-content"}` }}
        >
          {label}
        </div>
      )}
      <div className="btn-group">
        <button
          className={`btn btn-small ${value === "-" ? "active" : ""}`}
          onClick={() => {
            onChange("-");
          }}
        >
          <i className="icon-minus" />
        </button>
        <button
          className={`btn btn-small ${value === "Yes" ? "active" : ""}`}
          onClick={() => {
            onChange("Yes");
          }}
        >
          Yes
        </button>
        <button
          className={`btn btn-small ${value === "No" ? "active" : ""}`}
          onClick={() => {
            onChange("No");
          }}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default SelectCombobox;
