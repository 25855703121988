import { gql } from "@apollo/client";

export const USER_SETTING_THEME_AND_PAGINATION = gql`
  query userSettingQueries {
    userSettingDefaultCounts
    userSettingClientThemes {
      label
      value
    }
  }
`;
