import ProductRow from "./ProductRow";

const ProductTree: React.FC<{
  treeData: {
    id: number;
    name: string;
    children: [] | any[];
  }[];
  level: number;
  setProductCategoriesId: Function;
}> = ({ treeData, level, setProductCategoriesId }) => {
  const items = treeData;
  if (items?.length === 0) return null;

  return (
    <div>
      {items?.map((item) => (
        <ProductRow
          item={item}
          level={level}
          key={item?.id}
          setProductCategoriesId={setProductCategoriesId}
        >
          <ProductTree
            key={item?.id}
            treeData={item?.children}
            level={level + 1}
            setProductCategoriesId={setProductCategoriesId}
          />
        </ProductRow>
      ))}
    </div>
  );
};

export default ProductTree;
