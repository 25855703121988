import { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

class NestableItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    isCopy: PropTypes.bool,
    options: PropTypes.object,
    index: PropTypes.number,
    depth: PropTypes.number,
    showEditModal: PropTypes.bool,
    setShowEditModal: PropTypes.func,
    showDeleteModal: PropTypes.bool,
    setShowDeleteModal: PropTypes.func,
    setEditProduct: PropTypes.func,
  };
  static defaultProps = {
    depth: 0,
  };

  render() {
    const {
      item,
      isCopy,
      options,
      index,
      depth,
      showEditModal,
      setShowEditModal,
      showDeleteModal,
      setShowDeleteModal,
      setEditProduct,
    } = this.props;
    const { dragItem, renderItem, handler, idProp, childrenProp } = options;

    const isCollapsed = options.isCollapsed(item);
    const isDragging = !isCopy && dragItem && dragItem[idProp] === item[idProp];
    const hasChildren = item[childrenProp] && item[childrenProp].length > 0;

    let rowProps = {};
    let handlerProps = {};
    let wrappedHandler;

    if (!isCopy) {
      if (dragItem) {
        rowProps = {
          ...rowProps,
          onMouseEnter: (e) => options.onMouseEnter(e, item),
        };
      } else {
        handlerProps = {
          ...handlerProps,
          draggable: true,
          onDragStart: (e) => {
            options.onDragStart(e, item);
          },
        };
      }
    }

    if (handler) {
      wrappedHandler = (
        <span className="nestable-item-handler" {...handlerProps}>
          {handler}
        </span>
      );
      // wrappedHandler = React.cloneElement(handler, handlerProps);
    } else {
      rowProps = {
        ...rowProps,
        ...handlerProps,
      };
    }

    const baseClassName = "nestable-item" + (isCopy ? "-copy" : "");
    const itemProps = {
      className: cx(baseClassName, baseClassName + "-" + item[idProp], {
        "is-dragging": isDragging,
        [baseClassName + "--with-children"]: hasChildren,
        [baseClassName + "--children-open"]: hasChildren && !isCollapsed,
        [baseClassName + "--children-collapsed"]: hasChildren && isCollapsed,
      }),
    };

    const content = renderItem({
      depth,
      handler: wrappedHandler,
      index,
      item,
      showEditModal,
      setShowEditModal,
      showDeleteModal,
      setShowDeleteModal,
      setEditProduct,
      hasChildren,
    });

    if (!content) return null;

    return (
      <li {...itemProps}>
        <div className="nestable-item-name" {...rowProps}>
          {content}
        </div>

        {hasChildren && !isCollapsed && (
          <ol className="nestable-list">
            {item[childrenProp].map((item, i) => {
              return (
                <NestableItem
                  key={i}
                  index={i}
                  depth={depth + 1}
                  item={item}
                  options={options}
                  isCopy={isCopy}
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  setEditProduct={setEditProduct}
                />
              );
            })}
          </ol>
        )}
      </li>
    );
  }
}

export default NestableItem;
