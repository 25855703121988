import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import type { TableHeading } from "types/Table";
import { ChildrenTable } from "types/Table";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { CLIENT_LOGS_QUERY } from "../../Services/Queries/Queries";
import { Params } from "../../../../types/Main";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { convertDateToUSFormat, isNumber } from "../../../../helpers/utils";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";
import { Link } from "react-router-dom";

const EditClientLogsNew: FC<{
  clientId?: number;
}> = ({ clientId }) => {
  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "dateCreated",
        label: "Date_created",
      },
      {
        name: "user",
        label: "User",
      },
      {
        name: "data",
        label: "Data",
      },
    ],
    [],
  );

  const [fetchLogs] = useLazyQuery(CLIENT_LOGS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const userDetails: any = useReactiveVar(userContextData);
  const [isNeedUpdateData, setIsNeedUpdateData] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const [countPages, setCountPages] = useState(0);
  const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});
  const [time, setTime] = useState<number>(0);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);

  useEffect(() => {
    isLoadingLogs && setTime((start) => +new Date());
    !isLoadingLogs && setTime((start) => +new Date() - start);
  }, [isLoadingLogs]);

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const currentParams = {
      pageSize,
      currentPage,
      clientId,
      isNeedUpdateData,
    };

    onChangeParamsCallback(
      prevFetchSettings,
      currentParams,
      (newParams) => setPrevFetchSettings(newParams),
      () => {
        setIsNeedUpdateData(true);
      },
    );
  }, [pageSize, currentPage, clientId, prevFetchSettings]);

  useEffect(() => {
    if (isNeedUpdateData) {
      onFetchLogs();
    }
  }, [isNeedUpdateData]);

  const onErrorFetch = useCallback(() => {
    setCountPages(0);
    setDataCollection([]);
  }, []);

  const accessAdminPages = useMemo(() => userDetails?.user?.accessControlStructure?.modules?.admin === 'accessToAll', [userDetails])

  const onFetchLogs = useCallback(async () => {
    setIsLoadingLogs(true);
    await fetchLogs({
      variables: {
        clientId,
        limit: pageSize,
        page: currentPage,
      },
    })
      .then((res) => {
        const { data } = res;

        if (!data) {
          onErrorFetch();
          return;
        }
        const { clientLogs } = data;

        if (!clientLogs) {
          onErrorFetch();
          return;
        }

        const { dataCollection, totalNumberOfItems } = clientLogs;

        if (!dataCollection || !totalNumberOfItems) {
          onErrorFetch();
          return;
        }

        const newCountPages = Math.ceil(totalNumberOfItems / pageSize);

        setDataCollection(dataCollection);
        setCountPages(newCountPages);
      })
      .finally(() => {
        setIsLoadingLogs(false);

        if (isNeedUpdateData) {
          setIsNeedUpdateData(false);
        }
      });
  }, [pageSize, isNeedUpdateData, currentPage]);

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    dataCollection.forEach((dataCollectionItem) => {
      const { id, user, itemType, itemId, createdAt, data, client } =
        dataCollectionItem;

      let userName = user?.firstName;

      if (user?.lastName) {
        userName = userName.concat(` ${user?.lastName}`);
      }

      if (!userName) userName = `User with ID = ${user?.id}`;

      const parsedData = JSON.parse(data);
      let newItemData = [];

      const getEventName = () => {
        if (!parsedData || typeof parsedData !== 'object') {
          return 'Change'
        }

        if (parsedData.id && !parsedData.id[0] && !!parsedData.id[1]) {
          return 'Create'
        }

        return 'Update'
      }

      newItemData.push(`${getEventName()} ${itemType || "-"}${itemId ? ' #' + itemId : ''}:`);

      const keys = Object.keys(parsedData);

      keys.forEach((key) => {
        const values = parsedData[key];
        const prevValue = values[0]?.toString();
        const newValue = values[1]?.toString();

        const isNeedHiddenLogs = !prevValue && !newValue

        if (prevValue !== newValue && !isNeedHiddenLogs) {
          newItemData.push(`${key}: ${prevValue || '""'} ==> ${newValue || '""'}`);
        }
      });

      const getUserLink = () => {
        if (userName && user?.id && accessAdminPages) {
          return <Link to={`/admin/user/${user.id}/info`} target="_blank">{userName}</Link>
        }

        return userName || "-"
      }

      if (!newItemData.length) return

      currentChildrenForTable.push({
        id: id,
        row: {
          dateCreated: createdAt ? convertDateToUSFormat(createdAt) : "-",
          user: getUserLink(),
          data: (
            <Fragment>
              {newItemData.map((itemDataItem, index) => {
                return (
                  <div key={`client-log-${id}-${index}`}>{itemDataItem}</div>
                );
              })}
            </Fragment>
          ),
        },
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);
  return (
    <section>
      <TableWithHeaderNew
        header={`Quote history: ${time} ms`}
        heading={tableHeading}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={isLoadingLogs}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateData(true);
        }}
      />
    </section>
  );
};

export default EditClientLogsNew;
