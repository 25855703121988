import Logout from "modules/User/Logout";
import { FC, useState } from "react";
import { useLocation } from "react-router-dom";

import MenuDetails from "./MenuDetails";
import { menuList } from "./Menulist";

export interface MenuListType {
  name: string;
  iconName: string;
  subMenus: { subMenuName: string; subMenuLink: string; aclName: string }[];
  aclName: string;
}

const Menu: FC<{
  showIcon: boolean;
  setShowIcon: Function;
  themeColor: string;
  setAdminDashBoardSideBarClicked: Function;
}> = ({
  setShowIcon,
  showIcon,
  themeColor,
  setAdminDashBoardSideBarClicked,
}) => {
  const [activeMenu, setActiveMenu] = useState<number | undefined>(undefined);

  const path = useLocation();
  let pathName = path.pathname;
  let title = "";

  menuList.map((menuName) =>
    pathName?.includes(`${menuName?.link}`) ? (title = menuName.name) : null,
  );

  return (
    <nav
      className={`${
        showIcon ? "w-full" : "w-[42px]"
      } ${themeColor} bg-skin-mainMenuHeadingBackground`}
    >
      {menuList?.map((item, index) => (
        <MenuDetails
          key={index}
          item={item}
          setActiveMenu={setActiveMenu}
          activeMenu={activeMenu}
          index={index}
          showIcon={showIcon}
          title={title}
          themeColor={themeColor}
          setAdminDashBoardSideBarClicked={setAdminDashBoardSideBarClicked}
        />
      ))}
      <div
        onClick={Logout}
        className={`${themeColor} flex relative items-center cursor-pointer" 
          ${
            themeColor !== "pink"
              ? "group-hover:text-skin-sidebar-heading-hover"
              : ""
          } ${
            themeColor === "blue" || themeColor === "black"
              ? `before:content-[""] before:absolute before:w-[3px] h-full before:top-0 before:bottom-0 hover:before:bg-[#3382AF]`
              : ""
          }  ${
            themeColor === "default" && showIcon
              ? "before:content-[''] before:absolute before:w-[3px] h-full before:top-0 before:bottom-0 before:bg-[#444]"
              : ""
          } hover:bg-skin-sidebar-main-heading-hover group border-b border-skin-sideBarMainHeaderBorder transition-colors duration-1000`}
      >
        <i
          className={`${themeColor} ${
            themeColor !== "pink"
              ? "text-skin-sideBarMainHeadingTextColor"
              : "text-[#FFAB00]"
          }  icon-off-1 text-[16px] min-w-[42px] inline-flex justify-center items-center py-[6px] ${
            themeColor !== "pink"
              ? "group-hover:text-skin-sidebar-heading-hover"
              : "group-hover:text-white"
          } `}
        />
        <p
          className={`${themeColor} text-skin-sideBarMainHeadingTextColor ${
            themeColor === "pink" && showIcon
              ? "group-hover:font-semibold group-hover:text-skin-sidebar-heading-hover/50"
              : ""
          } group-hover:text-skin-sidebar-heading-hover text-[13px] py-2 ${
            showIcon
              ? "block min-w-[147px]"
              : `hidden group-hover:block absolute ${
                  themeColor === "pink"
                    ? "group-hover:text-[#FFAB00] font-bold"
                    : "group-hover:text-skin-sidebar-heading-hover"
                } left-[42px] pl-3 min-w-[175px] border border-[#ccc] shadow-[2px_1px_2px_0_transparent] transition-colors duration-1000 ${
                  themeColor !== "default"
                    ? "bg-skin-mainMenuHeadingBackground"
                    : "bg-skin-sidebar-main-heading-hover"
                }`
          }  
          `}
        >
          Logout
        </p>
      </div>
      <div
        onClick={() => {
          setShowIcon(!showIcon);
        }}
        className={`${themeColor} cursor-pointer relative border-b bg-skin-sideBarCollapseBackground border-skin-sideBarCollapseBorder flex justify-center py-[5px]`}
      >
        <div
          className={`border-t ${themeColor} border-skin-sideBarCollapseBorder absolute h-0 top-[12px] left-[3px] right-[3px] ${
            showIcon ? "sm:left-2 sm:right-2" : ""
          } `}
        />
        <i
          className={`icon-angle-double-left text-[#999999] bg-skin-sideBarArrowBackground flex justify-center items-center border border-[#999] w-[17px] h-[17px] rounded-full relative z-50 text-sm pb-[2.4px]`}
        />
      </div>
    </nav>
  );
};

export default Menu;
