import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { NormalButton } from "components/Buttons";
import { Select } from "components/Form";
import { LoadingDesign } from "helpers";
import { setNotification, userContextData } from "helpers/cache";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { SET_USER_SETTINGS } from "./Services/Mutations/Mutation";
import { USER_SETTING_THEME_AND_PAGINATION } from "./Services/Queries/Queries";

interface UserSettingType {
  quotesByDefault: string;
  clientsByDefault: string;
  skin: string;
  autoFollowUp: string;
}

const UserSettings = () => {
  const { data: userSettingsList, loading } = useQuery(
    USER_SETTING_THEME_AND_PAGINATION,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const userDetails: any = useReactiveVar(userContextData);
  const [checkbox, setCheckbox] = useState(false);
  const [setUserSetting] = useMutation(SET_USER_SETTINGS);

  const userSettingDefaultCounts =
    userSettingsList?.userSettingDefaultCounts?.map((count: any) => count);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<UserSettingType>();
  const [userSettingLoading, setUserSettingLoading] = useState<boolean>(false);
  const [userThemeColors, setUserThemeColors] = useState<
    { id: string; value: string }[]
  >([]);

  useEffect(() => {
    userSettingsList?.userSettingClientThemes &&
      setUserThemeColors(
        userSettingsList?.userSettingClientThemes?.map(
          (theme: { label: string; value: string }) => {
            return {
              id: theme?.value,
              name: theme?.label,
            };
          },
        ),
      );
  }, [userSettingsList?.userSettingClientThemes, userSettingsList]);

  useEffect(() => {
    const currentUserSettings = {
      quotesCount: '',
      clientsCount: '',
      theme: '',
      followUp: 'false',
    }

    if (userDetails?.user?.userSettings) {
      userDetails?.user?.userSettings.forEach((settings: {
        name: string;
        value: null | string
      }) => {
        if (!settings.value) {
          return;
        }

        switch (settings.name) {
          case 'quotes_default_count':
            currentUserSettings.quotesCount = settings.value
            break;
          case 'clients_default_count':
            currentUserSettings.clientsCount = settings.value
            break;
          case 'clients_theme':
            currentUserSettings.theme = settings.value
            break;
          case 'enable_auto_follow_up':
            currentUserSettings.followUp = settings.value
            break;
        }
      })
    }

    if (!currentUserSettings.quotesCount && !!userSettingDefaultCounts?.length) {
      currentUserSettings.quotesCount = `${userSettingDefaultCounts[0]}`
    }

    if (!currentUserSettings.clientsCount && !!userSettingDefaultCounts?.length) {
      currentUserSettings.clientsCount = `${userSettingDefaultCounts[0]}`
    }

    if (!currentUserSettings.theme && !!userThemeColors?.length) {
      currentUserSettings.theme = `${userThemeColors[0].id}`
    }

    if (!currentUserSettings.followUp) {
      currentUserSettings.followUp = `false`
    }

    if (currentUserSettings.quotesCount) {
      setValue("quotesByDefault", currentUserSettings.quotesCount);
    }

    if (currentUserSettings.clientsCount) {
      setValue("clientsByDefault", currentUserSettings.clientsCount);
    }

    if (currentUserSettings.theme) {
      setValue("skin", currentUserSettings.theme);
    }

    if (currentUserSettings.followUp) {
      setCheckbox(JSON.parse(currentUserSettings.followUp))
    }
  }, [userDetails?.user?.userSettings, userSettingDefaultCounts, userThemeColors]);

  const updateUserSetting = (userSettingData: UserSettingType) => {
    if (!userSettingLoading) {
      setUserSettingLoading(true);
      setUserSetting({
        variables: {
          userId: userDetails?.user?.id,
          settings: [
            {
              name: "clients_theme",
              value: userSettingData?.skin,
            },
            {
              name: "clients_default_count",
              value: userSettingData?.clientsByDefault,
            },
            {
              name: "quotes_default_count",
              value: userSettingData?.quotesByDefault,
            },
            {
              name: "enable_auto_follow_up",
              value: checkbox ? "true" : "false",
            },
          ],
        },
      })
        .then(() => {
          setUserSettingLoading(false);
          setNotification([
            {
              type: "SUCCESS",
              message: "User settings set successfully",
            },
          ]);
        })
        .catch((error) => {
          setUserSettingLoading(false);
          setNotification([
            {
              type: "ERROR",
              message: `${error?.message}`,
            },
          ]);
        });
    }
  };

  return (
    <Fragment>
      {loading ? (
        <LoadingDesign />
      ) : (
        <div>
          <nav
            className={`flex m-2 px-2 py-1 flex-col md:flex-row items-center bg-[#e7f2f8] rounded-[4px]`}
          >
            <div className="whitespace-nowrap space-x-1">
              <i className="icon-home text-[19px] text-[#555555]" />
              <NavLink
                to={"/"}
                className="hover:underline text-[13px] text-[#4c8fbd]"
              >
                Home
              </NavLink>
              <i className="icon-angle-right" />
              <span className="text-[#555555] text-[13px]">
                {" "}
                Account settings
              </span>
            </div>
          </nav>
          <div className="sm:pl-10 pl-4 pr-2">
            <h1 className="my-[10px] text-[20px] sm:text-[25px] leading-10 text-[#393939]">
              Account settings:
            </h1>
            <form onSubmit={handleSubmit(updateUserSetting)}>
              <div className="space-y-2 sm:space-y-4">
                <div className="space-y-1 sm:space-y-0 flex flex-col sm:items-center sm:gap-2 sm:flex-row">
                  <div>
                    <span className="font-thirteen-px inline-block sm:w-[145px]">
                      Quotes by default
                    </span>
                    <span> :</span>
                  </div>
                  <Select
                    errors={errors}
                    name="quotesByDefault"
                    register={register}
                    hideError
                    hideSelect
                    className="max-w-[300px]"
                    classForSelect="bg-[#ccc] focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)]"
                    optionsWithString={userSettingDefaultCounts}
                  />
                </div>
                <div className="space-y-1 sm:space-y-0 flex flex-col sm:items-center sm:gap-2 sm:flex-row">
                  <div>
                    <span className="font-thirteen-px inline-block sm:w-[145px]">
                      Clients by default
                    </span>
                    <span> :</span>
                  </div>
                  <Select
                    errors={errors}
                    name="clientsByDefault"
                    register={register}
                    hideError
                    hideSelect
                    className="max-w-[300px]"
                    classForSelect="bg-[#ccc] focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)]"
                    optionsWithString={userSettingDefaultCounts}
                    defaultSelectValue={userSettingDefaultCounts[0]}
                  />
                </div>
                <div className="space-y-1 sm:space-y-0 flex flex-col sm:items-center sm:gap-2 sm:flex-row">
                  <div>
                    <span className="font-thirteen-px inline-block sm:w-[145px]">
                      Skin
                    </span>
                    <span> :</span>
                  </div>
                  <Select
                    errors={errors}
                    name="skin"
                    register={register}
                    hideError
                    hideSelect
                    className="max-w-[300px]"
                    classForSelect="bg-[#ccc] focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)]"
                    optionsWithObject={userThemeColors}
                  />
                </div>
                <div className="space-y-1 sm:space-y-0 flex sm:items-center sm:gap-2 sm:flex-row gap-2">
                  <div>
                    <span className="font-thirteen-px inline-block sm:w-[145px]">
                      Enable auto follow up
                    </span>
                    <span> :</span>
                  </div>
                  <div>
                    <span
                      className={`w-[16px] h-[16px] flex justify-center items-center mt-[2px] bg-white cursor-pointer border border-[#ccc] hover:border-[#F59942]`}
                      onClick={() => {
                        setCheckbox(!checkbox);
                      }}
                    >
                      <i
                        className={`icon-ok text-xs text-[#32A3CE] ${
                          checkbox ? "block" : "hidden"
                        }`}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <NormalButton
                buttonName="Save"
                className={`border border-black sm:ml-[160px] rounded px-2 mt-5 mx-auto bg-[rgb(230,230,230,0.3)] hover:bg-[rgb(239,239,239)]
              `}
                buttonType="submit"
                icon={userSettingLoading ? "icon-spin4" : ""}
                loading={userSettingLoading}
              />
            </form>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UserSettings;
