import { FC, useMemo } from "react";
import { AdminDashboardType } from "../../../types/AdminDashboard";
import { ChildrenTable } from "../../../types/Table";

const AdminDashboardShowItem: FC<{
  fields: AdminDashboardType["fields"];
  currentChildrenForTable?: ChildrenTable;
}> = ({ fields, currentChildrenForTable }) => {
  const filteredFields = useMemo(
    () =>
      fields.filter(
        (el) =>
          el.name !== "id" &&
          el.name !== "createdAt" &&
          el.name !== "updatedAt",
      ),
    [fields],
  );
  return (
    <section>
      {!currentChildrenForTable && (
        <div className="admin-dashboard__info-list-item">
          <div className="label">Info</div>
          <div className="info">Data not found or loading...</div>
        </div>
      )}
      {currentChildrenForTable && (
        <div className="admin-dashboard__info-list">
          {filteredFields.map((filteredField) => (
            <div
              key={`admin-dashboard-info-${filteredField.name}`}
              className="admin-dashboard__info-list-item"
            >
              <div className="label">{filteredField.label}</div>
              <div className="info">
                {currentChildrenForTable.row[filteredField.name] || "-"}
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default AdminDashboardShowItem;
