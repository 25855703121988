import { FC, Fragment, useCallback, useEffect, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useMutation } from "@apollo/client";
import { CREATE_STATE, UPDATE_STATE } from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { getFloatNumberFromString } from "../../../../helpers/utils";

const AdminDashboardEditState: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentAlias("");
    setCurrentTax(null);
    setCurrentName("");
  }, []);

  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_STATE, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { updateState } = data;

      if (!updateState) return;

      const { id } = updateState;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_STATE, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createState } = data;

      if (!createState) return;

      const { id } = createState;

      onAfterSave(id || 0);
    },
  });

  const [currentAlias, setCurrentAlias] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [currentTax, setCurrentTax] = useState<string | null>(null);

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const { name, alias, tax } = currentDataCollectionItem;

    if (name) {
      setCurrentName(name);
    }

    if (alias) {
      setCurrentAlias(alias);
    }

    if (typeof tax === "number") {
      setCurrentTax(tax.toString());
    }
  }, [currentDataCollectionItem]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            name: currentName,
            tax: getFloatNumberFromString(currentTax),
            alias: currentAlias,
          },
        });
      }
      return await createItems({
        variables: {
          name: currentName,
          tax: getFloatNumberFromString(currentTax),
          alias: currentAlias,
        },
      });
    };

    await handleSaveById();
  }, [currentTax, currentId, currentAlias, currentName]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm = !!currentName;
    setDisabledSaveButton(!isValidForm);
  }, [currentName]);

  return (
    <Fragment>
      <Input
        value={currentName}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentName(value);
          }
        }}
        name="state-name"
        labelWidth={labelWidth}
        label="Name"
        placeholder="Name"
        inputWidth={inputWidth}
        isIncorrectValue={!currentName}
      />
      <Input
        value={
          currentTax !== null &&
          typeof +currentTax === "number" &&
          !isNaN(+currentTax)
            ? currentTax
            : ""
        }
        onChange={(value) => {
          const stringValue = value.toString();
          setCurrentTax(stringValue ? stringValue : null);
        }}
        name="state-tax"
        labelWidth={labelWidth}
        label="Tax"
        placeholder="Tax"
        inputWidth={inputWidth}
        type="number"
      />
      <Input
        value={currentAlias}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentAlias(value);
          }
        }}
        name="state-alias"
        labelWidth={labelWidth}
        label="Alias"
        placeholder="Alias"
        inputWidth={inputWidth}
      />
    </Fragment>
  );
};

export default AdminDashboardEditState;
