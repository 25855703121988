import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Filter from "../../../../components/Filter/Filter";
import { useReactiveVar } from "@apollo/client";
import { userContextData } from "../../../../helpers/cache";
import {
  FetchParamsFilterData,
  FilterDataList,
} from "../../../../types/Filter";
import {
  ChildrenTable,
  SortOrder,
  TableHeading,
} from "../../../../types/Table";
import { getFilterTableData } from "../../../../helpers/Table";
import { Params } from "../../../../types/Main";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { convertDateToUSFormat } from "../../../../helpers/utils";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";
import { Link } from "react-router-dom";

const ClientPoolList: FC<{}> = ({}) => {
  const pageName = useMemo(() => "pool_list", []);
  const filterFields = useMemo(
    () => [
      [
        {
          name: "firstName",
          label: "First Name",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "lastName",
          label: "Last Name",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "company",
          label: "Company",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "hasOrdered",
          label: "Has ordered",
          type: "yesOrNo",
        },
        {
          name: "quoteQuantity",
          label: "Quote Quantity",
          type: "input",
          selectOptions: {
            options: [">", "<", "="],
          },
          inputOptions: {
            isLargeInput: true,
          },
          numberValue: true,
        },
        {
          name: "quoteValue",
          label: "Quote Value",
          type: "input",
          selectOptions: {
            options: [">", "<", "="],
          },
          inputOptions: {
            isLargeInput: true,
          },
          numberValue: true,
        },
        {
          name: "fax",
          label: "Fax",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
      ],
      [
        {
          name: "email",
          label: "Email",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "phone1",
          label: "Phone",
          type: "input",
          selectOptions: {
            options: ["contains", "doesn't contain", "it`s start"],
          },
        },
        {
          name: "createdAt",
          label: "Date Created",
          type: "dateBetween",
        },
      ],
    ],
    [],
  );

  const clearFilterFieldsList = useMemo(
    (): FilterDataList => ({
      filterName: "",
      firstName: {
        select: "contains",
        value: "",
      },
      lastName: {
        select: "contains",
        value: "",
      },
      company: {
        select: "contains",
        value: "",
      },
      hasOrdered: "-",
      quoteQuantity: {
        select: "=",
        value: "",
      },
      quoteValue: {
        select: "=",
        value: "",
      },
      fax: {
        select: "contains",
        value: "",
      },
      email: {
        select: "contains",
        value: "",
      },
      phone1: {
        select: "contains",
        value: "",
      },
      createdAt: {
        from: "",
        to: "",
        value: "",
        select: "",
      },
    }),
    [],
  );

  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "id",
        label: "Id",
        sortable: true,
      },
      {
        name: "accountName",
        label: "Account Name",
        sortable: true,
      },
      {
        name: "firstName",
        label: "Name",
        sortable: true,
      },
      {
        name: "lastName",
        label: "Last name",
        sortable: true,
      },
      {
        name: "company",
        label: "Company",
        sortable: true,
      },
      {
        name: "email",
        label: "Email",
        sortable: true,
      },
      {
        name: "phone1",
        label: "Phone",
        sortable: true,
      },
      {
        name: "fax",
        label: "Fax",
        sortable: true,
      },
      {
        name: "createdAt",
        label: "Created at",
        sortable: true,
      },
      {
        name: "temporaryAccessDate",
        label: "Temporary Access At",
        sortable: true,
      },
    ],
    [],
  );

  // reactive variables
  const userDetails: any = useReactiveVar(userContextData);

  const [fetchParams, setFetchParams] = useState<FetchParamsFilterData>({});
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    columnName: "id",
    orderBy: "DESC",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const [countPages, setCountPages] = useState(0);
  const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});

  // loadings
  const [isLoadingCollectionList, setIsLoadingCollectionList] = useState(false);

  const [isNeedUpdateTableData, setIsNeedUpdateTableData] = useState(false);

  // effects
  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const currentParams = {
      pageSize,
      currentPage,
      sortOrder,
      fetchParams,
    };

    const isNeedClearPage =
      JSON.stringify(prevFetchSettings.fetchParams) !==
        JSON.stringify(currentParams.fetchParams) &&
      currentParams.currentPage !== 1;

    onChangeParamsCallback(
      prevFetchSettings,
      currentParams,
      (newParams) => setPrevFetchSettings(newParams),
      () => {
        if (isNeedClearPage) {
          setCurrentPage(1);
          return;
        }
        setIsNeedUpdateTableData(true);
      },
    );
  }, [pageSize, currentPage, sortOrder, fetchParams, prevFetchSettings]);

  useEffect(() => {
    if (isNeedUpdateTableData) {
      inUpdateClientListCollection();
    }
  }, [isNeedUpdateTableData]);

  // handles

  const inUpdateClientListCollection = useCallback(async () => {
    setIsLoadingCollectionList(true);

    await getFilterTableData(
      fetchParams,
      pageSize,
      currentPage,
      sortOrder,
      pageName,
    )
      .then((res) => {
        const { collectionList } = res;

        if (!collectionList) return;

        const { filterDataCollection, countPages } = collectionList;

        if (filterDataCollection) {
          setDataCollection(filterDataCollection);
        }

        setCountPages(countPages);
      })
      .finally(() => {
        setIsLoadingCollectionList(false);

        if (isNeedUpdateTableData) {
          setIsNeedUpdateTableData(false);
        }
      });
  }, [
    fetchParams,
    pageSize,
    currentPage,
    sortOrder,
    pageName,
    isNeedUpdateTableData,
  ]);

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    dataCollection.forEach((dataCollectionItem) => {
      const {
        id,
        defaultContact,
        accountName,
        company,
        createdAt,
        temporaryAccessDate,
      } = dataCollectionItem;

      currentChildrenForTable.push({
        id,
        row: {
          id: id || "-",
          accountName: <Link to={`/clients/${id}`}>{accountName || "-"}</Link>,
          firstName: defaultContact?.firstName || "-",
          lastName: defaultContact?.lastName || "-",
          company: company || "-",
          email: defaultContact?.email || "-",
          phone1: defaultContact?.phone1 || "-",
          fax: defaultContact?.fax || "-",
          createdAt: createdAt ? convertDateToUSFormat(createdAt) : "-",
          temporaryAccessDate: temporaryAccessDate
            ? convertDateToUSFormat(temporaryAccessDate)
            : "-",
        },
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);

  return (
    <section className="layout-content">
      <Filter
        pageName={pageName}
        setFetchParams={setFetchParams}
        filterFields={filterFields}
        clearFilterFieldsList={clearFilterFieldsList}
      />
      <TableWithHeaderNew
        header="History"
        heading={tableHeading}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={isLoadingCollectionList}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateTableData(true);
        }}
      />
    </section>
  );
};
export default ClientPoolList;
