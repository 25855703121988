import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_USER, UPDATE_USER } from "../../Services/Mutations/Mutaions";
import { setNotification } from "../../../../helpers/cache";
import { convertDateToUSFormat } from "../../../../helpers/utils";
import Input from "../../../../components/Form/El/Input";
import { isValidEmail } from "../../../../helpers/Validation";
import { USER_ITEM_QUERY } from "../../Services/Queries/Queries";
import { LoadingDesign } from "../../../../helpers";
import { regExpPhone } from "../../../../helpers/RegExp";

const EditOrCreateUserTemplate: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentConfirmationPassword, setCurrentConfirmationPassword] =
    useState("");
  const [currentResetPasswordTime, setCurrentResetPasswordTime] = useState("-");
  const [currentRememberCreatedAt, setCurrentRememberCreatedAt] = useState("-");
  const [currentSignInCount, setCurrentSignInCount] = useState(0);
  const [currentCurrentSignInAt, setCurrentCurrentSignInAt] = useState("-");
  const [currentCurrentSignInIp, setCurrentCurrentSignInIp] = useState("-");
  const [currentLastSignInIp, setCurrentLastSignInIp] = useState("-");
  const [currentLogin, setCurrentLogin] = useState("-");
  const [currentUserName, setCurrentUserName] = useState("");
  const [currentFirstName, setCurrentFirstName] = useState("");
  const [currentLastName, setCurrentLastName] = useState("");
  const [currentPhone, setCurrentPhone] = useState("");
  const [currentEmailSignature, setCurrentEmailSignature] = useState("");
  const [currentLastSignInAt, setCurrentLastSignInAt] = useState("");
  const [currentIsDeleted, setCurrentIsDeleted] = useState(false);
  const [currentRoles, setCurrentRoles] = useState<string[]>([]);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [currentDataCollectionItem, setCurrentDataCollectionItem] = useState<
    DataCollectionItem | undefined
  >(undefined);
  const onAfterSave = useCallback((id: number) => {
    //clearForm();
    afterSave(id || 0);
  }, []);

  const [getUser] = useLazyQuery(USER_ITEM_QUERY, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      const { user } = data;

      if (!user) return;

      setCurrentDataCollectionItem(user);
    },
  });

  const [createUser] = useMutation(CREATE_USER, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      const { createUser } = data;

      if (!createUser) return;

      const { id } = createUser;

      onAfterSave(id || 0);
    },
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      const { updateUser } = data;

      if (!updateUser) return;

      const { id } = updateUser;

      onAfterSave(id || 0);
    },
  });

  const onGetUser = useCallback(async () => {
    setIsLoadingUser(true);
    await getUser({
      variables: {
        id: currentId,
      },
    });
    setIsLoadingUser(false);
  }, [currentId]);

  useEffect(() => {
    if (currentId) {
      onGetUser();
    }
  }, [currentId]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateUser({
          variables: {
            id: currentId,
            email: currentEmail,
            emailSignature: currentEmailSignature,
            firstName: currentFirstName,
            lastName: currentLastName,
            password: currentPassword,
            userName: currentUserName,
            phone: currentPhone,
          },
        });
      }

      return await createUser({
        variables: {
          email: currentEmail,
          emailSignature: currentEmailSignature,
          firstName: currentFirstName,
          lastName: currentLastName,
          password: currentPassword,
          userName: currentUserName,
          phone: currentPhone,
        },
      });
    };

    await handleSaveById();
  }, [
    currentId,
    currentEmail,
    currentEmailSignature,
    currentFirstName,
    currentLastName,
    currentPassword,
    currentUserName,
    currentPhone,
  ]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm =
      !!currentEmail &&
      isValidEmail(currentEmail) &&
      !!currentPassword &&
      !!currentConfirmationPassword &&
      !!currentFirstName &&
      !!currentUserName &&
      !!currentPassword &&
      !!currentConfirmationPassword &&
      currentPassword === currentConfirmationPassword;

    setDisabledSaveButton(!isValidForm);
  }, [
    currentEmail,
    currentPassword,
    currentConfirmationPassword,
    currentFirstName,
    currentUserName,
    currentPassword,
    currentConfirmationPassword,
  ]);

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const {
      email,
      rememberCreatedAt,
      signInCount,
      currentSignInAt,
      lastSignInAt,
      currentSignInIp,
      lastSignInIp,
      login,
      userName,
      firstName,
      lastName,
      phone,
      emailSignature,
      isDeleted,
      roles,
    } = currentDataCollectionItem;

    if (email) {
      setCurrentEmail(email);
    }

    if (rememberCreatedAt) {
      setCurrentRememberCreatedAt(convertDateToUSFormat(rememberCreatedAt));
    }

    if (currentSignInAt) {
      setCurrentCurrentSignInAt(convertDateToUSFormat(currentSignInAt));
    }

    if (lastSignInAt) {
      setCurrentLastSignInIp(convertDateToUSFormat(lastSignInAt));
    }

    if (lastSignInAt) {
      setCurrentLastSignInAt(convertDateToUSFormat(lastSignInAt));
    }

    if (signInCount) {
      setCurrentSignInCount(signInCount);
    }

    if (currentSignInIp) {
      setCurrentCurrentSignInIp(currentSignInIp);
    }

    if (lastSignInIp) {
      setCurrentLastSignInIp(lastSignInIp);
    }

    if (login) {
      setCurrentLogin(login);
    }

    if (userName) {
      setCurrentUserName(userName);
    }

    if (firstName) {
      setCurrentFirstName(firstName);
    }

    if (lastName) {
      setCurrentLastName(lastName);
    }

    if (phone) {
      setCurrentPhone(phone);
    }

    if (emailSignature) {
      setCurrentEmailSignature(emailSignature);
    }

    if (isDeleted) {
      setCurrentIsDeleted(isDeleted);
    }

    if (roles && roles.length) {
      setCurrentRoles(roles.map((role: any) => role.name));
    }
  }, [currentDataCollectionItem]);

  return (
    <Fragment>
      {isLoadingUser && <LoadingDesign />}
      {!isLoadingUser && (
        <Fragment>
          <Input
            value={currentEmail}
            onChange={(value) => {
              if (typeof value === "string") {
                setCurrentEmail(value.trim());
              }
            }}
            name={`user-${currentId || "new"}-edit-email`}
            labelWidth={labelWidth}
            label="Email"
            placeholder="Email"
            inputWidth={inputWidth}
            isIncorrectValue={!currentEmail || !isValidEmail(currentEmail)}
          />
          <Input
            value={currentPassword}
            onChange={(value) => {
              if (typeof value === "string") {
                setCurrentPassword(value.trim());
              }
            }}
            name={`user-${currentId || "new"}-edit-password`}
            labelWidth={labelWidth}
            label="Password"
            placeholder="Password"
            inputWidth={inputWidth}
            isIncorrectValue={
              !currentPassword ||
              (!!currentPassword &&
                currentPassword !== currentConfirmationPassword)
            }
          />
          <Input
            value={currentConfirmationPassword}
            onChange={(value) => {
              if (typeof value === "string") {
                setCurrentConfirmationPassword(value.trim());
              }
            }}
            name={`user-${currentId || "new"}-edit-confirmation-password`}
            labelWidth={labelWidth}
            label="Confirmation Password"
            placeholder="Confirmation Password"
            inputWidth={inputWidth}
            isIncorrectValue={
              !currentConfirmationPassword ||
              (!!currentConfirmationPassword &&
                currentPassword !== currentConfirmationPassword)
            }
          />
          <Input
            value={currentResetPasswordTime}
            readonly={true}
            onChange={() => {}}
            name={`user-${currentId || "new"}-edit-reset-password-time`}
            labelWidth={labelWidth}
            label="Reset password sent at"
            inputWidth={inputWidth}
          />
          <Input
            value={currentRememberCreatedAt}
            readonly={true}
            onChange={() => {}}
            name={`user-${currentId || "new"}-edit-remember-created-at`}
            labelWidth={labelWidth}
            label="Remember created at"
            inputWidth={inputWidth}
          />
          <Input
            value={currentSignInCount}
            readonly={true}
            onChange={() => {}}
            name={`user-${currentId || "new"}-edit-sign-in-count`}
            labelWidth={labelWidth}
            label="Sign in count"
            inputWidth={inputWidth}
          />
          <Input
            value={currentCurrentSignInAt}
            readonly={true}
            onChange={() => {}}
            name={`user-${currentId || "new"}-edit-current-sign-in-at`}
            labelWidth={labelWidth}
            label="Current sign in at"
            inputWidth={inputWidth}
          />
          <Input
            value={currentLastSignInAt}
            readonly={true}
            onChange={() => {}}
            name={`user-${currentId || "new"}-edit-current-last-sign-in-at`}
            labelWidth={labelWidth}
            label="Last sign in at"
            inputWidth={inputWidth}
          />
          <Input
            value={currentCurrentSignInIp}
            readonly={true}
            onChange={() => {}}
            name={`user-${currentId || "new"}-edit-current-current-sign-in-ip`}
            labelWidth={labelWidth}
            label="Current sign in ip"
            inputWidth={inputWidth}
          />
          <Input
            value={currentLastSignInIp}
            readonly={true}
            onChange={() => {}}
            name={`user-${currentId || "new"}-edit-current-last-sign-in-ip`}
            labelWidth={labelWidth}
            label="Last sign in ip"
            inputWidth={inputWidth}
          />
          <Input
            value={currentLogin}
            readonly={true}
            onChange={() => {}}
            name={`user-${currentId || "new"}-edit-current-login`}
            labelWidth={labelWidth}
            label="Login"
            inputWidth={inputWidth}
          />
          <Input
            value={currentUserName}
            onChange={(value) => {
              if (typeof value === "string") {
                setCurrentUserName(value);
              }
            }}
            name={`user-${currentId || "new"}-edit-username`}
            labelWidth={labelWidth}
            label="User Name"
            placeholder="User Name"
            inputWidth={inputWidth}
            isIncorrectValue={!currentUserName}
          />
          <Input
            value={currentFirstName}
            onChange={(value) => {
              if (typeof value === "string") {
                setCurrentFirstName(value);
              }
            }}
            name={`user-${currentId || "new"}-edit-firstname`}
            labelWidth={labelWidth}
            label="First Name"
            placeholder="First Name"
            inputWidth={inputWidth}
            isIncorrectValue={!currentFirstName}
          />
          <Input
            value={currentLastName}
            onChange={(value) => {
              if (typeof value === "string") {
                setCurrentLastName(value);
              }
            }}
            name={`user-${currentId || "new"}-edit-lastname`}
            labelWidth={labelWidth}
            label="Last Name"
            placeholder="Last Name"
            inputWidth={inputWidth}
          />
          <Input
            value={currentPhone}
            onChange={(value) => {
              if (typeof value === "string") {
                setCurrentPhone(value.trim());
              }
            }}
            name={`user-${currentId || "new"}-edit-phone`}
            labelWidth={labelWidth}
            label="Phone"
            placeholder="Phone"
            inputWidth={inputWidth}
            pattern={regExpPhone}
          />
          <Input
            value={currentEmailSignature}
            onChange={(value) => {
              if (typeof value === "string") {
                setCurrentEmailSignature(value);
              }
            }}
            name={`user-${currentId || "new"}-edit-email-signature`}
            labelWidth={labelWidth}
            label="Email signature"
            placeholder="Email signature"
            inputWidth={inputWidth}
          />
          <Input
            value={currentIsDeleted ? "\u2705" : "\u274c"}
            readonly={true}
            name={`user-${currentId || "new"}-edit-deleted`}
            labelWidth={labelWidth}
            label="Is Deleted"
            inputWidth={inputWidth}
            onChange={() => {}}
          />
          <Input
            value={
              currentRoles && currentRoles.length
                ? currentRoles.toString()
                : "-"
            }
            readonly={true}
            name={`user-${currentId || "new"}-edit-deleted`}
            labelWidth={labelWidth}
            label="Roles"
            inputWidth={inputWidth}
            onChange={() => {}}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditOrCreateUserTemplate;
