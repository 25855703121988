import { FC } from "react";

// -- Expiry format is 203210 -> 10/32
const CreditCardExpiry: FC<{ expiry?: number | string }> = ({ expiry }) => {
  if (!expiry || !+expiry) return null;

  const [year, month] = [
    String(expiry).slice(0, String(expiry).length - 2),
    String(expiry).slice(-2),
  ];

  return !+year || !+month ? null : (
    <>
      {month}/{year.slice(-2)}
    </>
  );
};

export default CreditCardExpiry;
