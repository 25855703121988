import { FC, Fragment } from "react";
import { convertDateToUSFormat } from "helpers/utils";
import { TableHeading } from "../../../../types/Table";
import Table from "components/Table/Table";

const ProductPriceTableContent: FC<{
  currentPage: number;
  setCurrentPage: Function;
  pageSize: number;
  paginate: Function;
  setPageSize: Function;
  setActiveLine: Function;
  setShowUpdateForm: Function;
  setShowNewForm: Function;
  fetchProductPriceTable: Function;
  activeLine: number | undefined;
  setShowDeleteModal: Function;
  setEditProduct: Function;
  showDeleteModal: boolean;
  productPriceTablesList: any;
  setChangeOnDoubleClick: Function;
}> = ({
  currentPage,
  setCurrentPage,
  pageSize,
  paginate,
  setPageSize,
  setActiveLine,
  setShowUpdateForm,
  setShowNewForm,
  fetchProductPriceTable,
  activeLine,
  setShowDeleteModal,
  setEditProduct,
  showDeleteModal,
  productPriceTablesList,
  setChangeOnDoubleClick,
}) => {
  const tableHeading = [
    "#",
    "Name",
    "Options column",
    "Product catergory",
    "Created at",
    "Delete",
  ];

  const tableHeadingNew: TableHeading = [
    {
      label: "#",
      name: "#",
    },
    {
      label: "Name",
      name: "name",
    },
    {
      label: "Options column",
      name: "optionsColumn",
    },
    {
      label: "Product category",
      name: "productCategory",
    },
    {
      label: "Created at",
      name: "createdAt",
    },
    {
      label: "Delete",
      name: "delete",
    },
  ];

  return (
    <Fragment>
      <Table
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currentItemsPerPage={pageSize}
        setPageSize={setPageSize}
        heading={tableHeadingNew}
        colorHeader="blue"
        tableType="default"
        countPages={Math.ceil(
          productPriceTablesList?.productPriceTables?.totalNumberOfItems /
            pageSize,
        )}
      >
        {productPriceTablesList?.productPriceTables?.dataCollection?.map(
          (
            tableContent: {
              id: number;
              name: string;
              productOption: { id: number; title: string };
              productCategory: { id: number; name: string };
              createdAt: string;
              updatedAt: string;
            },
            index: number,
          ) => (
            <tr
              key={index}
              onClick={() => {
                setActiveLine(index);
              }}
              onDoubleClick={() => {
                setShowUpdateForm(true);
                setShowNewForm(false);
                setChangeOnDoubleClick((prevState: number) => {
                  return prevState + 1;
                });
                fetchProductPriceTable({
                  variables: {
                    id: tableContent?.id,
                  },
                });
              }}
              className={activeLine === index ? "active" : "inactive"}
            >
              <td>{index + 1}</td>
              <td>{tableContent?.name ? tableContent?.name : "-"}</td>
              <td>
                {tableContent?.productOption?.title
                  ? tableContent?.productOption?.title
                  : "-"}
              </td>
              <td>
                {tableContent?.productCategory?.name
                  ? tableContent?.productCategory?.name
                  : "-"}
              </td>
              <td>
                {tableContent?.createdAt
                  ? convertDateToUSFormat(tableContent?.createdAt)
                    ? convertDateToUSFormat(tableContent?.createdAt)
                    : "-"
                  : "-"}
              </td>
              <td>
                <span
                  className={`block bg-[url('./assets/delete-red-image.png')] cursor-pointer w-5 h-5  bg-no-repeat bg-center`}
                  onClick={() => {
                    setShowDeleteModal(!showDeleteModal);
                    setEditProduct({
                      id: tableContent?.id,
                      name: tableContent?.name,
                    });
                  }}
                />
              </td>
            </tr>
          ),
        )}
      </Table>
    </Fragment>
  );
};

export default ProductPriceTableContent;
