import {
	Route,
	Routes,
} from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import AdminUtils from "./AdminUtils";

const Admin = () => {
	return (
		<div className="w-full">
			<Routes>
				<Route
					path={`utils/*`}
					element={<AdminUtils/>}
				/>
				<Route
					path={`/*`}
					element={<AdminDashboard/>}
				/>
			</Routes>
		</div>
	);
};

	export default Admin;
