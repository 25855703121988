import { useLazyQuery } from "@apollo/client";
import { Combobox, Transition } from "@headlessui/react";
import useSearch from "../../../../helpers/hooks/useSearch";
import { READ_CLIENTS } from "../../Services/Queries/Queries";
import { FC, Fragment, useEffect, useState } from "react";

const EditQuoteQuoteInfoSearchBox: FC<{
  value: number;
  onChange: (id: number) => void;
}> = ({ value, onChange }) => {
  const [fetchClient, { data: readClients, loading: readClientLoading }] =
    useLazyQuery(READ_CLIENTS);
  const [query, setQuery] = useState("");

  const search = useSearch(query);

  useEffect(() => {
    if (search) {
      fetchClient({
        variables: {
          search: search ?? null,
        },
      });
    }
  }, [fetchClient, search]);

  const clientsList =
    query === ""
      ? readClients?.readClients
        ? readClients?.readClients?.map((client: any) => ({
            id: client?.id,
            text: `${client?.accountName || ""} companyName: ${
              client?.company || ""
            } contactName: ${client?.defaultContact?.firstName || ""} ${
              client?.defaultContact?.lastName || ""
            }`,
            ...client,
          }))
        : []
      : readClients?.readClients &&
        readClients?.readClients
          ?.map((client: any) => ({
            id: client?.id,
            text: `${client?.accountName || ""}    companyName: ${
              client?.company || ""
            } contactName: ${client?.defaultContact?.firstName || ""} ${
              client?.defaultContact?.lastName || ""
            }`,
            ...client,
          }))
          ?.filter((client: any) =>
            client?.text
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")),
          );

  return (
    <div className="w-full flex items-center">
      <Combobox
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        nullable
      >
        <div className="relative w-full">
          <div className="flex max-w-[440px]">
            <Combobox.Input
              id="quoteInfo.clientSearchbox"
              placeholder="Client search box..."
              autoComplete="off"
              className="border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] w-full"
              displayValue={(person: any) => person?.text}
              onChange={(event) => setQuery(event?.target?.value)}
              onKeyDown={(e: any) => {
                if (e.shiftKey) {
                  switch (e.code) {
                    case "Home":
                      e?.target?.select();
                      break;
                  }
                }
              }}
            />
            <Combobox.Button>
              <span className="border-4 border-[#ABBAC3] bg-[#ABBAC3] hover:bg-[#8B9AA3] cursor-pointer h-[30px] grid place-content-center">
                <i className="icon-search text-white text-sm mx-1" />
              </span>
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {!clientsList && (query || readClientLoading) ? (
                <div className="relative cursor-default  py-2 px-4 text-gray-700">
                  Loading...
                </div>
              ) : (clientsList?.length === 0 && query !== "") ||
                typeof readClients?.readClients === "undefined" ? (
                <div className="relative cursor-default  py-2 px-4 text-gray-700">
                  No results found.
                </div>
              ) : (
                clientsList?.map((client: { id: number; text: string }) => (
                  <Combobox.Option
                    key={client?.id}
                    className={({ active }) =>
                      `relative  cursor-pointer text-[13px] leading-5  py-1 pl-10 pr-4 ${
                        active ? "bg-[#a7d8e5] text-white" : "text-black"
                      }`
                    }
                    value={client?.id}
                  >
                    {({ selected }) => {
                      let string = client?.text?.substr(
                        0,
                        client?.text.toLowerCase().indexOf(query.toLowerCase()),
                      );
                      let endString = client?.text.substr(
                        client?.text
                          .toLowerCase()
                          .indexOf(query.toLowerCase()) + query.length,
                      );
                      let highlightedText = client?.text.substr(
                        client?.text.toLowerCase().indexOf(query.toLowerCase()),
                        query.length,
                      );
                      return (
                        <p key={client?.id} className="truncate">
                          {string}
                          <span className="font-bold">{highlightedText}</span>
                          {endString}
                        </p>
                      );
                    }}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default EditQuoteQuoteInfoSearchBox;
