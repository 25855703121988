import React, { FC, useCallback, useEffect, useState } from "react";
import {
  CREATE_FILTER,
  UPDATE_FILTER,
} from "modules/Clients/Services/Mutations/Mutation";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { userContextData } from "../../helpers/cache";
import {
  FetchParamsFilterData,
  FilterDataList,
  FilterDataObj,
  FilterTemplateCollection,
} from "../../types/Filter";
import { CHOOSE_FILTER_lIST_QUERY } from "../../modules/Quotes/Services/Queries/Queries";
import { CLIENTS_FILTER_QUERIES } from "../../modules/Clients/Services/Queries/Queries";
import { Menu, Transition } from "@headlessui/react";
import { testJSON } from "../../helpers/utils";
import Input from "../Form/El/Input";
import SelectCombobox from "../Form/El/SelectCombobox";
import Calendar from "../Form/El/Calendar";
import YesOrNoBox from "../Form/El/YesOrNoBox";
import Select from "../Form/El/Select";
import { regExpNumber } from "../../helpers/RegExp";
import { Params } from "../../types/Main";
import { onChangeParamsCallback } from "../../helpers/Main";

const Filter: FC<{
  pageName: string;
  setFetchParams: (fetchParams: FetchParamsFilterData) => void;
  filterFields: any;
  clearFilterFieldsList: FilterDataList;
}> = ({ pageName, setFetchParams, filterFields, clearFilterFieldsList }) => {
  // constant variables
  const labelWidth = "102px";

  // user details
  const userDetails: any = useReactiveVar(userContextData);

  // reactive variables
  const [isNeedUpdateFilter, setIsNeedUpdateFilter] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [currentFilterData, setCurrentFilterData] =
    useState<FilterDataList | null>(null);
  const [defaultFilterData, setDefaultFilterData] =
    useState<FilterDataList | null>(null);
  const [currentFilterTemplateId, setCurrentFilterTemplateId] = useState<
    number | null
  >(null);
  const [filterTemplateCollectionList, setFilterTemplateCollectionList] =
    useState<FilterTemplateCollection[]>([]);
  const [showFilter, setShowFilter] = useState(true);

  const [prevFilter, setPrevFilter] = useState<Params>({});

  // loading variables
  const [isLoadingCreateAndUpdateFilter, setIsLoadingCreateAndUpdateFilter] =
    useState(false);
  const [
    isLoadingUpdateFilterCollectionList,
    setIsLoadingUpdateFilterCollectionList,
  ] = useState(false);
  const [isLoadingUpdateDefaultFilter, setIsLoadingUpdateDefaultFilter] =
    useState(false);
  const [lastRequestFilterDataTime, setLastRequestFilterDataTime] = useState<
    number | null
  >(null);
  const [timeoutRequestId, setTimeoutRequestId] = useState<number | null>(null);

  // requests to backend
  const [createFilter] = useMutation(CREATE_FILTER, {
    variables: {
      userId: userDetails?.user?.id,
      data: JSON.stringify(currentFilterData),
      name: filterName,
      page: pageName,
    },
  });

  const [updateFilter] = useMutation(UPDATE_FILTER, {
    variables: {
      id: currentFilterTemplateId,
      userId: userDetails?.user?.id,
      data: JSON.stringify(currentFilterData),
      name: filterName,
      page: pageName,
    },
  });

  const [getFilterTemplateCollectionList] = useLazyQuery(
    CHOOSE_FILTER_lIST_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: userDetails?.user?.id,
        page: pageName,
      },
    },
  );

  const [getDefaultFilter] = useLazyQuery(CLIENTS_FILTER_QUERIES, {
    variables: {
      userId: userDetails?.user?.id,
      page: pageName,
    },
  });

  // effects

  useEffect(() => {
    if (userDetails?.user?.id) {
      onUpdateFilterCollectionList();
      onUpdateDefaultFilter();
    }
  }, [userDetails]);

  useEffect(() => {
    if (defaultFilterData && !currentFilterData) {
      setCurrentFilterData(defaultFilterData);
    }
  }, [defaultFilterData]);

  useEffect(() => {
    if (clearFilterFieldsList && !currentFilterData) {
      setCurrentFilterData(clearFilterFieldsList);
    }
  }, [clearFilterFieldsList]);

  const onSetFetchParams = useCallback(() => {
    const filterDataList = transformFilterDataObjToFetchData(
      currentFilterData || clearFilterFieldsList,
    );
    setLastRequestFilterDataTime(Date.now());
    setFetchParams(filterDataList);
    setIsNeedUpdateFilter(false);
  }, [currentFilterData, clearFilterFieldsList, setFetchParams]);

  useEffect(() => {
    if (!isNeedUpdateFilter) {
      return;
    }

    setIsNeedUpdateFilter(false);

    const delayRequest = 2000;
    const currentDateTime = Date.now();
    const canSendRequest =
      !lastRequestFilterDataTime ||
      currentDateTime - lastRequestFilterDataTime > delayRequest;

    if (canSendRequest) {
      onSetFetchParams();
      return;
    }

    if (timeoutRequestId) {
      window.clearTimeout(timeoutRequestId);
      setTimeoutRequestId(null);
    }

    if (!canSendRequest && lastRequestFilterDataTime) {
      setTimeoutRequestId(
        window.setTimeout(
          () => {
            onSetFetchParams();
          },
          delayRequest - (currentDateTime - lastRequestFilterDataTime),
        ),
      );
    }
  }, [isNeedUpdateFilter]);

  useEffect(() => {
    if (typeof currentFilterTemplateId !== "number") return;

    const currentFilterTemplate = filterTemplateCollectionList.find(
      (el) => el.id === currentFilterTemplateId,
    );

    if (currentFilterTemplate) {
      setFilterName(currentFilterTemplate.name);
      setCurrentFilterData(JSON.parse(currentFilterTemplate.data));
    }
  }, [currentFilterTemplateId]);

  // handles

  const transformFilterDataObjToFetchData = (
    filterData: FilterDataList,
  ): FetchParamsFilterData => {
    const fetchData: FetchParamsFilterData = {};

    const keys = Object.keys(filterData);

    const getFilterSelectValue = (value: string) => {
      switch (value) {
        case "contains":
          return "contains";
        case "doesn't contain":
          return "notContains";
        case "it`s start":
          return "startsWith";
        case "=":
          return "number";
        case ">":
          return "greaterThan";
        case "<":
          return "lessThan";
        default:
          return "";
      }
    };

    const getFetchDataSelectItemByKey = (
      key: string,
      isNumberValue = false,
    ) => {
      const currentValue = filterData[key];

      if (
        currentValue &&
        typeof currentValue === "object" &&
        currentValue.select && (typeof currentValue.value === 'number' || (typeof currentValue.value === 'string' && currentValue.value))
      ) {
        return {
          [getFilterSelectValue(currentValue.select)]: isNumberValue
            ? parseInt(currentValue.value)
            : currentValue.value,
        };
      }
    };

    const getFetchDataDateBetweenItemByKey = (key: string) => {
      const currentValue = filterData[key];

      if (currentValue && typeof currentValue === "object") {
        const { from, to } = currentValue;

        const checkForUndefined = (date: string | undefined) => {
          if (!date) {
            return undefined;
          }

          return date;
        };

        const dateFormattedFrom: string | undefined = checkForUndefined(from);
        const dateFormattedTo: string | undefined = checkForUndefined(to);

        if (dateFormattedFrom || dateFormattedTo) {
          return {
            between: {
              from: dateFormattedFrom,
              to: dateFormattedTo,
            },
          };
        }

        return undefined;
      }
    };

    const getFetchDataYesOrNoItemByKey = (key: string) => {
      const currentValue = filterData[key];

      switch (currentValue) {
        case "Yes":
          return true;
        case "No":
          return false;
        default:
          return undefined;
      }
    };

    keys.forEach((key) => {
      if (
        key === "hasOrdered" ||
        key === "withoutTax" ||
        key === "withoutTotal" ||
        key === "followUp"
      ) {
        fetchData[key] = getFetchDataYesOrNoItemByKey(key);
      }

      if (
        key === "createdAt" ||
        key === "dateOrderWasPlaced" ||
        key === "dateOrderWasCompleted"
      ) {
        fetchData[key] = getFetchDataDateBetweenItemByKey(key);
      }

      if (
        key === "firstName" ||
        key === "lastName" ||
        key === "company" ||
        key === "fax" ||
        key === "email" ||
        key === "phone1" ||
        key === "quoteQuantity" ||
        key === "quoteValue" ||
        key === "quoteId" ||
        key === "orderId" ||
        key === "status" ||
        key === "paymentStatus" ||
        key === "phone"
      ) {
        fetchData[key] = getFetchDataSelectItemByKey(key);
      }
    });

    return fetchData;
  };
  const getValue = (
    name: string,
    type?: "value" | "from" | "to" | "select",
  ) => {
    const currentValue = currentFilterData?.[name];
    if (!type && !currentValue) {
      return "";
    }

    if (!type && currentValue && typeof currentValue === "string") {
      return currentValue;
    }

    if (type && !currentValue) {
      return "";
    }

    if (type && currentValue && typeof currentValue === "object") {
      return currentValue[type]?.toString() || "";
    }

    return "";
  };

  const setNewValue = (
    name: string,
    value: string,
    type?: "value" | "from" | "to" | "select",
    isNumber?: boolean,
  ) => {
    let currentFilterFieldsValue = {
      ...currentFilterData,
    };

    if (!type) {
      currentFilterFieldsValue[name] = value;
    }

    const getCurrentValue = () => {
      if (isNumber && value && !isNaN(+value)) {
        return +value;
      }

      return value
    }

    if (type && !currentFilterFieldsValue[name]) {
      currentFilterFieldsValue[name] = {
        [type]: getCurrentValue(),
      };
    }

    if (type && currentFilterFieldsValue[name]) {
      const prevVal = currentFilterFieldsValue[name] as FilterDataObj;
      currentFilterFieldsValue[name] = {
        ...prevVal,
        [type]: getCurrentValue(),
      };
    }
    setCurrentFilterData(currentFilterFieldsValue);
  };

  const onUpdateFilterCollectionList = async () => {
    setIsLoadingUpdateFilterCollectionList(true);

    await getFilterTemplateCollectionList().then((res) => {
      const { data } = res;
      const { userFilters } = data;

      setFilterTemplateCollectionList(userFilters || []);
    });
    setIsLoadingUpdateFilterCollectionList(false);
  };

  const onUpdateOrCreateFilter = async () => {
    setIsLoadingCreateAndUpdateFilter(true);
    let newCurrentTemplateId: number | null = null;

    const handleUpdateOrCreateFilter = async () => {
      if (currentFilterTemplateId) {
        await updateFilter();
        return;
      }

      await createFilter().then((res) => {
        const { data } = res;
        const { createFilter } = data;

        if (createFilter.id) {
          newCurrentTemplateId = createFilter.id;
        }
      });
    };

    await handleUpdateOrCreateFilter()
      .then(async () => {
        await onUpdateFilterCollectionList();
      })
      .then(() => {
        if (newCurrentTemplateId) {
          setCurrentFilterTemplateId(newCurrentTemplateId);
        }
      })
      .finally(() => {
        setIsLoadingCreateAndUpdateFilter(false);
      });
  };

  const onResetFilterHandle = () => {
    let currentFilterTemplate: FilterTemplateCollection | undefined = undefined;
    let resetData = clearFilterFieldsList;

    if (currentFilterTemplateId) {
      currentFilterTemplate = filterTemplateCollectionList.find(
        (template) => template.id === currentFilterTemplateId,
      );
    }

    if (currentFilterTemplate && testJSON(currentFilterTemplate.data)) {
      resetData = JSON.parse(currentFilterTemplate.data);
    }

    setCurrentFilterData(resetData);
    setIsNeedUpdateFilter(true);
  };

  const onApplyFilter = useCallback(() => {
    if (currentFilterData) {
      setIsNeedUpdateFilter(true);
    }
  }, [currentFilterData]);

  const onUpdateDefaultFilter = async () => {
    setIsLoadingUpdateDefaultFilter(true);
    await getDefaultFilter()
      .then((res) => {
        const { data } = res;
        const { userDefaultFilter } = data;

        if (!userDefaultFilter || !userDefaultFilter.length) return;

        const defaultFilterValue = userDefaultFilter[0].data;
        if (
          defaultFilterValue &&
          !defaultFilterData &&
          testJSON(defaultFilterValue)
        ) {
          setDefaultFilterData(JSON.parse(defaultFilterValue));
        }
      })
      .finally(() => {
        setIsLoadingUpdateDefaultFilter(false);
      });
  };

  useEffect(() => {
    const currentFilter = { filter: currentFilterData };
    const isNotNullableAndNotEqual =
      !!currentFilterData &&
      JSON.stringify(prevFilter) === JSON.stringify(currentFilter);

    onChangeParamsCallback(
      prevFilter,
      currentFilter,
      (newFilter) => {
        setPrevFilter(newFilter);
      },
      () => {
        if (isNotNullableAndNotEqual) {
          onApplyFilter();
        }
      },
    );
  }, [prevFilter, currentFilterData]);

  return (
    <div className="filter-container">
      <div className="filter-heading">
        <div className="filter-heading__management-state-box">
          <div className="filter-heading__title"> Filters</div>
          <button
            onClick={() => setShowFilter(!showFilter)}
            className={`filter-heading__toggle-opened-state-btn filter-heading__toggle-opened-state-btn_${
              showFilter ? "rotate-0" : "rotate-180"
            }`}
          >
            <i className={`icon-up-open`} />
          </button>
        </div>
        <div className="filter-heading__management-filter">
          <div className="filter-heading__management-filter-input">
            <Input
              value={filterName}
              onChange={(value) => {
                if (typeof value === "string") {
                  setFilterName(value);
                }

                if (!value) {
                  setCurrentFilterTemplateId(null);
                }
              }}
              placeholder="Name"
              name="filter-name"
              disabled={
                isLoadingCreateAndUpdateFilter ||
                isLoadingUpdateFilterCollectionList
              }
            />
          </div>
          <div className="filter-heading__management-filter-actions">
            <button
              className="btn btn-mini btn-success"
              disabled={
                isLoadingCreateAndUpdateFilter ||
                isLoadingUpdateFilterCollectionList ||
                (typeof currentFilterTemplateId !== "number" &&
                  filterTemplateCollectionList.some(
                    (el) => el.name === filterName,
                  )) ||
                !filterName
              }
              onClick={() => onUpdateOrCreateFilter()}
            >{`${
              typeof currentFilterTemplateId === "number" ? "Update" : "Save"
            } Filter`}</button>
            <Menu as={"div"} className="relative ">
              <Menu.Button
                className="btn btn-mini btn-yellow"
                disabled={isLoadingCreateAndUpdateFilter}
              >
                Choose Filter
                <i className="icon-angle-down" />
              </Menu.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                className={`absolute top-[120%] sm:top-[115%] md:top-[125%] rounded max-h-[200px] right-0 z-[999] after:z-[999] bg-white flex flex-col shadow-[0px_2px_4px_#cccccc]  py-[5px] after:content-[''] after:absolute  after:-top-[6px] md:after:-top-[8px] after:right-[10px] sm:after:right-[10px] md:after:right-[4px] after:inline-block after:[border-right:6px_solid_transparent] after:[border-bottom:6px_solid_#ffffff] after:[border-left:6px_solid_transparent] md:after:[border-right:12px_solid_transparent] md:after:[border-bottom:12px_solid_#ffffff] md:after:[border-left:12px_solid_transparent]`}
              >
                <Menu.Items
                  as="ul"
                  className="overflow-y-auto overflow-x-hidden"
                >
                  {isLoadingUpdateFilterCollectionList ? (
                    <Menu.Item
                      as="li"
                      className="bg-white w-[120px] px-5 text-[13px] leading-5 text-[#545454]"
                    >
                      Loading...
                    </Menu.Item>
                  ) : filterTemplateCollectionList?.length > 0 ? (
                    filterTemplateCollectionList?.map((filterList, index) => (
                      <Menu.Item
                        key={`filter-template-collection-${filterList.id}-${index}`}
                        as="li"
                      >
                        {({ active }) => (
                          <span
                            className={`cursor-pointer w-full block text-[13px] leading-5  px-2 py-[2px] text-[#545454] ${
                              active
                                ? "bg-[#fbe088]"
                                : "bg-white hover:bg-[#fbe088]"
                            } w-[120px] truncate`}
                            onClick={() => {
                              setCurrentFilterTemplateId(filterList.id);
                            }}
                          >
                            {filterList.name}
                          </span>
                        )}
                      </Menu.Item>
                    ))
                  ) : (
                    <Menu.Item
                      as="li"
                      className="bg-white min-w-[120px] text-[13px] leading-5 text-[#545454] px-5"
                    >
                      Not found.
                    </Menu.Item>
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      {showFilter && (
        <div className="filter-container__content">
          <div className="filter-form-content row-fluid">
            {filterFields.map((fieldListItem: any, index: number) => {
              return (
                <div
                  className="filter-form-content__form box-5"
                  key={`filter-form-options-${index}`}
                >
                  {fieldListItem.map((fieldItem: any, index: number) => {
                    return (
                      <div
                        className="filter-form-content__field-wrapper"
                        key={`filter-form-${fieldItem.type}-${fieldItem.name}`}
                      >
                        {fieldItem.type === "input" && (
                          <Input
                            value={getValue(fieldItem.name, "value")}
                            onChange={(value) => {
                              if (typeof value === "string") {
                                setNewValue(
                                  fieldItem.name,
                                  value,
                                  "value",
                                  fieldItem?.numberValue,
                                );
                              }
                            }}
                            name={`${fieldItem.name}.value`}
                            label={fieldItem.label}
                            inputWidth={
                              fieldItem.inputOptions?.isLargeInput
                                ? "220px"
                                : "164px"
                            }
                            labelWidth={labelWidth}
                            placeholder={fieldItem.label || ""}
                            pattern={fieldItem?.numberValue ? regExpNumber : undefined}
                          >
                            <SelectCombobox
                              name={`${fieldItem.name}.select`}
                              value={getValue(fieldItem.name, "select")}
                              onChange={(value) => {
                                setNewValue(fieldItem.name, value, "select");
                              }}
                              selectOptions={fieldItem.selectOptions.options}
                            />
                          </Input>
                        )}
                        {fieldItem.type === "dateBetween" && (
                          <Calendar
                            name={fieldItem.name}
                            startDate={getValue(fieldItem.name, "from") ||  null
                            }
                            endDate={getValue(fieldItem.name, "to") ||  null
                            }
                            onStartDateChange={(value) => {
                              setNewValue(
                                fieldItem.name,
                                value ? value.toString() : "",
                                "from",
                              );
                            }}
                            onEndDateChange={(value) => {
                              setNewValue(
                                fieldItem.name,
                                value ? value.toString() : "",
                                "to",
                              );
                            }}
                            type="range"
                            labelWidth={labelWidth}
                            label={fieldItem.label}
                            inputWidth="156px"
                          />
                        )}
                        {fieldItem.type === "yesOrNo" && (
                          <YesOrNoBox
                            value={getValue(fieldItem.name)}
                            onChange={(value) => {
                              setNewValue(fieldItem.name, value);
                            }}
                            label={fieldItem.label}
                            labelWidth={labelWidth}
                          />
                        )}
                        {fieldItem.type === "multipleSelect" && (
                          <Select
                            value={getValue(fieldItem.name, "value")}
                            optionsGroups={
                              fieldItem.selectOptions.optionsForValue
                            }
                            onChange={(value) => {
                              if (value !== undefined) {
                                setNewValue(
                                  fieldItem.name,
                                  value.toString(),
                                  "value",
                                  fieldItem?.numberValue,
                                );
                              }
                            }}
                            name={`${fieldItem.name}.value`}
                            multiple
                            label={fieldItem.label}
                            labelWidth={labelWidth}
                          >
                            <SelectCombobox
                              name={`${fieldItem.name}.select`}
                              value={getValue(fieldItem.name, "select")}
                              onChange={(value) => {
                                setNewValue(fieldItem.name, value, "select");
                              }}
                              selectOptions={fieldItem.selectOptions.options}
                            />
                          </Select>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="filter-form-button">
            <button
              onClick={() => {
                onApplyFilter();
              }}
              id="PoolListApplyButton"
              type="submit"
              className="btn btn-small btn-success"
            >
              Apply
            </button>
            <button
              onClick={() => {
                onResetFilterHandle();
              }}
              className="btn btn-small btn-warning"
            >
              Clear
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
