import { useReactiveVar } from "@apollo/client";
import { userContextData } from "helpers/cache";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ClientPoolList from "./Pages/ClientPoolList/ClientPoolList";
import ListClients from "./Pages/ListClients/ListClients";
import EditClientNew from "./Pages/EditClient/EditClient";
import { useEffect, useMemo } from "react";

const Clients = () => {
  const userDetails: any = useReactiveVar(userContextData);
  const clientPrivileges =
    userDetails?.user?.accessControlStructure?.modules?.client?.tabs;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  let clientId = useMemo(() => pathname.split("/")[2], [pathname]);
  const pathList = useMemo(
    () => ["list_clients", "clients_pool_list", "new_client"],
    [],
  );
  useEffect(() => {
    if (
      clientId !== undefined &&
      isNaN(+clientId) &&
      !pathList.includes(clientId)
    ) {
      navigate("/clients/list_clients");
    }
  }, [clientId]);

  return (
    <div className="w-full">
      <Routes>
        {clientPrivileges?.listClient && (
          <Route path="list_clients" element={<ListClients />} />
        )}
        {clientPrivileges?.poolList && (
          <Route path="clients_pool_list" element={<ClientPoolList />} />
        )}
        {clientPrivileges?.newClient && (
          <Route path="new_client/*" element={<EditClientNew />} />
        )}
        {clientPrivileges?.editClient && (
          <Route
            path={`${pathname.split("/")[2]}/*`}
            element={<EditClientNew clientId={+clientId} />}
          />
        )}
        <Route
          path="/*"
          element={
            <Navigate
              to={
                clientPrivileges?.listClient
                  ? "/clients/list_clients"
                  : "/quotes/history"
              }
              replace
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Clients;
