import { FC } from "react";

const RenderItem: FC<{
  item: any;
  handler: any;
  showEditModal: boolean;
  setShowEditModal: Function;
  showDeleteModal: boolean;
  setShowDeleteModal: Function;
  setEditProduct: Function;
  hasChildren: boolean;
}> = ({
  handler,
  item,
  setShowEditModal,
  showEditModal,
  showDeleteModal,
  setShowDeleteModal,
  setEditProduct,
  hasChildren,
}) => {
  return (
    <div className="flex justify-between items-center bg-[#EFEFEF] mt-1 px-2 py-1 hover:bg-[#FEE] w-full">
      <div className="flex space-x-2 items-center">
        <div className="flex-shrink-0">{handler}</div>
        <p className="text-base leading-5 text-[#393939] font-Open-sans font-normal">
          {item?.name}
        </p>
      </div>
      <div className="flex gap-2 items-center">
        <span
          title="Edit"
          className="cursor-pointer w-5 h-5 bg-[url('./assets/edit-blue-image.png')] hover:bg-[url('./assets/edit-red-image.png')] bg-no-repeat bg-center"
          onClick={() => {
            setShowEditModal(!showEditModal);
            setEditProduct({ id: item?.id, name: item?.name });
          }}
        />
        <span
          title={hasChildren ? "Can't delete this record" : "Delete"}
          className={`${
            hasChildren
              ? "bg-[url('./assets/delete-disable.png')]"
              : "bg-[url('./assets/delete-blue-image.png')] hover:bg-[url('./assets/delete-red-image.png')] cursor-pointer"
          }  w-5 h-5  bg-no-repeat bg-center`}
          onClick={() => {
            if (!hasChildren) {
              setShowDeleteModal(!showDeleteModal);
              setEditProduct({ id: item?.id, name: item?.name });
            }
          }}
        />
      </div>
    </div>
  );
};

export default RenderItem;
