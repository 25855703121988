import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { SearchBoxValue } from "../../../types/Form";

const SearchBox: FC<{
  value: number | string;
  onChange: (value: number | string) => void;
  isIncorrectValue?: boolean;
  labelWidth?: string;
  inputWidth?: string;
  loading?: boolean;
  options: SearchBoxValue[];
  label?: string;
  selectedValues?: SearchBoxValue[];
  hiddenButton?: boolean;
  className?: string;
  width?: string;
  defaultDisplayValue?: string;
}> = ({
  value,
  onChange,
  isIncorrectValue,
  labelWidth,
  inputWidth,
  label,
  options,
  loading,
  selectedValues,
  hiddenButton,
  className,
  width,
  defaultDisplayValue,
}) => {
  const [filteredOptionList, setFilteredOptionList] = useState<
    SearchBoxValue[]
  >([]);
  const [query, setQuery] = useState<string>("");
  const [displayedValue, setDisplayedValue] = useState<string>("");

  useEffect(() => {
    setFilteredOptionList(
      options.filter((option) => {
        if (query) {
          return option.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""));
        }
        return options;
      }),
    );
  }, [options, query]);

  const onBeforeLeaveOptionsListHandler = useCallback(() => {
    if (filteredOptionList.length === 1) {
      onChange(filteredOptionList[0].id);
      return;
    }

    let currentDisplayedValue: SearchBoxValue | undefined = undefined;

    if (displayedValue) {
      currentDisplayedValue = options.find(
        (option) => option.name === displayedValue,
      );
    }

    if (currentDisplayedValue) {
      onChange(currentDisplayedValue.id);
    }

    if (!query) {
      onChange("");
    }
  }, [filteredOptionList, onChange, displayedValue]);

  const valueObj = useMemo(() => {
    if (!loading) {
      return options.find((option) => option.id === value);
    }
  }, [value, loading, options]);

  useEffect(() => {
    if (valueObj) {
      setDisplayedValue(valueObj.name);
      return;
    }
  }, [valueObj]);

  useEffect(() => {
    if (defaultDisplayValue && !displayedValue) {
      setDisplayedValue(defaultDisplayValue);
    }
  }, [valueObj, options, defaultDisplayValue]);

  const onBeforeEnterHandler = useCallback(() => {
    if (!!filteredOptionList.length) {
      value = filteredOptionList[0].id
    }
  }, [filteredOptionList])

  return (
    <div
      className={`search-box ${className || ""} ${
        label ? "search-box_with-label" : ""
      }`}
    >
      {label && (
        <div
          className="search-box__label"
          style={{ width: `${labelWidth || "max-content"}` }}
        >
          {label}{" "}
          {isIncorrectValue && <i className="icon-cancel-circled red" />}
        </div>
      )}
      <div style={{ width: `${inputWidth || "unset"}` }}>
        <Combobox
          value={value}
          onChange={(newCurrentValue) => onChange(newCurrentValue)}
        >
          {!!selectedValues && !!selectedValues.length && (
            <div className="search-box__selected-list">
              {" "}
              {selectedValues.map((selectedItem, index) => (
                <div
                  key={`selected-item=search-box-${selectedItem.id}-${index}`}
                  className="search-box__selected-item"
                >
                  <span className="label">{selectedItem.name}</span>
                  <button
                    onClick={() => {
                      onChange(selectedItem.id);
                    }}
                  >
                    <i className="icon-cancel" />
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="search-box__wrapper">
            <Combobox.Input
              onChange={(event) => {
                const value = event.currentTarget.value;
                setQuery(value);
              }}
              displayValue={() => valueObj?.name || query || ""}
              className={`search-box__input ${
                isIncorrectValue ? "search-box__input_error" : ""
              }`}
              placeholder={displayedValue || "Search..."}
            />
            {!hiddenButton && (
              <Combobox.Button>
                <div className={`btn btn-small btn-blue search-box__button`}>
                  <i className="icon-angle-down" />
                </div>
              </Combobox.Button>
            )}

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeLeave={onBeforeLeaveOptionsListHandler}
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options
                static
                className="search-box__options"
                style={{ width: width ? width : "100%" }}
              >
                {loading && (
                  <Combobox.Option
                    disabled
                    value="loading"
                    className="search-box__option"
                  >
                    Loading...
                  </Combobox.Option>
                )}
                {!loading && !filteredOptionList.length && query && (
                  <Combobox.Option
                    disabled
                    value="no-data"
                    className="search-box__option"
                  >
                    Nothing found.
                  </Combobox.Option>
                )}
                {!loading &&
                  filteredOptionList.map((option) => (
                    <Combobox.Option
                      key={option.id}
                      onMouseEnter={() => {
                        setDisplayedValue(option.name);
                      }}
                      value={option.id}
                    >
                      {({ active, selected }) => (
                          <div className={`search-box__option font-bold ${active ? 'search-box__option_active' : ''}`}>{option.name}</div>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
};

export default SearchBox;
