import React, { FC, ReactNode, useCallback } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormatter } from "../../../helpers/utils";

const Calendar: FC<{
  labelWidth?: string;
  inputWidth?: string;
  label?: string | ReactNode;
  name: string;
  onStartDateChange: (value: string | null) => void;
  onEndDateChange?: (value: string | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  classNameForInput?: string;
  isIncorrectValue?: boolean;
  disabled?: boolean;
  startDate?: string | null;
  endDate?: string | null;
  type: "default" | "range";
}> = ({
  labelWidth,
  label,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  classNameForInput,
  disabled,
  isIncorrectValue,
  className,
  name,
  inputWidth,
  type = "default",
}) => {
  const getDateFromString = useCallback((date?: string|null) => {
    if (!date) {
      return null
    }
    //set time and timezone offset
    const currentDate = new Date();
    const newDate = new Date(Date.parse(date) + currentDate.getTimezoneOffset() *  60 * 1000);

    newDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds())

    return newDate
  }, [])

  const formatDate = useCallback((date: Date | null) => {
    let formatValue = "";

    if (date) {
      const currentDate = new Date();
      //set current date time
      date.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds())

      formatValue = dateFormatter(date); //mins -> ms
    }

    if (formatValue.includes("undefined")) {
      formatValue = "";
    }

    return formatValue || null;
  }, []);
  return (
    <div className={`calendar-box calendar-box_${type} ${className || ""}`}>
      {label && (
        <div
          className="calendar-box__label"
          style={{ maxWidth: `${labelWidth || "max-content"}` }}
        >
          {label}
          {isIncorrectValue && <i className="icon-cancel-circled red" />}
        </div>
      )}
      <div
        className="calendar-box__input-list"
        style={{ maxWidth: `${inputWidth || "unset"}` }}
      >
        <label
          className="calendar-box__input-wrapper"
          htmlFor={`${name}-start`}
        >
          <i className="icon-calendar" />
          <ReactDatePicker
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            autoComplete="off"
            className={`calendar-box__input ${classNameForInput || ""}`}
            onChange={(value) => {
              const formattedValue = formatDate(value);

              onStartDateChange(formattedValue);
            }}
            selected={getDateFromString(startDate)}
            name={`${name}-start`}
            id={`${name}-start`}
            disabled={disabled}
          />
        </label>

        {type === "range" && (
          <label
            className="calendar-box__input-wrapper"
            htmlFor={`${name}-end`}
          >
            <i className="icon-calendar" />
            <ReactDatePicker
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              autoComplete="off"
              className={`calendar-box__input ${classNameForInput || ""}`}
              onChange={(value) => {
                if (onEndDateChange) {
                  const formattedValue = formatDate(value);
                  onEndDateChange(formattedValue);
                }
              }}
              selected={getDateFromString(endDate)}
              name={`${name}-end`}
              id={`${name}-end`}
              disabled={disabled}
            />
          </label>
        )}
      </div>
    </div>
  );
};

export default Calendar;
