import { FC } from "react";
import QuoteFileIcon from "helpers/QuoteFileIcon";

const AttachFile: FC<{
  title: string;
  url?: string;
  isChecked: boolean;
  onToggle: () => unknown;
  isDisabled?: boolean;
}> = ({ url, title, isChecked, onToggle, isDisabled }) => (
  <label
    key={title}
    className={`
      cursor-pointer text-[13px] leading-5 text-[#393939] grid gap-4 w-[150px] overflow-hidden border-[1px] py-4 border-[#dddddd]
      ${isChecked ? "bg-[#f5f5f5]" : ""}
      ${isDisabled ? "opacity-50" : ""}
    `}
  >
    <div className="text-center w-[150px] overflow-hidden">{title}</div>
    <div className="w-[70px] h-[70px] flex mx-auto justify-center items-center">
      <QuoteFileIcon imageName={title} imageURL={url} />
    </div>
    <div className="flex gap-2 justify-center">
      <input
        type="checkbox"
        className="hidden"
        checked={isChecked}
        onChange={onToggle}
        name={`attached-file-${url}`}
      />
      <span className="w-[16px] h-[16px] flex justify-center items-center bg-white cursor-pointer border border-[#ccc] hover:border-[#F59942]">
        <i
          className={`icon-ok text-xs text-[#32A3CE] ${isChecked ? "" : "hidden"}`}
        ></i>
      </span>
    </div>
  </label>
);

export default AttachFile;
