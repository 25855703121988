import { FC, Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { useQuery } from "@apollo/client";
import { STATES_QUERY } from "../../../modules/Services/Queries/Common/CommonQueries";
import { State } from "../../../types/State";

const StateBox: FC<{
  value: string;
  onChange: (value: string) => void;
  isIncorrectValue?: boolean;
  labelWidth?: string;
  inputWidth?: string;
  disabled?: boolean;
  onChangeTax?: (value: null | number) => void;
}> = ({
  value,
  onChange,
  isIncorrectValue,
  labelWidth,
  inputWidth,
  disabled,
  onChangeTax,
}) => {
  const { data: client, loading: clientLoading } = useQuery(STATES_QUERY, {
    notifyOnNetworkStatusChange: true,
  });
  const [stateList, setStateList] = useState<State[]>([]);
  const [filteredStateList, setFilteredStateList] = useState<State[]>([]);
  const [query, setQuery] = useState<string>("");
  const [displayedValue, setDisplayedValue] = useState<string>("");

  useEffect(() => {
    if (client) {
      setStateList(client.states);
    }
  }, [client]);

  useEffect(() => {
    setFilteredStateList(
      stateList.filter((state) => {
        if (query) {
          return state.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""));
        }
        return stateList;
      }),
    );
  }, [stateList, query]);

  const onBeforeLeaveOptionsListHandler = () => {
    if (query && !filteredStateList.length) {
      onChange(query);
    }

    if (filteredStateList.length === 1) {
      onChange(filteredStateList[0].name);
      return;
    }

    if (displayedValue) {
      onChange(displayedValue);
    }

    if (!query) {
      onChange("");
    }
  };

  useEffect(() => {
    if (clientLoading || (value && !stateList.length)) return;

    const selectedValue = stateList.find((state) => state.name === value);

    if (onChangeTax) {
      onChangeTax(selectedValue?.tax || null);
    }
  }, [value, stateList, clientLoading]);

  return (
    <div className="state-box">
      <div
        className="state-box__label"
        style={{ width: `${labelWidth || "max-content"}` }}
      >
        State {isIncorrectValue && <i className="icon-cancel-circled red" />}
      </div>
      <div style={{ width: `${inputWidth || "unset"}` }}>
        <Combobox
          value={value}
          onChange={(stateName) => onChange(stateName)}
          disabled={disabled}
        >
          <div className="state-box__wrapper">
            <Combobox.Input
              onChange={(event) => {
                const value = event.currentTarget.value;
                setQuery(value);
              }}
              displayValue={() => displayedValue || value || query || ""}
              className={`state-box__input ${
                isIncorrectValue ? "state-box__input_error" : ""
              }`}
            />
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeLeave={onBeforeLeaveOptionsListHandler}
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options static className="state-box__options">
                {clientLoading && (
                  <Combobox.Option
                    disabled
                    value="loading"
                    className="state-box__option"
                  >
                    Loading
                  </Combobox.Option>
                )}
                {!clientLoading && !filteredStateList.length && query && (
                  <Combobox.Option
                    disabled
                    value="no-data"
                    className="state-box__option"
                  >
                    Nothing found.
                  </Combobox.Option>
                )}
                {!clientLoading &&
                  filteredStateList.map((state) => (
                    <Combobox.Option
                      key={state.id}
                      onMouseEnter={() => {
                        setDisplayedValue(state.name);
                      }}
                      value={state.name}
                      className="state-box__option"
                    >
                      <span className="font-bold">{state.name}</span>
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
};

export default StateBox;
