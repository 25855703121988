import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../../components/Form/El/Input";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_USER_EMAIL_DATA,
  UPDATE_USER_EMAIL_DATA,
} from "../../Services/Mutations/Mutaions";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { setNotification } from "../../../../helpers/cache";
import { USERS_COLLECTION_QUERY } from "../../Services/Queries/Queries";
import SearchBox from "../../../../components/Form/El/SearchBox";
import { User } from "../../../../types/User";
import EditOrCreateUserModal from "./Modal/EditOrCreateUserModal";
import { regExpNumber } from "../../../../helpers/RegExp";
import { isValidInteger } from "../../../../helpers/Validation";
import { getIntegerNumberFromString } from "../../../../helpers/utils";

const AdminDashboardEditEmailData: FC<{
  currentId?: number;
  isNeedSave: boolean;
  afterSave: (id: number) => void;
  labelWidth: string;
  inputWidth: string;
  currentDataCollectionItem?: DataCollectionItem;
  setIsSaveLoading: (state: boolean) => void;
  setDisabledSaveButton: (state: boolean) => void;
  onErrorSave: (error: ApolloError) => void;
}> = ({
  labelWidth,
  currentDataCollectionItem,
  inputWidth,
  isNeedSave,
  currentId,
  afterSave,
  setIsSaveLoading,
  setDisabledSaveButton,
  onErrorSave,
}) => {
  const clearForm = useCallback(() => {
    setCurrentDomain("");
    setCurrentUserName("");
    setCurrentAddress("");
    setCurrentUser(undefined);
    setCurrentPort("");
    setCurrentPassword("");
    setCurrentAuth("");
    setCurrentFrom("");
    setCurrentLastIud(null);
  }, []);

  const onAfterSave = useCallback((id: number) => {
    clearForm();
    afterSave(id || 0);
  }, []);

  const [updateItems] = useMutation(UPDATE_USER_EMAIL_DATA, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: (data) => {
      const { updateUserEmailData } = data;

      if (!updateUserEmailData) return;

      const { id } = updateUserEmailData;

      onAfterSave(id || 0);
    },
  });
  const [createItems] = useMutation(CREATE_USER_EMAIL_DATA, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { createUserEmailData } = data;

      if (!createUserEmailData) return;

      const { id } = createUserEmailData;

      onAfterSave(id || 0);
    },
  });
  const [getUsers] = useLazyQuery(USERS_COLLECTION_QUERY, {
    onError: (error) => {
      onErrorSave(error);
    },
    onCompleted: (data) => {
      const { users } = data;

      if (!users) return;

      setUsersList(users);
    },
  });
  const [currentDomain, setCurrentDomain] = useState("");
  const [currentUserName, setCurrentUserName] = useState("");
  const [currentPort, setCurrentPort] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [currentAuth, setCurrentAuth] = useState("");
  const [currentFrom, setCurrentFrom] = useState("");
  const [currentLastUid, setCurrentLastIud] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<number | undefined>();
  const [usersList, setUsersList] = useState<User[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isShowAddEditUserModal, setIsShowAddEditUserModal] = useState("");

  const isValidLastUid = useMemo(() => {
    return currentLastUid === null || isValidInteger(currentLastUid);
  }, [currentLastUid]);

  useEffect(() => {
    if (!currentDataCollectionItem) return;

    const {
      domain,
      user,
      userName,
      address,
      password,
      port,
      authentication,
      sender,
      lastUid,
    } = currentDataCollectionItem;

    if (user) {
      setCurrentUser(user.id);
    }

    if (domain) {
      setCurrentDomain(domain);
    }

    if (userName) {
      setCurrentUserName(userName);
    }

    if (address) {
      setCurrentAddress(address);
    }

    if (password) {
      setCurrentPassword(password);
    }

    if (port) {
      setCurrentPort(port);
    }

    if (authentication) {
      setCurrentAuth(authentication);
    }

    if (sender) {
      setCurrentFrom(sender);
    }

    if (typeof lastUid === "number") {
      setCurrentLastIud(lastUid.toString());
    }
  }, [currentDataCollectionItem]);

  const onGetUsers = useCallback(async () => {
    setIsLoadingUsers(true);

    await getUsers().finally(() => {
      setIsLoadingUsers(false);
    });
  }, []);

  useEffect(() => {
    onGetUsers();
  }, []);

  const usersForSelect = useMemo(() => {
    const newUserListForSelect = usersList.map((userItem) => {
      const userInUserList = usersList.find((user) => user.id === userItem.id);

      return {
        id: userInUserList?.id || 0,
        name: userInUserList?.userName || "",
      };
    });

    return newUserListForSelect;
  }, [usersList, currentUser]);

  const onSave = useCallback(async () => {
    setIsSaveLoading(true);
    const handleSaveById = async () => {
      if (currentId) {
        return await updateItems({
          variables: {
            id: currentId,
            address: currentAddress,
            authentication: currentAuth,
            domain: currentDomain,
            lastUid: getIntegerNumberFromString(currentLastUid),
            password: currentPassword,
            port: currentPort,
            sender: currentFrom,
            userId: currentUser,
            userName: currentUserName,
          },
        });
      }
      return await createItems({
        variables: {
          id: currentId,
          address: currentAddress,
          authentication: currentAuth,
          domain: currentDomain,
          lastUid: getIntegerNumberFromString(currentLastUid),
          password: currentPassword,
          port: currentPort,
          sender: currentFrom,
          userId: currentUser,
          userName: currentUserName,
        },
      });
    };

    await handleSaveById();
  }, [
    currentUserName,
    currentId,
    currentUser,
    currentFrom,
    currentPort,
    currentPassword,
    currentLastUid,
    currentDomain,
    currentAuth,
    currentAddress,
  ]);

  useEffect(() => {
    if (isNeedSave) {
      onSave();
    }
  }, [isNeedSave]);

  useEffect(() => {
    const isValidForm =
      !!currentUser &&
      !!currentDomain &&
      !!currentUserName &&
      !!currentPassword &&
      !!currentAddress &&
      !!currentPort &&
      !!currentFrom &&
      !!currentAuth &&
      isValidLastUid;
    setDisabledSaveButton(!isValidForm);
  }, [
    currentUserName,
    currentUser,
    currentFrom,
    currentPort,
    currentPassword,
    currentDomain,
    currentAuth,
    currentAddress,
    isValidLastUid,
  ]);

  return (
    <Fragment>
      <div className="btn-group flex justify-end mb-1">
        <button
          className="btn btn-small btn-blue"
          onClick={() => {
            setIsShowAddEditUserModal("new");
          }}
        >
          <i className="icon-plus pr-1" /> Add New User
        </button>
        <button
          className="btn btn-small btn-blue"
          onClick={() => {
            setIsShowAddEditUserModal("edit");
          }}
          disabled={!currentUser}
        >
          <i className="icon-pencil pr-1" /> Edit Current User
        </button>
      </div>
      <SearchBox
        value={currentUser || ""}
        onChange={(value) => {
          if (typeof value === "number") {
            setCurrentUser(value);
          }
        }}
        options={usersForSelect}
        loading={isLoadingUsers}
        label="User"
        labelWidth={labelWidth}
        inputWidth={inputWidth}
        isIncorrectValue={!currentUser}
      />
      <Input
        value={currentDomain}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentDomain(value.trim());
          }
        }}
        name="user-email-data-domain"
        labelWidth={labelWidth}
        label="Color"
        placeholder="Color"
        inputWidth={inputWidth}
        isIncorrectValue={!currentDomain}
      />
      <Input
        value={currentUserName}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentUserName(value.trim());
          }
        }}
        name="user-email-data-user-name"
        labelWidth={labelWidth}
        label="User name"
        placeholder="User name"
        inputWidth={inputWidth}
        isIncorrectValue={!currentUserName}
      />
      <Input
        value={currentPassword}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentPassword(value.trim());
          }
        }}
        name="user-email-data-password"
        labelWidth={labelWidth}
        label="Password"
        placeholder="Password"
        inputWidth={inputWidth}
        isIncorrectValue={!currentPassword}
      />
      <Input
        value={currentAddress}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentAddress(value.trim());
          }
        }}
        name="user-email-data-address"
        labelWidth={labelWidth}
        label="Address"
        placeholder="Address"
        inputWidth={inputWidth}
        isIncorrectValue={!currentAddress}
      />
      <Input
        value={currentPort}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentPort(value.trim());
          }
        }}
        name="user-email-data-port"
        labelWidth={labelWidth}
        label="Port"
        placeholder="Port"
        inputWidth={inputWidth}
        isIncorrectValue={!currentPort}
      />
      <Input
        value={currentAuth}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentAuth(value.trim());
          }
        }}
        name="user-email-data-auth"
        labelWidth={labelWidth}
        label="Authentication"
        placeholder="Authentication"
        inputWidth={inputWidth}
        isIncorrectValue={!currentAuth}
      />
      <Input
        value={currentFrom}
        onChange={(value) => {
          if (typeof value === "string") {
            setCurrentFrom(value.trim());
          }
        }}
        name="user-email-data-sender"
        labelWidth={labelWidth}
        label="From"
        placeholder="From"
        inputWidth={inputWidth}
        isIncorrectValue={!currentFrom}
      />
      <Input
        value={
          currentLastUid !== null &&
          typeof +currentLastUid === "number" &&
          !isNaN(+currentLastUid)
            ? currentLastUid
            : ""
        }
        onChange={(value) => {
          const stringValue = value.toString();
          setCurrentLastIud(stringValue ? stringValue : null);
        }}
        name="user-email-data-uid"
        labelWidth={labelWidth}
        label="Last uid"
        placeholder="Last uid"
        inputWidth={inputWidth}
        type={"number"}
        pattern={regExpNumber}
        isIncorrectValue={!isValidLastUid}
      />
      {!!isShowAddEditUserModal && (
        <EditOrCreateUserModal
          closeModal={() => {
            setIsShowAddEditUserModal("");
          }}
          afterSave={() => {
            onGetUsers();
          }}
          currentId={
            isShowAddEditUserModal === "edit" ? currentUser : undefined
          }
        />
      )}
    </Fragment>
  );
};

export default AdminDashboardEditEmailData;
