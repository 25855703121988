import { useReactiveVar } from "@apollo/client";
import { userContextData } from "helpers/cache";
import { FC, Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuListType } from "./Menu";

const MenuDetails: FC<{
  item: MenuListType;
  setActiveMenu: Function;
  activeMenu: number | undefined;
  index: number;
  showIcon: boolean;
  title: string;
  themeColor: string;
  setAdminDashBoardSideBarClicked: Function;
}> = ({
  item,
  activeMenu,
  setActiveMenu,
  index,
  showIcon,
  title,
  themeColor,
  setAdminDashBoardSideBarClicked,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (activeMenu !== index) {
      setShow(false);
    }
  }, [activeMenu, index]);

  let iconColorForPinkTheme: any;
  let textColorForPinkTheme: any;
  let mainHeadingHover: any;
  let submenuBackground: any;
  if (item?.name === "Clients") {
    iconColorForPinkTheme = "text-[#00BE67]";
    textColorForPinkTheme = "group-hover:text-[#00BE67] font-semibold";
    mainHeadingHover = "hover:bg-[#00BE67]";
    submenuBackground = "before:bg-[#00BE671F]";
  } else if (item?.name === "Quotes") {
    iconColorForPinkTheme = "text-[#5ED02B]";
    textColorForPinkTheme = "group-hover:text-[#5ED02B] font-semibold";
    mainHeadingHover = "hover:bg-[#5ED02B]";
    submenuBackground = "before:bg-[#5ED02B1F]";
  } else if (item?.name === "Product") {
    iconColorForPinkTheme = "text-[#A7D700]";
    textColorForPinkTheme = "group-hover:text-[#A7D700] font-semibold";
    mainHeadingHover = "hover:bg-[#A7D700]";
    submenuBackground = "before:bg-[#A7D7001F]";
  } else if (item?.name === "Admin") {
    iconColorForPinkTheme = "text-[#FFAB00]";
    textColorForPinkTheme = "group-hover:text-[#FFAB00] font-semibold";
    mainHeadingHover = "hover:bg-[#FFAB00]";
    submenuBackground = "before:bg-[#FFAB001F]";
  }
  const userDetails: any = useReactiveVar(userContextData);
  const accessControlStructure =
    userDetails?.user?.accessControlStructure?.modules;

  return (
    <Fragment>
      {accessControlStructure &&
        Object.entries(accessControlStructure)
          ?.map(([key]) => {
            return item?.aclName.toLowerCase() === key.toLowerCase();
          })
          .includes(true) && (
          <div
            className={`${themeColor} relative border-b group border-skin-sideBarMainHeaderBorder transition-colors duration-1000 ${
              themeColor !== "pink"
                ? "hover:bg-skin-sidebar-main-heading-hover"
                : mainHeadingHover
            }  ${
              title === item.name
                ? `${
                    themeColor !== "pink"
                      ? "bg-skin-sidebar-main-heading-hover"
                      : ""
                  }`
                : ""
            }
        } ${showIcon ? "w-[189px]" : ""}`}
          >
            <div
              onClick={() => {
                setActiveMenu(index);
                setShow(!show);
              }}
              className={`flex items-center relative ${
                themeColor === "blue" || themeColor === "black"
                  ? `before:content-[""] before:absolute before:w-[3px] h-full before:top-0 before:bottom-0  ${
                      themeColor === "black"
                        ? "before:bg-[#222A2D]"
                        : "before:bg-[#F9F9F9]"
                    } hover:before:bg-[#3382AF]`
                  : ""
              } ${
                themeColor === "default" && showIcon
                  ? "before:content-[''] before:absolute before:w-[3px] h-full before:top-0 before:bottom-0 before:bg-[#444]"
                  : ""
              }`}
            >
              <i
                className={`${item.iconName} ${themeColor} ${
                  themeColor === "pink"
                    ? title === item.name
                      ? "text-skin-side-icon-hover group-hover:text-white"
                      : "group-hover:text-white "
                    : "group-hover:text-skin-sidebar-heading-hover"
                } cursor-pointer ${
                  themeColor === "pink"
                    ? `${iconColorForPinkTheme}`
                    : "text-skin-sideBarMainHeadingTextColor"
                }  ${
                  title === item.name || show
                    ? themeColor !== "pink"
                      ? "text-skin-sideBarMainHeadingTextColorActive"
                      : `${iconColorForPinkTheme}`
                    : ""
                } text-lg group-hover:text-skin-sidebar-heading-hover min-w-[42px] inline-flex justify-center items-center py-2`}
              />
              <div
                className={`${themeColor} cursor-pointer flex items-center w-full pr-[16px] text-skin-sideBarMainHeadingTextColor
             ${
               title === item.name || show
                 ? `${
                     themeColor !== "pink"
                       ? "text-skin-sideBarMainHeadingTextColorActive"
                       : "text-[#ccc]"
                   }`
                 : ""
             } ${
               themeColor === "pink" && showIcon
                 ? "group-hover:font-semibold group-hover:text-skin-sidebar-heading-hover/50"
                 : "group-hover:text-skin-sidebar-heading-hover"
             }  justify-between ${
               showIcon
                 ? "block"
                 : `hidden group-hover:block ${
                     themeColor === "pink" ? `${textColorForPinkTheme}` : ""
                   } absolute left-[42px] pl-3 min-w-[175px] border-x border-t border-[#ccc] group-hover:py-[10px] ${
                     themeColor !== "default"
                       ? "group-hover:bg-skin-mainMenuHeadingBackground"
                       : "group-hover:bg-skin-sidebar-main-heading-hover"
                   }`
             }`}
              >
                <p className="text-[13px]">{item.name}</p>
                <i
                  className={`icon-angle-down ${showIcon ? "block" : "hidden"}`}
                />
              </div>
            </div>
            <div
              className={`${
                !showIcon ? "sub-menu-sidebar" : ""
              }  text-xs leading-5 w-full  ${
                themeColor === "default" && showIcon ? "relative" : ""
              } overflow-y-auto scrollbarHide ${
                showIcon
                  ? activeMenu === index && show
                    ? "max-h-[2000px] [transition:max-height_1.5s_ease-in-out]"
                    : "max-h-0 [transition:max-height_1s_cubic-bezier(0,1,0,1)]"
                  : "hidden group-hover:block absolute w-[175px] top-[36px] left-[42px] border border-[#ccc] shadow-[2px_1px_2px_0_transparent]"
              }`}
            >
              {themeColor === "default" && showIcon ? (
                <span className="w-[3px] bg-[#444] h-full absolute top-0 bottom-0 left-0 z-40 overflow-hidden text-transparent">
                  Helper Text
                </span>
              ) : null}
              {item?.subMenus?.map((subMenuItem, index) => {
                let subMenuName: any = Object.entries(
                  accessControlStructure,
                )?.filter(([key]: any) => {
                  if (item.aclName.toLowerCase() === key.toLowerCase()) {
                    return key === item.aclName;
                  }
                  return null;
                })[0][1];

                return (subMenuName?.tabs &&
                  Object.entries(subMenuName?.tabs)
                    ?.map(([key]: any) => {
                      return (
                        key?.toLowerCase() ===
                        subMenuItem?.aclName?.toLowerCase()
                      );
                    })
                    .includes(true)) ||
                  item.name === "Admin" ||
                  item.name === "Product" ? (
                  <NavLink
                    to={subMenuItem.subMenuLink}
                    key={index}
                    onClick={() => {
                      setAdminDashBoardSideBarClicked(subMenuItem.subMenuName);
                    }}
                    className={`${themeColor} last:border-none submenu
                  text-skin-sideBarSubMenuTextColor hover:text-skin-sidebar-sub-heading-hover ${
                    themeColor !== "pink"
                      ? "bg-skin-submenuHeaderBackground"
                      : `before:content-[''] before:w-full before:h-full before:absolute before:top-0 before:left-0 ${submenuBackground} bg-[#303030]`
                  } border-b border-dotted border-skin-sideBarSubMenuBorder w-full flex relative`}
                  >
                    <i
                      className={` ${
                        themeColor !== "black" && themeColor !== "pink"
                          ? "icon-angle-double-right z-10 absolute top-[6px] left-2 hidden bg-white"
                          : ""
                      }`}
                    />
                    <p
                      className={`py-[6px] ml-4 relative text-[13px] ${
                        showIcon && themeColor !== "pink"
                          ? "border-l before:w-[9px] border-[#B1C9E0] border-dashed"
                          : ""
                      } pl-4 before:content-[''] before:absolute before:left-[0px] before:top-[17px] before:[border-top:1px_dashed_#B1C9E0]`}
                    >
                      {subMenuItem.subMenuName}
                    </p>
                  </NavLink>
                ) : null;
              })}
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default MenuDetails;
