import PrivateLayout from "layouts/PrivateLayout";
import ProductsCSV from "modules/Export/Pages/ProductsCSV"
import Account from "modules/User/Pages/Account";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import "fontello/css/fontello.css";
import "./index.css";
import Clients from "modules/Clients/Clients";
import Quotes from "modules/Quotes/Quotes";
import AdminDashboard from "modules/AdminDashboard/AdminDashboard";
import Products from "modules/Products/Products";
import ProductsPriceTable from "modules/Products/Pages/ProductsPriceTable/ProductsPriceTable";
import UserSettings from "modules/Settings/UserSettings";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { userContextData } from "helpers/cache";
import NotFoundPage from "helpers/NotFoundPage";
import { useState } from "react";
import { SALES_QUERIES } from "layouts/Header/Services/Queries/SalesQueries";
import Admin from "./modules/AdminDashboard/Admin";

const App = () => {
  const userDetails: any = useReactiveVar(userContextData);

  let accessControlStructure: any =
    userDetails?.user?.accessControlStructure?.modules;
  const [adminDashBoardSideBarClicked, setAdminDashBoardSideBarClicked] =
    useState("");

  const [
    fetchCommission,
    {
      data: salesCommission,
      loading: salesCommissionLoading,
      refetch: refetchSalesCommission,
    },
  ] = useLazyQuery(SALES_QUERIES, {
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className="3xl:w-[1900px] 3xl:mx-auto">
      <Routes>
        <Route path="/users/sign_in/*" element={<Account />} />
        <Route path="/export/products_csv/*" element={<ProductsCSV />} />
        <Route
          element={
            <PrivateLayout
              setAdminDashBoardSideBarClicked={setAdminDashBoardSideBarClicked}
              fetchCommission={fetchCommission}
              salesCommission={salesCommission}
              refetchSalesCommission={refetchSalesCommission}
              salesCommissionLoading={salesCommissionLoading}
            />
          }
        >
          {
            <Route
              path="/*"
              element={
                accessControlStructure?.quote ? (
                  <Quotes refetchSalesCommission={refetchSalesCommission} />
                ) : accessControlStructure?.client ? (
                  <Clients />
                ) : accessControlStructure?.product ? (
                  <Products />
                ) : accessControlStructure?.admin ? (
                  <AdminDashboard />
                ) : (
                  <NotFoundPage />
                )
              }
            />
          }
          {accessControlStructure?.admin && (
            <Route path="/admin/*" element={<Admin />} />
          )}
          {accessControlStructure?.client && (
            <Route path="/clients/*" element={<Clients />} />
          )}
          {accessControlStructure?.quote && (
            <Route
              path="/quotes/*"
              element={
                <Quotes refetchSalesCommission={refetchSalesCommission} />
              }
            />
          )}
          {accessControlStructure?.product && (
            <Route path="/products/*" element={<Products />} />
          )}
          {accessControlStructure?.product && (
            <Route path="/price_table/index" element={<ProductsPriceTable />} />
          )}

          <Route path="/user_settings/*" element={<UserSettings />} />
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="/*" element={<Navigate to="/users/sign_in/" replace />} />
      </Routes>
    </div>
  );
};

export default App;
