import { FC, Fragment, useState } from "react";
import NewPaymentLink from "./NewPaymentLink";
import PaymentLinkTpls from "./PaymentLinkTpls";
import { QuoteEditData } from "../../../../../types/Quote";
import NewTransaction from "./NewTransaction";

export interface IQuotePaymentPrivilage {
  newPayment?: boolean;
  deletePayment?: boolean;
}

const EditQuotePayment: FC<{
  quote: QuoteEditData;
  accessControlStructure: IQuotePaymentPrivilage;
  refetchQuote: Function;
  stateTax: number | null;
}> = ({ quote, accessControlStructure, refetchQuote, stateTax }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Fragment>
      <div className="grid gap-4">
        {!!accessControlStructure?.newPayment && (
          <>
            <div className="max-w-[875px] w-full">
              <NewTransaction
                quote={quote}
                refetchQuote={refetchQuote}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
            <div className="max-w-[875px] w-full">
              <NewPaymentLink
                quote={quote}
                refetchQuote={refetchQuote}
                loading={loading}
                setLoading={setLoading}
                stateTax={stateTax}
              />
            </div>
          </>
        )}
        {!!quote?.quotePaymentLinkTpls?.length && (
          <div className="w-full">
            <PaymentLinkTpls
              tpls={quote?.quotePaymentLinkTpls}
              refetchQuote={refetchQuote}
              accessControlStructure={accessControlStructure}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default EditQuotePayment;
