import React, { FC } from "react";
import NormalButton from "components/Buttons/NormalButton";
import ProductCheckbox from "../ProductCheckbox";

const ProductsListEditModalOptions: FC<{
  editProductList?: number;
  productOptions: {
    id: number;
    name: string;
    children: [] | any[];
  }[];
  setValue: Function;
  register: Function;
  product:
    | {
        coverImageFile: string;
        coverImageName: string;
        coverImageUid: string;
        description: any;
        id: number;
        link: any;
        name: string | null;
        price: string | null;
        priceMethod: string | null;
        priceOffset: string | null;
        productCategory: {
          id: number;
          name: string | null;
        };
        productOptionsProducts: any[];
      }
    | undefined;
  loading: boolean;
}> = ({
  editProductList,
  productOptions,
  setValue,
  register,
  product,
  loading,
}) => {
  return (
    <div className={`${editProductList ? "block p-4" : "hidden"}`}>
      <p className="text-[13px] leading-5">Options:</p>
      {productOptions?.map((productOption, index) => {
        return (
          <div
            className={`space-y-1 ${
              productOption?.children?.length > 0 ? "block" : "hidden"
            }`}
            key={index}
          >
            <p className="text-[13px] leading-5 ml-[30px]">
              {productOption?.name}
            </p>
            {productOption?.children?.length > 0
              ? productOption?.children?.map((productOptionChildren, index) => {
                  setValue(
                    `productOption.${productOptionChildren?.id}`,
                    product?.productOptionsProducts
                      ?.map((options) => options?.productOption?.id)
                      .includes(productOptionChildren?.id)
                      ? true
                      : false,
                  );
                  return (
                    <div
                      className="flex w-full text-[13px] leading-5 font-bold justify-between pl-16"
                      key={index}
                    >
                      <label
                        htmlFor={productOptionChildren?.name}
                        className="max-w-[160px] truncate md:max-w-[250px] lg:max-w-[300px]"
                      >
                        {productOptionChildren?.name}
                      </label>
                      <ProductCheckbox
                        name={`productOption.${productOptionChildren?.id}`}
                        setValue={setValue}
                        register={register}
                        defaultChecked={
                          product?.productOptionsProducts
                            ?.map((options) => options?.productOption?.id)
                            .includes(productOptionChildren?.id)
                            ? true
                            : false
                        }
                      />
                    </div>
                  );
                })
              : null}
          </div>
        );
      })}
      <NormalButton
        buttonName="Save"
        className={`border border-black rounded px-2 mt-5 mx-auto hover:bg-[rgb(239,239,239)] ${
          editProductList ? "block lg:hidden" : "hidden"
        }`}
        buttonType="submit"
        icon={loading ? "icon-spin4" : ""}
        loading={loading}
      />
    </div>
  );
};

export default ProductsListEditModalOptions;
