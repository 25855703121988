export const menuList = [
  {
    name: "Clients",
    iconName: "icon-users",
    link: "/clients",
    aclName: "client",
    subMenus: [
      {
        subMenuName: "List Clients",
        subMenuLink: "/clients/list_clients",
        aclName: "listClient",
      },
      {
        subMenuName: "Clients Pool list",
        subMenuLink: "/clients/clients_pool_list",
        aclName: "poolList",
      },
      {
        subMenuName: "New Client",
        subMenuLink: "/clients/new_client",
        aclName: "newClient",
      },
    ],
  },
  {
    name: "Quotes",
    iconName: "icon-bookmark",
    link: "/quotes",
    aclName: "quote",
    subMenus: [
      {
        subMenuName: "History",
        subMenuLink: "/quotes/history",
        aclName: "history",
      },
      {
        subMenuName: "Active Leads",
        subMenuLink: "/quotes/active_leads",
        aclName: "activeLeads",
      },
      {
        subMenuName: "Processing",
        subMenuLink: "/quotes/processing",
        aclName: "processing",
      },
      {
        subMenuName: "New Quote",
        subMenuLink: "/quotes/new_quotes",
        aclName: "newQuote",
      },
    ],
  },
  {
    name: "Product",
    iconName: "icon-list-alt",
    link: "/products",
    aclName: "product",
    subMenus: [
      { subMenuName: "Products", subMenuLink: "/products", aclName: "history" },
      {
        subMenuName: "Products Category",
        subMenuLink: "/products/edit_tree",
        aclName: "productCategory",
      },
      {
        subMenuName: "Products Option",
        subMenuLink: "/products/options",
        aclName: "productOption",
      },
      {
        subMenuName: "Products Price Table",
        subMenuLink: "/price_table/index",
        aclName: "productPrizeTable",
      },
    ],
  },
  {
    name: "Admin",
    iconName: "icon-gauge",
    link: "/admin",
    aclName: "admin",
    subMenus: [
      { subMenuName: "Dashboard", subMenuLink: "/admin", aclName: "" },
      { subMenuName: "Countries", subMenuLink: "/admin/country", aclName: "" },
      {
        subMenuName: "Crm settings",
        subMenuLink: "/admin/crm_settings",
        aclName: "",
      },
      {
        subMenuName: "Domain ignore lists",
        subMenuLink: "/admin/domain_ignore_list",
        aclName: "",
      },
      { subMenuName: "Expenses", subMenuLink: "/admin/expense", aclName: "" },
      {
        subMenuName: "Highlights",
        subMenuLink: "/admin/highlight",
        aclName: "",
      },
      {
        subMenuName: "Ignore word searches",
        subMenuLink: "/admin/ignore_word_search",
        aclName: "",
      },
      {
        subMenuName: "Industry tags",
        subMenuLink: "/admin/industry_tag",
        aclName: "",
      },
      {
        subMenuName: "Old user new users",
        subMenuLink: "/admin/old_user_new_user",
        aclName: "",
      },
      { subMenuName: "Products", subMenuLink: "/admin/product", aclName: "" },
      {
        subMenuName: "Quick quote additional infos",
        subMenuLink: "/admin/quick_quote_additional_info",
        aclName: "",
      },
      {
        subMenuName: "Quick quote lines",
        subMenuLink: "/admin/quick_quote_line",
        aclName: "",
      },
      { subMenuName: "Roles", subMenuLink: "/admin/role", aclName: "" },
      { subMenuName: "States", subMenuLink: "/admin/state", aclName: "" },
      {
        subMenuName: "System errors",
        subMenuLink: "/admin/system_error",
        aclName: "",
      },
      {
        subMenuName: "Time zones",
        subMenuLink: "/admin/time_zones",
        aclName: "",
      },
      { subMenuName: "Users", subMenuLink: "/admin/user", aclName: "" },
      {
        subMenuName: "User email data",
        subMenuLink: "/admin/user_email_data",
        aclName: "",
      },
      {
        subMenuName: "User settings",
        subMenuLink: "/admin/user_settings",
        aclName: "",
      },
      {
        subMenuName: "Merge Clients",
        subMenuLink: "/admin/utils/merge-clients",
        aclName: "",
      },
	  
    ],
  },
];
