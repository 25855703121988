import { client } from "Services/ClientConfig";

const Logout = () => {
  window.location.replace("/users/sign_in");
  localStorage.clear();
  sessionStorage.clear();
  client.resetStore();
};

export default Logout;
