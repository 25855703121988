import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { QUOTES_LOGS_BY_USER } from "../../Services/Queries/Queries";
import { Params } from "../../../../types/Main";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { convertDateToUSFormat, isNumber } from "../../../../helpers/utils";
import { Link } from "react-router-dom";

const EditQuoteLogs: FC<{ quoteId: number }> = ({ quoteId }) => {
  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "dateCreated",
        label: "Date_created",
        sortable: false,
      },
      {
        name: "user",
        label: "User",
        sortable: false,
      },
      {
        name: "data",
        label: "Data",
        sortable: false,
      },
    ],
    [],
  );

  const [fetchLogs] = useLazyQuery(QUOTES_LOGS_BY_USER, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const [time, setTime] = useState<number>(0);

  const userDetails: any = useReactiveVar(userContextData);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const [countPages, setCountPages] = useState(0);
  const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});

  const [isLoadingLogs, setIsLoadingLogs] = useState(false);

  useEffect(() => {
    isLoadingLogs && setTime((start) => +new Date());
    !isLoadingLogs && setTime((start) => +new Date() - start);
  }, [isLoadingLogs]);

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  const accessAdminPages = useMemo(() => userDetails?.user?.accessControlStructure?.modules?.admin === 'accessToAll', [userDetails])

  useEffect(() => {
    const currentParams = {
      pageSize,
      currentPage,
      quoteId,
    };

    onChangeParamsCallback(
      prevFetchSettings,
      currentParams,
      (newParams) => setPrevFetchSettings(newParams),
      () => onFetchLogs(),
    );
  }, [pageSize, currentPage, quoteId, prevFetchSettings]);

  const onErrorFetch = () => {
    setCountPages(0);
    setDataCollection([]);
  };

  const onFetchLogs = async () => {
    if (!quoteId) return;
    setIsLoadingLogs(true);

    await fetchLogs({
      variables: {
        quoteId,
        limit: pageSize,
        page: currentPage,
      },
    })
      .then((res) => {
        const { data } = res;

        if (!data) {
          onErrorFetch();
          return;
        }
        const { quoteLogs } = data;

        if (!quoteLogs) {
          onErrorFetch();
          return;
        }

        const { dataCollection, totalNumberOfItems } = quoteLogs;

        if (!dataCollection || !totalNumberOfItems) {
          onErrorFetch();
          return;
        }

        const newCountPages = Math.ceil(totalNumberOfItems / pageSize);

        setDataCollection(dataCollection);
        setCountPages(newCountPages);
      })
      .finally(() => {
        setIsLoadingLogs(false);
      });
  };

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    dataCollection.forEach((dataCollectionItem, index) => {
      const { id, createdAt, user, data, itemType, itemId, quote } =
        dataCollectionItem;

      const firstName = user?.firstName;
      const lastName = user?.lastName;

      let userName = firstName || "";

      if (lastName) {
        userName = userName.length ? userName.concat(` ${lastName}`) : lastName;
      }

      if (!userName) userName = `User with ID = ${user?.id}`;

      let itemData = [];

      itemData.push(`Change #${quote?.id || "-"} ${itemType || "-"}:`);

      const parsedData = JSON.parse(data);

      let keys: string[] = [];

      if (parsedData && typeof parsedData === "object")
        keys = Object.keys(parsedData);
      else itemData.push(`- Nothing change`);

      keys.forEach((key) => {
        const values = parsedData[key];
        const prevValue = values[0]?.toString();
        const newValue = values[1]?.toString();

        const isNeedHiddenLogs = !prevValue && !newValue

        if (prevValue !== newValue && !isNeedHiddenLogs) {
          itemData.push(`${key}: ${prevValue || '""'} ==> ${newValue || '""'}`);
        }
      });

      const getUserLink = () => {
        if (userName && user?.id && accessAdminPages) {
          return <Link to={`/admin/user/${user.id}/info`} target="_blank">{userName}</Link>
        }

        return userName || "-"
      }
      
      if (!itemData.length) return

      currentChildrenForTable.push({
        id: id ?? index,
        row: {
          dateCreated: createdAt ? convertDateToUSFormat(createdAt) : "-",
          user: getUserLink(),
          data: (
            <Fragment>
              {itemData.map((itemDataItem, index) => {
                return (
                  <div key={`quote-logs-${id}-${index}`}>{itemDataItem}</div>
                );
              })}
            </Fragment>
          ),
        },
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);

  return (
    <Fragment>
      <TableWithHeaderNew
        heading={tableHeading}
        header={`Quote history: ${time} ms`}
        setIsNeedUpdateTableData={() => {}}
        loading={isLoadingLogs}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        children={childrenForTable}
      />
    </Fragment>
  );
};

export default EditQuoteLogs;
