import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  PriceDataList, PriceTable,
  ProductEditDataWithIndex,
  ProductPrices,
  ProductWithCategory,
} from "../../../../types/Product";
import SearchBox from "../../../../components/Form/El/SearchBox";
import Input from "../../../../components/Form/El/Input";
import { useLazyQuery } from "@apollo/client";
import { PRODUCT_OPTION_PRIZES } from "../../Services/Queries/Queries";
import { setNotification } from "../../../../helpers/cache";
import Select from "../../../../components/Form/El/Select";
import { cloneDeep, isArray } from "lodash";
import { onChangeParamsCallback } from "helpers/Main";
import { Params } from "types/Main";

const EditQuoteQuoteInfoTableItemDescription: FC<{
  product: ProductEditDataWithIndex;
  productsWithCategory: ProductWithCategory[];
  isLoadingProductsWithCategory?: boolean;
  setProductValue: (
    index: number,
    data: Partial<ProductEditDataWithIndex>,
  ) => void;
  setProductPricesList: (
    index: number | undefined,
    productId: number,
    data: PriceDataList | number,
  ) => void;
}> = ({
  product,
  productsWithCategory,
  isLoadingProductsWithCategory,
  setProductValue,
  setProductPricesList,
}) => {
  const [fetchProductPrizes, { loading: isLoadingProductsOptionPrices }] =
    useLazyQuery(PRODUCT_OPTION_PRIZES, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      onCompleted: (data) => {
        if (data?.productOptionsPrices) {
          setProductPrices(data?.productOptionsPrices);
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    });

  const onFetchProductPrizes = useCallback(async () => {
    await fetchProductPrizes({
      variables: {
        productId: product.productId,
      },
    });
  }, [product, fetchProductPrizes]);

  const [productPrices, setProductPrices] = useState<ProductPrices>({
    options: {},
    priceData: [],
    priceTables: [],
  });
  const [currentOptionArray, setCurrentOptionArray] = useState<number[]>([]);
  const [isNeedForceUpdateDescription, setIsNeedForceUpdateDescription] = useState(false);
  const [prevProductValue, setPrevProductValue] = useState<Params>({})

  const productPricePriceData = useMemo(
    () => productPrices.priceData,
    [productPrices],
  );

  const productPricePriceTables = useMemo(
    () => productPrices.priceTables,
    [productPrices],
  );

  const productPriceOption = useMemo(
      () => {
        return productPrices.options;
      },
      [productPrices],
  );

  const currentMainProductIndexInOptionString = useMemo(() => {
    const priceTableId = product.priceTableId;
    let currentPriceTable: PriceTable|undefined = undefined;

    if (typeof priceTableId === 'number') {
      currentPriceTable = productPricePriceTables.find(el => +el.id === priceTableId);
    }

    let currentMainProductId: number | undefined = undefined;

    if (currentPriceTable && currentPriceTable.productOption) {
      currentMainProductId = currentPriceTable.productOption.id;
    }

    const productPriceOptionKey = Object.keys(productPriceOption);
    let currentIndex = 0;

    if (typeof currentMainProductId === 'number') {
      currentIndex =  productPriceOptionKey.indexOf(currentMainProductId.toString())
    }

    return currentIndex;
  }, [productPrices, product.priceTableId, productPriceOption])

  useEffect(() => {
    setProductValue(product.index, {
      currentMainProductIndexInOptionString: currentMainProductIndexInOptionString || 0,
    })
  }, [currentMainProductIndexInOptionString]);

  const prettifyStringProductOptions = useCallback(
    (stringValues: string) => {
      const lastIndexSign = stringValues.lastIndexOf("_");

      if (lastIndexSign === stringValues.length - 1) {
        stringValues = stringValues.slice(0, lastIndexSign);
      }

      setProductValue(product.index, {
        productOptionString: stringValues,
      });
    },
    [product, setProductValue],
  );

  useEffect(() => {
    const productPriceOptionEntries = Object.entries(productPriceOption);
    const productPriceOptionLength = productPriceOptionEntries?.length;
    const currentOptionArray = product.productOptionString?.split("_");
    const newArrayOptions: number[] = [];

    if (!isLoadingProductsOptionPrices && !!productPriceOptionLength) {
      productPriceOptionEntries.forEach(([key, value], index) => {
        if (
          currentOptionArray?.[index] &&
          value.some((el) => el.id === +currentOptionArray[index])
        ) {
          newArrayOptions.push(+currentOptionArray[index]);
          return;
        }
        newArrayOptions.push(value[0].id);
      });
    }

    setCurrentOptionArray(newArrayOptions);
  }, [productPriceOption, isLoadingProductsOptionPrices, product.productOptionString]);

  useEffect(() => {
    if (!!currentOptionArray.length) {
      const stringValues = currentOptionArray.toString().replaceAll(",", "_");
      prettifyStringProductOptions(stringValues);
    }
  }, [currentOptionArray]);

  useEffect(() => {
    if (product.productId) {
      onFetchProductPrizes();
    }
  }, [product.productId]);

  useEffect(() => {
    const currentProductValue = {
      productOptionString: product.productOptionString,
      productId: product.productId,
    };

    onChangeParamsCallback(
        prevProductValue,
        currentProductValue,
        (newProductValue) => {
          setPrevProductValue(newProductValue)
        },
        () => {
          const isValidNewValue = !!currentProductValue.productOptionString && !!currentProductValue.productId;
          const isProductOptionStringChanged = !!prevProductValue.productOptionString && prevProductValue.productOptionString !== currentProductValue.productOptionString
          const isProductIdChanged = !!prevProductValue.productId && prevProductValue.productId !== currentProductValue.productId

          if (isValidNewValue && (isProductIdChanged || isProductOptionStringChanged)) {
            setIsNeedForceUpdateDescription(true)
          }
        }
    )
  }, [product.productOptionString, product.productId, prevProductValue]);

  useEffect(() => {
    if (
      (!!currentOptionArray.length && !product.productOptionString) ||
      (!!product.description && !isNeedForceUpdateDescription) ||
      !product.productId ||
      isLoadingProductsOptionPrices ||
      isLoadingProductsWithCategory ||
      (!!productPriceOption &&
        !!Object.keys(productPriceOption).length &&
        Object.keys(productPriceOption).length !== currentOptionArray.length) ||
      (!Object.keys(productPriceOption).length && !isArray(productPriceOption))
    )
      return;

    const productName = productsWithCategory.find(
      (el) => el.id === product.productId,
    )?.description;
    const optionsName: string[] = [];
    const productPriceOptionValue = Object.values(productPriceOption);

    currentOptionArray.forEach((optionId, index) => {
      const optionName = productPriceOptionValue[index]?.find(
        (el) => el.id === optionId,
      );

      if (optionName) {
        optionsName.push(optionName.title);
      }
    });

    const currentDsc = `${productName}${
      optionsName.length ? ", " : ""
    }${optionsName.toString().replaceAll(",", ", ")}`;

    if (currentDsc !== "undefined") {
      setProductValue(product.index, { description: currentDsc });
    }

    if (isNeedForceUpdateDescription) {
      setIsNeedForceUpdateDescription(false)
    }
  }, [
    product.productId,
    product.productOptionString,
    currentOptionArray,
    productPriceOption,
    isLoadingProductsOptionPrices,
    isLoadingProductsWithCategory,
    setProductValue,
    isNeedForceUpdateDescription
  ]);

  useEffect(() => {
    if (!productPricePriceData || !product.productId) return;
    const arrayData = isArray(productPricePriceData);

    if (arrayData && !!productPricePriceData?.length) {
      const currentProductPriceData = {};
      productPricePriceData.forEach((el) => {
        Object.assign(currentProductPriceData, el);
      });

      setProductPricesList(
        product.isNewProduct ? product.index : undefined,
        product.productId,
        currentProductPriceData,
      );
    }

    if (!arrayData) {
      setProductPricesList(
        product.isNewProduct ? product.index : undefined,
        product.productId,
        productPricePriceData.unitPrice || 0,
      );
    }
  }, [productPricePriceData]);

  return (
    <div
      className={`flex gap-2 ${
        productPriceOption && Object.keys(productPriceOption).length
          ? "flex-col"
          : ""
      }`}
    >
      <div className="flex gap-2">
        <SearchBox
          width={"300px"}
          value={product.productId || 0}
          onChange={(value) => {
            if (+value !== product.productId) {
              setProductValue(product.index, {
                productId: +value,
                productOptionString: "",
                description: "",
                priceTableId: null,
                isNewProduct: true,
              });
            }
          }}
          options={productsWithCategory.map((el) => ({
            id: el.id,
            name: `${el.category}, \n${el.description}`,
          }))}
          loading={isLoadingProductsWithCategory}
          hiddenButton={true}
        />
        {Object.entries(productPriceOption)?.map(([key, value], index) => (
          <Select
            value={currentOptionArray[index]}
            optionsGroups={
              [
                {
                  options: value.sort((a, b) => {
                    if (a.lft < b.lft) {
                      return -1;
                    }

                    if (a.lft > b.lft) {
                      return 1;
                    }

                    return 0
                  }).map((el) => ({
                    id: el.id,
                    value: el.title,
                  })),
                },
              ] || []
            }
            onChange={(value) => {
              let currentValue = value;

              if (typeof currentValue === "string") {
                currentValue = +currentValue;
              }

              if (currentValue && !isNaN(currentValue)) {
                const values = cloneDeep(currentOptionArray);

                values[index] = currentValue;
                setCurrentOptionArray(values);
              }
            }}
            name={`select-product-description-options-${Math.random()}`}
            key={`select-product-description-options-${Math.random()}`}
            loading={isLoadingProductsOptionPrices}
          />
        ))}
        {!!productPricePriceTables.length && (
          <Select
            value={product.priceTableId || undefined}
            defaultValue={productPricePriceTables[0].id}
            optionsGroups={
              [
                {
                  options: productPricePriceTables.map((el) => ({
                    id: el.id,
                    value: el.name,
                  })),
                },
              ] || []
            }
            onChange={(value) => {
              let currentValue = value;

              if (typeof currentValue === "string") {
                currentValue = +currentValue;
              }

              if (currentValue && !isNaN(currentValue)) {
                setProductValue(product.index, {
                  priceTableId: currentValue,
                });
              }
            }}
            name={`select-product-description-table-id-${Math.random()}`}
            loading={isLoadingProductsOptionPrices}
          />
        )}
      </div>

      <Input
        value={product.description || ""}
        onChange={(value) => {
          setProductValue(product.index, {
            description: value.toString(),
          });
        }}
        name={`input-product-description-${Math.random()}`}
        className="flex-shrink"
      />
    </div>
  );
};

export default EditQuoteQuoteInfoTableItemDescription;
