import { gql } from "@apollo/client";

export const USERS_QUERY = gql`
  query UsersQuery {
    users {
      id
      userName
      firstName
      lastName
      isDeleted
    }
  }
`;

export const COUNTRIES_QUERY = gql`
  query CountriesQuery {
    countries {
      id
      name
    }
  }
`;

export const GENDERS_QUERY = gql`
  query GendersQuery {
    genders {
      label
      value
    }
  }
`;

export const CLIENT_ACCESS_LEVEL_QUERY = gql`
  query ClientAccessLevelsQuery {
    clientAccessLevels {
      label
      value
    }
  }
`;

export const INDUSTRY_TAGS_QUERY = gql`
  query IndustryTagsQuery {
    industryTags {
      id
      name
    }
  }
`;

export const STATES_QUERY = gql`
  query StatesQuery {
    states {
      id
      name
      tax
    }
  }
`;

export const SHIPPIND_ADDRESS_TYPES_QUERY = gql`
  query leadSourceQuery {
    shippingAddressTypes {
      label
      value
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    userByToken {
      id
      email
      rememberCreatedAt
      signInCount
      currentSignInAt
      lastSignInAt
      currentSignInIp
      lastSignInIp
      createdAt
      updatedAt
      login
      userName
      firstName
      lastName
      phone
      emailSignature
      isDeleted
      roles {
        name
      }
      userSettings {
        id
        name
        value
      }
      accessControlStructure
    }
  }
`;

export const LEAD_SOURCE_QUERY = gql`
  query leadSourceQuery {
    leadSource {
      label
      value
    }
  }
`;

export const QUOTE_LEAD_STATUSES_QUERY = gql`
  query configQuotesLeadStatusesQuery {
    configQuotesLeadStatuses {
      id
      name
    }
  }
`;

export const QUOTE_STATUSES_QUERY = gql`
  query configQuotesStatusesQuery {
    configQuotesStatuses {
      id
      name
      position
    }
  }
`;

export const PAYMENT_TYPES_QUERY = gql`
  query paymentTypesQuery {
    paymentTypes {
      label
      value
    }
  }
`;

export const SHIPPING_METHODS_QUERY = gql`
  query shippingMethodsQuery {
    shippingMethods {
      label
      value
    }
  }
`;

export const SUPPLIERS_QUERY = gql`
  query supplierssQuery {
    suppliers {
      id
      userName
    }
  }
`;

export const PAYMENT_STATUSSES_QUERY = gql`
  query paymentStatusesQuery {
    paymentStatuses {
      label
      value
    }
  }
`;

export const EXPENSES_QUERY = gql`
  query expensesQuery {
    expenses {
      id
      name
    }
  }
`;

export const QUOTE_IMAGE_TYPES_QUERY = gql`
  query quoteImageTypesQuery {
    quoteImageTypes {
      label
      value
    }
  }
`;

export const SELECT_QUERY = gql`
  query SelectQuery {
    userByToken {
      id
      email
      rememberCreatedAt
      signInCount
      currentSignInAt
      lastSignInAt
      currentSignInIp
      lastSignInIp
      createdAt
      updatedAt
      login
      userName
      firstName
      lastName
      phone
      emailSignature
      isDeleted
      roles {
        name
      }
      userSettings {
        id
        name
        value
      }
      accessControlStructure
    }
    users {
      id
      userName
      firstName
      lastName
      isDeleted
    }
    countries {
      id
      name
    }
    genders {
      label
      value
    }
    clientAccessLevels {
      label
      value
    }
    industryTags {
      id
      name
    }
    states {
      id
      name
      tax
    }
    shippingAddressTypes {
      label
      value
    }
    leadSource {
      label
      value
    }
    configQuotesLeadStatuses {
      id
      name
    }
    configQuotesStatuses {
      id
      name
      position
    }
    paymentTypes {
      label
      value
    }
    shippingMethods {
      label
      value
    }
    suppliers {
      id
      userName
    }
    paymentStatuses {
      label
      value
    }
    expenses {
      id
      name
    }
    quoteImageTypes {
      label
      value
    }
  }
`;

export const QUOTE_MISC_QUERY_SUPPLIER = gql`
  query QuoteMiscQuerySupplier ($quoteId: Int!) {
    quoteMessagesByQuote(quoteId: $quoteId) {
      id
      text
      readable
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
    }
  }
`;

export const QUOTE_MISC_QUERY = gql`
  query QuoteMiscQuery($quoteId: Int!) {
    quoteExpensesByQuote(quoteId: $quoteId) {
      id
      value
      quote {
        id
      }
      expense {
        id
        name
      }
    }
    quoteFilesByQuote(quoteId: $quoteId) {
      id
      title
      comment
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
      fileUid
      fileURL
      fileName
    }
    quoteCommentsByQuote(quoteId: $quoteId) {
      id
      text
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
    }
    quoteMessagesByQuote(quoteId: $quoteId) {
      id
      text
      readable
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
    }
    quoteCreditsByQuote(quoteId: $quoteId) {
      id
      credit
      quote {
        id
      }
    }
  }
`;
