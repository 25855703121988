import React from "react";

const TextArea: React.FC<{
  register: Function;
  name: string;
  errors: any;
  defaultText?: string;
  disable?: boolean;
  required?: boolean;
  placeholderText?: string;
  className?: string;
  maxLength?: number;
  classForTextArea?: string;
  onChange?: Function;
  cols?: number;
  rows?: number;
  onKeyPress?: Function;
}> = ({
  register,
  name,
  errors,
  defaultText,
  disable,
  required,
  placeholderText,
  className,
  maxLength,
  classForTextArea,
  onChange,
  cols,
  rows,
  onKeyPress,
}) => {
  return (
    <div className={`w-full ${className ? className : ""}`}>
      <div className="w-full relative group">
        <textarea
          id={name}
          placeholder={placeholderText}
          defaultValue={defaultText}
          disabled={disable}
          cols={cols ? cols : 2}
          rows={rows ? rows : 3}
          onKeyPress={onKeyPress ? onKeyPress : undefined}
          maxLength={maxLength ? maxLength : ""}
          className={`w-full min-h-[30px] border border-[#cccccc]  focus:outline-none peer px-[6px] py-1 placeholder:text-sm placeholder:leading-8 placeholder:text-[#aaaaaa] text-sm leading-8 text-[#838281] appearance-none ${
            classForTextArea
              ? classForTextArea
              : "shadow-[inset_0_1px_1px_rgb(0_0_0/8%)] focus:border-[rgba(82,168,236,0.8)] transition-[box-shadow] duration-700 ease-linear focus:shadow-[inset_0_1px_1px_rgb(0_0_0/8%),0_0_8px_rgb(82_168_236/60%)] rounded"
          }`}
          {...register(name, {
            required: required ? "This is required field." : false,
            onChange: onChange,
          })}
        />
      </div>
      <div className={`w-full ${errors[name]?.message ? "block" : "hidden"}`}>
        {errors[name] ? (
          <span className="text-[#FF647C] text-xs leading-[15px]">
            {errors[name].message}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};

export default TextArea;
